import { useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect } from "react";
import { useParams, Navigate, NavLink } from "react-router-dom";
import Header from "../../components/Business/Header/Header";
import CloseIconBtn from "../../components/GlobalComponents/Buttons/CloseIconBtn/CloseIconBtn";
import AboutSection from "../../components/GlobalComponents/Sections/AboutSection/AboutSection";
import Comments from "../../components/GlobalComponents/Sections/Comments/Comments";
import WhatWeOffer from "../../components/GlobalComponents/Sections/WhatWeOffer/WhatWeOffer";
import Backdrop from "../../components/GlobalComponents/UI/Backdrop/Backdrop";
import business from "../../graphql/queries/pages/business";
import { editView, isAdmin, isEdit } from "../../utils/cache";
import styles from "./EditBusiness.module.css";
import { useOutletContext } from "react-router-dom";
import { isBusinessCompleted, warningToastHandler } from "../../utils";

const EditBusiness = () => {
  const { id } = useParams();
  const showSideBar = useOutletContext();
  const { data, loading, error, fetchMore } = useQuery(business, {
    variables: { id },
  });
  const isEditing = useReactiveVar(editView);
  const admin = useReactiveVar(isAdmin);

  useEffect(() => {
    isEdit(true);
    return () => {
      isEdit(false);
      editView(false);
    };
  }, []);
  const toggleEditHandler = () => {
    editView(!isEditing);
  };

  if (!data?.business && !loading) return <Navigate to='/business-dashboard' />;

  if (
    !admin &&
    !isBusinessCompleted(data?.business?.onboardingState) &&
    !loading
  ) {
    return <Navigate to='/business-dashboard' />;
  }

  return (
    <div
      className={`${styles.businessPage} ${
        showSideBar ? styles.withSidebar : styles.noSidebar
      }`}
    >
      <Backdrop show={isEditing} noPortal={true}>
        <CloseIconBtn
          clickHandler={toggleEditHandler}
          variant={styles.closeBtn}
        />
      </Backdrop>
      <Header businessData={data?.business} loading={loading} isEditPage />
      <div className={styles.middleContainer}>
        <div className={styles.aboutOwner}>
          <AboutSection
            data={data?.business}
            loading={loading}
            error={error}
            variant={styles.aboutSection}
            isEdit
          />
        </div>
        <div className={styles.rightContainer}>
          <Comments
            data={data?.business}
            loading={loading}
            error={error}
            variant={styles.comments}
            fetchMore={fetchMore}
          />
        </div>
      </div>

      {data?.business?.supplies?.data?.length >= 0 && (
        <WhatWeOffer
          items={data?.business?.supplies?.data?.slice(0, 5)}
          loading={loading}
          error={error}
          title='What we offer'
          isSeeAll={true}
          sectionClass={styles.whatWeOfferSection}
          isEditBusiness
        />
      )}
    </div>
  );
};

export default EditBusiness;
