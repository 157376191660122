import React, { useEffect, useState } from "react";
import styles from "./ShareOptions.module.css";
import PropTypes from "prop-types";
import closeIcons from "../../../../assets/images/close.svg";
import twitter from "../../../../assets/images/twitter.png";
import facebook from "../../../../assets/images/facebook.png";
import email from "../../../../assets/images/email.png";
import Modal from "../Modal/Modal";

const ShareOptions = (props) => {
  const { closeHandler, link, show } = props;
  const [showNotification, setShowNotification] = useState(false);

  const copyHandler = () => {
    navigator.clipboard.writeText(link);
    setShowNotification(true);
  };

  useEffect(() => {
    const hideNotification = setTimeout(() => setShowNotification(false), 1000);
    return () => clearTimeout(hideNotification);
  }, [showNotification]);

  return (
    <Modal variant={styles.sharePopup}>
      <header className={styles.popUpHeader}>
        <h6 className={styles.title}>Share</h6>
        <img
          src={closeIcons}
          className={styles.close}
          onClick={closeHandler}
          alt='close'
        />
      </header>
      <div className={styles.options}>
        <a
          target='_blank'
          href={`https://twitter.com/intent/tweet?text=${link}`}
          rel='noreferrer'
          onClick={closeHandler}
        >
          <img src={twitter} className={styles.twitter} alt='twitter' />
        </a>
        <a
          target='_blank'
          href={`https://www.facebook.com/sharer/sharer.php?u=${link}&amp;src=sdkpreparse`}
          rel='noreferrer'
          onClick={closeHandler}
        >
          <img src={facebook} className={styles.facebook} alt='facebook' />
        </a>
        <a
          href={`mailto:?subject=I wanted you to see this product&amp;body=Check out this product ${link}.`}
          title='Share by Email'
          onClick={closeHandler}
        >
          <img src={email} className={styles.email} alt='email' />
        </a>
      </div>
      <div className={styles.customLink}>
        <h6 className={styles.link}>
          {link}
        </h6>
        <button className={styles.copy} type='button' onClick={copyHandler}>
          copy
        </button>
      </div>
      <p
        style={
          showNotification
            ? { transform: "translateY(10px)", opacity: "1" }
            : { opacity: "0" }
        }
        className={styles.notification}
      >
        Link copied
      </p>
    </Modal>
  );
};

export default ShareOptions;

ShareOptions.propTypes = {
  closeHandler: PropTypes.func,
  link: PropTypes.string,
  show: PropTypes.bool,
};
