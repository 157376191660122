import { useQuery, useReactiveVar } from "@apollo/client";
import React from "react";
import regStyles from "../EditorPicksSection/EditorPicksSection.module.css";
import adminStyles from "../EditorPicksSection/AdminStyles.module.css";
import recentlyAdded from "../../../../graphql/queries/recentlyAdded";
import PropTypes from "prop-types";
import ErrorComponent from "../../UI/ErrorComponent/ErrorComponent";
import SectionLoader from "../../UI/SectionLoader/SectionLoader";
import { NavLink } from "react-router-dom";
import ImageShapes from "../../UI/ImageShapes/ImageShapes";
import UpVoteButton from "../../Buttons/UpVoteButton/UpVoteButton";
import AddToCollectionButton from "../../Buttons/AddToCollectionButton/AddToCollectionButton";
import { BUSINESS_TYPES, ITEM_TYPES } from "../../../../constants";
import { isAdmin } from "../../../../utils/cache";

const RecentlyListed = (props) => {
  const { pageName } = props;
  const { data, error, loading } = useQuery(recentlyAdded, {
    variables: { page: pageName },
  });
  const { recentlyAdded: items } = data ?? {};
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;

  const isEcommerce = pageName === BUSINESS_TYPES.ecommerce;

  const itemPriceAddress = (item) => {
    if (item?.price) {
      return `$${item?.price?.toFixed(2)}`;
    } else if (!isEcommerce) {
      return `${item?.city?.name}, ${item?.state?.abbr}`;
    } else {
      return "";
    }
  };

  return (
    <section className={styles.recentlyListed}>
      <div className={styles.editorPicksContainer}>
        <div className={styles.header}>
          <h3 className={styles.sectionTitle}>Recently Listed</h3>
        </div>
        <div className={styles.picks}>
          {error && <ErrorComponent />}
          {loading && <SectionLoader itemsNumber={4} />}
          {items?.map((pick, i) => (
            <NavLink
              to={`/${pick?.__typename?.toLowerCase()}/${pick?.id}`}
              key={`${pick?.name}-${i}`}
              className={styles.pick}
            >
              <div className={styles.imgContainer}>
                <ImageShapes
                  variant={styles.pickImg}
                  imgSrc={pick?.featuredImage?.path}
                  alt={pick?.product}
                />
                <UpVoteButton
                  variant={styles.upVote}
                  voteCount={pick?.votes}
                  itemType={pick?.__typename?.toUpperCase()}
                  itemId={+pick?.id}
                  voted={pick?.isVoted}
                  item={pick}
                />
                <AddToCollectionButton
                  variant={styles.addToCollection}
                  addedToCollection={pick?.isBookmarked}
                  itemType={pick?.__typename?.toUpperCase()}
                  itemId={+pick?.id}
                  item={pick}
                />
              </div>
              {pick?.__typename === ITEM_TYPES.BUSINESS ? (
                <>
                  <p className={styles.business}>{pick?.name}</p>
                  <p className={styles.product}>
                    {pick?.categories?.map((cat, i) => {
                      if (i !== pick?.categories?.length - 1)
                        return `${cat.name}, `;
                      else return `${cat.name}`;
                    })}
                  </p>
                </>
              ) : (
                <>
                  <p className={styles.business}>{pick?.business?.name}</p>
                  <p className={styles.product}>{pick?.name}</p>
                </>
              )}
              <p className={styles.price}>{itemPriceAddress(pick)}</p>
            </NavLink>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RecentlyListed;
RecentlyListed.propTypes = {
  pageName: PropTypes.string,
};
