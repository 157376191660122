import React, { useEffect, useState } from "react";
import styles from "./Type.module.css";
import PropTypes from "prop-types";
import { searchTypeFilter } from "../../../../constants/search";
import Input from "../../../GlobalComponents/UI/Input/Input";

const Type = (props) => {
  const { typeChangeHandler, chosenType } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    if (chosenType.length === 0) {
      setActiveIndex(0);
    } else if (chosenType.some((el) => el === "PRODUCT")) {
      setActiveIndex(1);
    } else {
      setActiveIndex(2);
    }
  }, [chosenType]);
  return (
    <>
      <h1 className={styles.title}>Type</h1>
      <div>
        {searchTypeFilter?.map((cat, i) => (
          <div key={cat?.name}>
            <Input
              elementType='radio'
              hasLabel={true}
              label={cat?.name}
              value={cat?.label}
              name={cat?.label}
              inpContainerClass={styles.inpContainer}
              checked={i === activeIndex}
              changeHandler={typeChangeHandler}
              customCheckBox={styles.checkMark}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Type;
Type.propTypes = {
  typeChangeHandler: PropTypes.func,
  chosenType: PropTypes.array,
};
