import React, { useEffect, useState } from "react";
import styles from "./DiscoverLayout.module.css";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import SideDrawer from "../Navigation/SideDrawer/SideDrawer";
import { Outlet, useLocation } from "react-router-dom";
import { useMutation, useReactiveVar } from "@apollo/client";
import { authVar, consumerOnboardingVar } from "../../../../utils/cache";
import setOnboarding from "../../../../graphql/mutations/setOnboarding";
import Backdrop from "../../UI/Backdrop/Backdrop";
import useHideMenu from "../../../../hooks/useHideMenu";
import { ToastContainer } from "react-toastify";

const DiscoverLayout = () => {
  const location = useLocation();
  const [backdrop, setBackdrop] = useState(false);
  const [setOnboardingFor] = useMutation(setOnboarding);
  const onboardingStep = useReactiveVar(consumerOnboardingVar);
  const [showFooter, setShowFooter] = useState(true);
  const isBusinessOnboarding = location.pathname.includes(
    "business-onboarding"
  );

  const { show: sideDrawer, setShow: setSideDrawer } =
    useHideMenu("side-drawer");
  const user = useReactiveVar(authVar);
  useEffect(() => {
    if (location.pathname === "/map") {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [location]);

  useEffect(() => {
    const locationArray = location?.pathname?.split("/");
    if (
      user &&
      location?.pathname !== "/" &&
      onboardingStep !== 4 &&
      (locationArray?.length !== 3 ||
        (!locationArray[1]?.includes("product") &&
          !locationArray[1]?.includes("service") &&
          !locationArray[1]?.includes("food")))
    ) {
      consumerOnboardingVar(-1);
      setOnboardingFor({ variables: { platform: "WEB" } });
    }
    if (
      user &&
      location?.pathname !== "/" &&
      locationArray?.length !== 3 &&
      !locationArray[1]?.includes("product") &&
      !locationArray[1]?.includes("service") &&
      !locationArray[1]?.includes("food") &&
      onboardingStep !== -1
    ) {
      consumerOnboardingVar(-1);
      setOnboardingFor({ variables: { platform: "WEB" } });
    }
  }, [location, onboardingStep, user]);

  const showSideDrawer = () => {
    setBackdrop(!backdrop);
    setSideDrawer(!sideDrawer);
  };
  const closeBackdrop = () => {
    setBackdrop(false);
    setSideDrawer(false);
  };

  return (
    <>
      <ToastContainer />
      <Backdrop className={styles.backdrop} show={onboardingStep !== -1} />
      <div className={`${styles.layout} ${showSideDrawer && styles.noScroll}`}>
        <Navigation
          backdrop={backdrop}
          showSideDrawer={showSideDrawer}
          closeBackdrop={closeBackdrop}
          sideDrawer={sideDrawer}
          closed={closeBackdrop}
        />
        <SideDrawer show={sideDrawer} closeSideDrawer={closeBackdrop} />
        <Outlet />
        {showFooter && !isBusinessOnboarding && <Footer />}
      </div>
    </>
  );
};

export default DiscoverLayout;
