import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./NotAuthorized.module.css";
import tryBusiness from "../../../../../assets/images/tryBusiness.png";
import tryConsumer from "../../../../../assets/images/tryConsumer.png";
import tryBusinessWebp from "../../../../../assets/images/tryBusiness.webp";
import tryConsumerWebp from "../../../../../assets/images/tryConsumer.webp";
import Picture from "../../../UI/Picture";

const NotAuthorized = () => {
  return (
    <>
      <h2 className={styles.title}>Official Black Wall Street</h2>
      <div className={styles.optionsContainer}>
        <div className={styles.option}>
          <Picture
            webpSrc={tryBusinessWebp}
            fallback={tryBusiness}
            alt='for business owners'
            variant={styles.optionImg}
          />
          <div className={styles.desc}>
            <h3 className={styles.optionTitle}>For Business Owners</h3>
            <p className={styles.optionDesc}>
              Join the largest platform for Black-owned businesses and begin
              earning extra money today.
            </p>

            <NavLink to='/business-register'>
              <button className={styles.optionLink}>START SELLING</button>
            </NavLink>
          </div>
        </div>
        <div className={styles.option}>
          <Picture
            webpSrc={tryConsumerWebp}
            fallback={tryConsumer}
            alt='for customers'
            variant={styles.optionImg}
          />

          <div className={styles.desc}>
            <h6 className={styles.optionTitle}>For Customers</h6>
            <p className={styles.optionDesc}>
              Discover, review, and shop your favorite Black-owned brands
              worldwide.
            </p>

            <NavLink to='/consumer-register'>
              <button className={styles.optionLink}>START SHOPPING</button>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotAuthorized;
