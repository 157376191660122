import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styles from "./Settings.module.css";
import useInput from "../../hooks/useInput";
import Input from "../../components/GlobalComponents/UI/Input/Input";
import { businessWebsiteValidation } from "../../utils/validationRules";
const BusinessWebsite = (props) => {
  const { existingWebsite, updateVariables } = props;
  const {
    value: website,
    hasError: websiteError,
    errorMessage: websiteErrorMessage,
    valueChangeHandler: websiteHandler,
    valueBlurHandler: websiteBlurHandler,
    isValid: websiteValid,
  } = useInput(businessWebsiteValidation, existingWebsite);
  useEffect(() => {
    updateVariables({
      website: website,
    });
  }, [website]);

  return (
    <Input
      hasLabel
      label="BUSINESS WEBSITE"
      placeholder="e.g. www.yourwebsite.com"
      type="text"
      inpContainerClass={`${styles.inpContainer} ${
        websiteError && styles.invalid
      }`}
      value={website}
      changeHandler={websiteHandler}
      blurHandler={websiteBlurHandler}
      invalid={websiteError}
      errorMessage={websiteErrorMessage}
    />
  );
};

export default BusinessWebsite;
BusinessWebsite.propTypes = {
  existingWebsite: PropTypes.string,
  updateVariables: PropTypes.func
};
