export const USER_TYPES = {
  admin: "1",
  business: "2",
  standard: "3",
};

export const USER_ROLES = {
  ADMIN: ["ADMIN"],
  BUSINESS_OWNER: ["BUSINESS_OWNER"],
  USER: ["USER"],
  ALL: ["BUSINESS_OWNER", "USER"],
};

export const PAGES_NAMES = {
  DISCOVER: "Discover",
  PRODUCTS: "Product",
  SERVICES: "Service",
  BUSINESSES: "Business",
  BRICKMORTARS: "BrickMortars",
  ECOMMERCE: "Ecommerce",
  RESTAURANTS: "Restaurants",
  FOOD: "Food",
};

export const BusinessOnboardingState = {
  businessName: "businessName",
  businessPhone: "businessPhone",
  businessEmail: "businessEmail",
  businessWebsite: "businessWebsite",
  businessTypes: "businessTypes",
  mainCategories: "mainCategories",
  businessAddress: "businessAddress",
  businessImages: "businessImages",
  socialLinks: "socialLinks",
  businessHours: "businessHours",
  businessCategories: "businessCategories",
  aboutBusiness: "aboutBusiness",
  aboutFounder: "aboutFounder",
  businessItems: "businessItems",
  ownerId: "ownerId",
  done: "done",
  approve: "approve",
  disapprove: "disapprove",
  idDeclined: "idDeclined",
};

export const BUSINESS_TYPES = {
  brickMortars: "BrickMortars",
  ecommerce: "Ecommerce",
  restaurants: "Restaurants",
};

export const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const imgShapes = [
  "shape0",
  "shape1",
  "shape2",
  "shape3",
  "shape4",
  "shape5",
  "shape6",
  "shape7",
  "shape8",
  "shape9",
  "shape10",
];

export const MIN_MESSAGE_LENGTH = 50;
export const MAX_MESSAGE_LENGTH = 400;
export const STRING_MAX_LENGTH = 255;
export const CATEGORIES_CHILDREN_PAGE_SIZE = 6;

export const ITEM_TYPES = {
  PRODUCT: "Product",
  SERVICE: "Service",
  FOOD: "Food",
  BUSINESS: "Business",
};

export const ITEM_STATUS = {
  isEdit: "created",
  isDeleted: "deleted",
  notCreated: "notCreated",
};

export const BUSINESS_STATUS = {
  APPROVED: "approved",
  DISAPPROVED: "disapproved",
  UNDER_REVIEW: "done",
  ID_DECLINED: "idDeclined",
};

export const EMPTY_ITEM = {
  id: 0,
  type: ITEM_TYPES.PRODUCT,
  name: "",
  price: "",
  website: "",
  gallery: [],
  featuredImage: "",
  categories: [],
  desc: "",
  ingredientsAndNutritional: "",
  specification: "",
  isSubCategorySelected: false,
  status: ITEM_STATUS.notCreated,
};
export const pagesFilters = {
  Discover: "",
  Product: "PRODUCT",
  Service: "SERVICE",
  Food: "FOOD",
  Business: "BUSINESS",
  BrickMortars: "BrickMortars",
  Ecommerce: "Ecommerce",
  Restaurants: "Restaurants",
};

export const filtersToDisplay = {
  PRODUCT: "Products",
  SERVICE: "Services",
  FOOD: "Food",
  BUSINESS: "Businesses",
  BrickMortars: "Brick & Mortar",
  Ecommerce: "E-Commerce",
  Restaurants: "Restaurants",
};

export const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC",
  ASCSearchFilter: "ALPHABETIC",
  DESCSearchFilter: "VOTES",
};
export const pageOptions = [
  {
    label: "DISCOVER",
    filter: ["PRODUCT", "SERVICE", "FOOD"],
    adminRoute: "/admin-editor",
    value: "Discover",
  },
  {
    label: "PRODUCTS",
    filter: ["PRODUCT"],
    adminRoute: "/admin-editor/products",
    value: "Product",
  },
  {
    label: "SERVICES",
    filter: ["SERVICE"],
    adminRoute: "/admin-editor/services",
    value: "Service",
  },
  {
    label: "BUSINESS",
    filter: ["BrickMortars", "Ecommerce", "Restaurants"],
    adminRoute: "/admin-editor/businesses",
    value: "Business",
  },
  {
    label: "BRICK & MORTARS",
    filter: ["BrickMortars"],
    adminRoute: "/admin-editor/brick-and-mortars",
    value: "BrickMortars",
  },
  {
    label: "E-COMMERCE",
    filter: ["Ecommerce"],
    adminRoute: "/admin-editor/e-commerce",
    value: "Ecommerce",
  },
  {
    label: "RESTAURANTS",
    filter: ["Restaurants"],
    adminRoute: "/admin-editor/restaurants",
    value: "Restaurants",
  },
  { label: "FOOD", filter: ["FOOD"], adminRoute: "/food", value: "Food" },
];

export const STRIP_FREE_PLAN = {
  id: "0",
  active: "true",
  name: "The Basic Plan",
  currency: "usd",
  desc: "",
  type: "recurring",
  features: [
    {
      active: true,
      desc: "Create a listing for your business",
    },
    {
      active: true,
      desc: "Add up to 5 products or services to your listing",
    },
    {
      active: true,
      desc: "Reply to listing & product reviews",
    },
  ],
  recurringInterval: "month",
  intervalCount: 1,
  trialDays: null,
  amount: 0,
  __typename: "StripePrice",
};

export const STRIPE_PLANS_NAMES = {
  RESOURCE: "The Resource Plan",
  GROWTH: "The Growth Plan",
  GET_LISTED: "The Get Listed Plan",
};

export const STRIPE_PLANS_INTERVALS = {
  MONTHLY: { period: "month", shortcut: "mo" },
  YEARLY: { period: "year", shortcut: "y" },
};

export const SEARCH_TYPES = {
  PRODUCT: "PRODUCT",
  SERVICE: "SERVICE",
  FOOD: "FOOD",
  BrickMortars: "BrickMortars",
  Ecommerce: "Ecommerce",
  Restaurants: "Restaurants",
};

export const BUSINESS_SEARCH_TYPES = [
  SEARCH_TYPES.BrickMortars,
  SEARCH_TYPES.Ecommerce,
  SEARCH_TYPES.Restaurants,
];

export const BUSINESS_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const EST_BEOTYA_START_DATE = "2022-08-26T12:00:00.000-04:00";
export const EST_BEOTYA_COMPLETE_DATE = "2022-08-30T00:00:00.000-04:00";
export const EST_BEOTYA_VIEW_WINNERS = "2022-08-30T16:00:00.000-04:00";
