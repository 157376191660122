import React, { useEffect, useRef, useState } from "react";
import AboutWinners from "./AboutWinners";
import ImagesSlider from "./ImagesSlider";
import styles from "./Winners.module.css";
import { useQuery } from "@apollo/client";
import nominatedBusinesses from "../../../graphql/queries/nominatedBusinesses.js";
const Winners = () => {
  const { data, loading, error, refetch } = useQuery(nominatedBusinesses, {
    variables: { isSorted: true },
  });
  const { businessesAward: winnersInfo } = data ?? {};

  const verticalRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    verticalRef.current?.slickGoTo(currentIndex);
  }, [currentIndex]);

  return (
    <section className={styles.winnersSection} id='winners'>
      <div className={styles.winnersContainer}>
        <div className={styles.desc}>
          <AboutWinners
            setCurrentIndex={setCurrentIndex}
            winnersInfo={winnersInfo}
          />
        </div>
        <ImagesSlider
          setCurrentIndex={setCurrentIndex}
          ref={verticalRef}
          currentIndex={currentIndex}
          winnersInfo={winnersInfo}
        />
      </div>
    </section>
  );
};

export default Winners;
