import { useQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { authVar } from "../../utils/cache";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import styles from "./Settings.module.css";
import redirectIcon from "../../assets/images/redirectIcon.svg";
import BusinessPhone from "./BusinessPhone";
import useEditItem from "../../hooks/useEditItem";
import business from "../../graphql/queries/pages/business";
import SaveBtn from "./SaveBtn";
import CloseAccountBtn from "./CloseAccountBtn";
import BusinessEmail from "./BusinessEmail";
import BusinessWebsite from "./BusinessWebsite";
import BusinessSocials from "./BusinessSocials";
import viewPortalUrl from "../../graphql/queries/viewPortalUrl";
import closeMyAccount from "../../graphql/mutations/closeMyAccount";
import { errorToastHandler, successToastHandler } from "../../utils";

const Settings = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useReactiveVar(authVar)?.businesses ?? {};
  const [selectedBusiness, setSelectedBusiness] = useState();
  const [variables, setVariables] = useState({});
  const {
    loading: loadingStripe,
    data: url,
    error,
  } = useQuery(viewPortalUrl, {
    variables: {
      returnUrl: "/business-settings/" + id,
    },
  });
  const [
    closeAccount,
    {
      data: closedAccount,
      loading: closeAccountLoading,
      error: closeAccountError,
    },
  ] = useMutation(closeMyAccount);
  useEffect(() => {
    if (data) {
      const business = data?.find((el) => el.id === id) ?? data[0];
      setSelectedBusiness(business);
    }
  }, [data, id]);
  const updateVariables = (variable) => {
    setVariables((prevVars) => ({ ...prevVars, ...variable }));
  };
  const handleCloseAccount = () => {
    closeAccount()
      .then(() => successToastHandler("ACCOUNT CLOSED"))
      .catch(() => errorToastHandler());
  };

  const handleUpdateBusinessSuccess = () => navigate("/business-dashboard");

  const { loading, editFormHandler: editBusiness } = useEditItem(
    id,
    {
      ...variables,
    },
    "business",
    business,
    handleUpdateBusinessSuccess
  );
  if (!id || id === "undefined") return <Navigate to='/business-dashboard' />;

  return (
    <div className={styles.page}>
      <p className={styles.title}>Settings</p>
      <p className={styles.paragraph}>
        Edit Business contact information, manage your subscription, and update
        social media links here
      </p>
      <div className={styles.formContainer}>
        <form onSubmit={editBusiness}>
          <BusinessPhone
            phone={selectedBusiness?.phone}
            updateVariables={updateVariables}
          />
          <BusinessEmail
            existingEmail={selectedBusiness?.email}
            updateVariables={updateVariables}
          />
          <BusinessWebsite
            existingWebsite={selectedBusiness?.website}
            updateVariables={updateVariables}
          />

          {!error && (
            <>
              <hr className={styles.horizontalLine} />

              <a
                href={url?.viewPortalUrl}
                rel='noreferrer'
                target='_blank'
                className={styles.subscription}
              >
                MANAGE SUBSCRIPTION <img src={redirectIcon} />
              </a>
              <hr className={styles.horizontalLine} />
            </>
          )}

          <p className={styles.title}>Social Media Links</p>
          <BusinessSocials
            facebook={selectedBusiness?.facebook}
            instagram={selectedBusiness?.instagram}
            twitter={selectedBusiness?.twitter}
            tiktok={selectedBusiness?.tiktok}
            updateVariables={updateVariables}
          />
          <hr className={styles.horizontalLine} />
          <div className={styles.btnContainer}>
            <SaveBtn loading={loading} />
            <CloseAccountBtn
              loading={closeAccountLoading}
              handleCloseAccount={handleCloseAccount}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
