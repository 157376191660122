import React from "react";
import PropTypes from "prop-types";
import Logo from "../../Logo/Logo";
import ProfileNavigation from "../../Navigation/ProfileNavigation/ProfileNavigation";
import styles from "./Header.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { authVar, rolesVar } from "../../../../../utils/cache";
import { checkUserRole } from "../../../../../utils";
import { USER_TYPES } from "../../../../../constants";
import SavingInfoState from "../../../../BusinessRegister/SavingInfoState";

const Header = (props) => {
  const { title, className } = props;
  const { pathname } = useLocation();
  const { data: businesses } = useReactiveVar(authVar)?.businesses ?? {};
  const roles = useReactiveVar(rolesVar);
  const showAddBtn =
    !pathname.includes("/business-onboarding") &&
    !pathname.includes("/create-business");
  return (
    <header className={`${styles.headerContainer} ${className || ""}`}>
      <div className={styles.logoContainer}>
        <NavLink to='/'>
          <Logo variant={styles.logo} />
        </NavLink>
        <h1 className={styles.title}>{title}</h1>
      </div>
      {title !== "Admin" && !showAddBtn && <SavingInfoState />}
      <div className={styles.rightContainer}>
        {title === "Admin" ? (
          <ProfileNavigation />
        ) : (
          <div className={styles.authState}>
            <div className={styles.rightContainer}>
              {showAddBtn && (
                <NavLink
                  to='/create-business'
                  className={`${styles.addBtn} ${
                    businesses?.length >= 3 &&
                    !checkUserRole(roles, USER_TYPES.admin)
                      ? styles.dimmed
                      : styles.regBtn
                  }`}
                >
                  + ADD BUSINESS
                </NavLink>
              )}
              <ProfileNavigation />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
Header.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};
