import React, { useState } from "react";
import EmailForm from "../../components/ForgotPassword/EmailForm/EmailForm";
import ResendTemplate from "../../components/ForgotPassword/ResendTemplate/ResendTemplate";
import useInput from "../../hooks/useInput";
import { emailValidationRules } from "../../utils/validationRules";
import styles from "./ForgotPassword.module.css";
const ForgotPassword = () => {
  const {
    value: email,
    hasError: emailError,
    errorMessage: emailErrorMessage,
    valueChangeHandler: emailHandler,
    valueBlurHandler: emailBlurHandler,
    isValid: emailValid,
  } = useInput(emailValidationRules);

  const [emailSent, setEmailSent] = useState(false);
  const sendEmail = () => {
    setEmailSent(true);
  };
  return (
    <div className={styles.forgotPage}>
      <div className={styles.forgotContainer}>
        {emailSent ? (
          <ResendTemplate email={email} />
        ) : (
          <EmailForm
            email={email}
            emailError={emailError}
            emailBlurHandler={emailBlurHandler}
            emailHandler={emailHandler}
            emailErrorMessage={emailErrorMessage}
            emailValid={emailValid}
            sendEmail={sendEmail}
          />
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
