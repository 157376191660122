import React from "react";
import GetListedBtn from "../../GlobalComponents/Buttons/GetListedBtn/GetListedBtn";
import styles from "./ListYourBiz.module.css";
import arrow from "../../../assets/images/leftDirArrow.png";
const ListYourBiz = () => {
  return (
    <section className={styles.listSection}>
      <div className={styles.card}>
        <div className={styles.overlay} />
        <h3 className={styles.mainText}>
          <span className={styles.wanna}>Want to</span>
          <div className={styles.textContainer}>
            <span className={styles.list}>list your </span>
            <span className={styles.business}>
              <span className={styles.extraAfter}> business?</span>
            </span>
          </div>
        </h3>
        <div className={styles.bottomContainer}>
          <div className={styles.btnTextContainer}>
            <p className={styles.desc}>
              <span className={styles.show}>Let us show you what</span>
              <span className={styles.obws}> OBWS can do for you.</span>
            </p>
            <GetListedBtn variant={styles.register}>
              List with OBWS
            </GetListedBtn>
          </div>
          <img className={styles.arrow} alt='arrow' src={arrow} />
        </div>
      </div>
    </section>
  );
};

export default ListYourBiz;
