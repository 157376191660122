import Cookies from "universal-cookie";
import { authVar, consumerOnboardingVar, rolesVar } from "./cache";

export const logoutFunc = () => {
  const cookies = new Cookies();
  cookies.remove("auth-token", { path: "/" });
  cookies.remove("refresh-token", { path: "/" });
  cookies.remove("user-id", { path: "/" });
  cookies.remove("expired-at", { path: "/" });
  cookies.remove("business-id", { path: "/" });
  authVar(null);
  rolesVar(null);
  rolesVar([]);
  consumerOnboardingVar(-1);
};
