import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "./ImageShapes.module.css";
import Loader from "../../UI/Loader/Loader";
import { imgShapes } from "../../../../constants";
import { Img } from "react-image";
import placeholder from "../../../../assets/images/itemPlaceholder.jpg";
const ImageShapes = (props) => {
  const { variant, imgSrc } = props;

  return (
    <Img
      src={imgSrc}
      className={`${variant} ${
        styles[imgShapes[Math.floor(Math.random() * imgShapes.length)]]
      }`}
      alt='product'
      loader={
        <Loader
          variant={`${variant} ${
            styles[imgShapes[Math.floor(Math.random() * imgShapes.length)]]
          }`}
        />
      }
      unloader={
        <img
          src={placeholder}
          alt='No Image'
          className={`${variant} ${
            styles[imgShapes[Math.floor(Math.random() * imgShapes.length)]]
          }`}
        />
      }
    />
  );
};

export default memo(ImageShapes);

ImageShapes.propTypes = {
  variant: PropTypes.string,
  imgSrc: PropTypes.string,
};
