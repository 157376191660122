import React from "react";
import styles from "./Logo.module.css";
import PropTypes from "prop-types";
import orangeLogo from "../../../../assets/images/orangeLogo.svg";
const Logo = (props) => {
  const { variant } = props;
  return <img src={orangeLogo} alt='logo' className={variant} />;
};

export default Logo;
Logo.propTypes = { variant: PropTypes.string };
