import { gql } from "@apollo/client";
export default gql`
  query ($id: ID!, $size: Int, $page: Int) {
    user(id: $id) {
      upvotedItems(page: $page, size: $size) {
        paginatorInfo {
          currentPage
          hasMorePages
          total
          lastPage
        }
        data {
          item {
            __typename
            ... on Product {
              id
              name
              price
              votes
              isVoted
              isBookmarked
              featuredImage {
                id
                path
              }
              business {
                name
                id
                businessTypes
              }
            }

            ... on Service {
              id
              name
              price
              votes
              isVoted
              isBookmarked
              featuredImage {
                id
                path
              }
              business {
                name
                id
                businessTypes
              }
            }
            ... on Food {
              id
              name
              price
              votes
              isVoted
              isBookmarked
              featuredImage {
                id
                path
              }
              business {
                name
                id
                businessTypes
              }
            }
            ... on Business {
              id
              name
              votes
              isVoted
              isBookmarked
              businessTypes
              featuredImage {
                id
                path
              }
              categories {
                id
                name
                parent {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
