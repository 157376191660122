import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import declineBusinessMutation from "../../../graphql/mutations/declineBusiness";
import search from "../../../graphql/queries/search/search";
import useInput from "../../../hooks/useInput";
import { isRequired } from "../../../utils/validationRules";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import Input from "../../GlobalComponents/UI/Input/Input";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import { errorToastHandler, successToastHandler } from "../../../utils";
import styles from "./DeclineReason.module.css";
import { BUSINESS_STATUS, BUSINESS_SEARCH_TYPES } from "../../../constants";

export default function DeclineReason() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [declineBusiness, { loading: loadingDeclineBusiness }] = useMutation(
    declineBusinessMutation,
    {
      refetchQueries: [
        {
          query: search,
          variables: {
            text: "",
            page: 1,
            filters: {
              typesAndCategories: {
                types: BUSINESS_SEARCH_TYPES,
                status: [BUSINESS_STATUS.UNDER_REVIEW],
              },
            },
          },
        },
      ],
    }
  );
  const {
    value: reason,
    hasError: reasonError,
    errorMessage: reasonErrorMessage,
    valueChangeHandler: reasonHandler,
    valueBlurHandler: reasonBlurHandler,
    isValid: reasonValid,
  } = useInput(isRequired, "");

  const handleDeclineBusinessReason = () => {
    declineBusiness({
      variables: {
        email: state?.email,
        reason,
      },
    })
      .then(() => {
        successToastHandler("EMAIL SENT SUCCESSFULLY");
        navigate("/admin");
      })
      .catch(() => errorToastHandler());
  };
  return (
    <div className={styles.declineReasonContainer}>
      <AdminTitle title='Reason for declining' />

      <Input
        hasLabel={false}
        placeholder='Add message here as to why you’ve declined the user’s application'
        elementType='textArea'
        value={reason}
        inpContainerClass={styles.textArea}
        changeHandler={reasonHandler}
        blurHandler={reasonBlurHandler}
        invalid={reasonError}
        errorMessage={reasonErrorMessage}
      />

      <RegisterNextBtn
        variant={parentStyles.continueBtn}
        clickHandler={handleDeclineBusinessReason}
        isLoading={loadingDeclineBusiness}
        disabled={!reasonValid}
      >
        {loadingDeclineBusiness ? <Spinner /> : "SAVE & CONTINUE"}
      </RegisterNextBtn>
    </div>
  );
}
