import React from "react";
import regStyles from "./AuthorizedConsumer.module.css";
import adminStyles from "./AdminStyles.module.css";
import { useReactiveVar } from "@apollo/client";
import { isAdmin } from "../../../../../utils/cache";
import GetListedBtn from "../../../Buttons/GetListedBtn/GetListedBtn";

const AuthorizedConsumer = () => {
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  return (
    <div className={styles.trySection}>
      <h3 className={styles.optionTitle}>Try OBWS</h3>
      <p className={styles.optionDesc}>
        Join the largest platform for Black-owned businesses and begin earning
        extra money today.
      </p>
      <div className={styles.btnImg}>
        <div className={styles.businessImg}>
          <GetListedBtn variant={styles.learn}>Get Started</GetListedBtn>
        </div>
      </div>
    </div>
  );
};

export default AuthorizedConsumer;
