import React from "react";
import PropTypes from "prop-types";
import AdminTableHeader from "../../GlobalComponents/Admin/AdminTableHeader";
import UserRow from "./UserRow.js";
import tableStyles from "../../GlobalComponents/Admin/AdminTableStyles/Table.module.css";

export default function UserManagementTable({ users, handleSort, sortOrder }) {
  return (
    <table className={tableStyles.tableContainer}>
      <AdminTableHeader
        columns={["NAME OF USER", "USER TYPE", "EMAIL ADDRESS", "STATUS"]}
        handleSort={handleSort}
        sortOrder={sortOrder}
      />
      <tbody>
        {users?.map((user) => (
          <UserRow user={user} key={user.id} />
        ))}
      </tbody>
    </table>
  );
}

UserManagementTable.propTypes = {
  users: PropTypes.array,
  handleSort: PropTypes.func,
  sortOrder: PropTypes.string,
};
