import React from "react";
import styles from "../SharedStyles.module.css";
import ig from "../../../../../assets/images/ig.svg";
import fb from "../../../../../assets/images/footerFb.svg";
import linkden from "../../../../../assets/images/linkedin.svg";
import tweet from "../../../../../assets/images/tweet.svg";
import youtube from "../../../../../assets/images/youtube.svg";
import tiktok from "../../../../../assets/images/footerTiktok.svg";
const SocialLinks = () => {
  const links = [
    { imgSrc: ig, link: "https://www.instagram.com/officialblackwallstreet/" },
    { imgSrc: fb, link: "https://www.facebook.com/officialblackwallstreet/" },
    {
      imgSrc: linkden,
      link: "https://www.linkedin.com/company/official-black-wall-street/",
    },
    { imgSrc: tweet, link: "https://twitter.com/OBWSapp" },
    {
      imgSrc: youtube,
      link: "https://www.youtube.com/c/officialblackwallstreet",
    },
    { imgSrc: tiktok, link: "https://www.tiktok.com/@obwsapp" },
  ];
  return (
    <div className={styles.container}>
      <span className={styles.containerTitle}>Follow us</span>
      <div className={styles.socials}>
        {links?.map((social) => (
          <a
            key={social?.link}
            href={social?.link}
            target='_blank'
            rel='noreferrer'
          >
            <img src={social?.imgSrc} alt='icon' />
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialLinks;
