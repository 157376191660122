import React, { useCallback, useRef } from "react";
import styles from "./AboutWinners.module.css";
import PropTypes from "prop-types";
import clover from "../../../../assets/images/clover.png";
import comcast from "../../../../assets/images/comcast.png";
import { NavLink } from "react-router-dom";
const AboutWinners = (props) => {
  const { setCurrentIndex, winnersInfo } = props;
  const options = { threshold: 1 };
  const firstObserver = useRef();
  const secondObserver = useRef();

  const firstItemRef = useCallback((node) => {
    if (firstObserver.current) firstObserver.current.disconnect();
    firstObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setCurrentIndex(+entries[0]?.target?.id);
      }
    }, options);
    if (node) firstObserver.current.observe(node);
  }, []);
  const secondItemRef = useCallback((node) => {
    if (secondObserver.current) secondObserver.current.disconnect();
    secondObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setCurrentIndex(+entries[0]?.target?.id);
      }
    }, options);
    if (node) secondObserver.current.observe(node);
  }, []);

  const refHandler = (index) => {
    switch (index) {
      case 0:
        return firstItemRef;
      case 1:
        return secondItemRef;
    }
  };
  const visitWebsiteHandler = (website) => {
    if (website) {
      return `${
        website?.includes("http://") || website?.includes("https://")
          ? website
          : `//` + website
      }`;
    } else {
      return;
    }
  };

  return (
    <div className={styles.aboutContainer}>
      {winnersInfo?.map((winner, i) => (
        <div
          id={i}
          className={styles.aboutWinner}
          key={winner?.name}
          ref={refHandler(i)}
        >
          <div className={styles.header}>
            <span className={styles.winnerTitle}>
              {" "}
              2022 {i === 0 ? "BEOTY" : "SEOTY"} AWARD
            </span>{" "}
            <a
              href={
                i === 0
                  ? "https://www.clover.com/"
                  : "https://bit.ly/RISE22Q3_Editorial_OBWS"
              }
              target='_blank'
              rel='noreferrer'
            >
              <img src={i === 0 ? clover : comcast} alt='sponsor' />
            </a>
          </div>
          <h3 className={styles.name}>
            {winner?.ownerFirstName} {winner?.ownerLastName}
          </h3>
          <a
            href={visitWebsiteHandler(winner?.business?.website)}
            target='_blank'
            rel='noreferrer'
            className={styles.link}
          >
            {winner?.business?.name}
          </a>
          <span className={`${styles.winnerTitle} ${styles.descMargin}`}>
            {winner?.business?.aboutOwner?.slice(0, 125)}...
          </span>
          <NavLink
            to={`/business/${winner?.business?.id}#founder`}
            className={styles.businessPage}
          >
            READ FULL PROFILE
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default AboutWinners;
AboutWinners.propTypes = {
  setCurrentIndex: PropTypes.func,
  winnersInfo: PropTypes.array,
};
