import { useQuery } from "@apollo/client";
import React from "react";
import { useParams, Navigate, NavLink } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { authVar } from "../../utils/cache";
import WhatWeOffer from "../../components/GlobalComponents/Sections/WhatWeOffer/WhatWeOffer";
import AboutBusiness from "../../components/GlobalComponents/Sections/AboutBusiness/AboutBusiness";
import AboutSection from "../../components/GlobalComponents/Sections/AboutSection/AboutSection";
import CarouselHeader from "../../components/GlobalComponents/Sections/CarouselHeader/CarouselHeader";
import Comments from "../../components/GlobalComponents/Sections/Comments/Comments";
import MightLike from "../../components/GlobalComponents/Sections/MightLike/MightLike";
import SignUpAd from "../../components/GlobalComponents/Sections/SignUpAd/SignUpAd";
import service from "../../graphql/queries/pages/service";
import styles from "./Service.module.css";
import adImg from "../../assets/images/signUpAd.png";
import SharedSignUpAd from "../../components/GlobalComponents/Sections/SharedSignUpAd/SharedSignUpAd";
import { ITEM_TYPES } from "../../constants";

const Service = () => {
  const { id } = useParams();
  const userData = useReactiveVar(authVar);
  const { data, loading, error, fetchMore } =
    useQuery(service, { variables: { id } }) ?? {};
  const { data: supplies } = data?.service?.business?.supplies ?? {};
  const { data: mainCategoryItems } = data?.service?.mainCategoryServices ?? {};

  const filteredSupplies = supplies?.filter((item) => {
    if (
      item?.item?.id === id &&
      item?.item?.__typename === ITEM_TYPES.SERVICE
    ) {
      return false;
    } else {
      return true;
    }
  });

  if ((!data && !loading) || error) return <Navigate to='/' />;

  return (
    <div className={styles.page}>
      <CarouselHeader data={data?.service} loading={loading} />
      <div className={styles.middleContainer}>
        <div className={styles.aboutOwner}>
          <AboutSection
            data={data?.service}
            loading={loading}
            error={error}
            variant={styles.aboutSection}
            thirdParagraph='Shipping + pricing'
          />
        </div>
        <div className={styles.rightContainer}>
          <AboutBusiness data={data?.service} loading={loading} error={error} />
          <Comments
            data={data?.service}
            variant={styles.comments}
            renderLoading={loading}
            renderError={error}
            fetchMore={fetchMore}
          />
        </div>
      </div>
      {filteredSupplies?.length > 0 && (
        <WhatWeOffer
          title='More from this store'
          isSeeAll={false}
          sectionClass={styles.moreClass}
          items={filteredSupplies}
          loading={loading}
          error={error}
        />
      )}
      {mainCategoryItems?.length > 0 ? (
        <div className={styles.overFlowContainer}>
          {!userData && (
            <>
              {" "}
              <img src={adImg} alt='sign up ad' className={styles.adImg} />
              <NavLink to='/consumer-register' className={styles.learn}>
                SIGNUP NOW
              </NavLink>
            </>
          )}
          <MightLike
            data={mainCategoryItems?.slice(0, 5)}
            loading={loading}
            error={error}
            seeAllLink={`/search?c=${data?.service?.business?.categories[0]?.name}&f=SERVICE`}
          />
          {!userData && (
            <SignUpAd
              text='Sign up and find everything black-owned'
              btnText='SIGNUP NOW'
              btnLink='/consumer-register'
            />
          )}
        </div>
      ) : (
        !userData && (
          <SharedSignUpAd
            text='Sign up and find everything black-owned'
            btnText='SIGNUP NOW'
            btnLink='/consumer-register'
          />
        )
      )}
    </div>
  );
};

export default Service;
