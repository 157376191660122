import React from "react";
import styles from "./Timer.module.css";
import PropTypes from "prop-types";
const Timer = (props) => {
  const { hours, minutes, seconds, text } = props;
  return (
    <div className={styles.timerText}>
      <h3 className={styles.text}>{text} </h3>
      <div className={styles.timerContainer}>
        <span className={styles.count}>{hours}</span>
        <span>:</span>
        <span className={styles.count}>{minutes}</span>
        <span>:</span>
        <span className={styles.count}>{seconds}</span>
      </div>
    </div>
  );
};

export default Timer;
Timer.propTypes = {
  hours: PropTypes.string,
  minutes: PropTypes.string,
  seconds: PropTypes.string,
  text: PropTypes.string,
};
