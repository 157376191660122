import React from "react";
import styles from "./BusinessAddress.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { editView } from "../../../../../utils/cache";
import EditAddressForm from "./EditAddressForm/EditAddressForm";

const BusinessAddress = (props) => {
  const {
    address,
    showEditTooltip,
    editToggleHandler,
    address2,
    city,
    state,
    zip,
    country,
  } = props;

  const isEditing = useReactiveVar(editView);

  return (
    <div
      className={`${isEditing ? styles.edit : undefined}
    ${showEditTooltip === "address" ? styles.highZ : undefined}`}
    >
      {(isEditing || address) && (
        <p
          className={`${styles.address} ${
            !isEditing ? styles.noEdit : undefined
          }
        `}
          onClick={editToggleHandler.bind(null, "address")}
        >
          {isEditing && !address ? "CLICK TO ADD ADDRESS" : address}
        </p>
      )}
      <EditAddressForm
        address={address}
        address2={address2}
        initialZip={zip}
        initialCity={city}
        initialCountry={country}
        initialState={state}
        showEditTooltip={showEditTooltip}
        editToggleHandler={editToggleHandler}
      />
    </div>
  );
};

export default BusinessAddress;
BusinessAddress.propTypes = {
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  zip: PropTypes.string,
  showEditTooltip: PropTypes.string,
  editToggleHandler: PropTypes.func,
};
