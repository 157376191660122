import React from "react";
import { useNavigate } from "react-router-dom";
import ListingSection from "../../components/Collections/ListingSection/ListingSection";
import BackButton from "../../components/GlobalComponents/Buttons/BackButton/BackButton";
import styles from "./Collections.module.css";

const Collections = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  return (
    <div className={styles.collectionsPage}>
      <div className={styles.header}>
        <div className={styles.backBtnWrapper}>
          <BackButton variant={styles.backBtn} backHandler={handleBack} />
        </div>
        <h1 className={styles.title}>Collections</h1>
      </div>
      <ListingSection />
    </div>
  );
};

export default Collections;
