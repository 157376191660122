import { gql } from "@apollo/client";
export default gql`
  mutation resetPassword(
    $email: Email!
    $code: String!
    $expiresAt: Int!
    $password: String!
  ) {
    resetPw(
      email: $email
      password: $password
      code: $code
      expiresAt: $expiresAt
    )
  }
`;
