import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import useInput from "../../../../hooks/useInput";
import { itemEditField, editView } from "../../../../utils/cache";
import { businessWebsiteValidation } from "../../../../utils/validationRules";
import ApplyEditBtn from "../../Buttons/ApplyEditBtn/ApplyEditBtn";
import EditTooltip from "../EditTooltip/EditTooltip";
import Input from "../Input/Input";
import styles from "./EditWebsite.module.css";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import useEditItem from "../../../../hooks/useEditItem";

const EditWebsite = (props) => {
  const { website, itemType } = props;
  const {
    value: businessWebsite,
    hasError: businessWebsiteError,
    errorMessage: businessWebsiteErrorMessage,
    valueChangeHandler: businessWebsiteHandler,
    valueBlurHandler: businessWebsiteBlurHandler,
    isValid: businessWebsiteValid,
  } = useInput(
    businessWebsiteValidation,
    website?.includes("http") ? website : website?.replace("//", "www.")
  );
  const [formIsValid, setFormIsValid] = useState(false);

  const selectedToEdit = useReactiveVar(itemEditField);
  const isEditing = useReactiveVar(editView);
  const closeTooltip = () => itemEditField("");

  useEffect(() => {
    if (
      (!businessWebsiteValid && businessWebsite) ||
      businessWebsite === website
    ) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }
  }, [businessWebsiteValid, businessWebsite]);

  const { id } = useParams();

  const { loading, editFormHandler } = useEditItem(
    id,
    {
      website: businessWebsite,
    },
    itemType
  );

  const itemLabel = () => {
    if (itemType === "business") return "BUSINESS WEBSITE";
    else if (itemType === "food") return "DELIVERY SERVICE LINK";
    else return `${itemType?.toUpperCase()} LINK`;
  };

  return (
    <EditTooltip
      show={selectedToEdit === "visitBtn" && isEditing}
      variant={`${
        itemType === "business" ? styles.businessTooltip : styles.itemTooltip
      }`}
      anchorStyle='left'
      closeHandler={closeTooltip}
    >
      <form onSubmit={editFormHandler}>
        <Input
          hasLabel={true}
          label={itemLabel()}
          placeholder='e.g. www.yourwebsite.com'
          type='text'
          inpContainerClass={`${styles.inpContainer} ${
            businessWebsiteError && styles.invalid
          }`}
          value={businessWebsite}
          changeHandler={businessWebsiteHandler}
          blurHandler={businessWebsiteBlurHandler}
          invalid={businessWebsiteError}
          errorMessage={businessWebsiteErrorMessage}
        />
        <ApplyEditBtn disable={!formIsValid} loading={loading} />
      </form>
    </EditTooltip>
  );
};

export default EditWebsite;
EditWebsite.propTypes = {
  website: PropTypes.string,
  itemType: PropTypes.string,
};
