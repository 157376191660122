import React, { useEffect, useState } from "react";
import Modal from "../../../GlobalComponents/UI/Modal/Modal";
import styles from "./CreateNewCollection.module.css";
import plusIcon from "../../../../assets/images/plusIcon.svg";
import Input from "../../../GlobalComponents/UI/Input/Input";
import useInput from "../../../../hooks/useInput";
import { collectionNameRules } from "../../../../utils/validationRules";
import { useMutation } from "@apollo/client";
import createCollection from "../../../../graphql/mutations/collections/createCollection";
import PropTypes from "prop-types";
import Spinner from "../../../GlobalComponents/UI/Spinner/Spinner";
import getUser from "../../../../graphql/queries/getUser";
import { errorToastHandler } from "../../../../utils";

const CreateNewCollection = (props) => {
  const { addToCollection, item, closeHandler, setActive } = props;

  const {
    value: collection,
    hasError: collectionError,
    errorMessage: collectionErrorMessage,
    valueChangeHandler: collectionHandler,
    valueBlurHandler: collectionBlurHandler,
    isValid: collectionValid,
  } = useInput(collectionNameRules);
  const [createNew, { loading, error }] = useMutation(createCollection, {
    refetchQueries: [getUser],
  });
  const [isError, setIsError] = useState(error);

  useEffect(() => {
    if (isError) {
      setIsError(false);
    }
  }, [collection]);
  useEffect(() => {
    if (error || collectionError) {
      setIsError(true);
    }
  }, [error, collectionError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!collectionValid) return;

    createNew({ variables: { name: collection } })
      .then(({ data }) => {
        if (item)
          addToCollection([
            {
              collectionId: data?.createCollection?.id,
              collectableId: item.id,
              collectableType: item.__typename?.toUpperCase(),
            },
          ]);
        if (closeHandler) closeHandler(data?.createCollection);
      })
      .catch(() => errorToastHandler());
  };
  return (
    <Modal variant={styles.popUp}>
      <header className={styles.popUpHeader}>
        <h6 className={styles.title}>New Collection</h6>
        <img
          src={plusIcon}
          className={styles.add}
          alt='add'
          onClick={handleSubmit}
        />
      </header>
      <div className={styles.brdr} />
      <form onSubmit={handleSubmit} className={styles.addForm}>
        <Input
          type='text'
          inpContainerClass={styles.inpContainer}
          hasLabel
          isRequired
          label='NAME'
          placeholder='Collection Name'
          value={collection}
          changeHandler={collectionHandler}
          blurHandler={collectionBlurHandler}
          invalid={isError}
          errorMessage={error?.message || collectionErrorMessage}
        />
        <div className={styles.btnContainer}>
          <button
            type='submit'
            className={` ${styles.confirmBtn} ${
              loading ? styles.loadingBg : styles.regularBg
            }`}
            disabled={!collectionValid}
          >
            {loading ? (
              <Spinner variant={styles.loader} />
            ) : (
              <svg
                width='37'
                height='18'
                viewBox='0 0 37 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M6.99382e-07 9L36 9M36 9L28 17M36 9L28 0.999999'
                  stroke='#F2F1ED'
                />
              </svg>
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateNewCollection;
CreateNewCollection.propTypes = {
  addToCollection: PropTypes.func,
  closeHandler: PropTypes.func,
  item: PropTypes.object,
  setActive: PropTypes.func,
};
