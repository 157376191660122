import mandy from "../../../assets/images/mandy.png";
import tasha from "../../../assets/images/tasha.png";
import keewa from "../../../assets/images/keewa.png";
import abouFofanah from "../../../assets/images/abouFofanah.png";
export const judgesInfo = [
  {
    name: "Mandy Bowman",
    title: "Founder & CEO",
    linkText: "Official Black Wall Street",
    linkHref: `${process.env.REACT_APP_URL}`,
    imgSrc: mandy,
    shape: "firstShape",
  },
  {
    name: "Tasha Helm",
    title: "Director, Corporate Citizenship",
    linkText: "Fiserv",
    linkHref: `https://www.fiserv.com/en.html`,
    imgSrc: tasha,
  },
  {
    name: "Keewa Nurullah",
    title: "Founder + Past BEOTYA 2021 Winner",
    linkText: "Kido",
    linkHref: `https://kidochicago.com/`,
    imgSrc: keewa,
  },
  {
    name: "Abu Fofanah",
    title: "Managing Director",
    linkText: "House of Fofanah",
    linkHref: `https://www.houseoffofanah.com`,
    imgSrc: abouFofanah,
  },
];
