import React from "react";
import regStyles from "./BrowseAllSection.module.css";
import adminStyles from "./AdminStyles.module.css";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import SeeAllBtn from "../../Buttons/SeeAllBtn/SeeAllBtn";
import { useQuery, useReactiveVar } from "@apollo/client";
import browseAll from "../../../../graphql/queries/browseAll";
import SectionLoader from "../../UI/SectionLoader/SectionLoader";
import ErrorComponent from "../../UI/ErrorComponent/ErrorComponent";
import { pagesFilters } from "../../../../constants";
import { isAdmin } from "../../../../utils/cache";
import UpdateBtn from "../../Buttons/UpdateBtn/UpdateBtn";
import bg1 from "../../../../assets/images/categoryBg1.png";
import bg2 from "../../../../assets/images/categoryBg2.png";
import bg3 from "../../../../assets/images/categoryBg3.png";
import bg4 from "../../../../assets/images/categoryBg4.png";
import bg5 from "../../../../assets/images/categoryBg5.png";
import bg6 from "../../../../assets/images/categoryBg6.png";
import bg7 from "../../../../assets/images/categoryBg7.png";
import bg8 from "../../../../assets/images/categoryBg8.png";
import bgWebp1 from "../../../../assets/images/categoryBg1.webp";
import bgWebp2 from "../../../../assets/images/categoryBg2.webp";
import bgWebp3 from "../../../../assets/images/categoryBg3.webp";
import bgWebp4 from "../../../../assets/images/categoryBg4.webp";
import bgWebp5 from "../../../../assets/images/categoryBg5.webp";
import bgWebp6 from "../../../../assets/images/categoryBg6.webp";
import bgWebp7 from "../../../../assets/images/categoryBg7.webp";
import bgWebp8 from "../../../../assets/images/categoryBg8.webp";
import Picture from "../../UI/Picture";
const BrowseAllSection = (props) => {
  const { pageName } = props;
  const { data, loading, error } = useQuery(browseAll, {
    variables: { page: pageName },
  });
  const pageFilter = pagesFilters[pageName];
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  const categoriesSrcs = [
    { fallback: bg1, webSrc: bgWebp1 },
    { fallback: bg2, webSrc: bgWebp2 },
    { fallback: bg3, webSrc: bgWebp3 },
    { fallback: bg4, webSrc: bgWebp4 },
    { fallback: bg5, webSrc: bgWebp5 },
    { fallback: bg6, webSrc: bgWebp6 },
    { fallback: bg7, webSrc: bgWebp7 },
    { fallback: bg8, webSrc: bgWebp8 },
  ];
  return (
    <section className={styles.browseAllContainer}>
      <div className={styles.browseAllSection}>
        <div className={styles.header}>
          <h3 className={styles.sectionTitle}>
            Browse for the things you need
          </h3>
          <h3 className={styles.smallTitle}>Browse All</h3>
          {admin ? (
            <UpdateBtn link={`/admin-editor/categories?p=${pageName}`} />
          ) : (
            <SeeAllBtn
              route={`/search?c=All Categories${
                pageFilter && `&f=${pageFilter}`
              }`}
            />
          )}
        </div>
        <div className={styles.categories}>
          {loading && (
            <SectionLoader
              itemsNumber={8}
              isBrowseAll={true}
              browseVariant={styles.category}
            />
          )}
          {error && <ErrorComponent error={error?.message} />}
          {data?.browseThings?.map((category, i) => (
            <NavLink
              to={`/search?c=${category?.name?.replace("&", "%26")}${
                pageFilter && `&f=${pageFilter}`
              }`}
              key={i}
              className={styles.category}
            >
              <Picture
                fallback={categoriesSrcs[i]?.fallback}
                webpSrc={categoriesSrcs[i]?.webSrc}
                alt={`bg${i}`}
                variant={styles.bg}
              />
              <h1 className={styles.categoryTitle}>{category.name}</h1>
            </NavLink>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BrowseAllSection;

BrowseAllSection.propTypes = {
  pageName: PropTypes.string,
};
