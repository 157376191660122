import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ItemsTableBody from "../../components/GlobalComponents/Admin/ItemTableBody/ItemTableBody";
import Header from "../../components/AdminEditorPicks/Header/Header";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";
import { pageOptions } from "../../constants";
import setEditorPicks from "../../graphql/mutations/setEditorPicks";
import editorPicks from "../../graphql/queries/editorPicks";
import styles from "./EditEditorPicks.module.css";
import { ToastContainer } from "react-toastify";
import { errorToastHandler, successToastHandler } from "../../utils";

const itemLabel = {
  Discover: "ITEM",
  Product: "PRODUCT",
  Service: "SERVICE",
  Business: "BUSINESS",
  BrickMortars: "BUSINESS",
  Ecommerce: "BUSINESS",
  Restaurants: "BUSINESS",
  Food: "FOOD",
};

const EditEditorPicks = () => {
  const [page, setPage] = useSearchParams();
  const pageName = page?.get("p");
  const { data, loading, error } = useQuery(editorPicks, {
    variables: { page: pageName },
  });
  const [items, setItems] = useState([]);
  const [
    updatePicks,
    { data: newData, loading: updateLoading, error: updateError },
  ] = useMutation(setEditorPicks, {
    refetchQueries: [{ query: editorPicks, variables: { page: pageName } }],
  });

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (data) setItems(data?.editorPicks);
  }, [data]);

  useEffect(() => {
    setDisabled(true);
    if (pageName === "Discover" && items?.length === 8) {
      setDisabled(false);
    } else if (pageName !== "Discover" && items?.length === 4) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [items, pageName]);

  const pageInfo = pageOptions.find((el) => el?.value === pageName);

  const addItem = (item) => setItems((prevItems) => [...prevItems, item]);
  const updatePicksHandler = (e) => {
    e.preventDefault();
    updatePicks({
      variables: {
        page: pageName,
        items: items?.map((item) => ({
          id: item?.id,
          type: item?.__typename?.toUpperCase(),
        })),
      },
    })
      .then(() => successToastHandler("UPDATED SUCCESSFULLY"))
      .catch(() => errorToastHandler());
  };

  const tableCols = [
    `${itemLabel[pageName]} NAME`,
    "BUSINESS OWNER",
    `TYPE OF ${itemLabel[pageName]}`,
  ];

  return (
    <form className={styles.editorPage} onSubmit={updatePicksHandler}>
      <Header addItem={addItem} />
      <ItemsTableBody
        items={items}
        loading={loading}
        error={error}
        setItems={setItems}
        cols={tableCols}
        isDelete
        isDrag
        loadingRows={pageName === "Discover" ? 8 : 4}
      />
      {disabled && !updateLoading && (
        <p className={styles.errorMessage}>{`Editor picks for ${
          pageInfo?.label
        } page must be ${pageName === "Discover" ? 8 : 4} items.`}</p>
      )}
      <button
        type='submit'
        className={` ${styles.btn} ${
          updateLoading ? styles.loadingBg : styles.regularBg
        }`}
        disabled={updateLoading ? false : disabled}
      >
        {updateLoading ? <Spinner /> : "SAVE CHANGES"}
      </button>
    </form>
  );
};

export default EditEditorPicks;
