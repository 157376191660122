import React from "react";
import styles from "./ItemName.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { editView, itemEditField } from "../../../../utils/cache";
import EditTooltip from "../EditTooltip/EditTooltip";
import Input from "../Input/Input";
import useInput from "../../../../hooks/useInput";
import { businessNameValidation } from "../../../../utils/validationRules";
import ApplyEditBtn from "../../Buttons/ApplyEditBtn/ApplyEditBtn";
import { useParams } from "react-router-dom";
import useEditItem from "../../../../hooks/useEditItem";
import { ToastContainer } from "react-toastify";

const ItemName = (props) => {
  const { name, itemType } = props;
  const {
    value: itemName,
    hasError: itemNameError,
    errorMessage: itemNameErrorMessage,
    valueChangeHandler: itemNameHandler,
    valueBlurHandler: itemNameBlurHandler,
    isValid: itemNameValid,
  } = useInput(businessNameValidation, name);
  const isEditing = useReactiveVar(editView);
  const { id } = useParams();
  const showEditTooltip = useReactiveVar(itemEditField);

  const { loading, editFormHandler } = useEditItem(
    id,
    {
      name: itemName,
    },
    itemType
  );

  return (
    <div
      className={`${isEditing ? styles.edit : styles.noEdit} ${
        showEditTooltip === "name" ? styles.highZ : undefined
      }`}
    >
      <h1 className={styles.title} onClick={itemEditField.bind(null, "name")}>
        {name}
      </h1>
      <EditTooltip
        show={showEditTooltip === "name" && isEditing}
        variant={styles.tooltip}
        anchorStyle='top'
        closeHandler={itemEditField.bind(null, "")}
      >
        <form onSubmit={editFormHandler}>
          <Input
            hasLabel
            isRequired
            label={`YOUR ${itemType?.toUpperCase()} NAME`}
            placeholder='e.g. Jamie’s Gardening'
            type='text'
            inpContainerClass={`${styles.inpContainer} ${
              itemNameError && styles.invalid
            }`}
            value={itemName}
            changeHandler={itemNameHandler}
            blurHandler={itemNameBlurHandler}
            invalid={itemNameError}
            errorMessage={itemNameErrorMessage}
          />
          <ApplyEditBtn
            disable={!itemNameValid || name === itemName}
            loading={loading}
          />
        </form>
      </EditTooltip>
    </div>
  );
};

export default ItemName;
ItemName.propTypes = {
  name: PropTypes.string,
  itemType: PropTypes.string,
};
