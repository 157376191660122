import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { isBusinessType } from "../../../utils";
import ImageShapes from "../../GlobalComponents/UI/ImageShapes/ImageShapes";
import UpVoteButton from "../../GlobalComponents/Buttons/UpVoteButton/UpVoteButton";
import AddToCollectionButton from "../../GlobalComponents/Buttons/AddToCollectionButton/AddToCollectionButton";
import { BUSINESS_TYPES } from "../../../constants";
import styles from "./ItemCard.module.css";

export default function ItemCard({ item }) {
  const isBusinessItem = item?.__typename.toLowerCase() === "business";
  const businessTypes = isBusinessItem
    ? item?.businessTypes
    : item?.business?.businessTypes;

  const isEcommerce = isBusinessType(businessTypes, BUSINESS_TYPES.ecommerce);

  const itemPriceAddress = (item) => {
    if (item?.price) {
      return `$${item?.price?.toFixed(2)}`;
    } else if (!isEcommerce) {
      return `${item?.city?.name}, ${item?.state?.abbr}`;
    } else {
      return "";
    }
  };
  return (
    <NavLink
      to={`/${item?.__typename?.toLowerCase()}/${item?.id}`}
      className={`${styles.item}`}
    >
      <div className={`${styles.imgContainer}`}>
        <ImageShapes
          variant={`${styles.itemImg}`}
          imgSrc={item?.featuredImage?.path}
          alt={item?.product}
        />
        <UpVoteButton
          variant={`${styles.upVote}`}
          voteCount={item?.votes}
          itemType={item?.__typename.toUpperCase()}
          itemId={+item?.id}
          voted={item?.isVoted}
          item={item}
        />
        <AddToCollectionButton
          variant={`${styles.addToCollection}`}
          addedToCollection={item?.isBookmarked}
          itemType={item?.__typename.toUpperCase()}
          itemId={+item?.id}
          item={item}
        />
      </div>
      {isBusinessItem ? (
        <>
          <p className={styles.business}>{item?.name}</p>
          <p className={styles.product}>
            {item?.categories?.map((cat, i) => {
              if (i !== item?.categories?.length - 1) return `${cat.name}, `;
              else return `${cat.name}`;
            })}
          </p>
        </>
      ) : (
        <>
          <p className={styles.business}>{item?.business?.name}</p>
          <p className={styles.product}>{item?.name}</p>
        </>
      )}

      <p className={styles.price}>{itemPriceAddress(item)}</p>
    </NavLink>
  );
}
ItemCard.propTypes = {
  item: PropTypes.object,
};
