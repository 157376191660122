import React, { useEffect, useState } from "react";
import Input from "../../../GlobalComponents/UI/Input/Input";
import styles from "./SortBy.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import {
  differentSearchLocationSelected,
  searchUserLocation,
  viewSearchMap,
} from "../../../../utils/cache";
import { searchSortArr } from "../../../../constants/search";

const SortBy = (props) => {
  const { sortChangeHandler, sort } = props;
  const [params, setParams] = useSearchParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const userLocation = useReactiveVar(searchUserLocation);
  const searchLocation = useReactiveVar(differentSearchLocationSelected);
  const viewMap = useReactiveVar(viewSearchMap);
  const activeIndexHandler = (indx) => {
    if (indx === 5 && !userLocation && !searchLocation) return;

    setActiveIndex(indx);
  };

  useEffect(() => {
    if ((userLocation || searchLocation) && viewMap) {
      setActiveIndex(5);
    } else {
      setActiveIndex(0);
    }
  }, [userLocation, searchLocation, viewMap]);
  useEffect(() => {
    activeIndexHandler(searchSortArr.findIndex((el) => el.label === sort));
  }, [sort]);

  useEffect(() => {
    const sortParam = params.get("s");
    if (sortParam === "Most Popular") {
      sortChangeHandler({ target: { name: "VOTES" } });
    } else if (sortParam) {
      const sortName = searchSortArr.find(
        (el) => el.name?.toLowerCase() === sortParam?.toLowerCase()
      );
      sortChangeHandler({ target: { name: sortName?.label } });
    }
  }, [params]);

  return (
    <>
      <h1 className={styles.title}>Sort by</h1>
      <div>
        {searchSortArr?.map((cat, i) => (
          <div key={cat?.name}>
            <Input
              elementType='radio'
              hasLabel={true}
              label={cat?.name}
              value={cat?.label}
              name={cat?.label}
              inpContainerClass={styles.inpContainer}
              checked={i === activeIndex}
              changeHandler={sortChangeHandler}
              customCheckBox={styles.checkMark}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default SortBy;
SortBy.propTypes = {
  sortChangeHandler: PropTypes.func,
  sort: PropTypes.string,
};
