import React from "react";
import styles from "./ProfilePicture.module.css";
import placeholder from "../../../../../../assets/images/profilePlaceholder.png";
import PropTypes from "prop-types";
import { authVar } from "../../../../../../utils/cache";
import Cookies from "universal-cookie";
import { useReactiveVar } from "@apollo/client";

const ProfilePicture = (props) => {
  const { toggleHandler, variant, withInitials } = props;
  const userData = useReactiveVar(authVar);
  const { path } = userData?.avatar ?? {};

  const cookies = new Cookies();
  const userId = cookies.get("user-id");
  if (userId && !userData) return <div className={styles.loader} />;

  if (!path) {
    if (!withInitials) {
      return (
        <img
          src={placeholder}
          alt='profile picture'
          className={`${variant} profile-menu ${styles.lightBg}`}
          onClick={toggleHandler}
        />
      );
    } else {
      return (
        <div className={`${variant}`}>
          {userData?.firstName?.charAt(0)}
          {userData?.lastName?.charAt(0)}
        </div>
      );
    }
  }
  return (
    <img
      src={path}
      alt='profile picture'
      className={`${variant} profile-menu ${styles.lightBg}`}
      onClick={toggleHandler}
    />
  );
};

export default ProfilePicture;
ProfilePicture.propTypes = {
  toggleHandler: PropTypes.func,
  variant: PropTypes.string,
  withInitials: PropTypes.bool,
};
