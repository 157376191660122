import React from "react";
import BackButton from "../../Buttons/BackButton/BackButton";
import styles from "./HeaderBackBtn.module.css";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
const HeaderBackBtn = (props) => {
  const { backRoute, isEdit } = props;
  const navigate = useNavigate();

  const backHandler = () => {
    navigate(backRoute);
  };

  return (
    <button
      className={`${styles.backBtn} ${isEdit && styles.editStyle}`}
      onClick={backHandler}
    >
      <BackButton variant={styles.backArrow} />
      <span className={styles.backText}>BACK</span>
    </button>
  );
};

export default HeaderBackBtn;
HeaderBackBtn.propTypes = {
  backRoute: PropTypes.any,
  isEdit: PropTypes.bool,
};
