import { useState } from "react";
import Resizer from "react-image-file-resizer";
import { errorToastHandler } from ".";

const useImageHandler = (newWidth, newHeight, maxImages = null) => {
  const [errorMessage, setErrorMessage] = useState();
  const [images, setImages] = useState([]);
  const [resizing, setResizing] = useState(false);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        newWidth,
        newHeight,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const resizingHandler = async (files) => {
    setErrorMessage("");
    if (!files || files.length === 0) {
      return;
    }
    setResizing(true);
    const filesArr = [];
    const iterationLength = files.length < maxImages ? files.length : maxImages;
    for (let i = 0; i < iterationLength; i++) {
      if (!files[i].type.includes("image")) {
        setErrorMessage("Not valid file type");
      }
      let file = files[i];
      try {
        const image = await resizeFile(file);
        filesArr.push(image);
      } catch (err) {
        errorToastHandler();
      }
    }
    setImages(filesArr);
  };

  const changeHandler = (e) => {
    const files = e.target.files;
    resizingHandler(files);
    setResizing(false);
  };
  const dragHandler = (e) => e.preventDefault();

  const dropHandler = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    resizingHandler(files);
    setResizing(false);
  };
  const resetHandler = (e) => {
    e.target.value = null;
  };

  return {
    errorMessage,
    images,
    changeHandler,
    dragHandler,
    dropHandler,
    resizing,
    resetHandler,
  };
};
export default useImageHandler;
