import React from "react";
import ApplyEditBtn from "../../../Buttons/ApplyEditBtn/ApplyEditBtn";
import EditTooltip from "../../../UI/EditTooltip/EditTooltip";
import Input from "../../../UI/Input/Input";
import styles from "../AboutSection.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { itemEditField, editView } from "../../../../../utils/cache";
import useEditItem from "../../../../../hooks/useEditItem";
import useInput from "../../../../../hooks/useInput";
import { MIN_MESSAGE_LENGTH } from "../../../../../constants";
import { queryToRefetch } from "../../../../../utils";
import { useParams } from "react-router-dom";

const Specification = (props) => {
  const { prevSpecification, itemType } = props;
  const showEditTooltip = useReactiveVar(itemEditField);
  const isEditing = useReactiveVar(editView);

  const {
    value: desc,
    hasError: descError,
    errorMessage: descErrorMessage,
    valueChangeHandler: descHandler,
    valueBlurHandler: descBlurHandler,
  } = useInput(null, prevSpecification);
  const { id } = useParams();
  const { loading, editFormHandler } = useEditItem(
    id,
    {
      [`${
        itemType === "service" ? "specification" : "ingredientsAndNutritional"
      }`]: desc,
    },
    itemType
  );

  return (
    <div className={styles.container}>
      <EditTooltip
        show={showEditTooltip === "specification" && isEditing}
        variant={styles.descTooltip}
        anchorStyle='left'
        closeHandler={itemEditField.bind(null, "")}
      >
        <form onSubmit={editFormHandler}>
          <Input
            hasLabel
            label='ABOUT THE OWNER'
            placeholder='Add message here'
            type='text'
            elementType='textArea'
            inpContainerClass={`${styles.inpContainer} ${
              descError && styles.invalid
            }`}
            value={desc}
            changeHandler={descHandler}
            blurHandler={descBlurHandler}
            invalid={descError}
            errorMessage={descErrorMessage}
          />

          <ApplyEditBtn
            loading={loading}
            disable={
              desc?.length < MIN_MESSAGE_LENGTH || desc === prevSpecification
            }
          />
        </form>
      </EditTooltip>
      <div
        className={`${styles.desc}
      ${isEditing ? styles.edit : styles.noEdit}`}
        onClick={itemEditField.bind(null, "specification")}
      >
        <p>{prevSpecification}</p>
      </div>
    </div>
  );
};

export default Specification;
Specification.propTypes = {
  prevSpecification: PropTypes.string,
  itemType: PropTypes.string,
};
