import React from "react";
import PropTypes from "prop-types";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import styles from "./BusinessApplication.module.css";
import { checkUserRole } from "../../../utils";
import { USER_TYPES } from "../../../constants";

export default function IdVerificationSection({
  idPhotoURL,
  stripeIdentity,
  handleDeclineUserId,
  isSavingChanges,
  isNewUser,
  admin,
}) {
  const handleIdmessage = () => {
    if (checkUserRole(admin, USER_TYPES.admin))
      return "This business was created by an admin. No ID verification was acquired.";
    else
      return "This user was migrated from the previous version of OBWS. Their ID was approved in the previous version of OBWS.";
  };
  return (
    <div className={styles.section}>
      <AdminTitle title='ID Verification' />
      {/*  TODO: this code belongs to stripe id verification step may be reverted in the future  */}
      {/* {!isAdminUser(admin) && (
        <div className={styles.idDescContainer}>
          <p className={styles.idDescription}>
            Verified Name on ID:
            {`${stripeIdentity?.firstName} ${stripeIdentity?.lastName}`}
          </p>
          <p className={styles.idDescription}>
            Verified ID number: {stripeIdentity?.number}
          </p>
        </div>
      )} */}
      <div className={styles.group}>
        <div className={styles.imageContainer}>
          {isNewUser && !checkUserRole(admin, USER_TYPES.admin) ? (
            <img src={idPhotoURL} alt='id' className={styles.idImage} />
          ) : (
            <p className={styles.oldUserId}>{handleIdmessage()}</p>
          )}
        </div>
        <div>
          <button
            className={`${styles.btn} ${styles.declineBtn}`}
            onClick={handleDeclineUserId}
            disabled={isSavingChanges}
          >
            Decline ID
          </button>
        </div>
      </div>
    </div>
  );
}

IdVerificationSection.propTypes = {
  setIdPhotoURL: PropTypes.func,
  setIdPhotoId: PropTypes.func,
  idPhotoURL: PropTypes.string,
  stripeIdentity: PropTypes.object,
  handleDeclineUserId: PropTypes.func,
  isSavingChanges: PropTypes.bool,
  isNewUser: PropTypes.bool,
  admin: PropTypes.array,
};
