import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/useInput";
import { emailValidationRules } from "../../../utils/validationRules";
import Input from "../../GlobalComponents/UI/Input/Input";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import styles from "./EmailForm.module.css";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import resetPasswordRequest from "../../../graphql/mutations/authentication/resetPasswordRequest";

const EmailForm = (props) => {
  const {
    sendEmail,
    email,
    emailError,
    emailErrorMessage,
    emailHandler,
    emailBlurHandler,
    emailValid,
  } = props;
  const [resetRequest, { loading, error }] = useMutation(resetPasswordRequest);
  const [emailExists, setEmailExists] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (error && !emailError) {
      setErrorMessage(error?.message);
    } else if (emailErrorMessage === "This field is required.") {
      setErrorMessage("Please enter an Email Address");
    } else if (emailErrorMessage !== "This field is required.") {
      setErrorMessage(emailErrorMessage);
    }
  }, [emailErrorMessage, error]);

  useEffect(() => {
    if (error) {
      setEmailExists(true);
    }
  }, [error]);

  useEffect(() => {
    if (emailExists) {
      setEmailExists(false);
    }
  }, [email]);

  const submitHandler = (e) => {
    e.preventDefault();
    resetRequest({ variables: { email } }).then(() => {
      sendEmail();
    });
    setEmailExists(false);
  };
  return (
    <form className={styles.emailInp} onSubmit={submitHandler}>
      <p className={styles.subTitle}>
        Type in your email to reset your password
      </p>

      <Input
        hasLabel
        isRequired
        label='EMAIL'
        placeholder='Enter your e-mail'
        type='email'
        inpContainerClass={`${styles.inpContainer} ${
          (emailError || emailExists) && styles.invalid
        }`}
        value={email}
        changeHandler={emailHandler}
        blurHandler={emailBlurHandler}
        invalid={emailExists || emailError}
        errorMessage={errorMessage}
      />
      <RegisterNextBtn
        variant={styles.continueBtn}
        disabled={!emailValid}
        isLoading={loading}
      >
        {loading ? <Spinner /> : "CONTINUE"}
      </RegisterNextBtn>
    </form>
  );
};

export default EmailForm;
EmailForm.propTypes = {
  sendEmail: PropTypes.func,
  email: PropTypes.string,
  emailErrorMessage: PropTypes.string,
  emailError: PropTypes.bool,
  emailHandler: PropTypes.func,
  emailBlurHandler: PropTypes.func,
  emailValid: PropTypes.bool,
};
