import React from "react";
import PropTypes from "prop-types";
import styles from "./CellPhone.module.css";
import ImgsSlider from "../../GlobalComponents/UI/ImgsSlider/ImgsSlider";
import emptyImage from "../../../assets/images/emptyImage.svg";
import { ReactComponent as EmptyIcon } from "../../../assets/images/iconPlaceholder.svg";
import phoneIcon from "../../../assets/images/phone.png";
import mapIcon from "../../../assets/images/locationIcon.png";
import { ReactComponent as ShareIcon } from "../../../assets/images/shareIcon.svg";
import { ReactComponent as WebsiteIcon } from "../../../assets/images/websiteIcon.svg";
import { ReactComponent as PillPlaceholder } from "../../../assets/images/pillPlaceholder.svg";

const BusinessRegister = (props) => {
  const { businessData } = props;

  const businessImages = () => {
    if (!businessData?.featuredImage && !businessData?.randomImages) return [];
    else if (businessData?.featuredImage && !businessData?.randomImages)
      return businessData?.featuredImage;
    else if (businessData?.featuredImage && businessData?.randomImages)
      return [businessData?.featuredImage, ...businessData?.randomImages];
  };
  return (
    <div className={styles.bodyContainer}>
      <div className={styles.upperBody}>
        <div className={styles.categoriesContainer}>
          {businessData?.businessCategoriesNames?.map((category) => (
            <div
              key={category}
              className={`${styles.pillContainer} ${styles.categoryPill}`}
            >
              {category}
            </div>
          ))}
        </div>
        <div className={styles.categoriesContainer}>
          {businessData?.businessTypes?.map((type) => (
            <div
              key={type}
              className={`${styles.pillContainer} ${styles.typePill}`}
            >
              {type}
            </div>
          ))}
        </div>
        <h2 className={styles.businessTitle}>{businessData?.name ?? ""}</h2>
        <p className={styles.addressContainer}>
          {businessData?.addrLine1 ?? <PillPlaceholder />}
        </p>
        <div className={styles.iconsContainer}>
          <button className={styles.icon}>
            {businessData?.phone && <img src={phoneIcon} />}
          </button>
          <button className={styles.icon}>
            {businessData?.addrLine1 && <img src={mapIcon} />}
          </button>
          {businessData?.addrLine1 ? (
            <ShareIcon />
          ) : (
            <button className={styles.icon} />
          )}
        </div>
      </div>
      <div className={styles.carouselContainer}>
        <ImgsSlider
          rightArrowClass={styles.rightArrowContainer}
          leftArrowClass={styles.leftArrowContainer}
          containerClass={styles.slidesContainer}
          elementClass={styles.slideContainer}
          sliderImages={
            businessImages()?.length > 0
              ? businessImages()?.map((img) => ({
                  path: img?.path,
                }))
              : [{ path: emptyImage }]
          }
          website={businessData?.website ?? ""}
          noWebsiteButton
        />
        <div className={styles.websiteContainer}>
          {businessData?.website && <WebsiteIcon />}
        </div>
      </div>
    </div>
  );
};

export default BusinessRegister;
BusinessRegister.propTypes = {
  businessData: PropTypes.object,
};
