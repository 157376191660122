import React from "react";
import AboutAward from "../../components/LandingPage/AboutAward";
import LandingCountdown from "../../components/LandingPage/LandingCountdown";
import Criteria from "../../components/LandingPage/Criteria";
import HeroHeader from "../../components/LandingPage/HeroHeader";
import Judges from "../../components/LandingPage/Judges";
import ListYourBiz from "../../components/LandingPage/ListYourBiz";
import styles from "./LandingPage.module.css";
import ProcessAndBenefits from "../../components/LandingPage/ProcessAndBenefits";
import Nominations from "../../components/LandingPage/Nominations";
import { useReactiveVar } from "@apollo/client";
import { beyotaCompleted, beyotaStared, viewWinners } from "../../utils/cache";
import Winners from "../../components/LandingPage/Winners";
const LandingPage = () => {
  const isStarted = useReactiveVar(beyotaStared);
  const isCompleted = useReactiveVar(beyotaCompleted);
  const winnersAnnounced = useReactiveVar(viewWinners);
  return (
    <div className={styles.landingPage}>
      <LandingCountdown />
      <HeroHeader />
      <AboutAward />
      <ProcessAndBenefits />
      <Criteria />
      {winnersAnnounced && <Winners />}
      {(isStarted || isCompleted) && <Nominations />}
      {!winnersAnnounced && <Judges />}
      <ListYourBiz />
    </div>
  );
};

export default LandingPage;
