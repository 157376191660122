import React from "react";
import styles from "../AboutSection.module.css";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { itemEditField, editView } from "../../../../../utils/cache";
import useEditItem from "../../../../../hooks/useEditItem";
import EditTooltip from "../../../UI/EditTooltip/EditTooltip";
import Input from "../../../UI/Input/Input";
import ApplyEditBtn from "../../../Buttons/ApplyEditBtn/ApplyEditBtn";
import useInput from "../../../../../hooks/useInput";
import { MIN_MESSAGE_LENGTH } from "../../../../../constants";

const Description = (props) => {
  const { existingDesc, itemType } = props;
  const showEditTooltip = useReactiveVar(itemEditField);
  const isEditing = useReactiveVar(editView);
  const { id } = useParams();
  const {
    value: desc,
    hasError: descError,
    errorMessage: descErrorMessage,
    valueChangeHandler: descHandler,
    valueBlurHandler: descBlurHandler,
  } = useInput(null, existingDesc);

  const { loading, editFormHandler } = useEditItem(
    id,
    {
      desc,
    },
    itemType
  );

  return (
    <div className={styles.container}>
      <EditTooltip
        show={showEditTooltip === "description" && isEditing}
        variant={styles.descTooltip}
        anchorStyle='left'
        closeHandler={itemEditField.bind(null, "")}
      >
        <form onSubmit={editFormHandler}>
          <Input
            hasLabel
            isRequired
            label={`ABOUT THE ${itemType}`}
            placeholder='Add message here'
            type='text'
            elementType='textArea'
            inpContainerClass={`${styles.inpContainer} ${
              descError && styles.invalid
            }`}
            value={desc}
            changeHandler={descHandler}
            blurHandler={descBlurHandler}
            invalid={descError}
            errorMessage={descErrorMessage}
          />

          <ApplyEditBtn
            loading={loading}
            disable={desc?.length < MIN_MESSAGE_LENGTH || desc === existingDesc}
          />
        </form>
      </EditTooltip>
      <div className={styles.desc}>
        <div
          className={`${styles.desc}
      ${isEditing ? styles.edit : styles.noEdit}`}
          onClick={itemEditField.bind(null, "description")}
        >
          <p>{existingDesc}</p>
        </div>
      </div>
    </div>
  );
};

export default Description;
Description.propTypes = {
  existingDesc: PropTypes.string,
  itemType: PropTypes.string,
};
