import React, { useEffect } from "react";
import styles from "./Settings.module.css";
import PropTypes from "prop-types";
import useInput from "../../hooks/useInput";
import { businessPhoneValidation } from "../../utils/validationRules";
import Input from "../../components/GlobalComponents/UI/Input/Input";

const BusinessPhone = (props) => {
  const { phone, updateVariables } = props;
  const {
    value: businessPhone,
    hasError: businessPhoneError,
    errorMessage: businessPhoneErrorMessage,
    valueChangeHandler: businessPhoneHandler,
    valueBlurHandler: businessPhoneBlurHandler,
    isValid: businessPhoneValid,
  } = useInput(
    businessPhoneValidation,
    phone?.indexOf("+") === -1 ? `+1${phone}` : phone,
    null,
    true
  );
  useEffect(() => {
    updateVariables({
      phone: businessPhone,
    });
  }, [businessPhone]);

  return (
    <Input
      hasLabel
      label='BUSINESS PHONE NUMBER'
      placeholder='e.g. 555-555-5555'
      elementType='phone'
      inpContainerClass={styles.inpContainer}
      value={businessPhone}
      changeHandler={businessPhoneHandler}
      blurHandler={businessPhoneBlurHandler}
      invalid={businessPhoneError}
      errorMessage={businessPhoneErrorMessage}
    />
  );
};

export default BusinessPhone;
BusinessPhone.propTypes = {
  phone: PropTypes.string,
  updateVariables: PropTypes.func,
};
