import { gql } from "@apollo/client";
export default gql`
  mutation uploadStripeVerification($sessionId: String!) {
    uploadStripeVerification(sessionId: $sessionId) {
      id
      firstName
      lastName
    }
  }
`;
