import React from "react";
import PropTypes from "prop-types";
import ItemForm from "../../BusinessOnboarding/ProductsAndServices/ItemForm";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import { ITEM_STATUS } from "../../../constants";
import styles from "./BusinessApplication.module.css";
export default function ProductsSection({
  suppliesData,
  handleItemChange,
  handleDeleteItem,
  items,
}) {
  return (
    <div className={styles.section}>
      <AdminTitle title="Products & Services" />
      {/* TODO merge featured image to randomImages array*/}
      {items?.map((item, index) => {
        if (item.status !== ITEM_STATUS.isDeleted) {
          return (
            <ItemForm
              item={item}
              key={`${item.id}-${index}`}
              itemCategories={
                suppliesData?.length > 0
                  ? suppliesData[index]?.item.categories
                  : []
              }
              itemGallery={
                suppliesData?.length > 0
                  ? suppliesData[index]?.item?.randomImages?.length > 0
                    ? suppliesData[index]?.item?.randomImages
                    : suppliesData[index]?.item?.featuredImage
                    ? [suppliesData[index]?.item?.featuredImage]
                    : []
                  : []
              }
              handleItemChange={handleItemChange}
              handleDeleteItem={handleDeleteItem}
            />
          );
        }
      })}
    </div>
  );
}

ProductsSection.propTypes = {
  suppliesData: PropTypes.array,
  handleItemChange: PropTypes.func,
  handleDeleteItem: PropTypes.func,
  items: PropTypes.array,
};
