import React from "react";
import styles from "./Collection.module.css";
import CollectionsHeader from "../../components/Collections/CollectionsHeader/CollectionsHeader";
import SavedItems from "../../components/Collections/SavedItems/SavedItems";

const Collection = () => (
  <div className={styles.collectionPage}>
    <CollectionsHeader />
    <SavedItems />
  </div>
);

export default Collection;
