import { gql } from "@apollo/client";
export default gql`
  query editorPicks($page: pageNames, $pageNum: Int) {
    editorPicks(page: $page) {
      __typename
      ... on Product {
        id
        name
        desc
        price
        votes
        isVoted
        isBookmarked
        collections(page: $pageNum) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }
        featuredImage {
          id
          path
        }
        categories {
          name
          parent {
            name
          }
        }
        business {
          name
          id
          businessTypes
          categories {
            name
            parent {
              name
            }
          }
          representative {
            firstName
            id
            lastName
            desc
            avatar {
              path
            }
          }
        }
      }
      ... on Service {
        id
        name
        desc
        price
        votes
        isVoted
        isBookmarked
        collections(page: $pageNum) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }

        featuredImage {
          id
          path
        }
        categories {
          name
          parent {
            name
          }
        }
        business {
          name
          id
          businessTypes
          categories {
            name
            parent {
              name
            }
          }
          representative {
            firstName
            id
            lastName
            desc
            avatar {
              path
            }
          }
        }
      }
      ... on Food {
        id
        name
        desc
        price
        votes
        isVoted
        isBookmarked
        collections(page: $pageNum) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }

        featuredImage {
          id
          path
        }
        categories {
          name
          parent {
            name
          }
        }
        business {
          name
          id
          businessTypes
          categories {
            name
            parent {
              name
            }
          }
          representative {
            firstName
            id
            lastName
            desc
            avatar {
              path
            }
          }
        }
      }
      ... on Business {
        id
        name
        businessTypes
        categories {
          name
        }
        representative {
          firstName
          id
          lastName
          desc
          avatar {
            path
          }
        }

        votes
        isVoted
        isBookmarked
        collections(page: $pageNum) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }

        categories {
          name
          parent {
            name
          }
        }
        city {
          name
        }
        state {
          abbr
        }
        addrLine1
        featuredImage {
          id
          path
        }
      }
    }
  }
`;
