import React from "react";
import RegisterForm from "../../components/ConsumerRegister/RegisterForm/RegisterForm";
import styles from "./BusinessRegister.module.css";
import Tap from "@tapfiliate/tapfiliate-js";

const BusinessRegister = () => {
  return (
    <div className={styles.registerPage}>
      <div className={styles.progressBarContainer} />
      <div className={styles.registerContainer}>
        <RegisterForm />
      </div>
    </div>
  );
};

export default BusinessRegister;
