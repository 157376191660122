import React, { useEffect } from "react";
import regStyles from "./Discover.module.css";
import adminStyles from "../../assets/styles/shared/AdminEditor.module.css";
import DiscoverHeroHeader from "../../components/GlobalComponents/Sections/DiscoverHeroHeader/DiscoverHeroHeader";
import EditorPicksSection from "../../components/GlobalComponents/Sections/EditorPicksSection/EditorPicksSection";
import ExploreThePlatform from "../../components/GlobalComponents/Sections/ExploreThePlatform/ExploreThePlatform";
import BrowseAllSection from "../../components/GlobalComponents/Sections/BrowseAllSection/BrowseAllSection";
import TryOBWS from "../../components/GlobalComponents/Sections/TryOBWS/TryOBWS";
import MostPopularSection from "../../components/GlobalComponents/Sections/MostPopularSection/MostPopularSection";
import SignUpAd from "../../components/GlobalComponents/Sections/SignUpAd/SignUpAd";
import { categories } from "../../components/GlobalComponents/Sections/ExploreThePlatform/ExploreThePlatform.stories";
import adImg from "../../assets/images/signUpAd.png";
import adImgWebp from "../../assets/images/signUpAd.webp";
import { useQuery, useReactiveVar } from "@apollo/client";
import discoverMostPopular from "../../graphql/queries/mostPopular/discoverMostPopular";
import { isAdmin } from "../../utils/cache";
import GetListedBtn from "../../components/GlobalComponents/Buttons/GetListedBtn/GetListedBtn";
import Picture from "../../components/GlobalComponents/UI/Picture";
import Tap from "@tapfiliate/tapfiliate-js";
import { PAGES_NAMES } from "../../constants";
import RecentlyListed from "../../components/GlobalComponents/Sections/RecentlyListed";
const Discover = () => {
  const { data, loading, error } = useQuery(discoverMostPopular);
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod") {
      Tap.init("35661-e23a0a", { integration: "stripe" });
    }
  }, []);

  return (
    <div className={styles.discoverPage}>
      <DiscoverHeroHeader loaded={!loading} />
      <EditorPicksSection pageName={PAGES_NAMES.DISCOVER} />
      <RecentlyListed pageName={PAGES_NAMES.DISCOVER} />
      <ExploreThePlatform categories={categories} />
      <BrowseAllSection pageName={PAGES_NAMES.DISCOVER} />
      <TryOBWS />
      <div className={styles.overFlowContainer}>
        <Picture
          fallback={adImg}
          alt='sign up ad'
          variant={styles.adImg}
          webpSrc={adImgWebp}
        />
        <GetListedBtn variant={styles.learn}>Get Started</GetListedBtn>
        <MostPopularSection
          sectionTitle='Most Popular'
          mostPopular={data?.discoverMostPopular}
          loading={loading}
          error={error}
          pageName={PAGES_NAMES.DISCOVER}
        />
        <SignUpAd />
      </div>
    </div>
  );
};

export default Discover;
