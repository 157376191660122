import React, { useEffect, useState } from "react";
import Input from "../../../GlobalComponents/UI/Input/Input";
import styles from "./Price.module.css";
import PropTypes from "prop-types";
import Spinner from "../../../GlobalComponents/UI/Spinner/Spinner";
import ErrorComponent from "../../../GlobalComponents/UI/ErrorComponent/ErrorComponent";

const Price = (props) => {
  const [maxRange, setMaxRange] = useState(0);
  const [minRange, setMinRange] = useState(0);
  const {
    handleRangeMinChange,
    handleRangeMaxChange,
    rangeMinValue,
    rangeMaxValue,
    range,
    mouseUpHandler,
    loading,
    error,
  } = props;
  useEffect(() => {
    if (range) {
      setMaxRange(range[0]?.price?.toFixed(0));
      setMinRange(range[1]?.price?.toFixed(0));
    }
  }, [range]);

  return (
    <div className={styles.priceContainer}>
      <h1 className={styles.title}>Price</h1>
      {loading && <Spinner variant={styles.loader} />}
      {error && <ErrorComponent variant={styles.errorMessage} />}
      {minRange && maxRange ? (
        <div className={styles.container}>
          <span className={styles.mark}>$</span>
          <Input
            elementType='twoValuesSlider'
            rangeMinValue={+rangeMinValue}
            rangeMaxValue={+rangeMaxValue}
            rangeMinText='$'
            rangeMaxText='$$$$'
            hasLabel={false}
            maxRange={+maxRange}
            minRange={+minRange}
            mouseUpHandler={mouseUpHandler}
            inpContainerClass={styles.inpContainer}
            handleRangeMaxChange={handleRangeMaxChange}
            handleRangeMinChange={handleRangeMinChange}
            rangeSliderClass={styles.slider}
          />
          <span className={styles.mark}>$$$$</span>
        </div>
      ) : null}
    </div>
  );
};

export default Price;
Price.propTypes = {
  handleRangeMinChange: PropTypes.func,
  handleRangeMaxChange: PropTypes.func,
  mouseUpHandler: PropTypes.func,
  rangeMinValue: PropTypes.number,
  rangeMaxValue: PropTypes.number,
  range: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.any,
};
