import React, { useEffect, useState } from "react";
import styles from "./ResetPassword.module.css";
import adImg from "../../assets/images/resetImg.png";
import logo from "../../assets/images/resetLogo.png";
import Input from "../../components/GlobalComponents/UI/Input/Input";
import RegisterNextBtn from "../../components/GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import useInput from "../../hooks/useInput";
import {
  confirmPasswordRules,
  passwordValidationRules,
} from "../../utils/validationRules";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import resetPassword from "../../graphql/mutations/authentication/resetPassword";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";
import isResetValid from "../../graphql/queries/isResetValid";

const ResetPassword = () => {
  const [reset, { loading }] = useMutation(resetPassword);
  const [checkForLink, { data, error, loading: checkLoading }] =
    useLazyQuery(isResetValid);
  const [btnText, setBtnText] = useState("");
  useEffect(() => {
    const email = resetParams.get("email");
    checkForLink({ variables: { email } }).then(({ data }) => {
      if (data?.isResetLinkValid) {
        setBtnText("UPDATE PASSWORD");
        setFormIsValid(false);
      } else {
        setBtnText("Navigate to forgot password");
        setFormIsValid(true);
      }
    });
  }, []);
  const {
    value: password,
    hasError: passwordError,
    errorMessage: passwordErrorMessage,
    valueChangeHandler: passwordHandler,
    valueBlurHandler: passwordBlurHandler,
    isValid: passwordValid,
  } = useInput(passwordValidationRules);

  const {
    value: rePassword,
    hasError: rePasswordError,
    errorMessage: confirmPasswordErrorMessage,
    valueChangeHandler: rePasswordHandler,
    valueBlurHandler: rePasswordBlurHandler,
    isValid: confirmPasswordValid,
  } = useInput(confirmPasswordRules, null, password && password);

  const [formIsValid, setFormIsValid] = useState(false);
  const { pathname } = useLocation();
  const [resetParams, setResetParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !pathname.includes("/password-reset") ||
      !resetParams.has("email") ||
      !resetParams.has("expiresAt") ||
      !resetParams.has("code")
    ) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (passwordValid && confirmPasswordValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [passwordError, confirmPasswordValid]);

  const clickHandler = () => {
    const code = resetParams.get("code");
    const email = resetParams.get("email");
    const expiresAt = +resetParams.get("expiresAt");
    if (data?.isResetLinkValid) {
      reset({ variables: { code, email, expiresAt, password } }).then(() =>
        navigate("/login")
      );
    } else {
      navigate("/forgot-password");
    }
  };

  return (
    <div className={styles.resetPage}>
      <img src={adImg} alt='img' className={styles.resetImg} />
      <div className={styles.content}>
        <img src={logo} alt='logo' className={styles.logo} />
        {checkLoading && <Spinner variant={styles.loader} />}
        {!data?.isResetLinkValid && !checkLoading && (
          <p className={styles.errorMessage}>
            This link has expired, please return to the forgot password screen
            to generate a new link
          </p>
        )}
        {data?.isResetLinkValid && !checkLoading && (
          <>
            <h6 className={styles.header}>Add New Password</h6>
            <Input
              hasLabel
              isRequired
              label='NEW PASSWORD'
              placeholder='Enter Password'
              type='password'
              inpContainerClass={`${styles.inpContainer} ${
                passwordError && styles.invalid
              }`}
              value={password}
              changeHandler={passwordHandler}
              blurHandler={passwordBlurHandler}
              invalid={passwordError}
              errorMessage={passwordErrorMessage}
            />
            <Input
              hasLabel
              isRequired
              label='CONFIRM PASSWORD'
              placeholder='Confirm Password'
              type='password'
              inpContainerClass={`${styles.inpContainer} ${
                rePasswordError && styles.invalid
              }`}
              value={rePassword}
              changeHandler={rePasswordHandler}
              blurHandler={rePasswordBlurHandler}
              invalid={rePasswordError}
              errorMessage={confirmPasswordErrorMessage}
            />
          </>
        )}
        <RegisterNextBtn
          variant={styles.continueBtn}
          disabled={!formIsValid}
          clickHandler={clickHandler}
          isLoading={loading}
        >
          {loading ? <Spinner /> : btnText}
        </RegisterNextBtn>
      </div>
    </div>
  );
};

export default ResetPassword;
