import React, { useEffect, useState } from "react";
import ApplyEditBtn from "../../../Buttons/ApplyEditBtn/ApplyEditBtn";
import EditTooltip from "../../../UI/EditTooltip/EditTooltip";
import Input from "../../../UI/Input/Input";
import styles from "../AboutSection.module.css";
import PropTypes from "prop-types";
import { useMutation, useReactiveVar } from "@apollo/client";
import { itemEditField, editView } from "../../../../../utils/cache";
import useInput from "../../../../../hooks/useInput";
import { nameValidationRules } from "../../../../../utils/validationRules";
import updateUser from "../../../../../graphql/mutations/updateUser";
import business from "../../../../../graphql/queries/pages/business";
import {
  errorToastHandler,
  queryToRefetch,
  successToastHandler,
} from "../../../../../utils";
import { ITEM_TYPES } from "../../../../../constants";

const OwnerName = (props) => {
  const { ownerFirstName, ownerLastName, itemType, ownerId, itemId } = props;
  const showEditTooltip = useReactiveVar(itemEditField);
  const isEditing = useReactiveVar(editView);
  const [formIsValid, setFormIsValid] = useState(false);

  const [updateOwner, { loading }] = useMutation(updateUser, {
    refetchQueries: [
      {
        query: queryToRefetch(itemType?.toLowerCase()),
        variables: { id: itemId },
      },
    ],
  });

  const {
    value: firstName,
    hasError: firstNameError,
    errorMessage: firstNameErrorMessage,
    valueChangeHandler: firstNameHandler,
    valueBlurHandler: firstNameBlurHandler,
    isValid: firstNameValid,
  } = useInput(nameValidationRules, ownerFirstName);
  const {
    value: lastName,
    hasError: lastNameError,
    errorMessage: lastNameErrorMessage,
    valueChangeHandler: lastNameHandler,
    valueBlurHandler: lastNameBlurHandler,
    isValid: lastNameValid,
  } = useInput(nameValidationRules, ownerLastName);

  useEffect(() => {
    if (
      (firstName !== ownerFirstName && firstNameValid) ||
      (lastName !== ownerLastName && lastNameValid)
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [firstNameValid, lastNameValid, lastName, firstName]);

  const submitHandler = (e) => {
    e.preventDefault();
    updateOwner({ variables: { id: ownerId, firstName, lastName } })
      .then(() => {
        itemEditField(null);
        successToastHandler("UPDATED SUCCESSFULLY");
        analytics.identify(ownerId, {
          firstName: firstName,
          lastName: lastName,
        });
      })
      .catch(() => errorToastHandler());
  };
  return (
    <div className={styles.container}>
      <EditTooltip
        show={showEditTooltip === "ownerName" && isEditing}
        variant={styles.nameTooltip}
        anchorStyle='top'
        closeHandler={itemEditField.bind(null, "")}
      >
        <form onSubmit={submitHandler}>
          <Input
            hasLabel
            isRequired
            label='OWNER FIRST NAME'
            placeholder='e.g. Isaiah'
            type='text'
            inpContainerClass={`${styles.inpContainer} ${styles.first} ${
              firstNameError && styles.invalid
            }`}
            value={firstName}
            changeHandler={firstNameHandler}
            blurHandler={firstNameBlurHandler}
            invalid={firstNameError}
            errorMessage={firstNameErrorMessage}
          />
          <Input
            hasLabel
            isRequired
            label='OWNER LAST NAME'
            placeholder='e.g. Isaiah'
            type='textarea'
            inpContainerClass={`${styles.inpContainer} ${
              lastNameError && styles.invalid
            }`}
            value={lastName}
            changeHandler={lastNameHandler}
            blurHandler={lastNameBlurHandler}
            invalid={lastNameError}
            errorMessage={lastNameErrorMessage}
          />

          <ApplyEditBtn loading={loading} disable={!formIsValid} />
        </form>
      </EditTooltip>
      <h6
        className={`${styles.userName}
        ${
          isEditing && itemType === ITEM_TYPES?.BUSINESS
            ? styles.edit
            : styles.noEdit
        }`}
        onClick={
          itemType === ITEM_TYPES?.BUSINESS
            ? itemEditField.bind(null, "ownerName")
            : null
        }
      >
        {ownerFirstName} {ownerLastName}
      </h6>
    </div>
  );
};

export default OwnerName;
OwnerName.propTypes = {
  ownerFirstName: PropTypes.string,
  ownerLastName: PropTypes.string,
  itemType: PropTypes.string,
  ownerId: PropTypes.string,
  itemId: PropTypes.string,
};
