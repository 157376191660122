import { gql } from "@apollo/client";
export default gql`
  query ($id: ID!, $page: Int, $size: Int) {
    category(id: $id) {
      id
      name
      children(size: $size, page: $page) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          id
          name
        }
      }
    }
  }
`;
