import React from "react";
import regStyles from "./TryOBWS.module.css";
import adminStyles from "./AdminStyles.module.css";
import NotAuthorized from "./NotAuthorized/NotAuthorized";
import AuthorizedConsumer from "./AuthorizedConsumer/AuthorizedConsumer";
import { useReactiveVar } from "@apollo/client";
import { authVar, isAdmin } from "../../../../utils/cache";

const TryOBWS = () => {
  const isAuth = useReactiveVar(authVar);
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  return (
    <section
      className={`${styles.tryObws} ${
        isAuth ? styles.authenticated : styles.notAuthenticated
      }`}
    >
      <div className={styles.tryObwsContainer}>
        {isAuth ? <AuthorizedConsumer /> : <NotAuthorized />}
      </div>
    </section>
  );
};

export default TryOBWS;
