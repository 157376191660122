import React from "react";
import Modal from "../Modal/Modal";
import styles from "./ConfirmationModal.module.css";
import PropTypes from "prop-types";
import closeIcon from "../../../../assets/images/close.svg";
import Backdrop from "../Backdrop/Backdrop";
import Spinner from "../Spinner/Spinner";

const ConfirmationModal = (props) => {
  const {
    confirmHandler,
    cancelHandler,
    message,
    show,
    loading,
    noActionModal,
  } = props;
  return (
    <Backdrop show={show} clickHandler={cancelHandler}>
      <Modal variant={styles.popUp}>
        <img
          src={closeIcon}
          className={styles.close}
          onClick={cancelHandler}
          alt='close'
        />
        <p className={styles.message}>{message}</p>
        <div className={styles.btnsContainer}>
          {!noActionModal && (
            <button
              className={`${styles.confirm} ${loading && styles.loading}`}
              onClick={confirmHandler}
            >
              {loading ? <Spinner variant={styles.loader} /> : "YES"}
            </button>
          )}
          <button className={styles.cancel} onClick={cancelHandler}>
            {noActionModal ? "OK" : "NO"}
          </button>
        </div>
      </Modal>
    </Backdrop>
  );
};

export default ConfirmationModal;
ConfirmationModal.propTypes = {
  confirmHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  message: PropTypes.string,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  noActionModal: PropTypes.bool,
};
