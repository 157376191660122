import { gql } from "@apollo/client";
export default gql`
  query ($size: Int, $subCategoryPage: Int, $subCategorySize: Int) {
    mainCategories(size: $size) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
        parent {
          id
          name
        }
        children(size: $subCategorySize, page: $subCategoryPage) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }
      }
    }
  }
`;
