import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import updateBusinessMutation from "../../../graphql/mutations/updateBusiness";
import search from "../../../graphql/queries/search/search";
import UserPicture from "../../GlobalComponents/UI/UserPicture/UserPicture";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { errorToastHandler, successToastHandler } from "../../../utils";

import {
  BUSINESS_STATUS,
  BusinessOnboardingState,
  BUSINESS_SEARCH_TYPES,
} from "../../../constants";
import rowStyles from "../../GlobalComponents/Admin/AdminTableStyles/Row.module.css";
import Tags from "../../GlobalComponents/UI/Tags/Tags";

const ITEMS_SIZE = 20;
export default function BusinessRequestRow({ business, handleToggleRefetch }) {
  const { representative } = business;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDeclinedRequestsPage = pathname.includes("declined-requests");

  const [updateBusiness, { loading: updateBusinessLoading }] = useMutation(
    updateBusinessMutation,
    {
      refetchQueries: [
        {
          query: search,

          variables: {
            text: "",
            page: 1,
            size: ITEMS_SIZE,
            filters: {
              typesAndCategories: {
                types: BUSINESS_SEARCH_TYPES,
                status: isDeclinedRequestsPage
                  ? [BUSINESS_STATUS.DISAPPROVED, BUSINESS_STATUS.ID_DECLINED]
                  : [BUSINESS_STATUS.UNDER_REVIEW],
              },
            },
          },
        },
      ],
    }
  );

  const handleApproveBusiness = () => {
    updateBusiness({
      variables: {
        id: business.id,
        onboardingState: BusinessOnboardingState.approve,
      },
    })
      .then(() => {
        handleToggleRefetch();
        successToastHandler("APPROVED SUCCESSFULLY");
      })
      .catch(() => errorToastHandler());
  };

  const handleViewApplication = () => {
    navigate(`/admin/view-app/${business.id}`);
  };

  return (
    <tr className={rowStyles.row}>
      <td className={rowStyles.name}>
        <p>{business.name}</p>
      </td>
      <td className={`${rowStyles.column} ${rowStyles.ownerColumn}`}>
        <UserPicture
          path={representative?.avatar?.path}
          variant={rowStyles.avatar}
        />
        <div className={rowStyles.ownerTextWrapper}>
          <p
            className={rowStyles.ownerName}
          >{`${representative?.firstName} ${representative?.lastName}`}</p>
          {representative.desc && (
            <p className={rowStyles.ownerDesc}>{representative?.desc}</p>
          )}
        </div>
      </td>
      <td className={`${rowStyles.column} ${rowStyles.flexCell}`}>
        <div className={rowStyles.tagsList}>
          {business?.businessTypes[0] && (
            <Tags tag={business?.businessTypes[0] || ""} isBusinessType />
          )}
          {business?.categories[0] && (
            <Tags tag={business?.categories[0]?.name || ""} />
          )}
        </div>
      </td>
      <td className={`${rowStyles.column} ${rowStyles.alignEnd}`}>
        <div className={rowStyles.buttonsWrapper}>
          {updateBusinessLoading && <Spinner />}
          <button
            className={rowStyles.viewButton}
            onClick={handleViewApplication}
          >
            View Application
          </button>
          <button
            className={rowStyles.approveButton}
            onClick={handleApproveBusiness}
          >
            APPROVE
          </button>
        </div>
      </td>
    </tr>
  );
}
BusinessRequestRow.propTypes = {
  business: PropTypes.object,
  handleToggleRefetch: PropTypes.func,
};
