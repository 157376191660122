import { gql } from "@apollo/client";

export default gql`
  mutation UPDATE_SERVICE(
    $id: ID!
    $featuredImage: ID
    $name: String
    $desc: String
    $price: Float
    $categories: [ID]
    $website: String
    $gallery: [ID]
    $specification: String
  ) {
    updateService(
      id: $id
      featuredImage: $featuredImage
      name: $name
      desc: $desc
      price: $price
      categories: $categories
      website: $website
      gallery: $gallery
      specification: $specification
    ) {
      id
      business {
        id
        name
        supplies {
          data {
            item {
              ... on Product {
                name
              }
              ... on Service {
                name
              }
              ... on Food {
                name
              }
            }
          }
        }
      }
    }
  }
`;
