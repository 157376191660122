import React from "react";
import styles from "./Loader.module.css";
const Loader = () => {
  return (
    <div className={styles.content}>
      <div className={styles.imgBtn}>
        <div className={`${styles.heroImg} ${styles.loading}`}></div>
        <div className={`${styles.learn} ${styles.learnLoading}`}></div>
      </div>
      <div className={styles.info}>
        <div className={`${styles.title} ${styles.loading}`}></div>
        <div className={`${styles.desc} ${styles.loading}`}></div>
        <div className={`${styles.add} ${styles.loading}`}></div>
      </div>
    </div>
  );
};

export default Loader;
