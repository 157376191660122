import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import useInput from "../../../hooks/useInput";
import business from "../../../graphql/queries/pages/business";
import states from "../../../graphql/queries/states";
import cities from "../../../graphql/queries/cities";
import updateUserMutation from "../../../graphql/mutations/updateUser";
import updateBusinessMutation from "../../../graphql/mutations/updateBusiness";
import declineUserIdMutation from "../../../graphql/mutations/declineUserId";
import search from "../../../graphql/queries/search/search";
import updateFood from "../../../graphql/mutations/updateFood";
import updateProduct from "../../../graphql/mutations/updateProduct";
import updateService from "../../../graphql/mutations/updateService";
import deleteProduct from "../../../graphql/mutations/deleteProduct";
import deleteService from "../../../graphql/mutations/deleteService";
import deleteFood from "../../../graphql/mutations/deleteFood";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import UserPicture from "../../GlobalComponents/UI/UserPicture/UserPicture";
import BusinessOwnerSection from "./BusinessOwnerSection";
import BusinessNameSection from "./BusinessNameSection";
import BusinessPhoneSection from "./BusinessPhoneSection";
import BusinessWebSiteSection from "./BusinessWebSiteSection";
import BusinessAddressSection from "./BusinessAddressSection";
import IdVerificationSection from "./IdVerificationSection";
import ProductsSection from "./ProductsSection";

import {
  ITEM_STATUS,
  ITEM_TYPES,
  BusinessOnboardingState,
  BUSINESS_SEARCH_TYPES,
  BUSINESS_STATUS,
} from "../../../constants";
import {
  emailValidationRules,
  itemWebsiteValidation,
  businessWebsiteValidation,
  descriptionValidation,
  businessPhoneValidation,
  businessAddressValidationRules,
  stateValidationRules,
  zipCodeValidationRules,
  countryValidationRules,
  businessNameValidation,
  businessOwnerNameRules,
} from "../../../utils/validationRules";
import { inputValidation } from "../../../utils/inputValidation";
import {
  getUserNames,
  errorToastHandler,
  successToastHandler,
  isOnlineBusiness,
} from "../../../utils";
import styles from "./BusinessApplication.module.css";

export default function BusinessApplication() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading: loadingBusiness } = useQuery(business, {
    variables: {
      id,
    },
  });
  const { data: statesData, loading: stateLoading } = useQuery(states);
  const { data: citiesData, loading: cityLoading } = useQuery(cities);

  const businessData = data?.business ?? {};
  const suppliesData = businessData?.supplies?.data;
  const representative = businessData?.representative ?? {};
  const stripeIdentity = representative?.stripeIdentity ?? {};

  const searchQueryVariables = {
    text: "",
    page: 1,
    filters: {
      typesAndCategories: {
        types: BUSINESS_SEARCH_TYPES,
        status: [BUSINESS_STATUS.UNDER_REVIEW],
      },
    },
  };

  const [updateUser, { loading: loadingUpdateUser }] =
    useMutation(updateUserMutation);
  const [updateBusiness, { loading: loadingUpdateBusiness }] = useMutation(
    updateBusinessMutation,
    {
      refetchQueries: [
        {
          query: business,
          variables: {
            id: businessData?.id,
          },
        },
        {
          query: search,
          variables: searchQueryVariables,
        },
      ],
    }
  );
  const [updateProductItem, { loading: updateProductLoading }] =
    useMutation(updateProduct);
  const [updateServiceItem, { loading: updateServiceLoading }] =
    useMutation(updateService);
  const [updateFoodItem, { loading: updateFoodLoading }] =
    useMutation(updateFood);

  const [deleteProductItem, { loading: deleteProductLoading }] =
    useMutation(deleteProduct);
  const [deleteServiceItem, { loading: deleteServiceLoading }] =
    useMutation(deleteService);
  const [deleteFoodItem, { loading: deleteFoodLoading }] =
    useMutation(deleteFood);

  const [declineUserId, { loading: loadingDeclineUserId }] = useMutation(
    declineUserIdMutation,
    {
      refetchQueries: [
        {
          query: search,
          variables: searchQueryVariables,
        },
      ],
    }
  );

  const [items, setItems] = useState([]);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [isOnline, setIsOnline] = useState();
  const [addressLocation, setAddressLocation] = useState();
  const [idPhotoId, setIdPhotoId] = useState(stripeIdentity?.govImage?.id);
  const [idPhotoURL, setIdPhotoURL] = useState(stripeIdentity?.govImage?.path);

  const isSavingChanges =
    loadingUpdateUser ||
    loadingUpdateBusiness ||
    updateProductLoading ||
    updateServiceLoading ||
    updateFoodLoading ||
    deleteProductLoading ||
    deleteServiceLoading ||
    deleteFoodLoading ||
    loadingDeclineUserId;

  useEffect(() => {
    if (businessData) {
      setIsOnline(
        isOnlineBusiness(businessData?.addrLine1, businessData?.businessTypes)
      );
    }
  }, [businessData]);

  const {
    value: ownerName,
    hasError: ownerNameError,
    errorMessage: ownerNameErrorMessage,
    valueChangeHandler: ownerNameHandler,
    valueBlurHandler: ownerNameBlurHandler,
    isValid: ownerNameValid,
  } = useInput(
    businessOwnerNameRules,
    `${representative?.firstName} ${representative?.lastName}`
  );

  const {
    value: ownerEmail,
    hasError: ownerEmailError,
    errorMessage: ownerEmailErrorMessage,
    valueChangeHandler: ownerEmailHandler,
    valueBlurHandler: ownerEmailBlurHandler,
    isValid: ownerEmailValid,
  } = useInput(emailValidationRules, representative?.email);

  const {
    value: businessName,
    hasError: businessNameError,
    errorMessage: businessNameErrorMessage,
    valueChangeHandler: businessNameHandler,
    valueBlurHandler: businessNameBlurHandler,
    isValid: businessNameValid,
  } = useInput(businessNameValidation, businessData?.name);

  const {
    value: businessPhone,
    hasError: businessPhoneError,
    errorMessage: businessPhoneErrorMessage,
    valueChangeHandler: businessPhoneHandler,
    valueBlurHandler: businessPhoneBlurHandler,
    isValid: businessPhoneValid,
  } = useInput(businessPhoneValidation, businessData?.phone, null, true);

  const {
    value: businessWebsite,
    hasError: businessWebsiteError,
    errorMessage: businessWebsiteErrorMessage,
    valueChangeHandler: businessWebsiteHandler,
    valueBlurHandler: businessWebsiteBlurHandler,
    isValid: businessWebsiteValid,
  } = useInput(businessWebsiteValidation, businessData?.website);

  const {
    value: businessAddress,
    hasError: businessAddressError,
    errorMessage: businessAddressErrorMessage,
    valueChangeHandler: businessAddressHandler,
    valueBlurHandler: businessAddressBlurHandler,
    isValid: businessAddressValid,
  } = useInput(
    businessAddressValidationRules,
    businessData?.addrLine1,
    null,
    true
  );

  const {
    value: optionalAddress,
    hasError: optionalAddressError,
    errorMessage: optionalAddressErrorMessage,
    valueChangeHandler: optionalAddressHandler,
    valueBlurHandler: optionalAddressBlurHandler,
    isValid: optionalAddressValid,
  } = useInput({}, businessData?.addrLine2);

  const {
    value: city,
    hasError: cityError,
    errorMessage: cityErrorMessage,
    valueChangeHandler: cityHandler,
    valueBlurHandler: cityBlurHandler,
    isValid: cityValid,
  } = useInput(null, businessData?.city?.id);

  const {
    value: state,
    hasError: stateError,
    errorMessage: stateErrorMessage,
    valueChangeHandler: stateHandler,
    valueBlurHandler: stateBlurHandler,
    isValid: stateValid,
  } = useInput(stateValidationRules, businessData?.state?.id);
  const {
    value: zipCode,
    hasError: zipCodeError,
    errorMessage: zipCodeErrorMessage,
    valueChangeHandler: zipCodeHandler,
    valueBlurHandler: zipCodeBlurHandler,
    isValid: zipCodeValid,
  } = useInput(zipCodeValidationRules, businessData?.zip);

  const {
    value: country,
    hasError: countryError,
    errorMessage: countryErrorMessage,
    valueChangeHandler: countryHandler,
    valueBlurHandler: countryBlurHandler,
    isValid: countryValid,
  } = useInput(countryValidationRules, businessData?.country);

  useEffect(() => {
    if (suppliesData?.length > 0) {
      const mappedItems = suppliesData?.map(({ item }) => ({
        id: item.id,
        type: item.__typename,
        name: item.name,
        price: item.price,
        website:
          item.__typename === ITEM_TYPES.FOOD ? item.orderLink : item.website,
        gallery:
          item.randomImages?.length > 0
            ? item.randomImages?.map((image) => image.id)
            : item.featuredImage?.id
            ? [item.featuredImage?.id]
            : [],

        featuredImage: item?.featuredImage?.id,
        categories: item?.categories?.map((category) => category.id) || [],
        desc: item.desc,
        ingredientsAndNutritional: item.ingredients || "",
        specification: item.specification || "",
        isSubCategorySelected: item?.categories?.length > 1, // if categories has children selected
        status: ITEM_STATUS.isEdit,
      }));
      setItems(mappedItems);
    }
  }, [businessData]);

  useEffect(() => {
    if (
      (businessAddressValid &&
        optionalAddressValid &&
        zipCodeValid &&
        countryValid &&
        cityValid &&
        stateValid &&
        !isOnline) ||
      isOnline
    ) {
      setIsValidAddress(true);
    } else {
      setIsValidAddress(false);
    }
  }, [
    businessAddressValid,
    optionalAddressValid,
    cityValid,
    stateValid,
    zipCodeValid,
    countryValid,
    isOnline,
  ]);

  useEffect(() => {
    // validate form on mounting
    ownerNameBlurHandler();
    ownerEmailBlurHandler();
    businessNameBlurHandler();
    businessPhoneBlurHandler();
    businessWebsiteBlurHandler();
    businessAddressBlurHandler();
    optionalAddressBlurHandler();
    stateBlurHandler();
    zipCodeBlurHandler();
    countryBlurHandler();
  }, []);

  const toggleIsOnlineHandler = () => setIsOnline(!isOnline);

  const handleItemChange = (id, fieldName, val, isSubCategory = false) => {
    let newItems = items;
    let value = val;
    if (fieldName === "price") {
      value = value.replace(/[^0-9.]/g, "");
      value = +value;
    }

    newItems = newItems.map((item) => {
      if (item.id === id) {
        if (fieldName === "categories" && isSubCategory) {
          return { ...item, [fieldName]: value, isSubCategorySelected: true };
        } else if (fieldName === "categories" && !isSubCategory) {
          return { ...item, [fieldName]: value, isSubCategorySelected: false };
        } else if (fieldName === "gallery") {
          return { ...item, [fieldName]: value, featuredImage: value[0] };
        } else return { ...item, [fieldName]: value };
      } else return item;
    });
    setItems(newItems);
  };

  const handleDeleteItem = (itemId) => {
    const newItems = items.map((item) => {
      if (item.id === itemId) {
        return { ...item, status: ITEM_STATUS.isDeleted };
      } else {
        return item;
      }
    });
    setItems(newItems);
  };

  const handleSaveChanges = async () => {
    if (isSavingChanges) return;

    const {
      firstName: representativeFirstName,
      lastName: representativeLastName,
    } = getUserNames(ownerName);

    const cityValue = citiesData?.cities?.data?.find((cty) =>
      cty?.name?.toLowerCase()?.includes(city?.toLowerCase())
    )?.id;

    const stateValue = statesData?.states?.data?.find((stat) =>
      stat?.name?.toLowerCase()?.includes(state?.toLowerCase())
    )?.id;
    const updateOwnerPromise = updateUser({
      variables: {
        id: representative?.id,
        firstName: representativeFirstName,
        lastName: representativeLastName,
        email: ownerEmail,
      },
    })
      .then(() => {
        successToastHandler("UPDATED SUCCESSFULLY");
      })
      .catch(() => errorToastHandler());

    const updateBusinessPromise = updateBusiness({
      variables: {
        id: businessData?.id,
        name: businessName,
        phone: businessPhone,
        website: businessWebsite,
        addrLine1: businessAddress,
        addrLine2: optionalAddress,
        zip: zipCode,
        lat: addressLocation?.lat || businessData?.lat || 0,
        lng: addressLocation?.lng || businessData?.lng || 0,
        govImageId: idPhotoId || stripeIdentity?.govImage?.id,
        city: cityValue ? +cityValue : null,
        state: stateValue ? +stateValue : null,
      },
    });

    const itemsPromises = items.map((item) => {
      if (validateItem(item)) {
        const variables = {
          businessId: item.id,
          featuredImage: item.featuredImage,
          name: item.name,
          desc: item.desc,
          price: item.price,
          website: item.website,
          gallery: item?.gallery?.filter((id) => id !== item.featuredImage),
          categories: item.categories,
          specification: item.specification || "",
        };
        const foodVariables = {
          businessId: item.id,
          featuredImage: item.featuredImage,
          name: item.name,
          desc: item.desc,
          price: item.price,
          orderLink: item.website,
          gallery: item.gallery.filter((id) => id !== item.featuredImage),
          categories: item.categories,
          ingredientsAndNutritional: item.ingredientsAndNutritional,
        };

        switch (item.type) {
          case ITEM_TYPES.PRODUCT:
            switch (item.status) {
              case ITEM_STATUS.isDeleted:
                return deleteProductItem({
                  variables: { id: item.id },
                });
              case ITEM_STATUS.isEdit:
                return updateProductItem({
                  variables: { ...variables, id: item.id },
                });
            }
          case ITEM_TYPES.SERVICE:
            switch (item.status) {
              case ITEM_STATUS.isDeleted:
                return deleteServiceItem({
                  variables: { id: item.id },
                });
              case ITEM_STATUS.isEdit:
                return updateServiceItem({
                  variables: { ...variables, id: item.id },
                });
            }

          default:
            switch (item.status) {
              case ITEM_STATUS.isDeleted:
                return deleteFoodItem({
                  variables: { id: item.id },
                });
              case ITEM_STATUS.isEdit:
                return updateFoodItem({
                  variables: { ...foodVariables, id: item.id },
                });
            }
        }
      }
    });
    return await Promise.all([
      updateOwnerPromise,
      updateBusinessPromise,
      ...itemsPromises,
    ]);
  };

  const handleApproveBusiness = () => {
    updateBusiness({
      variables: {
        id: businessData?.id,
        onboardingState: BusinessOnboardingState.approve,
      },
    })
      .then(() => {
        successToastHandler("APPROVED SUCCESSFULLY");
        analytics.track("new_business_listed");
        navigate("/admin");
      })
      .catch(() => errorToastHandler());
  };

  const handleDeclineBusiness = (isIdVerificationDeclined) => {
    if (isIdVerificationDeclined) {
    } else {
      updateBusiness({
        variables: {
          id: businessData?.id,
          onboardingState: BusinessOnboardingState.disapprove,
        },
      }).then(() => {
        successToastHandler("DECLINED SUCCESSFULLY");
        navigate("/admin/decline-reason", {
          state: {
            email: representative?.email,
          },
        });
      });
    }
  };

  const handleDeclineUserId = () => {
    declineUserId({
      variables: {
        id: representative?.id,
      },
    }).then((data) => {
      successToastHandler("DECLINED SUCCESSFULLY");
      navigate("/admin");
    });
  };

  const validateItem = (item) => {
    const { isValid: isValidWebsite } = inputValidation(
      itemWebsiteValidation,
      item?.website
    );
    const { isValid: isValidName } = inputValidation(
      businessNameValidation,
      item?.name
    );
    const { isValid: isValidDescription } = inputValidation(
      descriptionValidation,
      item?.desc
    );
    const hasCategories = item?.categories?.length > 0;
    const hasPhotos = item?.gallery?.length > 0;
    const isValidPrice = item.price > 0;
    return (
      isValidWebsite &&
      isValidName &&
      isValidDescription &&
      hasCategories &&
      hasPhotos &&
      isValidPrice
    );
  };

  const hasValidItem = () =>
    items.every(
      (item) =>
        (item?.type && validateItem(item)) ||
        item?.status === ITEM_STATUS.isDeleted
    );

  const isAllItemsDeleted = () =>
    items.every((item) => item.status === ITEM_STATUS.isDeleted);

  const isValidOwner = ownerNameValid && ownerEmailValid;
  const isValidProductsSection = hasValidItem() && !isAllItemsDeleted();

  const shouldDisableSaveChanges =
    !isValidOwner ||
    !isValidProductsSection ||
    !isValidAddress ||
    !businessNameValid ||
    (!businessPhoneValid && businessPhone) ||
    !businessWebsiteValid;

  if (loadingBusiness) {
    return <Spinner />;
  }
  return (
    <div className={styles.businessAppContainer}>
      <p className={styles.viewingText}>Viewing “{businessData?.name}”</p>
      <div className={styles.avatarWrapper}>
        <AdminTitle title='Business Owner' />
        <UserPicture
          path={businessData?.representative?.avatar?.path || ""}
          variant={styles.avatar}
        />
      </div>
      <BusinessOwnerSection
        ownerName={ownerName}
        ownerNameHandler={ownerNameHandler}
        ownerNameBlurHandler={ownerNameBlurHandler}
        ownerNameError={ownerNameError}
        ownerNameErrorMessage={ownerNameErrorMessage}
        ownerEmail={ownerEmail}
        ownerEmailHandler={ownerEmailHandler}
        ownerEmailBlurHandler={ownerEmailBlurHandler}
        ownerEmailError={ownerEmailError}
        ownerEmailErrorMessage={ownerEmailErrorMessage}
      />
      <BusinessNameSection
        businessName={businessName}
        businessNameHandler={businessNameHandler}
        businessNameBlurHandler={businessNameBlurHandler}
        businessNameError={businessNameError}
        businessNameErrorMessage={businessNameErrorMessage}
      />
      <BusinessPhoneSection
        businessPhone={businessPhone}
        businessPhoneHandler={businessPhoneHandler}
        businessPhoneBlurHandler={businessPhoneBlurHandler}
        businessPhoneError={businessPhoneError}
        businessPhoneErrorMessage={businessPhoneErrorMessage}
      />
      <BusinessWebSiteSection
        businessWebsite={businessWebsite}
        businessWebsiteHandler={businessWebsiteHandler}
        businessWebsiteBlurHandler={businessWebsiteBlurHandler}
        businessWebsiteError={businessWebsiteError}
        businessWebsiteErrorMessage={businessWebsiteErrorMessage}
      />
      <BusinessAddressSection
        businessAddress={businessAddress}
        businessAddressError={businessAddressError}
        businessAddressErrorMessage={businessAddressErrorMessage}
        businessAddressHandler={businessAddressHandler}
        businessAddressBlurHandler={businessAddressBlurHandler}
        optionalAddress={optionalAddress}
        optionalAddressError={optionalAddressError}
        optionalAddressErrorMessage={optionalAddressErrorMessage}
        optionalAddressHandler={optionalAddressHandler}
        optionalAddressBlurHandler={optionalAddressBlurHandler}
        city={city}
        cityError={cityError}
        cityErrorMessage={cityErrorMessage}
        cityHandler={cityHandler}
        cityBlurHandler={cityBlurHandler}
        state={state}
        stateError={stateError}
        stateErrorMessage={stateErrorMessage}
        stateHandler={stateHandler}
        stateBlurHandler={stateBlurHandler}
        zipCode={zipCode}
        zipCodeError={zipCodeError}
        zipCodeErrorMessage={zipCodeErrorMessage}
        zipCodeHandler={zipCodeHandler}
        zipCodeBlurHandler={zipCodeBlurHandler}
        country={country}
        countryError={countryError}
        countryErrorMessage={countryErrorMessage}
        countryHandler={countryHandler}
        countryBlurHandler={countryBlurHandler}
        businessData={businessData}
        toggleIsOnlineHandler={toggleIsOnlineHandler}
        isOnline={isOnline}
        setAddressLocation={setAddressLocation}
      />
      <IdVerificationSection
        isNewUser={data?.business?.representative?.isNewUser}
        admin={data?.business?.representative?.roles}
        idPhotoURL={stripeIdentity?.govImage?.path}
        stripeIdentity={stripeIdentity}
        handleDeclineUserId={handleDeclineUserId}
        isSavingChanges={isSavingChanges}
      />
      <ProductsSection
        items={items}
        suppliesData={suppliesData}
        handleDeleteItem={handleDeleteItem}
        handleItemChange={handleItemChange}
      />

      <div className={styles.buttonsWrapper}>
        <button
          className={`${styles.btn} ${styles.saveBtn}`}
          disabled={shouldDisableSaveChanges || isSavingChanges}
          onClick={handleSaveChanges}
        >
          Save Changes
        </button>
        <button
          className={`${styles.btn} ${styles.approveBtn}`}
          onClick={handleApproveBusiness}
          disabled={isSavingChanges}
        >
          APPROVE
        </button>
        <button
          className={`${styles.btn} ${styles.declineBtn}`}
          disabled={isSavingChanges}
          onClick={() => handleDeclineBusiness(false)}
        >
          Decline
        </button>
        {isSavingChanges && <Spinner />}
      </div>
    </div>
  );
}
