import React from "react";
import styles from "./FilterBubbles.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import {
  appliedSearchFilters,
  notAppliedSearchFilters,
} from "../../../../utils/cache";
import deleteFilter from "../../../../assets/images/deleteFilter.svg";

const FilterBubbles = (props) => {
  const { applyFilter, applied, removeFilter } = props;
  const appliedFilters = useReactiveVar(appliedSearchFilters);
  const notAppliedFilters = useReactiveVar(notAppliedSearchFilters);

  if (applied) {
    return (
      <ul className={styles.appliedList}>
        {appliedFilters.map((filter, i) => (
          <li key={i} className={styles.appliedFilter} data-testid='filterTest'>
            <span className={styles.appliedName}>{filter?.name}</span>
            <img
              src={deleteFilter}
              alt={filter?.name}
              className={styles.remove}
              onClick={removeFilter.bind(null, filter?.filterType)}
            />
          </li>
        ))}
      </ul>
    );
  }

  return (
    <ul className={styles.filtersList}>
      {notAppliedFilters.map((filter, i) => (
        <li
          key={i}
          className={styles.filter}
          onClick={applyFilter.bind(null, filter.filterType)}
          data-testid='filterTest'
        >
          <img src={filter.icon} alt={filter.name} className={styles.icon} />
          <span className={styles.filterName}>{filter.name}</span>
        </li>
      ))}
    </ul>
  );
};

export default FilterBubbles;
FilterBubbles.propTypes = {
  applyFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  applied: PropTypes.bool,
};
