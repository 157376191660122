import React, { useState } from "react";
import styles from "./CollectionsHeader.module.css";
import toggle from "../../../assets/images/collectionToggle.png";
import { NavLink } from "react-router-dom";
import BackButton from "../../GlobalComponents/Buttons/BackButton/BackButton";
import Backdrop from "../../GlobalComponents/UI/Backdrop/Backdrop";
import SideMenu from "../SideMenu/SideMenu";
const CollectionsHeader = () => {
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const toggleBackdrop = () => {
    setShowBackdrop(!showBackdrop);
    setShowSideMenu(!showSideMenu);
  };
  return (
    <>
      <Backdrop show={showBackdrop} clickHandler={toggleBackdrop}>
        <SideMenu show={showSideMenu} cancelHandler={toggleBackdrop} />
      </Backdrop>
      <header className={styles.header}>
        <div className={styles.collectionHeader}>
          <NavLink to='/collections' className={styles.name}>
            <div className={styles.backLink}>
              <BackButton variant={styles.backBtn} />
              <p className={styles.backText}>COLLECTIONS</p>
            </div>
          </NavLink>
          <img
            src={toggle}
            className={styles.toggle}
            alt='toggle'
            onClick={toggleBackdrop}
          />
        </div>
      </header>
    </>
  );
};

export default CollectionsHeader;
