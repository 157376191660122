import React, { forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import styles from "./ConsumerOnboarding.module.css";
import  NextArrow  from "../../../assets/images/nextArrow.png";
import RegisterNextBtn from "../../../components/GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import setOnboarding from "../../../graphql/mutations/setOnboarding";
import { useMutation } from "@apollo/client";
import { consumerOnboardingVar } from "../../../utils/cache";
const ConsumerOnboarding = forwardRef((props, ref) => {
  const { heading, description, onboardingStep, handleNext } = props;

  const [setOnboardingFor] = useMutation(setOnboarding);

  const onArrowClick = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    handleNext();
  };

  useImperativeHandle(ref, () => ({
    handleClose(e) {
      e?.preventDefault();
      e?.stopPropagation();
      consumerOnboardingVar(-1);
      setOnboardingFor({ variables: { platform: "WEB" } });
    },
  }));

  return (
    <div className={styles.onboardingContainer}>
      {onboardingStep <= 6 && <p>{`${onboardingStep}/6`}</p>}
      <div>
        <p className={styles.onboardingHeading}>{heading}</p>
        <p className={styles.onboardingText}>{description}</p>
      </div>
      <div className={styles.buttonGroup}>
        <a onClick={(e) => ref?.current?.handleClose(e)}>SKIP FOR NOW</a>
        {onboardingStep === 6 ? (
          <RegisterNextBtn
            variant={styles.finishButton}
            clickHandler={(e) => ref?.current?.handleClose(e)}
          >
            FINISH
          </RegisterNextBtn>
        ) : (
          <img src={NextArrow} className={styles.NextArrow} onClick={onArrowClick}/>
        )}
      </div>
    </div>
  );
});

ConsumerOnboarding.displayName = "ConsumerOnboarding";
export default ConsumerOnboarding;

ConsumerOnboarding.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  handleNext: PropTypes.func,
  onboardingStep: PropTypes.number,
};
