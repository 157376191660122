import React, { useState } from "react";
import Modal from "../../GlobalComponents/UI/Modal/Modal";
import styles from "./SideMenu.module.css";
import PropTypes from "prop-types";
import MenuList from "./MenuList/MenuList";
import DeleteAlert from "./DeleteAlert/DeleteAlert";
import EditCollection from "./EditCollection/EditCollection";
import { useQuery } from "@apollo/client";
import collection from "../../../graphql/queries/collection";
import { useParams } from "react-router-dom";
import AddToCollection from "./AddToCollection/AddToCollection";
import RemoveFromCollection from "./RemoveFromCollection/RemoveFromCollection";

const SideMenu = (props) => {
  const { show, cancelHandler } = props;
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const { id } = useParams();
  const { data } = useQuery(collection, { variables: { id } });
  const { name } = data?.collection ?? {};

  const deleteAlertToggle = () => {
    setDeleteAlert(!deleteAlert);
    setShowMenu(!showMenu);
  };
  const editToggle = () => {
    setShowEdit(!showEdit);
    setShowMenu(false);
  };
  const addToggle = () => {
    setShowAdd(!showAdd);
    setShowMenu(false);
  };
  const removeToggle = () => {
    setShowRemove(!showRemove);
    setShowMenu(false);
  };
  return (
    <>
      {show ? (
        <Modal
          variant={` ${showAdd || showRemove ? styles.addPopUp : styles.popUp}`}
        >
          {showMenu && (
            <MenuList
              cancelHandler={cancelHandler}
              deleteToggle={deleteAlertToggle}
              editToggle={editToggle}
              addToggle={addToggle}
              removeToggle={removeToggle}
              collectionName={name}
            />
          )}
          {deleteAlert && <DeleteAlert alertToggle={deleteAlertToggle} />}
          {showEdit && (
            <EditCollection
              editToggle={editToggle}
              closeHandler={cancelHandler}
            />
          )}
          {showAdd && (
            <AddToCollection
              addToggle={addToggle}
              closeHandler={cancelHandler}
            />
          )}
          {showRemove && (
            <RemoveFromCollection
              removeToggle={removeToggle}
              closeHandler={cancelHandler}
            />
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default SideMenu;
SideMenu.propTypes = {
  show: PropTypes.bool,
  cancelHandler: PropTypes.func,
};
