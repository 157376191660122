import { gql } from "@apollo/client";
export default gql`
  query BROWSE_ALL($page: pageNames) {
    browseThings(page: $page) {
      name
      id
      desc
      priority
      color
    }
  }
`;
