import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { CATEGORIES_CHILDREN_PAGE_SIZE } from "../constants";
import getUser from "../graphql/queries/getUser";
import { authVar, rolesVar } from "../utils/cache";

const useUpdateUser = (callback) => {
  const cookies = new Cookies();
  const userId = cookies.get("user-id");
  const [updateUser, { data, loading }] = useLazyQuery(getUser, {
    variables: {
      id: userId,
      categoriesChildrenPage: 1,
      categoriesChildrenSize: CATEGORIES_CHILDREN_PAGE_SIZE,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      authVar(data?.user);
      rolesVar(data?.user?.roles);
      callback && callback();
    }
  }, [data]);

  return { updateUser, userLoading: loading };
};
export default useUpdateUser;
