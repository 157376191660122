import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SignUpAd from "../../components/GlobalComponents/Sections/SignUpAd/SignUpAd";
import Header from "../../components/Search/Header/Header";
import MiddleContainer from "../../components/Search/MiddleContainer/MiddleContainer";
import styles from "./Search.module.css";
import adImg from "../../assets/images/signUpAd.png";
import Tap from "@tapfiliate/tapfiliate-js";

const Search = () => {
  const [showFilters, setShowFilters] = useState(false);
  const drawerToggleHandler = () => setShowFilters(!showFilters);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod") {
      Tap.init("35661-e23a0a", { integration: "stripe" });
    }
  }, []);

  return (
    <div className={styles.searchPage}>
      <Header
        showFilters={showFilters}
        showFilterHandler={drawerToggleHandler}
      />
      <div className={styles.overFlowContainer}>
        <img src={adImg} alt='sign up ad' className={styles.adImg} />
        <NavLink to='/consumer-register' className={styles.learn}>
          LEARN MORE
        </NavLink>
        <MiddleContainer
          filtersDrawer={showFilters}
          drawerToggleHandler={drawerToggleHandler}
        />
        <SignUpAd />
      </div>
    </div>
  );
};

export default Search;
