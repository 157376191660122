import React from "react";
import PropTypes from "prop-types";
import { Img } from "react-image";
import Loader from "../Loader/Loader";

const Picture = (props) => {
  const {
    webpSrc,
    fallback,
    variant,
    alt,
    loaderStyles,
    onClick,
    loadingPlaceholder,
    isDarkColor,
  } = props;
  return (
    <Img
      src={[webpSrc, fallback]}
      className={variant}
      alt={alt}
      loader={
        loadingPlaceholder ?? (
          <Loader variant={loaderStyles ?? variant} darkColor={isDarkColor} />
        )
      }
      onClick={onClick}
    />
  );
};

export default Picture;
Picture.propTypes = {
  webpSrc: PropTypes.string,
  fallback: PropTypes.string,
  variant: PropTypes.string,
  alt: PropTypes.string,
  loaderStyles: PropTypes.string,
  onClick: PropTypes.func,
  loadingPlaceholder: PropTypes.any,
  isDarkColor: PropTypes.bool,
};
