import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GoogleMap, DirectionsRenderer, Marker } from "@react-google-maps/api";
import styles from "./Map.module.css";
import { useSearchParams } from "react-router-dom";
import DirectionsCard from "../../components/Map/DirectionsCard/DirectionsCard";

const Map = () => {
  const [location, setLocation] = useSearchParams();
  const [destination, setDestination] = useState();
  const [userLocation, setUserLocation] = useState();
  const [directions, setDirections] = useState();
  const [status, setStatus] = useState();
  const mapRef = useRef();

  useEffect(() => {
    setDestination({
      lat: +location.get("lat"),
      lng: +location.get("lng"),
    });
  }, []);

  const options = useMemo(
    () => ({
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  );

  const fetchDirections = () => {
    if (!destination) return;
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setUserLocation({
        lat: latitude,
        lng: longitude,
      });
      const service = new google.maps.DirectionsService();
      service.route(
        {
          origin: {
            lat: latitude,
            lng: longitude,
          },
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          setStatus(status);
          if (status === "OK" && result) {
            setDirections(result);
          }
        }
      );
    });
  };
  const onLoad = useCallback((map) => (mapRef.current = map), []);

  return (
    <div className={styles.mapContainer}>
      <DirectionsCard
        userLocation={userLocation}
        destination={destination}
        fetchDirections={fetchDirections}
        status={status}
      />
      <GoogleMap
        mapContainerClassName={styles.containerElement}
        center={destination}
        options={options}
        zoom={10}
        onLoad={onLoad}
      >
        {directions && <DirectionsRenderer directions={directions} />}
        <Marker position={destination} />
      </GoogleMap>
    </div>
  );
};

export default Map;
