import React, { useEffect, useState } from "react";
import styles from "./EditBrowseAll.module.css";
import { ToastContainer } from "react-toastify";
import { errorToastHandler, successToastHandler } from "../../utils";
import { useMutation, useQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import browseAll from "../../graphql/queries/browseAll";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";
import setBrowseAll from "../../graphql/mutations/setBrowseAll";
import Header from "../../components/AdminBrowseAll/Header";
import Categories from "../../components/AdminBrowseAll/Categories";

const MAX_CATEGORIES_LENGTH = 8;
const EditBrowseAll = () => {
  const [page, setPage] = useSearchParams();
  const pageName = page?.get("p");
  const { data, loading, error } = useQuery(browseAll, {
    variables: { page: pageName },
  });
  const [
    updateCategories,
    { data: newData, loading: updateLoading, error: updateError },
  ] = useMutation(setBrowseAll, {
    refetchQueries: [{ query: browseAll, variables: { page: pageName } }],
  });

  const [categories, setCategories] = useState([]);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (data) setCategories(data?.browseThings);
  }, [data]);

  useEffect(() => {
    if (categories?.length === MAX_CATEGORIES_LENGTH) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [categories]);

  const addCategory = (category) =>
    setCategories((prevCategories) => [...prevCategories, category]);
  const updateCategoriesHandler = (e) => {
    e.preventDefault();
    updateCategories({
      variables: {
        page: pageName,
        categoriesId: categories?.map((category) => category?.id),
      },
    })
      .then(() => successToastHandler("UPDATED SUCCESSFULLY"))
      .catch(() => errorToastHandler());
  };

  return (
    <form className={styles.editorPage} onSubmit={updateCategoriesHandler}>
      <Header addCategory={addCategory} />
      <Categories
        existingCategories={categories}
        setCategories={setCategories}
      />
      {disabled && !updateLoading && (
        <p className={styles.errorMessage}>
          Browse all section must contains 8 categories.
        </p>
      )}
      <button
        type='submit'
        className={` ${styles.btn} ${
          updateLoading ? styles.loadingBg : styles.regularBg
        }`}
        disabled={updateLoading ? false : disabled}
      >
        {updateLoading ? <Spinner /> : "CONFIRM"}
      </button>
    </form>
  );
};

export default EditBrowseAll;
