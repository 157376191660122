import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styles from "./BusinessOwnerLayout.module.css";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";

const BusinessOwnerLayout = () => {
  const { pathname } = useLocation();
  const [showSidebar, setShowSideBar] = useState(false);

  useEffect(() => {
    if (
      pathname.includes("/business-onboarding") ||
      pathname.includes("/business-dashboard") ||
      pathname.includes("/create-business") ||
      pathname.includes("/upload-id") ||
      pathname.includes("/select-tier") ||
      pathname.includes("/owner-picture")
    )
      setShowSideBar(false);
    else setShowSideBar(true);
  }, [pathname]);

  return (
    <div className={styles.layoutContainer}>
      <ToastContainer />
      <Header title='For Business' />
      <div className={styles.container}>
        {showSidebar ? <Sidebar /> : null}
        <Outlet context={showSidebar} />
      </div>
    </div>
  );
};

export default BusinessOwnerLayout;
