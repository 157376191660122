import React, { useEffect, useState } from "react";
import styles from "./AboutSection.module.css";
import PropTypes from "prop-types";
import Spinner from "../../UI/Spinner/Spinner";
import ErrorComponent from "../../UI/ErrorComponent/ErrorComponent";
import OwnerName from "./OwnerName/OwnerName";
import OwnerDesc from "./OwnerDesc/OwnerDesc";
import { useReactiveVar } from "@apollo/client";
import { editView } from "../../../../utils/cache";
import OwnerPicture from "./OwnerPicture/OwnerPicture";
import Description from "./Description/Description";
import EditBtns from "../../Buttons/EditBtns/EditBtns";
import Specification from "./Specification/Specification";
import { ITEM_TYPES } from "../../../../constants";

const AboutSection = (props) => {
  const { data, loading, error, variant, thirdParagraph, isEdit } = props;
  const [businessData, setBusinessData] = useState();
  const isEditing = useReactiveVar(editView);
  useEffect(() => {
    if (data?.__typename === "Business") {
      setBusinessData(data);
    } else {
      setBusinessData(data?.business);
    }
  }, [data]);
  return (
    <section className={styles.aboutSection}>
      {!loading && isEdit && <EditBtns variant={styles.editBtn} />}

      <div className={variant}>
        <div className={styles.about}>
          <h1
            className={`${styles.title} ${
              isEditing && data?.__typename === ITEM_TYPES?.BUSINESS
                ? styles.highlight
                : styles.noEdit
            }`}
          >
            About the founder
          </h1>
          <div className={styles.user}>
            {loading && <Spinner variant={styles.loader} />}
            {error && <ErrorComponent error={error?.message} />}
            {businessData && (
              <OwnerPicture
                avatar={businessData?.representative?.avatar}
                itemType={data?.__typename}
                ownerId={businessData?.representative?.id}
                itemId={data?.id}
              />
            )}
            <div className={styles.userInfo}>
              <OwnerName
                ownerFirstName={businessData?.representative?.firstName}
                ownerLastName={businessData?.representative?.lastName}
                itemType={data?.__typename}
                ownerId={businessData?.representative?.id}
                itemId={data?.id}
              />
              <p className={styles.userRole}>{businessData?.ownerRole}</p>
            </div>
          </div>

          <OwnerDesc
            aboutOwner={businessData?.aboutOwner}
            businessId={businessData?.id}
            itemType={data?.__typename}
          />
        </div>
        {data?.desc && (
          <>
            <h1
              className={`${styles.title} ${styles.secondTitle} ${
                isEditing ? styles.highlight : styles.noEdit
              }`}
            >
              Description
            </h1>
            <Description
              existingDesc={data?.desc}
              itemType={data?.__typename?.toLowerCase()}
            />
          </>
        )}
        {(data?.specification || data?.ingredients) && (
          <>
            <h1
              className={`${styles.title} ${styles.secondTitle} ${
                styles.spec
              } ${isEditing ? styles.highlight : styles.noEdit}`}
            >
              {thirdParagraph}
            </h1>
            <Specification
              prevSpecification={data?.specification || data?.ingredients}
              itemType={data?.__typename?.toLowerCase()}
            />
          </>
        )}
      </div>
    </section>
  );
};

export default AboutSection;
AboutSection.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  variant: PropTypes.string,
  thirdParagraph: PropTypes.string,
  isEdit: PropTypes.bool,
};
