import React from "react";
import { useQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import business from "../../graphql/queries/pages/business";
import UploadID from "../../components/BusinessOnboarding/UploadID";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";
import styles from "./ReUploadID.module.css";

export default function ReUpload() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading: loadingBusiness } = useQuery(business, {
    variables: { id },
  });
  const businessData = data?.business;

  const handleNext = () => {
    navigate("/business-dashboard");
  };
  if (loadingBusiness) {
    return <Spinner variant={styles.loader} />;
  }
  return (
    <div className={styles.reUploadContainer}>
      <div className={styles.uploadWarning}>
        YOUR ID COULD NOT BE VERIFIED. PLEASE RE-UPLOAD!
      </div>
      <UploadID
        businessData={businessData}
        nextStepHandler={handleNext}
        isReUploadPage={true}
      />
    </div>
  );
}
