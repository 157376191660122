import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { mapUserRole } from "../../../utils/index";
import rowStyles from "../../GlobalComponents/Admin/AdminTableStyles/Row.module.css";

export default function UserRow({ user }) {
  const navigate = useNavigate();
  const handleViewProfile = () => {
    navigate(`/admin/${user.id}`);
  };

  return (
    <tr className={rowStyles.row}>
      <td className={rowStyles.name}>
        {user?.firstName} {user?.lastName}
      </td>
      <td className={rowStyles.column}>
        {user?.roles?.map((role) => mapUserRole(role))}
      </td>
      <td className={rowStyles.column}>{user.email}</td>

      <td className={`${rowStyles.column} ${rowStyles.status}`}>
        {user?.isBlocked ? `Blocked` : "Unblocked"}
      </td>
      <td className={`${rowStyles.column} ${rowStyles.alignEnd}`}>
        <button className={rowStyles.viewButton} onClick={handleViewProfile}>
          View Profile
        </button>
      </td>
    </tr>
  );
}

UserRow.propTypes = {
  user: PropTypes.object,
};
