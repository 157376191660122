import { useReactiveVar } from "@apollo/client";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { authVar, savedPath } from "../../../../../utils/cache";
import Input from "../../../UI/Input/Input";
import styles from "./CommentInput.module.css";
import PropTypes from "prop-types";
import Spinner from "../../../UI/Spinner/Spinner";
import UserPicture from "../../../UI/UserPicture/UserPicture";
const CommentInput = (props) => {
  const { handleCommentSubmit, newComment, commentChangeHandler, loading } =
    props;
  const { pathname } = useLocation();
  const userData = useReactiveVar(authVar);
  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();
    if (!userData) {
      navigate("/login");
    } else {
      handleCommentSubmit(newComment);
    }
  };

  const navigateHandler = () => {
    savedPath(`${pathname}#comments`);
    navigate("/login");
  };

  return (
    <div className={styles.newCommentContainer}>
      {userData ? (
        <form className={styles.newComment} onSubmit={submitHandler}>
          <UserPicture
            path={userData?.avatar?.path}
            variant={styles.profilePic}
          />
          <Input
            placeholder='Type or add a comment'
            value={newComment}
            changeHandler={commentChangeHandler}
            type='text'
            inpContainerClass={styles.commentInp}
          />
          {loading ? (
            <Spinner variant={styles.loader} />
          ) : (
            <button type='submit' className={styles.submitBtn}>
              <svg
                width='26'
                height='22'
                viewBox='0 0 26 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1.08228 11.0705L24.5497 11.0705M24.5497 11.0705L13.454 21.0566M24.5497 11.0705L13.454 1.08436'
                  stroke='#1C1C1E'
                  strokeOpacity='0.4'
                  strokeWidth='0.998614'
                  strokeLinecap='square'
                  strokeLinejoin='round'
                />
              </svg>
            </button>
          )}
        </form>
      ) : (
        <button
          to='/login'
          className={styles.signInBtn}
          onClick={navigateHandler}
        >
          LOGIN TO COMMENT
        </button>
      )}
    </div>
  );
};

export default CommentInput;
CommentInput.propTypes = {
  handleCommentSubmit: PropTypes.func,
  newComment: PropTypes.string,
  commentChangeHandler: PropTypes.func,
  loading: PropTypes.bool,
};
