import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import regStyles from "./ExploreThePlatform.module.css";
import adminStyles from "./AdminStyles.module.css";
import { useReactiveVar } from "@apollo/client";
import { isAdmin } from "../../../../utils/cache";
import Picture from "../../UI/Picture";
const ExploreThePlatform = (props) => {
  const { categories } = props;
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  return (
    <section className={styles.exploreSection}>
      <div className={styles.exploreContainer}>
        <h3 className={styles.sectionTitle}>Explore the platform</h3>
        <div className={styles.items}>
          {categories?.map((item, i) => (
            <NavLink
              to={item.route}
              key={i}
              className={`${styles.item} ${styles[`item${i}`]}`}
            >
              <div
                className={`${styles.imgContainer} ${
                  styles[`imgContainer${i}`]
                }`}
              >
                <Picture
                  variant={`${i === 0 ? styles.firstItemImg : styles.itemImg}`}
                  fallback={item.imgSrc}
                  alt={item.title}
                  webpSrc={item?.webpSrc}
                />
              </div>
              <p className={styles.title}>{item.title}</p>
              <p className={styles.subTitle}>{item.subTitle}</p>
            </NavLink>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExploreThePlatform;
ExploreThePlatform.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};
