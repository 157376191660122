import React from "react";
import { useMutation, useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import logout from "../../../../graphql/mutations/authentication/logout";
import { checkUserRole, warningToastHandler } from "../../../../utils";
import { USER_TYPES } from "../../../../constants";
import { rolesVar } from "../../../../utils/cache";
import { logoutFunc } from "../../../../utils/logout";
import PropTypes from "prop-types";
import styles from "./GetListedBtn.module.css";
import { toast } from "react-toastify";
const GetListedBtn = (props) => {
  const { children, variant, btnLink } = props;
  const role = useReactiveVar(rolesVar);
  const navigate = useNavigate();
  const [signOut, { client }] = useMutation(logout);

  const logoutHandler = () => {
    toast.update("sign out", { isLoading: true });

    signOut()
      .then(() => {
        logoutFunc();
        client.resetStore();
      })
      .finally(() => {
        navigate("/business-register");
      });
  };

  const handleClick = () => {
    if (role?.length === 0 || !role) {
      navigate(btnLink ? btnLink : "/business-register");
      return;
    }
    if (
      checkUserRole(role, USER_TYPES.business) ||
      checkUserRole(role, USER_TYPES.admin)
    ) {
      navigate("/business-dashboard");
    } else {
      warningToastHandler(
        <>
          <span>
            To start listing your business, you will have to sign out and
            register as a business user!
          </span>
          <button className={styles.signOut} onClick={logoutHandler}>
            SIGN OUT
          </button>
        </>,
        { isLoading: false, toastId: "sign out", closeOnClick: false }
      );
    }
  };

  return (
    <button className={variant} onClick={handleClick}>
      {children}
    </button>
  );
};
export default GetListedBtn;
GetListedBtn.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.any,
  btnLink: PropTypes.string,
};
