import React from "react";
import PropTypes from "prop-types";
import styles from "./BusinessRegisterSteps.module.css";
import CellPhone from "../CellPhone/CellPhone";
import BusinessName from "../BusinessName/BusinessName";
import BusinessPhone from "../BusinessPhone/BusinessPhone";
import BusinessTypes from "../BusinessTypes/BusinessTypes";
import BusinessPhotos from "../BusinessPhotos/BusinessPhotos";
import BusinessSocials from "../BusinessSocials/BusinessSocials";
import BusinessAddress from "../BusinessAddress/BusinessAddress";
import BusinessWebsite from "../BusinessWebsite/BusinessWebsite";
import BusinessCategories from "../BusinessCategories/BusinessCategories";
import BusinessEmail from "../BusinessEmail";
import BackButton from "../../GlobalComponents/Buttons/BackButton/BackButton";

const BusinessRegister = (props) => {
  const {
    registerStepIndex,
    insertBusinessData,
    nextStepHandler,
    businessData,
    previousStepHandler,
  } = props;

  return (
    <div className={styles.businessStepsContainer}>
      <div className={styles.phoneContainer}>
        <div className={styles.phone}>
          <CellPhone businessData={businessData} />
        </div>
      </div>
      <div
        className={`${styles.businessStepContainer} ${
          registerStepIndex === 0 ? styles.noBack : styles.withBack
        }`}
      >
        <div className={styles.btnContainer}>
          {registerStepIndex !== 0 && (
            <BackButton
              variant={styles.backBtn}
              backHandler={previousStepHandler}
            />
          )}
        </div>
        {registerStepIndex === 0 && (
          <BusinessName
            insertBusinessData={insertBusinessData}
            nextStepHandler={nextStepHandler}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 1 && (
          <BusinessPhone
            insertBusinessData={insertBusinessData}
            nextStepHandler={nextStepHandler}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 2 && (
          <BusinessEmail
            insertBusinessData={insertBusinessData}
            nextStepHandler={nextStepHandler}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 3 && (
          <BusinessWebsite
            insertBusinessData={insertBusinessData}
            nextStepHandler={nextStepHandler}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 4 && (
          <BusinessTypes
            insertBusinessData={insertBusinessData}
            nextStepHandler={nextStepHandler}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 5 && (
          <BusinessCategories
            insertBusinessData={insertBusinessData}
            nextStepHandler={nextStepHandler}
            businessData={businessData}
            selected={businessData?.businessCategoriesNames}
          />
        )}
        {registerStepIndex === 6 && (
          <BusinessAddress
            insertBusinessData={insertBusinessData}
            nextStepHandler={nextStepHandler}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 7 && (
          <BusinessPhotos
            registerStepIndex={registerStepIndex}
            insertBusinessData={insertBusinessData}
            nextStepHandler={nextStepHandler}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 8 && (
          <BusinessSocials
            insertBusinessData={insertBusinessData}
            nextStepHandler={nextStepHandler}
            businessData={businessData}
          />
        )}
      </div>
    </div>
  );
};

export default BusinessRegister;
BusinessRegister.propTypes = {
  registerStepIndex: PropTypes.number,
  insertBusinessData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  businessData: PropTypes.object,
  previousStepHandler: PropTypes.func,
  businessId: PropTypes.string,
};
