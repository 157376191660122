import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Businesses from "./Businesses/Businesses";
import styles from "./Sidebar.module.css";
const Sidebar = () => {
  const { id } = useParams();
  const [businessId, setBusinessID] = useState();
  return (
    <div className={styles.sidebar}>
      <Businesses setBusinessID={setBusinessID} />
      <NavLink
        to='/business-dashboard'
        className={({ isActive }) => (isActive ? styles.active : styles.link)}
      >
        Home
      </NavLink>
      <a
        href='http://obwsads.com/'
        className={styles.link}
        target='_blank'
        rel='noreferrer'
      >
        Advertise
      </a>
      <a
        href='https://www.obwsads.com/members-only'
        className={styles.link}
        target='_blank'
        rel='noreferrer'
      >
        Resource Suite
      </a>
      <NavLink
        to={`/working-hours/${businessId}`}
        className={({ isActive }) => (isActive ? styles.active : styles.link)}
      >
        Business Hours
      </NavLink>
      <NavLink
        to={`business-support/${id}`}
        className={({ isActive }) => (isActive ? styles.active : styles.link)}
      >
        Support
      </NavLink>

      <NavLink
        to={`/business-settings/${businessId}`}
        className={({ isActive }) => (isActive ? styles.active : styles.link)}
      >
        Settings
      </NavLink>
      <NavLink to='/' className={styles.visitLink}>
        <div className={styles.visitContainer}>
          <p className={styles.visitText}>Visit obws.com</p>
          <svg
            width='31'
            height='13'
            viewBox='0 0 33 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M-5.68248e-07 7.5L31 7.5M31 7.5L24.1111 1M31 7.5L24.1111 14'
              stroke='#1977F3'
              strokeWidth='1.47222'
            />
          </svg>
        </div>
      </NavLink>
    </div>
  );
};

export default Sidebar;
