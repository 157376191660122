export const FIRST_COLUMN_FAQ = [
  {
    question:
      "What do the unlimited legal services entail and how does it work?",
    answer: `Thanks to our partnership with the global law firm, Baker Botts, we are able to provide our paid business members with unlimited legal services. Through this partnership, Baker Botts has committed to providing $10 Million in free legal services to help Black entrepreneurs protect and grow their businesses without the financial barriers.

Once you're a member, you'll receive a form to fill out whenever there is a service needed. An attorney at Baker Botts will reach out within 1 week to begin working on your request.`,
  },
  {
    question: "What services can I use the free legal services for?",
    answer:
      "The partnership with Baker Botts is solely for legal services related to your business(es) that are listed on OBWS. Services include contract review, patent services, privacy policy, TOS formation and review, trademark services, employment matters, establishing a 501c3, Human Resources support, lawsuit consultation, LLC/entity establishment, marketing and branding, and more.",
  },
  {
    question:
      "I am interested in only listing my business without participating in additional benefits from OBWS. How much is it to list my business?",
    answer:
      "To list your business on the platform and gain access to promote your product and services to the OBWS community, it costs $50 yearly.  Please note the $50 fee will be billed only if your business is approved to be on the OBWS platform.",
  },
];
export const SECOND_COLUMN_FAQ = [
  {
    question:
      "I operate my business outside of the U.S. Can I create a listing?",
    answer: `Absolutely. OBWS businesses span 10 different countries (& growing). We welcome Black entrepreneurs from all over the world.

Our partner law firm also has offices in six other countries around the world! Click "Contact Us" below and reach out if you'd like to see if your region is covered.`,
  },
  {
    question: "Can I cancel my membership?",
    answer:
      "Yes, while we’d love for you to stay, there are no fees or penalties for canceling your subscription at any time. If your subscription is canceled, you'll still be able to use your account until the end of your current subscription period.",
  },
  {
    question: "What are quarterly founder office hours?",
    answer:
      "Each quarter we schedule 1 hour-long office hours to provide support to our Growth members, answer any business-related questions and help strategize.",
  },
  {
    question: "What is the OBWS Resource Suite and why do I need it?",
    answer:
      "The OBWS Resource Suite is exclusively for our Resource and Growth Plan members. Here you'll gain access to business perks from OBWS partners, your free legal service request form, an ongoing directory of grants and funding opportunities you can apply for, and more perks right at your fingertips.",
  },
];
