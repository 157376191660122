import { gql } from "@apollo/client";
export default gql`
  query {
    __type(name: "BusinessType") {
      name
      enumValues {
        name
      }
    }
  }
`;
