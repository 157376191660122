import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TierCard from "./TierCard";
import styles from "./SelectTier.module.css";
import { STRIPE_PLANS_NAMES, STRIPE_PLANS_INTERVALS } from "../../../constants";
import MembershipFaq from "./MembershipFaq";
import Input from "../../GlobalComponents/UI/Input/Input";
export default function SelectTier({ planPriceId, setPlan, prices, user }) {
  const [plans, setPlans] = useState([]);
  const [monthly, setMonthly] = useState(true);
  const getMonthlyPlanByName = (planName) =>
    prices?.stripePrices.find(
      (plan) =>
        plan.name === planName &&
        plan.recurringInterval === STRIPE_PLANS_INTERVALS.MONTHLY.period
    );
  const getYearlyPlanByName = (planName) =>
    prices?.stripePrices.find(
      (plan) =>
        plan.name === planName &&
        plan.recurringInterval === STRIPE_PLANS_INTERVALS.YEARLY.period
    );

  const getGetListedPlan = () =>
    prices?.stripePrices.find(
      (plan) =>
        plan.name === STRIPE_PLANS_NAMES.GET_LISTED && plan.recurringInterval
    );

  const mapStripePlans = () => {
    if (prices?.stripePrices) {
      let pricesData;
      if (monthly) {
        pricesData = [
          getGetListedPlan(),
          getMonthlyPlanByName(STRIPE_PLANS_NAMES.RESOURCE),
          getMonthlyPlanByName(STRIPE_PLANS_NAMES.GROWTH),
        ];
      } else {
        pricesData = [
          getGetListedPlan(),
          getYearlyPlanByName(STRIPE_PLANS_NAMES.RESOURCE),
          getYearlyPlanByName(STRIPE_PLANS_NAMES.GROWTH),
        ];
      }

      pricesData = pricesData.map((price) =>
        planPriceId?.length > 0
          ? { ...price, selected: planPriceId === price.id }
          : { ...price, selected: false }
      );
      setPlans(pricesData);
    }
  };

  useEffect(() => {
    mapStripePlans();
  }, [prices, monthly]);

  const handleCardSelection = (plan) => {
    const newPlans = plans.map((plan) => {
      if (plan?.id === plan.id) {
        return { ...plan, selected: true };
      }
      return { ...plan, selected: false };
    });
    setPlans(newPlans);
    setPlan(plan);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.title}>Select a plan...</h2>
          <div className={styles.radioContainer}>
            <Input
              elementType='radio'
              hasLabel
              label='Pay Monthly'
              inpContainerClass={`${styles.checkboxContainer} ${
                monthly ? styles.labelActive : styles.notActiveLabel
              }`}
              checked={monthly}
              changeHandler={() => setMonthly(!monthly)}
              name='period'
              id='monthly'
              customCheckBox={styles.checkMark}
            />

            <Input
              elementType='radio'
              hasLabel
              label='Pay Yearly (Save 15%)'
              inpContainerClass={`${styles.checkboxContainer} ${
                !monthly ? styles.labelActive : styles.notActiveLabel
              }`}
              name='period'
              checked={!monthly}
              id='yearly'
              changeHandler={() => setMonthly(!monthly)}
              customCheckBox={styles.checkMark}
            />
          </div>
        </div>
        <div className={styles.tiersContainer}>
          {plans.map((plan) => (
            <TierCard
              key={plan.id}
              plan={plan}
              handleCardSelection={handleCardSelection}
              plans={plans}
            />
          ))}
        </div>
        <span className={styles.hint}>
          You will only be charged if your business is approved for listing.
        </span>
      </div>
      <MembershipFaq />
    </>
  );
}

SelectTier.propTypes = {
  nextStepHandler: PropTypes.func,
  businessId: PropTypes.string,
  planPriceId: PropTypes.string,
  setPlan: PropTypes.func,
  prices: PropTypes.object,
  showPaymentMethodStep: PropTypes.bool,
  user: PropTypes.object,
};
