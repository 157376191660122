import React from "react";
import PropTypes from "prop-types";
import styles from "./TierCard.module.css";
import popular from "../../../assets/images/popular.png";
import { STRIPE_PLANS_NAMES, STRIP_FREE_PLAN } from "../../../constants";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { useReactiveVar } from "@apollo/client";
import { authVar } from "../../../utils/cache";
export default function TierCard({ plan, handleCardSelection, plans }) {
  const periodShortcut = { month: "mo", year: "yr" };
  const { userId } = useReactiveVar(authVar)?.id ?? {};
  const noteText = () => {
    if (plan.name === STRIPE_PLANS_NAMES.RESOURCE)
      return "Everything in Get Listed, plus:";
    else if (plan.name === STRIPE_PLANS_NAMES.GROWTH)
      return "Everything in Resource, plus:";
  };

  const selectClickHandler = () => {
    handleCardSelection(plan);
    analytics.identify(userId, {
      business_membership_level: plan?.name,
    });
    analytics.track("business_tier", {
      subscription_plan: plan?.name,
      subscription_price: plan.amount / 100,
    });
  };
  const isOneTimePayment = plan.recurringInterval === "0";

  const planAmount = (plan) => plan.amount / 100;

  return (
    <div className={styles.card}>
      <div>
        {plan.name === STRIPE_PLANS_NAMES.RESOURCE && (
          <img src={popular} alt='label' className={styles.popularIcon} />
        )}
        <p className={styles.planName}>{plan.name}</p>
        <p className={styles.amount}>
          {planAmount(plan)}
          <span
            className={isOneTimePayment ? styles["one-time"] : styles.recurring}
          >
            {isOneTimePayment
              ? "one-time"
              : `/${periodShortcut[plan.recurringInterval]}`}
          </span>
        </p>
        {plan.name !== STRIP_FREE_PLAN.name && (
          <p className={styles.hintText}>{noteText()}</p>
        )}
        <ul className={styles.list}>
          {plan?.features?.map(
            (feature, index) =>
              feature.active && (
                <li className={styles.planDescription} key={index}>
                  {feature.desc}
                </li>
              )
          )}
          {plan.name === STRIPE_PLANS_NAMES.RESOURCE && (
            <li className={styles.faqLink}>
              <a href='#faq'> Unlimited legal services</a>
            </li>
          )}
        </ul>
      </div>
      <button className={styles.selectBtn} onClick={selectClickHandler}>
        SELECT
      </button>
    </div>
  );
}

TierCard.propTypes = {
  plan: PropTypes.object,
  handleCardSelection: PropTypes.func,
  handleSave: PropTypes.func,
  loading: PropTypes.bool,
  plans: PropTypes.array,
};
