import moment from "moment";
import format from "moment-duration-format";

export const numberFormatter = (value) => {
  var suffixes = ["", "k", "m", "b", "t"];
  var suffixNum = Math.floor(("" + value).length / 3);
  var shortValue = parseFloat(
    (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  );
  if (shortValue % 1 != 0) {
    shortValue = shortValue.toFixed(1);
  }
  if (value > 9999) {
    return shortValue + suffixes[suffixNum];
  } else {
    return value;
  }
};

export const dateFormatter = (createdAt) => {
  const secDiff = (new Date() - new Date(`${createdAt} UTC`)) / 1000;
  let returnValue = null;
  if (secDiff < 60) {
    returnValue = "now";
  }
  if (secDiff > 29030400) {
    returnValue = `${Math.floor(secDiff / 29030400)}Y`;
  }
  if (secDiff > 2419200 && !returnValue) {
    returnValue = `${Math.floor(secDiff / 2419200)}M`;
  }
  if (secDiff > 604800 && !returnValue) {
    returnValue = `${Math.floor(secDiff / 604800)}W`;
  }
  if (secDiff > 86400 && !returnValue) {
    returnValue = `${Math.floor(secDiff / 86400)}D`;
  }
  if (secDiff > 3600 && !returnValue) {
    returnValue = `${Math.floor(new Date(secDiff) / 3600)}H`;
  }
  if (secDiff > 60 && !returnValue) {
    returnValue = `${Math.floor(secDiff / 60)}M`;
  }
  return returnValue;
};

export const convertTimeToFloat = (time) => {
  const timeFields = time.split(":");

  return (
    +timeFields[0] +
    (timeFields[1] && +timeFields[1] / 60) +
    (timeFields[2] && +timeFields[2] / 3600)
  );
};

export const convertFloatToTime = (time) => {
  const hours = Math.floor(time);
  const amPm = hours >= 12 ? "pm" : "am";
  const minutes = Math.floor((Math.abs(time) - hours) * 60);
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${amPm}`;
};

export const convertFloatToMoment = (float) => {
  const hoursFormat = moment.duration(float, "h").format("HH:mm:ss");
  const date = new Date();
  const fullTimeFormat = new Date(
    `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date
      .getFullYear()
      .toString()
      .padStart(4, "0")} ${hoursFormat}`
  );

  return moment(fullTimeFormat);
};
