import React from "react";
import "./App.css";
import "react-phone-number-input/style.css";
import { Routes, Route, Navigate } from "react-router-dom";
import DiscoverLayout from "./components/GlobalComponents/Layouts/DiscoverLayout/DiscoverLayout";
import Discover from "./Pages/Discover/Discover";
import BusinessRegister from "./Pages/BusinessRegister/BusinessRegister";
import BusinessOnboarding from "./Pages/BusinessOnboarding";
import AuthLayout from "./components/GlobalComponents/Layouts/AuthLayout/AuthLayout";
import ConsumerRegister from "./Pages/ConsumerRegister/ConsumerRegister";
import Login from "./Pages/Login/Login";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ResetPasswod/ResetPassword";
import Products from "./Pages/Products/Products";
import Services from "./Pages/Services/Services";
import Businesses from "./Pages/Businesses/Businesses";
import ECommerce from "./Pages/ECommerce/ECommerce";
import Restaurants from "./Pages/Restaurants/Restaurants";
import BrickMortars from "./Pages/BrickMortars/BrickMortars";
import Food from "./Pages/Food/Food";
import ConfirmEmail from "./Pages/ConfirmEmail/ConfirmEmail";
import Business from "./Pages/Business/Business";
import Map from "./Pages/Map/Map";
import Product from "./Pages/Product/Product";
import { authVar } from "./utils/cache";
import Collections from "./Pages/Collections/Collections";
import Collection from "./Pages/Collection/Collection";
import Search from "./Pages/Search/Search";
import BusinessOwnerLayout from "./components/GlobalComponents/Layouts/BusinessOwnerLayout/BusinessOwnerLayout";
import BusinessSupport from "./Pages/BusinessSupport/BusinessSupport";
import BusinessDashboard from "./Pages/BusinessDashboard/BusinessDashboard";
import EditBusiness from "./Pages/EditBusiness/EditBusiness";
import WorkingHours from "./Pages/WorkingHours/WorkingHours";
import AddItem from "./Pages/AddItem/AddItem";
import EditProduct from "./Pages/EditProduct/EditProduct";
import EditFood from "./Pages/EditFood/EditFood";
import EditService from "./Pages/EditService/EditService";
import AdminLayout from "./components/GlobalComponents/Layouts/AdminLayout";
import AdminManageTeam from "./Pages/AdminManageTeam";
import AdminUserManagement from "./Pages/AdminUserManagement";
import RequestsTable from "./components/AdminUserManagement/RequestsTable";
import UserForm from "./components/AdminManageTeam/ManageTeamTable/UserForm";
import Service from "./Pages/Service/Service";
import Settings from "./Pages/Settings/Settings";
import SingleFoodPage from "./Pages/SingleFoodPage/SingleFoodPage";
import AdminEditor from "./Pages/AdminEditor/AdminEditor";
import useGetUser from "./hooks/useGetUser";
import { checkUserRole } from "./utils";
import EditEditorPicks from "./Pages/EditEditorPicks/EditEditorPicks";
import { useReactiveVar } from "@apollo/client";
import UserProfile from "./components/AdminUserManagement/UserProfile";
import EditBrowseAll from "./Pages/EditBrowseAll/EditBrowseAll";
import ContactUs from "./Pages/ContactUs/ContactUs";
import ListingManagement from "./Pages/ListingManagement";
import BusinessApplication from "./components/AdminUserManagement/BusinessApplication";
import DeclineReason from "./components/AdminUserManagement/DeclineReason";
import ReUploadID from "./Pages/ReUploadID";
import ConsumerProfile from "./Pages/ConsumerProfile";
import { USER_TYPES } from "./constants";
import AppLogic from "./AppLogic";
import LandingPage from "./Pages/LandingPage";
import JoinRedirection from "./Pages/JoinRedirection";
import OwnerProfilePic from "./Pages/OwnerProfilePic";
import Tiers from "./Pages/Tiers";
import SignUpTerms from "./components/GlobalComponents/Authentication/SignUpTerms/SignUpTerms";

const App = () => {
  const { userData, userRole, loading } = useGetUser();
  const savedUserData = useReactiveVar(authVar);
  const user = userData || savedUserData;
  const role = userRole || savedUserData?.roles;
  const isConsumerUser = checkUserRole(role, USER_TYPES.standard);
  const createBusinessRoutes = [
    "/create-business",
    "/create-business/:id",
    "/business-onboarding",
    "/business-onboarding/:id",
  ];
  return (
    <>
      <AppLogic />
      <Routes>
        <Route path='/password-reset' element={<ResetPassword />} />

        <Route element={<AuthLayout />}>
          {((isConsumerUser && !loading) || (!user && !loading)) && (
            <Route path='/business-register' element={<BusinessRegister />} />
          )}
          {!user && !loading && (
            <>
              <Route path='/consumer-register' element={<ConsumerRegister />} />{" "}
              <Route path='/login' element={<Login />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/confirm-email' element={<ConfirmEmail />} />
              <Route path='/terms-conditions' element={<SignUpTerms />} />
            </>
          )}
        </Route>
        {(checkUserRole(role, USER_TYPES.business) ||
          checkUserRole(role, USER_TYPES.admin)) && (
          <Route element={<BusinessOwnerLayout />}>
            <Route path='/edit-food/:id' element={<EditFood />} />
            <Route path='/edit-service/:id' element={<EditService />} />
            <Route path='/edit-product/:id' element={<EditProduct />} />
            <Route path='/add-item/:id' element={<AddItem />} />
            <Route path='/working-hours/:id' element={<WorkingHours />} />
            {createBusinessRoutes?.map((path) => (
              <Route key={path} path={path} element={<BusinessOnboarding />} />
            ))}
            <Route path='/business-dashboard' element={<BusinessDashboard />} />
            <Route path='/select-tier' element={<Tiers />} />
            <Route path='/owner-picture' element={<OwnerProfilePic />} />
            <Route path='/edit-business/:id' element={<EditBusiness />} />
            <Route path='/business-support/:id' element={<BusinessSupport />} />
            <Route path='/upload-id/:id' element={<ReUploadID />} />
            <Route path='/business-settings/:id' element={<Settings />} />
            <Route path='/login' element={<Navigate replace to='/' />} />
          </Route>
        )}
        <Route path='/join' element={<JoinRedirection />} />
        <Route element={<DiscoverLayout />}>
          <Route path='/awards2022' element={<LandingPage />} />
          {checkUserRole(role, USER_TYPES.standard) && (
            <>
              <Route path='/profile' element={<ConsumerProfile />} />
              <Route
                path='/up-voted'
                element={<ConsumerProfile hideComments />}
              />
              <Route
                path='/comments'
                element={<ConsumerProfile hideUpvoted />}
              />
            </>
          )}
          <Route path='/search' element={<Search />} />
          <Route path='/collections' element={<Collections />} />
          <Route path='/collections/:id' element={<Collection />} />
          <Route path='/map' element={<Map />} />
          <Route path='/business/:id' element={<Business />} />
          <Route path='/product/:id' element={<Product />} />
          <Route path='/service/:id' element={<Service />} />
          <Route path='/food/:id' element={<SingleFoodPage />} />
          <Route path='/restaurants' element={<Restaurants />} />
          <Route path='/brick-and-mortars' element={<BrickMortars />} />
          <Route path='/e-commerce' element={<ECommerce />} />
          <Route path='/businesses' element={<Businesses />} />
          <Route path='/food' element={<Food />} />
          <Route path='/services' element={<Services />} />
          <Route path='/products' element={<Products />} />
          <Route path='/' element={<Discover />} />
          <Route path='/contact-us' element={<ContactUs />} />
        </Route>
        {checkUserRole(role, USER_TYPES.admin) && (
          <Route element={<AdminLayout />}>
            <Route path='/admin-editor' element={<AdminEditor />}>
              <Route
                path='/admin-editor/categories'
                element={<EditBrowseAll />}
              />
              <Route
                path='/admin-editor/editor-picks'
                element={<EditEditorPicks />}
              />
              <Route element={<DiscoverLayout />}>
                <Route path='/admin-editor/products' element={<Products />} />
                <Route path='/admin-editor/services' element={<Services />} />
                <Route
                  path='/admin-editor/businesses'
                  element={<Businesses />}
                />
                <Route
                  path='/admin-editor/brick-and-mortars'
                  element={<BrickMortars />}
                />
                <Route
                  path='/admin-editor/e-commerce'
                  element={<ECommerce />}
                />
                <Route
                  path='/admin-editor/restaurants'
                  element={<Restaurants />}
                />
                <Route path='/admin-editor/food' element={<Food />} />
                <Route path='' element={<Discover />} />
              </Route>
            </Route>
            <Route
              path='/listing-management/edit-food/:id'
              element={<EditFood />}
            />
            <Route
              path='/listing-management/edit-service/:id'
              element={<EditService />}
            />
            <Route
              path='/listing-management/edit-product/:id'
              element={<EditProduct />}
            />
            <Route
              path='/listing-management/edit-business/:id'
              element={<EditBusiness />}
            />
            <Route
              path='/listing-management/add-item/:id'
              element={<AddItem />}
            />
            <Route
              path='/listing-management/working-hours/:id'
              element={<WorkingHours />}
            />
            <Route path='/listing-management' element={<ListingManagement />} />
            <Route path='/admin-manage-team/add' element={<UserForm />} />
            <Route path='/admin-manage-team/edit/:id' element={<UserForm />} />
            <Route path='/admin-manage-team' element={<AdminManageTeam />} />
            <Route path='/admin' element={<AdminUserManagement />} />
            <Route path='admin/requests' element={<RequestsTable />} />
            <Route path='admin/declined-requests' element={<RequestsTable />} />
            <Route path='admin/:id' element={<UserProfile />} />
            <Route
              path='admin/view-app/:id'
              element={<BusinessApplication />}
            />
            <Route path='admin/decline-reason' element={<DeclineReason />} />
            <Route path='/login' element={<Navigate replace to='/' />} />
          </Route>
        )}
        {!loading && <Route path='*' element={<Navigate replace to='/' />} />}
      </Routes>
    </>
  );
};
export default App;
