import React from "react";
import PropTypes from "prop-types";
import styles from "./GettingStartedSidebar.module.css";

export default function GettingStartedSideBar({ registerStepIndex }) {
  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarHeader}>
        <p className={styles.status}>STATUS</p>
        <p className={styles.title}>Getting Started</p>
      </div>
      <div className={styles.stepsWrapper}>
        <div
          className={`${styles.step} ${
            registerStepIndex === 9 ? styles.activeStep : ""
          }`}
        >
          Business hours
        </div>
        <div
          className={`${styles.step} ${
            registerStepIndex === 10 ? styles.activeStep : ""
          }`}
        >
          Categories
        </div>
        <div
          className={`${styles.step} ${
            registerStepIndex === 11 ? styles.activeStep : ""
          }`}
        >
          About the business
        </div>
        <div
          className={`${styles.step} ${
            registerStepIndex === 12 ? styles.activeStep : ""
          }`}
        >
          About the founders
        </div>
        <div
          className={`${styles.step} ${
            registerStepIndex === 13 ? styles.activeStep : ""
          }`}
        >
          Products & services
        </div>
      </div>
    </div>
  );
}

GettingStartedSideBar.propTypes = {
  registerStepIndex: PropTypes.number,
};
