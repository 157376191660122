import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SeeAllBtn from "../../GlobalComponents/Buttons/SeeAllBtn/SeeAllBtn";
import ItemCard from "./ItemCard";
import styles from "./UpVotedSection.module.css";
import allUpVoted from "../../../graphql/queries/allUpVoted";
import { useLazyQuery } from "@apollo/client";
import Pagination from "../../GlobalComponents/UI/Pagination";
import Cookies from "universal-cookie";
import SectionLoader from "../../GlobalComponents/UI/SectionLoader/SectionLoader";

export default function UpVotedSection({
  upVotedItems,
  hasMoreItems,
  commentsHidden,
}) {
  const [getUpvotes, { loading, data: upvotedPageItems }] =
    useLazyQuery(allUpVoted);
  const cookies = new Cookies();
  const userId = cookies.get("user-id");
  const variables = { id: userId, size: 8 };
  const { lastPage } =
    upvotedPageItems?.user?.upvotedItems?.paginatorInfo ?? {};
  const sectionRef = useRef();
  const seeAllBtnRender = () => {
    if (commentsHidden)
      return <SeeAllBtn route='/comments' btnText='SHOW COMMENTS' />;
    if (hasMoreItems) return <SeeAllBtn route='/up-voted' />;
  };

  const renderUpvotedItems = () => {
    if (commentsHidden) return upvotedPageItems?.user?.upvotedItems?.data;
    else return upVotedItems?.slice(0, 4);
  };

  const scrollToTopHandler = () => {
    window.scrollTo({
      top: sectionRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div
      className={`${styles.upVotedSection} ${
        commentsHidden ? styles.fullWidth : styles.partialWidth
      }`}
      ref={sectionRef}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Upvoted</p>
          {seeAllBtnRender()}
        </div>
        {upVotedItems?.length === 0 && (
          <p className={styles.noItemsText}>No up-voted items found</p>
        )}
        <div className={styles.itemsList}>
          {loading && <SectionLoader itemsNumber={5} />}
          {upVotedItems?.length &&
            renderUpvotedItems()?.map((item, i) => (
              <ItemCard item={item.item} key={`${item?.id} ${i}`} />
            ))}
        </div>
      </div>
      {commentsHidden && lastPage !== 1 && (
        <Pagination
          fetchHandler={getUpvotes}
          restVariables={variables}
          totalPages={lastPage}
          callback={scrollToTopHandler}
        />
      )}
    </div>
  );
}

UpVotedSection.propTypes = {
  upVotedItems: PropTypes.array,
  hasMoreItems: PropTypes.bool,
  commentsHidden: PropTypes.bool,
  userId: PropTypes.string,
};
