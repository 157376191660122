import React from "react";
import styles from "./SliderImg.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { editView, itemEditField } from "../../../../../utils/cache";
import itemPlaceholder from "../../../../../assets/images/itemPlaceholder.jpg";
const SliderImg = (props) => {
  const { path, elementClass, imgClass } = props;
  const isEditing = useReactiveVar(editView);
  const selectedFieldHandler = (field) => {
    itemEditField(field);
  };

  return (
    <div
      className={elementClass}
      onClick={selectedFieldHandler.bind(null, "itemImages")}
    >
      <img
        className={`${imgClass} ${isEditing ? styles.edit : undefined}`}
        alt='business image'
        src={path ?? itemPlaceholder}
      />
    </div>
  );
};

export default SliderImg;
SliderImg.propTypes = {
  path: PropTypes.string,
  elementClass: PropTypes.string,
  imgClass: PropTypes.string,
};
