import React from "react";
import styles from "../ProcessAndBenefits.module.css";
import TitleSlider from "../Slider";

const Process = () => {
  return (
    <div className={styles.processContainer}>
      <TitleSlider text={"THE PROCESS"} />
      <div className={styles.content}>
        <h3 className={styles.mainDesc}>
          <span>Award application submissions</span>
          <span> are open through August 12th</span>
        </h3>
        <h3 className={styles.mainDesc}>
          <span>Finalists are then handpicked by a</span>
          <span> panel of our OBWS judges</span>
        </h3>
        <h3 className={styles.mainDesc}>
          <span>Finalists are voted on by YOU in a</span>
          <span> 4-day public voting event</span>
        </h3>
        <h3 className={styles.mainDesc}>
          <span>Award winners are announced &</span>
          <span> celebrated at the end of August</span>
        </h3>
      </div>
    </div>
  );
};

export default Process;
