import { useQuery, useReactiveVar } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import ProductsAndServices from "../../components/BusinessOnboarding/ProductsAndServices";
import HeaderBackBtn from "../../components/GlobalComponents/Admin/HeaderBackBtn";
import business from "../../graphql/queries/pages/business";
import { isAdmin } from "../../utils/cache";
import styles from "./AddItem.module.css";
const AddItem = () => {
  const { id } = useParams();
  const { data } = useQuery(business, { variables: { id } });
  const admin = useReactiveVar(isAdmin);

  return (
    <div className={styles.addItem}>
      {admin && <HeaderBackBtn isEdit backRoute={-1} />}
      <div className={`${styles.content} ${admin && styles.adminPadding}`}>
        <ProductsAndServices businessData={data?.business} />
      </div>
    </div>
  );
};

export default AddItem;
