import { gql } from "@apollo/client";
export default gql`
  query ($id: ID!, $size: Int, $page: Int) {
    user(id: $id) {
      comments(page: $page, size: $size) {
        paginatorInfo {
          currentPage
          hasMorePages
          lastPage
        }
        data {
          id
          message

          CommentOn {
            ... on Product {
              id
              name
              featuredImage {
                id
                path
              }
            }
            ... on Service {
              id
              name
              featuredImage {
                id
                path
              }
            }
            ... on Food {
              id
              name
              featuredImage {
                id
                path
              }
            }
            ... on Business {
              id
              name
              featuredImage {
                id
                path
              }
            }
          }
        }
      }
    }
  }
`;
