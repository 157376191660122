import { gql } from "@apollo/client";
export default gql`
  query COLLECTION($id: ID!, $page: Int, $collectionPage: Int) {
    collection(id: $id) {
      name
      id
      image {
        path
        id
      }
      collectable(page: $page) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          item {
            ... on Business {
              __typename
              id
              name
              collections(page: $collectionPage) {
                paginatorInfo {
                  currentPage
                  hasMorePages
                }
                data {
                  id
                  name
                }
              }

              label
              categories {
                name
              }
              city {
                name
              }
              state {
                abbr
              }

              isVoted
              isBookmarked
              businessTypes
              votes
              featuredImage {
                id
                path
              }
            }
            ... on Product {
              __typename
              id
              name
              desc
              collections(page: $collectionPage) {
                paginatorInfo {
                  currentPage
                  hasMorePages
                }
                data {
                  id
                  name
                }
              }

              price
              votes
              isVoted
              isBookmarked
              featuredImage {
                id
                path
              }
              business {
                name
                id
              }
            }
            ... on Service {
              __typename
              id
              name
              collections(page: $collectionPage) {
                paginatorInfo {
                  currentPage
                  hasMorePages
                }
                data {
                  id
                  name
                }
              }

              desc
              price
              votes
              isVoted
              isBookmarked
              featuredImage {
                id
                path
              }
              business {
                name
                id
              }
            }
            ... on Food {
              __typename
              id
              name
              desc
              collections(page: $collectionPage) {
                paginatorInfo {
                  currentPage
                  hasMorePages
                }
                data {
                  id
                  name
                }
              }

              price
              votes
              isVoted
              isBookmarked
              featuredImage {
                id
                path
              }
              business {
                name
                id
              }
            }
          }
        }
      }
    }
  }
`;
