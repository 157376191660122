import React from "react";
import styles from "./ProfileMenu.module.css";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { rolesVar } from "../../../../../../utils/cache";
import { useReactiveVar } from "@apollo/client";
import { checkUserRole } from "../../../../../../utils";
import { USER_TYPES } from "../../../../../../constants";

const ProfileMenu = (props) => {
  const { toggleHandler, variant, closeSideDrawer, toggleConfirmationHandler } =
    props;
  const roles = useReactiveVar(rolesVar);
  const isAdmin = checkUserRole(roles, USER_TYPES.admin);
  const isBusinessUser = checkUserRole(roles, USER_TYPES.business);
  const isConsumerUser = checkUserRole(roles, USER_TYPES.standard);

  const menuClickHandler = () => {
    closeSideDrawer && closeSideDrawer();
    toggleHandler();
  };
  const signoutClickHandler = () => {
    menuClickHandler();
    toggleConfirmationHandler();
  };
  return (
    <ul className={`${styles.menu} ${variant}`}>
      {isAdmin && (
        <li
          className={`${styles.item} ${styles.admin}`}
          onClick={menuClickHandler}
        >
          <NavLink to='/admin'>ADMIN EXPERIENCE</NavLink>
        </li>
      )}

      {(isBusinessUser || isAdmin) && (
        <li
          className={`${styles.item} ${
            isBusinessUser || isAdmin ? styles.business : styles.consumer
          }`}
          onClick={menuClickHandler}
        >
          <NavLink to='/business-dashboard'>BUSINESS DASHBOARD</NavLink>
        </li>
      )}
      {isConsumerUser && (
        <li
          className={`${styles.item} ${styles.consumer}`}
          onClick={menuClickHandler}
        >
          <NavLink to='/profile'>VIEW PROFILE</NavLink>
        </li>
      )}

      <li
        className={`${styles.item} ${
          isBusinessUser ? styles.business : styles.consumer
        }`}
        onClick={menuClickHandler}
      >
        <NavLink to='/collections'>MY COLLECTIONS</NavLink>
      </li>

      <li
        className={`${styles.item} ${
          isBusinessUser ? styles.business : styles.consumer
        }`}
        onClick={signoutClickHandler}
      >
        SIGN OUT
      </li>
    </ul>
  );
};

export default ProfileMenu;
ProfileMenu.propTypes = {
  toggleHandler: PropTypes.func,
  variant: PropTypes.string,
  closeSideDrawer: PropTypes.func,
  toggleConfirmationHandler: PropTypes.func,
};
