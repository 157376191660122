import { gql } from "@apollo/client";
export default gql`
  mutation SOCIAL_REGISTER($token: String!, $role: UserRole!) {
    signUpWithSocial(token: $token, role: $role) {
      accessToken
      refreshToken
      permissions
      userIsBlocked
      roles {
        id
        permissions
        name
        label
      }
      user {
        ... on User {
          id
          email
          isOnboardedOnWeb
          firstName
          lastName
          subscribed
          isNewUser
          avatar {
            id
            path
          }
        }
      }
    }
  }
`;
