import React, { useEffect, useState } from "react";
import ApplyEditBtn from "../../Buttons/ApplyEditBtn/ApplyEditBtn";
import EditTooltip from "../EditTooltip/EditTooltip";
import PropTypes from "prop-types";
import { useMutation, useReactiveVar } from "@apollo/client";
import { itemEditField, editView } from "../../../../utils/cache";
import styles from "./EditImages.module.css";
import Input from "../Input/Input";
import useImageHandler from "../../../../utils/useImageHandler";
import imageUpload from "../../../../graphql/mutations/imageUpload";
import removeIcon from "../../../../assets/images/remove.svg";
import { useParams } from "react-router-dom";
import useEditItem from "../../../../hooks/useEditItem";

const EditImages = (props) => {
  const { sliderImages, itemType } = props;
  const selectedToEdit = useReactiveVar(itemEditField);
  const isEditing = useReactiveVar(editView);
  const [uploadImages, { loading, error }] = useMutation(imageUpload);
  const [chosenPhoto, setChosenPhoto] = useState("");
  const [businessImages, setBusinessImages] = useState([]);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const {
    errorMessage,
    images,
    changeHandler,
    dragHandler,
    dropHandler,
    resizing,
    resetHandler,
  } = useImageHandler(900, 900, 4 - businessImages?.length);
  const { id } = useParams();
  const { loading: editing, editFormHandler } = useEditItem(
    id,
    {
      featuredImage: chosenPhoto,
      gallery: imagesToUpload,
    },
    itemType
  );
  useEffect(() => {
    if (sliderImages.length > 0) {
      const filtered = sliderImages?.filter((el) => el !== null);
      setBusinessImages(filtered);
      setChosenPhoto(filtered[0]?.id);
    }
  }, [sliderImages]);

  useEffect(() => {
    const filteredImages = businessImages?.filter(
      (img) => img?.id !== chosenPhoto
    );
    const ids = filteredImages?.map((img) => img?.id);
    setImagesToUpload(ids);
  }, [businessImages, chosenPhoto]);

  useEffect(() => {
    if (images?.length > 0) {
      uploadImages({
        variables: {
          files: images.map((file) => ({
            image: file,
            path: "applications",
          })),
        },
      }).then(({ data }) => {
        setBusinessImages((prevImages) => [
          ...prevImages,
          ...data?.uploadImages,
        ]);
        if (!chosenPhoto) setChosenPhoto(data?.uploadImages[0]?.id);
      });
    }
  }, [images]);
  const hideTooltip = () => itemEditField("");
  const deleteHandler = (deleteIndex) => {
    const filteredImages = businessImages?.filter(
      (img, i) => i !== deleteIndex
    );
    setBusinessImages(filteredImages);
    const featuredImage =
      filteredImages?.find((el) => el?.id === chosenPhoto) ??
      filteredImages[0]?.id;
    setChosenPhoto(featuredImage);
  };
  return (
    <EditTooltip
      show={selectedToEdit === "itemImages" && isEditing}
      variant={styles.tooltip}
      anchorStyle='top'
      closeHandler={hideTooltip}
    >
      <form className={styles.imagesForm} onSubmit={editFormHandler}>
        <Input
          hasLabel
          isRequired
          label={`${itemType} Images`}
          fileLabel='Browse up to 4 photos or drag to add here'
          elementType='file'
          type='file'
          multiple
          inpContainerClass={`${styles.inpContainer} ${styles.dropzoneContainer} `}
          changeHandler={changeHandler}
          handleDrop={dropHandler}
          handleDrag={dragHandler}
          invalid={error || errorMessage ? true : false}
          errorMessage={errorMessage}
          clickHandler={resetHandler}
        >
          <div className={styles.previewsContainer}>
            {businessImages?.length > 0 &&
              businessImages?.map(({ path, id }, index) => (
                <div
                  key={`${path}${index}`}
                  className={`${styles.imageContainer} ${styles.selectable} `}
                >
                  {id === chosenPhoto && <div className={styles.layer} />}
                  <img
                    src={path}
                    onClick={setChosenPhoto.bind(null, id)}
                    className={`${styles.businessImage} ${
                      id === chosenPhoto && styles.activePhoto
                    }`}
                  />
                  <img
                    src={removeIcon}
                    onClick={deleteHandler.bind(null, index)}
                    className={styles.remove}
                  />
                </div>
              ))}
          </div>
        </Input>

        <ApplyEditBtn
          loading={loading || resizing || editing}
          disable={businessImages.length === 0}
        />
      </form>
    </EditTooltip>
  );
};

export default EditImages;
EditImages.propTypes = {
  sliderImages: PropTypes.array,
  itemType: PropTypes.string,
};
