import React from "react";
import PropTypes from "prop-types";
import useInput from "../../../hooks/useInput";
import Input from "../../GlobalComponents/UI/Input/Input";
import { businessNameValidation } from "../../../utils/validationRules";
import styles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import createBusiness from "../../../graphql/mutations/createBusiness";
import { useMutation, useReactiveVar } from "@apollo/client";
import { BusinessOnboardingState } from "../../../constants";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { authVar, savingBizInfoState } from "../../../utils/cache";
import updateBusiness from "../../../graphql/mutations/updateBusiness";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

const BusinessName = (props) => {
  const { insertBusinessData, nextStepHandler, businessData } = props;
  const {
    value: businessName,
    hasError: businessNameError,
    errorMessage: businessNameErrorMessage,
    valueChangeHandler: businessNameHandler,
    valueBlurHandler: businessNameBlurHandler,
    isValid: businessNameValid,
  } = useInput(businessNameValidation, businessData?.name);
  const userInfo = useReactiveVar(authVar);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userId = new Cookies().get("user-id");

  const [createBiz, { loading: createLoading, error: createError }] =
    useMutation(createBusiness, {
      update: (cache, res) => {
        cache.modify({
          id: `User:${userId}`,
          fields: {
            subscribed(cashedValue) {
              if (!cashedValue) {
                return !cashedValue;
              } else {
                return cashedValue;
              }
            },
            businesses(cashedValue) {
              return { ...cashedValue, data: [res?.data?.createBusiness] };
            },
          },
        });
      },
      notifyOnNetworkStatusChange: true,
    });
  const [updateBiz, { loading: updateLoading, error: updateError }] =
    useMutation(updateBusiness);
  const formIsValid = businessNameValid && !createError && !updateError;
  const loading = createLoading || updateLoading;

  const submitHandler = (e) => {
    e.preventDefault();
    analytics.track("business_name");
    if (!businessData) {
      savingBizInfoState({ loading: true });
      createBiz({
        variables: {
          businessName,
          email: userInfo?.email,
          onboardingState: BusinessOnboardingState.businessPhone, // next step
        },
      })
        .then(({ data }) => {
          savingBizInfoState({ loading: false });
          nextStepHandler();
          navigate(`${pathname}/${data?.createBusiness?.id}`);
          insertBusinessData({
            name: businessName,
            id: data?.createBusiness?.id,
            email: userInfo?.email,
            onboardingState: BusinessOnboardingState.businessPhone, // next step
          });
        })
        .catch(() =>
          savingBizInfoState({
            loading: false,
            error: true,
            retry: submitHandler,
          })
        );
    } else {
      if (businessData?.name === businessName) {
        nextStepHandler();
        return;
      }
      savingBizInfoState({ loading: true });

      updateBiz({
        variables: {
          id: businessData?.id,
          name: businessName,
        },
      })
        .then(() => {
          savingBizInfoState({ loading: false });
          insertBusinessData({
            name: businessName,
          });
          nextStepHandler();
        })
        .catch(() => {
          savingBizInfoState({
            loading: false,
            error: true,
            retry: submitHandler,
          });
        });
    }
  };
  return (
    <form className={styles.registerForm} onSubmit={submitHandler}>
      <h2 className={styles.altHeader}>Let’s start with your business name</h2>

      <Input
        hasLabel
        isRequired
        label='YOUR BUSINESS NAME'
        placeholder='e.g. Jamie’s Gardening'
        type='text'
        inpContainerClass={`${styles.fullWidthContainer} ${
          businessNameError && styles.invalid
        }`}
        value={businessName}
        changeHandler={businessNameHandler}
        blurHandler={businessNameBlurHandler}
        invalid={businessNameError}
        errorMessage={businessNameErrorMessage}
      />
      <RegisterNextBtn
        variant={styles.continueBtn}
        disabled={!formIsValid}
        isLoading={loading}
      >
        {loading ? <Spinner /> : "SAVE & CONTINUE"}
      </RegisterNextBtn>
    </form>
  );
};
export default BusinessName;
BusinessName.propTypes = {
  insertBusinessData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  businessData: PropTypes.object,
};
