import React from "react";
import styles from "./BackButton.module.css";
import backBtn from "../../../../assets/images/backBtn.svg";
import PropTypes from "prop-types";
const BackButton = (props) => {
  const { variant, backHandler } = props;
  return (
    <img
      src={backBtn}
      alt='back button'
      className={variant}
      onClick={backHandler}
    />
  );
};

export default BackButton;
BackButton.propTypes = {
  variant: PropTypes.string,
  backHandler: PropTypes.func,
};
