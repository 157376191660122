import { useMutation } from "@apollo/client";
import React from "react";
import Input from "../../components/GlobalComponents/UI/Input/Input";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";
import contactUs from "../../graphql/mutations/contactUs";
import useInput from "../../hooks/useInput";
import PropTypes from "prop-types";
import {
  emailValidationRules,
  nameValidationRules,
} from "../../utils/validationRules";
import styles from "./ContactUs.module.css";

const ContactUs = (props) => {
  const {
    value: message,
    valueChangeHandler: messageHandler,
    resetHandler: messageResetHandler,
  } = useInput();

  const [sendMessage, { data, loading, error }] = useMutation(contactUs);
  const { userData } = props;
  const {
    value: firstName,
    hasError: firstNameError,
    errorMessage: firstNameErrorMessage,
    valueChangeHandler: firstNameHandler,
    valueBlurHandler: firstNameBlurHandler,
    isValid: firsNameValid,
  } = useInput(nameValidationRules, userData?.firstName);
  const {
    value: lastName,
    hasError: lastNameError,
    errorMessage: lastNameErrorMessage,
    valueChangeHandler: lastNameHandler,
    valueBlurHandler: lastNameBlurHandler,
    isValid: lastNameValid,
  } = useInput(nameValidationRules, userData?.lastName);

  const {
    value: email,
    hasError: emailError,
    errorMessage: emailErrorMessage,
    valueChangeHandler: emailHandler,
    valueBlurHandler: emailBlurHandler,
    isValid: emailValid,
  } = useInput(emailValidationRules, userData?.email);

  const submitHandler = (e) => {
    e.preventDefault();
    sendMessage({
      variables: {
        email: email,
        name: `${firstName} ${lastName}`,
        subject: "",
        content: message,
      },
    }).then(() => messageResetHandler());
  };

  return (
    <div className={styles.supportPage}>
      {data ? (
        <h1 className={styles.successMessage}>
          We just received your message. Thank you very much for writing to us.
          We will get in touch as soon as possible.
        </h1>
      ) : (
        <form className={styles.supportForm} onSubmit={submitHandler}>
          <h4 className={styles.header}>Contact Us</h4>
          <Input
            hasLabel
            isRequired
            label='FIRST NAME'
            placeholder='e.g. Isaiah'
            type='text'
            inpContainerClass={`${styles.halfWidthContainer} ${
              firstNameError && styles.invalid
            }`}
            value={firstName}
            changeHandler={firstNameHandler}
            blurHandler={firstNameBlurHandler}
            invalid={firstNameError}
            errorMessage={firstNameErrorMessage}
          />
          <Input
            hasLabel
            isRequired
            label='LAST NAME'
            placeholder='e.g. Isaiah'
            type='text'
            inpContainerClass={`${styles.halfWidthContainer} ${
              lastNameError && styles.invalid
            }`}
            value={lastName}
            changeHandler={lastNameHandler}
            blurHandler={lastNameBlurHandler}
            invalid={lastNameError}
            errorMessage={lastNameErrorMessage}
          />
          <Input
            hasLabel
            isRequired
            label='EMAIL'
            placeholder='e.g. yourname@gmail.com'
            type='email'
            inpContainerClass={`${styles.halfWidthContainer} ${
              emailError && styles.invalid
            }`}
            value={email}
            changeHandler={emailHandler}
            blurHandler={emailBlurHandler}
            invalid={emailError}
            errorMessage={emailErrorMessage}
          />
          <Input
            elementType='textArea'
            placeholder='Add your message here'
            value={message}
            changeHandler={messageHandler}
            inpContainerClass={styles.inpContainer}
            hasLabel
            isRequired
            invalid={error ? true : false}
            errorMessage={error?.message}
          />
          <button
            type='submit'
            className={`${styles.sendBtn} ${
              loading ? styles.loadingBg : styles.regularBg
            }`}
            disabled={
              message.length === 0 ||
              !firsNameValid ||
              !emailValid ||
              !lastNameValid
            }
          >
            {loading ? <Spinner /> : "SEND"}
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactUs;
ContactUs.propTypes = {
  userData: PropTypes.object,
};
