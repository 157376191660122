import React from "react";
import ImageShapes from "../../ImageShapes/ImageShapes";
import Loader from "../../Loader/Loader";
import styles from "./ItemLoader.module.css";
import PropTypes from "prop-types";

const ItemLoader = (props) => {
  const { variant, darkColor } = props;
  return (
    <div className={styles.pick}>
      <div className={styles.imgContainer}>
        <Loader
          variant={`${styles.pickImg} ${variant}`}
          darkColor={darkColor}
        />
      </div>
      <Loader variant={styles.business} darkColor={darkColor} />
      <Loader variant={styles.name} darkColor={darkColor} />
    </div>
  );
};

export default ItemLoader;
ItemLoader.propTypes = {
  variant: PropTypes.string,
  darkColor: PropTypes.bool,
};
