import React from "react";
import styles from "./SharedHeaderLoader.module.css";

const SharedHeaderLoader = () => {
  return (
    <div className={styles.content}>
      <div className={`${styles.title} ${styles.loading}`} />
      <div className={`${styles.desc} ${styles.loading}`} />
      <div className={styles.imgBtn}>
        <div className={`${styles.heroImg} ${styles.loading}`} />
        <div className={`${styles.learn} ${styles.learnLoading}`} />
      </div>
    </div>
  );
};

export default SharedHeaderLoader;
