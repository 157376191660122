import React from "react";
import PropTypes from "prop-types";
import SeeAllBtn from "../../GlobalComponents/Buttons/SeeAllBtn/SeeAllBtn";
import CollectionCard from "./CollectionCard";
import styles from "./CollectionsSection.module.css";

export default function CollectionsSection({ collections }) {
  return (
    <div className={styles.collectionsSection}>
      <div className={styles.header}>
        <p className={styles.title}>Collections</p>
        <SeeAllBtn route='/collections' />
      </div>

      {collections?.length === 0 ? (
        <p className={styles.noItemsText}>No collections found</p>
      ) : (
        <div className={styles.itemsList}>
          {collections?.map((collection, i) => (
            <CollectionCard
              collection={collection}
              key={`${collection?.id} ${i}`}
            />
          ))}
        </div>
      )}
    </div>
  );
}

CollectionsSection.propTypes = {
  collections: PropTypes.array,
};
