import React, { useEffect, useRef, useState } from "react";
import AboutJudge from "./AboutJudge";
import ImagesSlider from "./ImagesSlider";
import styles from "./Judges.module.css";
const Judges = () => {
  const verticalRef = useRef();
  const containerRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    verticalRef.current?.slickGoTo(currentIndex);
  }, [currentIndex]);

  return (
    <section className={styles.judgesSection} ref={containerRef}>
      <div className={styles.judgesContainer}>
        <div className={styles.desc}>
          <h2 className={styles.title}>MEET THE JUDGES</h2>
          <AboutJudge setCurrentIndex={setCurrentIndex} />
        </div>
        <ImagesSlider
          setCurrentIndex={setCurrentIndex}
          ref={verticalRef}
          currentIndex={currentIndex}
        />
      </div>
    </section>
  );
};

export default Judges;
