import React, { useEffect, useState } from "react";
import styles from "./ImgsSlider.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import PropTypes from "prop-types";
import NextBtn from "./NextBtn/NextBtn";
import PrevBtn from "./PrevBtn/PrevBtn";
import VisitBtn from "./VisitBtn/VisitBtn";
import SliderImg from "./SliderImg/SliderImg";
import { useReactiveVar } from "@apollo/client";
import { itemEditField, editView } from "../../../../utils/cache";
import EditImages from "../EditImages/EditImages";

const ImgsSlider = (props) => {
  const {
    sliderImages,
    website,
    noWebsiteButton,
    containerClass,
    elementClass,
    rightArrowClass,
    leftArrowClass,
    imgClass,
    btnClass,
    itemType,
  } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextBtn variant={rightArrowClass} />,
    prevArrow: <PrevBtn variant={leftArrowClass} />,
  };

  const isEditing = useReactiveVar(editView);
  const selectedToEdit = useReactiveVar(itemEditField);

  return (
    <div
      className={`${containerClass} ${
        selectedToEdit === "itemImages" || selectedToEdit === "visitBtn"
          ? styles.highlighted
          : styles.edit
      }`}
    >
      {sliderImages?.length > 0 && (
        <Slider {...settings}>
          {sliderImages?.map(function (slide) {
            return (
              <SliderImg
                key={slide}
                path={slide?.path}
                imgClass={imgClass}
                elementClass={elementClass}
              />
            );
          })}
        </Slider>
      )}
      <VisitBtn
        noWebsiteButton={noWebsiteButton}
        btnClass={btnClass}
        website={website}
        itemType={itemType}
      />
      <EditImages sliderImages={sliderImages} itemType={itemType} />
    </div>
  );
};

export default ImgsSlider;
ImgsSlider.propTypes = {
  sliderImages: PropTypes.arrayOf(PropTypes.object),
  website: PropTypes.string,
  noWebsiteButton: PropTypes.bool,
  containerClass: PropTypes.string,
  elementClass: PropTypes.string,
  rightArrowClass: PropTypes.string,
  leftArrowClass: PropTypes.string,
  imgClass: PropTypes.string,
  btnClass: PropTypes.string,
  itemType: PropTypes.string,
};
