export const firstColItems = [
  { item: "Discover", route: "/", adminRoute: "/admin-editor" },
  {
    item: "Products",
    route: "/products",
    adminRoute: "/admin-editor/products",
  },
  {
    item: "Services",
    route: "/services",
    adminRoute: "/admin-editor/services",
  },
  {
    item: "Businesses",
    route: "/businesses",
    adminRoute: "/admin-editor/businesses",
  },
  {
    item: "Brick & Mortar",
    route: "/brick-and-mortars",
    adminRoute: "/admin-editor/brick-and-mortars",
  },
  {
    item: "Online-Based",
    route: "/e-commerce",
    adminRoute: "/admin-editor/e-commerce",
  },
  {
    item: "Restaurants",
    route: "/restaurants",
    adminRoute: "/admin-editor/restaurants",
  },
  { item: "Food", route: "/food", adminRoute: "/admin-editor/food" },
];
export const secondColItems = [
  { item: "Get Listed", route: "/get-listed" },
  { item: "About Us", route: "https://obws.com/about-us/" },
  { item: "Blog", route: "https://blog.obws.com/" },
  { item: "Contact Us", route: "/contact-us" },
];
export const thirdColItems = [
  { item: "Terms", route: "/terms" },
  { item: "Privacy Policy", route: "/privacy-policy" },
];
