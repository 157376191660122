import { useState } from "react";
const useShowConfirmation = () => {
  const [show, setShow] = useState(false);
  const confirmationToggleHandler = () => setShow(!show);
  return {
    showConfirmation: show,
    toggleConfirmationHandler: confirmationToggleHandler,
  };
};
export default useShowConfirmation;
