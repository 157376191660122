import React from "react";

import styles from "./SignUpTerms.module.css";

const TermsText = () => {
  return (
    <div className={styles.termsTextContainer}>
      <p className={styles.termsText}>
        <b>Official Black Wall Street Terms of Service</b>
      </p>
      <p className={styles.termsText}>
        PLEASE READ THE FOLLOWING TERMS OF SERVICE AGREEMENT CAREFULLY. BY
        ACCESSING OR USING OUR SITES AND OUR SERVICES, YOU HEREBY AGREE TO BE
        BOUND BY THE TERMS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE. IF
        YOU DO NOT EXPRESSLY AGREE TO ALL OF THE TERMS AND CONDITIONS, THEN
        PLEASE DO NOT ACCESS OR USE OUR SITES OR OUR SERVICES.
      </p>
      <p className={styles.termsText}>
        <b>THIS TERMS OF SERVICE AGREEMENT IS EFFECTIVE AS OF 03/17/2021.</b>
      </p>
      <p className={styles.termsSubtitle}>1. ACCEPTANCE OF TERMS</p>
      <p className={styles.termsText}>
        The following Terms of Service Agreement (the <b>“Agreement”</b> or{" "}
        <b>“TOS”</b>) is a legally binding agreement that shall govern the
        relationship between Official Black Wall Street LLC and our affiliates
        (collectively, the
        <b>“Company,”</b> <b>“we,” </b> <b>“us,”</b> or <b> “our”</b>) and our
        users and others which may interact or interface with the Company
        through the use of the Company’s website and mobile application (the{" "}
        <b>“Site”</b>), and/or all related services offered by the Company,
        whether provided through the Site, mobile application, web application,
        social media, or otherwise (collectively, the <b>“Services”</b>).
      </p>
      <p className={styles.termsSubtitle}>2. USE OF SERVICES</p>
      <p className={styles.termsText}>
        All visitors to our Site, whether they are registered or not, shall be
        deemed “users” of the Company’s Services for the purpose of this TOS.
        Once an individual registers for our Services by creating an account and
        paying the applicable fee, the user shall then be considered a “member”.
        <br></br>
        <br></br>
        The user and/or member acknowledges and agrees that the Services
        provided and made available through the Site are the sole property of
        the Company. At its discretion, the Company may offer additional
        Services and/or products, or update, modify or revise any current
        content and Services, and this Agreement shall apply to any and all
        additional Services and/or products and any and all updated, modified or
        revised Services unless otherwise stipulated. The Company does hereby
        reserve the right to cancel and cease offering any of the aforementioned
        Services and/or products. You, as the end user and/ or member,
        acknowledge, accept and agree that the Company shall not be held liable
        for any such updates, modifications, revisions, suspensions or
        discontinuance of any of our Services and/or products. Your continued
        use of the Services after such posting of any updates, changes, and/or
        modifications shall constitute your acceptance of such updates, changes
        and/or modifications, and as such, frequent review of this Agreement and
        any and all applicable terms and policies should be made by you to
        ensure you are aware of all terms and policies currently in effect.
        Should you not agree to the updated, modified, revised or modified
        terms, you must stop using the provided Services. <br></br>
        <br></br>Furthermore, the user and/or member understands, acknowledges
        and agrees that the Services offered shall be provided “AS IS” and as
        such the Company shall not assume any responsibility or obligation for
        the timeliness, missed delivery, deletion and/or any failure to store
        user content, communication or personalization settings.
      </p>
      <p className={styles.termsSubtitle}>3. REGISTRATION</p>
      <p className={styles.termsText}>
        Users may register and become members by paying a subscription fee at
        the then applicable rate conforming to the subscription of your
        choosing. For more information on the types and costs of subscriptions,
        see the subscription section below, or the Company website at{" "}
        <a href="https://obws.com/applynow">https://obws.com/applynow</a>. To
        register and become a member, you must be 18 years of age and have the
        legal capacity to enter into and form a legally binding contract. In
        addition, you must be in good standing and not an individual that has
        been previously barred from receiving the Company’s Services under the
        laws and statutes of the United States or other applicable jurisdiction.
        Furthermore, the registering party hereby acknowledges, understands and
        agrees to:
      </p>
      <p className={styles.termsList}>
        1. furnish factual, correct, current and complete information with
        regards to yourself as may be requested by the data registration
        process; and <br></br> 2. maintain and promptly update your registration
        and profile information to maintain accuracy and completeness at all
        times.
      </p>
      <p className={styles.termsText}>
        If you knowingly provide any false, inaccurate, or incomplete
        information, the Company will have sufficient grounds and rights to
        suspend or terminate your membership and refuse any and all current or
        future use of the Company’s Services, or any portion thereof. It is the
        Company’s priority to ensure the safety and privacy of all its visitors,
        users and members, especially that of children. For this reason, the
        parents of any child under the age of 13 must grant permission for
        his/her child or children to access to the Company’s Services,
        including, but not limited to, the store and the blog. It is the
        parent’s and/or legal guardian’s responsibility to determine whether any
        of the Services and/or content provided are age-appropriate for his/her
        child.
      </p>
      <p className={styles.termsSubtitle}>4. PRIVACY POLICY</p>
      <p className={styles.termsText}>
        Every member’s registration data and other personally identifiable
        information is collected and protected according to the Company’s Online
        Privacy Policy (see the full Privacy Policy) As a member, you herein
        consent to the collection and use of the information provided, including
        the transfer of information within the United States and/or other
        countries for storage, processing or use by the Company and/or our
        affiliates.
      </p>
      <p className={styles.termsSubtitle}>
        5. MEMBER ACCOUNT, USERNAME, PASSWORD AND SECURITY
      </p>
      <p className={styles.termsText}>
        You shall be responsible for maintaining the secrecy and confidentiality
        of your password and for all activities that transpire on or within your
        account. It shall be your responsibility to notify the Company
        immediately if you notice any unauthorized access or use of your account
        or password or any other breach of security. The Company shall not be
        held liable for any loss and/or damage arising from any failure to
        comply with this section of the TOS. You agree to be responsible for all
        actions arising from your account as a member
      </p>
      <p className={styles.termsSubtitle}>6. CONDUCT</p>
      <p className={styles.termsText}>
        As a member of the Site, you herein acknowledge, understand and agree
        that all information, text, software, data, photographs, music, video,
        messages, tags or any other content, whether publicly or privately
        posted and/or transmitted, is the expressed sole responsibility of the
        individual from whom the content originated. This means that you and
        other users/members are solely responsible for any and all content
        posted, uploaded, linked, emailed, transmitted or otherwise made
        available by way of the Company’s Services, and as such, we do not
        guarantee the accuracy, integrity or quality of such content. It is
        expressly understood that by using our Services, you may be exposed to
        inaccurate, incomplete, or otherwise imperfect content including, but
        not limited to, any errors or omissions in any content posted. The
        Company will not be liable for any loss or damage of any kind incurred
        as a result of the use of any content posted, linked, emailed,
        transmitted or otherwise made available by the Company
      </p>
      <p className={styles.termsText}>
        <br></br>
        <br></br>Furthermore, you herein agree not to make use of the Company’s
        Services for the purpose of:
      </p>
      <p className={styles.termsList}>
        1. uploading, posting, emailing, transmitting, or otherwise making
        available any content that shall be deemed unlawful, harmful,
        threatening, abusive, harassing, tortious, defamatory, vulgar, obscene,
        libelous, or invasive of another’s privacy or which is hateful, and/or
        racially, ethnically, or otherwise objectionable; <br></br>2. causing
        harm to minors in any manner whatsoever; <br></br>3. impersonating any
        individual or entity, including, but not limited to, any Company
        officials, forum leaders, users, members, guides or hosts or falsely
        stating or otherwise misrepresenting any affiliation with an individual
        or entity; <br></br>4. forging captions, headings or titles or otherwise
        offering any content that you have no right to offer under any law,
        contract, or fiduciary relationship; <br></br>5. uploading, posting,
        emailing, transmitting or otherwise offering any such content that may
        infringe upon any patent, copyright, trademark, or any other proprietary
        or intellectual rights of any other party; <br></br>6. uploading,
        posting, emailing, transmitting or otherwise offering any content that
        you have no right to offer under any law, contract, or fiduciary
        relationship; <br></br>7. uploading, posting, emailing, transmitting, or
        otherwise offering any unsolicited or unauthorized advertising,
        promotional flyers, “junk mail,” “spam,” or any other form of
        solicitation, except in any such areas that may have been designated for
        such purpose; <br></br>8. uploading, posting, emailing, transmitting, or
        otherwise offering any source that may contain a software virus or other
        computer code, any files and/or programs which have been designed to
        interfere, destroy and/or limit the operation of any computer software,
        hardware, or telecommunication equipment;<br></br> 9. disrupting the
        normal flow of communication, or otherwise acting in any manner that
        would negatively affect other users’ ability to participate in any
        interactions; <br></br>10. interfering with or disrupting any Company
        Services, servers and/or networks that may be connected or related to
        our website, including, but not limited to, the use of any device
        software and/or routine to bypass the robot exclusion headers; <br></br>
        11. intentionally or unintentionally violating any local, state,
        federal, national or international law, including, but not limited to,
        rules, guidelines, and/or regulations decreed by the U.S. Securities and
        Exchange Commission, in addition to any rules of any nation or other
        securities exchange, that would include without limitation, the New York
        Stock Exchange, the American Stock Exchange, or the NASDAQ, and any
        regulations having the force of law; <br></br>12. providing
        informational support or resources, concealing and/or disguising the
        character, location, and or source to any organization delegated by the
        United States government as a “foreign terrorist organization” in
        accordance to Section 219 of the Immigration Nationality Act; <br></br>
        13. “stalking” or with the intent to otherwise harass another
        individual; and/or <br></br>14. collecting or storing of any personal
        data relating to any other member or user in connection with the
        prohibited conduct and/or activities which have been set forth in the
        aforementioned paragraphs.
      </p>
      <p className={styles.termsText}>
        The Company herein reserves the right to pre-screen, refuse and/or
        delete any content currently available through our Services. In
        addition, we reserve the right to modify, remove and/or delete any such
        content that would violate the TOS or which would otherwise be
        considered offensive to other visitors, users and/ or members. If we
        modify, remove and/or delete content, you may not be able to recover the
        original content, even if you uploaded it.<br></br>
        <br></br> The Company herein reserves the right to include the use of
        security components that may permit digital information or material to
        be protected, and that such use of information and/or material is
        subject to usage guidelines and regulations established by the Company
        or any other content providers supplying content services to the
        Company. You are hereby prohibited from making any attempt to override
        or circumvent any of the embedded usage rules in our Services.
        Furthermore, unauthorized reproduction, publication, distribution, or
        exhibition of any information or materials supplied by our Services,
        whether in whole or in part, is expressly prohibited.
      </p>
      <p className={styles.termsSubtitle}>
        7. CAUTIONS FOR GLOBAL USE AND EXPORT AND IMPORT COMPLIANCE
      </p>
      <p className={styles.termsText}>
        By using our network you hereby agree to comply with all laws,
        regulations, and rules relating to online conduct and that which is
        considered acceptable content in the jurisdiction from where you are
        accessing the Services. Uploading, posting and/or transferring of
        software, technology and other technical data may be subject to the
        export and import laws of the United States and possibly other
        countries. Through the use of our network, you thus agree to comply with
        all applicable export and import laws, statutes and regulations,
        including, but not limited to, the Export Administration Regulations (
        <a href="https://www.bis.doc.gov/index.php/regulations/export-administration-regulations-ear">
          Export Administration Regulations (EAR)
        </a>
        ), as well as the sanctions control program of the United States (
        <a href="https://home.treasury.gov/policy-issues/financial-sanctions/sanctions-programs-and-country-information">
          Sanctions Programs and Country Information
        </a>
        ). Furthermore, you state and pledge that you:
      </p>
      <p className={styles.termsList}>
        1. are not on the list of prohibited individuals which may be identified
        on any government export exclusion report nor a member of any other
        government which may be part of an exportprohibited country identified
        in applicable export and import laws and regulations; <br></br>2. agree
        not to use our Services to transfer any software, technology or any
        other technical data to any export-prohibited country; <br></br>3. agree
        not to use our website network Services for any military, nuclear,
        missile, chemical or biological weaponry end uses that would be a
        violation of the U.S. export laws; and<br></br> 4. agree not to post,
        transfer nor upload any software, technology or any other technical data
        which would be in violation of the U.S. or other applicable export
        and/or import laws.
      </p>
      <p className={styles.termsSubtitle}>
        8. CONTENT PLACED OR MADE AVAILABLE FOR COMPANY SERVICES
      </p>
      <p className={styles.termsText}>
        The Company shall not lay claim to ownership of any content submitted by
        any visitor, member or user, or make such content available for
        inclusion on our website Services. The Company may use any user or
        member’s brand logo for promotional purposes.
      </p>
      <p className={styles.termsSubtitle}>
        9. CONTRIBUTIONS TO COMPANY WEBSITE
      </p>
      <p className={styles.termsText}>
        The Company provides an area/email for our users and members to
        contribute feedback to our website. When you submit ideas, documents,
        suggestions and/or proposals (“Contributions”) to our site, you
        acknowledge and agree that:
      </p>
      <p className={styles.termsList}>
        1. your Contributions do not contain any type of confidential or
        proprietary information; <br></br>2. the Company shall not be liable or
        under any obligation to ensure or maintain confidentiality, expressed or
        implied, related to any Contributions; <br></br>3. the Company shall be
        entitled to make use of and/or disclose any such Contributions in any
        such manner as they may see fit; <br></br>4. the contributor’s
        Contributions shall automatically become the sole property of the
        Company; and <br></br>5. the Company is under no obligation to either
        compensate or provide any form of reimbursement or credit in any manner
        or nature.
      </p>
      <p className={styles.termsSubtitle}>10. INDEMNITY</p>
      <p className={styles.termsText}>
        All users and/or members herein agree to indemnify, defend, and hold
        harmless the Company, our affiliates, agents, employees, officers,
        partners and/or licensors for any claim, liability, cost, expense, or
        demand (which may include, but is not limited to, reasonable attorney
        fees) made by any third party, which may arise from any content a member
        or user of our site may submit, post, modify, transmit or otherwise make
        available through our Services (including the Payment Platform
        Services), the use of the Company’s Services or your connection with
        these Services, your violations of the Terms of Service and/ or your
        violation of any such rights of another person
      </p>
      <p className={styles.termsSubtitle}>11. COMMERCIAL REUSE OF SERVICES</p>
      <p className={styles.termsText}>
        The member or user herein agrees not to replicate, duplicate, copy,
        trade, sell, resell nor exploit for any commercial reason any part, use
        of, or access to the Company’s Services.
      </p>
      <p className={styles.termsSubtitle}>
        12. USE AND STORAGE GENERAL PRACTICES
      </p>
      <p className={styles.termsText}>
        You herein acknowledge that the Company may set up any such practices
        and/or limits regarding the use of our Services, without limitation of
        the maximum number of days that any uploaded content shall be retained
        by the Company, and/or the maximum number of times and/or duration that
        any member may access our Services in a given period of time. In
        addition, you also agree that the Company has absolutely no
        responsibility or liability for the removal or failure to maintain
        storage of any content maintained or transmitted by our Services. You
        also herein acknowledge that we reserve the right to delete or remove
        any account that is no longer active for an extended period of time.
        Furthermore, the Company shall reserve the right to modify, alter and/or
        update these general practices and limits at our discretion.
      </p>
      <p className={styles.termsSubtitle}>13. MODIFICATIONS</p>
      <p className={styles.termsText}>
        The Company shall reserve the right to modify, alter, suspend, and/or
        discontinue, whether temporarily or permanently, our service, or any
        part thereof, with or without prior notice. In addition, we shall not be
        held liable to you or to any third party for any such alteration,
        modification, suspension and/or discontinuance of our Services, or any
        part thereof.
      </p>
      <p className={styles.termsSubtitle}>14. SUBSCRIPTIONS</p>
      <p className={styles.termsText}>
        Some Services and account types are available only on a subscription
        basis (the “Subscription”). There are multiple levels of Subscription,
        with each having a set duration and cost as outlined on our Site (
        <a href="https://obws.com/apply-now/">https://obws.com/apply-now/</a>).
        By signing up for a Subscription through the Company (whether on the
        Site, app, or otherwise), you agree to be billed the then-current amount
        for the selected Subscription level at the scheduled interval, as
        indicated upon creation of the Subscription. Subscriptions will
        automatically renew at the then-current rate prior to the end of the
        current period unless terminated in accordance with the Termination
        section of this TOS.
      </p>
      <p className={styles.termsSubtitle}>15. TERMINATION</p>
      <p className={styles.termsText}>
        Subscriptions for business owners automatically renew unless terminated
        by the member at least 24- hours before the end of the current period.
        As a member of the Company, you may manage or terminate your
        Subscription level, payment method, associated email address, and/or
        other access to our Services by accessing your Account Settings after
        purchase.<br></br>
        <br></br> IF YOU TERMINATE YOUR ACCOUNT DURING A SUBSCRIPTION, YOU WILL
        CONTINUE TO BE BILLED FOR THE REMAINDER OF THE SUBSCRIPTION AND WILL
        HAVE ACCESS TO YOUR ACCOUNT UNTIL THE END OF THE SUBSCRIPTION PERIOD,
        UPON WHICH TIME YOUR SUBSCRIPTION AND ACCOUNT ACCESS WILL CEASE.
        <br></br>
        <br></br> IN ORDER TO AVOID RENEWAL OF A SUBSCRIPTION, YOU MUST CANCEL
        YOUR ACCOUNT AT LEAST 24 HOURS BEFORE THE SCHEDULED RENEWAL.<br></br>
        <br></br> The terms of any refund policy found on the website (
        <a href="https://obws.com/apply-now/">https://obws.com/apply-now/</a>)
        will take precedence over this section.
        <br></br>
        <br></br> As a member, you agree that the Company may, without any prior
        written notice, immediately suspend, terminate, discontinue and/or limit
        your account, any email associated with your account, and access to any
        of our Services at any time and for any reason. <br></br>
        <br></br>Furthermore, you herein agree that any and all terminations,
        suspensions, discontinuances, and or limitations of access for cause
        shall be made at our sole discretion and that we shall not be liable to
        you or any other third party with regards to the termination of your
        account, associated email address and/or access to any of our Services.
        <br></br>
        <br></br> The termination of your account with the Company may include
        any and/or all of the following:
      </p>
      <p className={styles.termsList}>
        1. the removal of any access to all or part of the Services offered
        within the Company; <br></br>2. the deletion of your password along with
        any and all information, files, and content that may be associated with
        or inside your account, or any part thereof; and <br></br>3. the barring
        of any further use of all or part of our Services.
      </p>
      <p className={styles.termsSubtitle}>16. ADVERTISING</p>
      <p className={styles.termsText}>
        The Company offers advertising options as part of our Services. Members
        may select various forms of advertising by paying the then-applicable
        rates as reflected on the Company’s website (
        <a href="https://obws.com/apply-now/">https://obws.com/apply-now/</a>).
        The Company will not grant refunds for advertising or for promotional
        campaigns purchased through our platforms. By purchasing advertising
        services, you confirm that you own or have permission to use any assets
        and images submitted for advertising and will further comply with the
        policies outlined in the Conduct section of this TOS.
      </p>
      <p className={styles.termsSubtitle}>17. ADVERTISERS</p>
      <p className={styles.termsText}>
        Any correspondence or business dealings with, or the participation in
        any promotions of, advertisers located on or through our Services, which
        may include the payment and/or delivery of such related goods and/or
        Services, and any such other term, condition, warranty and/or
        representation associated with such dealings, are and shall be solely
        between you and any such advertiser. Moreover, you herein agree that the
        Company shall not be held responsible or liable for any loss or damage
        of any nature or manner incurred as a direct result of any such dealings
        or as a result of the presence of such advertisers on our website.
      </p>
      <p className={styles.termsSubtitle}>18. LINKS</p>
      <p className={styles.termsText}>
        The Company, members, or other third parties may provide links to other
        websites and/or resources. You acknowledge and agree that we are not
        responsible for the availability of any such external sites or resources
        and we do not endorse nor are we responsible or liable for any content,
        products, advertising, or any other materials on or available from such
        third party sites or resources. Furthermore, you acknowledge and agree
        that the Company shall not be responsible or liable, directly or
        indirectly, for any damage or loss which may result from the use of or
        the reliance on any such content, goods or services made available on or
        through any such site or resource.
      </p>
      <p className={styles.termsSubtitle}>19. ASSIGNMENT OF RIGHTS</p>
      <p className={styles.termsText}>
        Because the Company relies on user content to provide great service, you
        agree to grant to the Company an irrevocable, perpetual, and worldwide
        right to use, display, modify, and distribute any and all content you
        upload or transmit to or through the Services. The Company may use the
        content in a variety of ways, such as promoting, providing, and
        improving Services. We may not be able to give users credit or
        compensation for the content. This license will continue even if you
        stop using the Services.
      </p>
      <p className={styles.termsSubtitle}>20. WARRANTY DISCLAIMERS</p>
      <p className={styles.termsText}>
        YOU HEREIN EXPRESSLY ACKNOWLEDGE AND AGREE THAT:
      </p>
      <p className={styles.termsList}>
        1. YOU USE THE COMPANY’S SERVICES AT YOUR SOLE RISK. OUR SERVICES SHALL
        BE PROVIDED ON AN “AS IS” AND/OR “AS AVAILABLE” BASIS. THE COMPANY AND
        OUR AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS
        EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESSED
        OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF
        TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
        NON-INFRINGEMENT. <br></br>2. THE COMPANY AND OUR SUBSIDIARIES,
        OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS MAKE NO SUCH
        WARRANTIES THAT: (i) THE COMPANY’S SERVICES WILL MEET YOUR REQUIREMENTS;
        (ii) THE COMPANY’S SERVICES SHALL BE UNINTERRUPTED, TIMELY, SECURE OR
        ERROR-FREE; (iii) ANY RESULTS WHICH MAY BE OBTAINED FROM THE USE OF THE
        COMPANY’S SERVICES WILL BE ACCURATE OR RELIABLE; AND (iv) THE QUALITY OF
        ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL WHICH MAY BE
        PURCHASED OR OBTAINED BY YOU THROUGH OUR SERVICES WILL MEET YOUR
        EXPECTATIONS. <br></br>3. ANY INFORMATION OR MATERIAL DOWNLOADED OR
        OTHERWISE OBTAIN BY WAY OF THE COMPANY’S SERVICES SHALL BE ACCESSED AT
        YOUR SOLE DISCRETION AND SOLE RISK, AND YOU SHALL BE SOLELY RESPONSIBLE
        FOR, AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH
        RESPECT TO, ANY DAMAGE TO YOUR COMPUTER AND/OR INTERNET ACCESS OR FOR
        ANY LOSS OF DATA THAT COULD RESULT FROM THE DOWNLOAD OF ANY INFORMATION
        OR MATERIAL.
        <br></br>4. NO ADVICE AND/OR INFORMATION, WHETHER WRITTEN OR ORAL, THAT
        MAY BE OBTAINED BY YOU FROM THE COMPANY OR BY WAY OF OR FROM OUR
        SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TOS.
      </p>
      <p className={styles.termsSubtitle}>21. LIMITATION OF LIABILITY</p>
      <p className={styles.termsText}>
        YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT THE COMPANY AND
        OUR AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS
        SHALL NOT BE LIABLE TO YOU FOR ANY PUNITIVE, INDIRECT, INCIDENTAL,
        SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED
        TO DAMAGES WHICH MAY BE RELATED TO THE LOSS OF ANY PROFITS, GOODWILL,
        USE, DATA AND/OR OTHER INTANGIBLE LOSSES, EVEN THOUGH WE MAY HAVE BEEN
        ADVISED OF THE POSSIBILITY THAT SAID DAMAGES MAY OCCUR, AND RESULT FROM:
      </p>
      <p className={styles.termsList}>
        1. THE USE OR INABILITY TO USE OUR SERVICE; <br></br>2. THE COST OF
        PROCURING SUBSTITUTE GOODS AND SERVICES; <br></br>3. UNAUTHORIZED ACCESS
        TO OR THE ALTERATION OF YOUR TRANSMISSIONS AND/OR DATA; <br></br>4.
        STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OUR SERVICE;<br></br> 5. AND
        ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.
      </p>
      <p className={styles.termsSubtitle}>22. EXCLUSION AND LIMITATIONS</p>
      <p className={styles.termsText}>
        THERE ARE SOME JURISDICTIONS WHICH DO NOT ALLOW THE EXCLUSION OF CERTAIN
        WARRANTIES OR THE LIMITATION OF EXCLUSION OF LIABILITY FOR INCIDENTAL OR
        CONSEQUENTIAL DAMAGES. THEREFORE, SOME OF THE ABOVE LIMITATIONS OF
        SECTIONS WARRANTY DISCLAIMERS AND LIMITATION OF LIABILITY MAY NOT APPLY
        TO YOU.
      </p>
      <p className={styles.termsSubtitle}>23. NOTICE</p>
      <p className={styles.termsText}>
        The Company may furnish you with notices, including those with regards
        to any changes to the Terms of Service, by way of email, postings on our
        website, text messages, or other reasonable means currently known or any
        which may be hereinafter developed. Any such notices may not be received
        if you violate any aspects of the TOS by accessing our Services in an
        unauthorized manner. Your acceptance of this TOS constitutes your
        agreement that you are deemed to have received any and all notices that
        would have been delivered had you accessed our Services in an authorized
        manner.
      </p>
      <p className={styles.termsSubtitle}>24. TRADEMARK INFORMATION</p>
      <p className={styles.termsText}>
        You herein acknowledge, understand and agree that all of the Company
        trademarks, copyright, trade name, service marks, and other Company
        logos and any brand features, and/or product and service names are
        trademarks and as such, are and shall remain the property of the
        Company. You may post the Company’s logo on your website when connected
        to a link to your products listed on our website. You herein agree not
        to otherwise display and/or use in any manner the Company’s logo or
        marks in a manner inconsistent with this Agreement or without the
        express written consent of the Company.
      </p>
      <p className={styles.termsSubtitle}>
        25. COPYRIGHT OR INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS NOTICE &
        PROCEDURES
      </p>
      <p className={styles.termsText}>
        The Company will always respect the intellectual property of others, and
        we ask that all of our users do the same. The Company may at its sole
        discretion disable and/or terminate the accounts of any user who
        repeatedly violates our TOS and/or infringes the rights of others. If
        you feel that your work has been duplicated in such a way that would
        constitute copyright infringement, or if you believe your intellectual
        property rights have been otherwise violated, you should provide to us
        the following information:
      </p>
      <p className={styles.termsList}>
        1. The electronic or the physical signature of the individual that is
        authorized on behalf of the owner of the copyright or other intellectual
        property interest; <br></br>2. A description of the copyrighted work or
        other intellectual property that you believe has been infringed upon;
        <br></br> 3. A description of the location of the site which you allege
        has been infringing upon your work; <br></br>4. Any relevant physical
        address, telephone number, and email addresses;<br></br> 5. A statement
        in which you state that the alleged and disputed use of your work is not
        authorized by the copyright owner, its agents or the law; <br></br>6.
        And finally, a statement, made under penalty of perjury, that the
        aforementioned information in your notice is truthful and accurate, and
        that you are the copyright or intellectual property owner,
        representative or agent authorized to act on the copyright or
        intellectual property owner’s behalf.
      </p>
      <p className={styles.termsText}>
        The Company’s Agent for notice of claims of copyright or other
        intellectual property infringement can be contacted as follows:
      </p>
      <p className={styles.termsText}>
        Email:{" "}
        <a href="mailto:info@officialblackwallstreet.com">
          info@officialblackwallstreet.com
        </a>
      </p>
      <p className={styles.termsSubtitle}>26. GENERAL INFORMATION</p>
      <p className={styles.termsText}>
        ENTIRE AGREEMENT <br></br> This TOS constitutes the entire agreement
        between you and the Company and shall govern the use of our Services,
        superseding any prior version of this TOS between you and us with
        respect to the Company’s Services. You may also be subject to additional
        terms and conditions that may apply when you use or purchase certain
        other Company Services, affiliate Services, third-party content or
        third-party software. <br></br>CHOICE OF LAW AND FORUM<br></br> Any
        relationship between the parties shall be governed by the laws of the
        state of New York without regard to its conflict of law provisions and
        that any and all claims, causes of action and/or disputes, arising out
        of or relating to the TOS, or the relationship between you and the
        Company, shall be filed within the courts having jurisdiction within
        Kings County, New York or the U.S. District Court located in said state.
        You and the Company agree to submit to the jurisdiction of the courts as
        previously mentioned and agree to waive any and all objections to the
        exercise of jurisdiction over the parties by such courts and to venue in
        such courts. <br></br>WAIVER AND SEVERABILITY OF TERMS <br></br>At any
        time, should the Company fail to exercise or enforce any right or
        provision of the TOS, such failure shall not constitute a waiver of such
        right or provision. If any provision of this TOS is found by a court of
        competent jurisdiction to be invalid, the parties nevertheless agree
        that the court should endeavor to give effect to the parties’ intentions
        as reflected in the provision, and the other provisions of the TOS
        remain in full force and effect. <br></br>NO RIGHT OF SURVIVORSHIP
        NON-TRANSFERABILITY<br></br> You acknowledge, understand and agree that
        your account is non-transferable and any rights to your ID and/or
        contents within your account shall terminate upon your death. Upon
        receipt of a copy of a death certificate, your account may be terminated
        and all contents therein permanently deleted. <br></br>VIOLATIONS
        <br></br> Please report any and all violations of this TOS to the
        Company as follows:
      </p>
      <p className={styles.termsText}>
        Email:{" "}
        <a href="mailto:info@officialblackwallstreet.com">
          info@officialblackwallstreet.com
        </a>
      </p>
    </div>
  );
};

export default TermsText;
