import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithRedirect,
  linkWithCredential,
  OAuthProvider,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import styles from "./AuthenticationOptions.module.css";
import PropTypes from "prop-types";
import googleIcon from "../../../../assets/images/google.svg";
import fbIcon from "../../../../assets/images/fb.svg";
import { errorToastHandler, warningToastHandler } from "../../../../utils";
import { useMutation } from "@apollo/client";
import socialLogin from "../../../../graphql/mutations/authentication/socialLogin";
import socialRegister from "../../../../graphql/mutations/authentication/socialRegister";
import useLogin from "../../../../hooks/useLogin";
import { NavLink } from "react-router-dom";
import Spinner from "../../UI/Spinner/Spinner";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const providerIdentifier = { google: "google", facebook: "fb" };
const AuthenticationOptions = (props) => {
  const { isRegister, isLogin, userType } = props;
  const [login, { loading: loginLoading, client: loginClient }] =
    useMutation(socialLogin);
  const [register, { loading: registerLoading, client: registerClient }] =
    useMutation(socialRegister);
  const loading = loginLoading || registerLoading;
  const [saveUserData, onSuccesHandler] = useLogin();
  const [selectedProvider, setSelectedProvider] = useState();
  const [linkingLoading, setLinkingLoading] = useState(false);
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  const fbProvider = new FacebookAuthProvider();

  const buttonText = () => {
    if (isLogin) return "log in";
    if (isRegister) return "sign up";
  };

  useEffect(() => {
    //handle linking after signin redirection

    const firebaseError = JSON.parse(localStorage.getItem("firebaseError"));
    auth.onAuthStateChanged((user) => {
      if (user && firebaseError) {
        setLinkingLoading(true);
        const pendingCreds = OAuthProvider.credentialFromError(firebaseError);
        if (pendingCreds.providerId === fbProvider.providerId) {
          setSelectedProvider(providerIdentifier.facebook);
        } else {
          setSelectedProvider(providerIdentifier.google);
        }
        fetchSignInMethodsForEmail(auth, user.email).then((providers) => {
          if (providers.some((el) => el !== pendingCreds.providerId)) {
            linkWithCredential(auth.currentUser, pendingCreds);
          }
        });
        authSuccessHander(user);
      }
    });
    return () => setLinkingLoading(false);
  }, []);

  const errorHandler = (errorMessage, user) => {
    if (errorMessage === `User doesn't exist`) {
      user?.delete();
      warningToastHandler(
        <>
          <span>{errorMessage}. Please signup as a </span>
          <NavLink className={styles.register} to='/consumer-register'>
            consumer
          </NavLink>
          <span> or a </span>
          <NavLink className={styles.register} to='/business-register'>
            business owner
          </NavLink>
          <span> first.</span>
        </>,
        { closeOnClick: true }
      );
    } else {
      errorToastHandler(errorMessage, { autoClose: 5000 });
    }
  };

  const authSuccessHander = (user) => {
    localStorage.removeItem("firebaseError");
    if (isLogin) {
      login({ variables: { token: user?.accessToken } })
        .then(({ data }) => {
          saveUserData(data?.loginWithSocial);
          loginClient.resetStore();
          return data;
        })
        .then((data) => {
          onSuccesHandler(data?.loginWithSocial);
          setLinkingLoading(false);
        })
        .catch((err) => {
          const errorMessage = err.message;
          errorHandler(errorMessage, user);
          setLinkingLoading(false);
        });
    }
    if (isRegister) {
      register({
        variables: { token: user?.accessToken, role: userType },
      })
        .then(({ data }) => {
          saveUserData(data?.signUpWithSocial);
          registerClient.resetStore();
          setLinkingLoading(false);
          return data;
        })
        .then((data) => {
          onSuccesHandler(data?.signUpWithSocial);
        })
        .catch((err) => {
          const errorMessage = err.message;
          errorHandler(errorMessage, user);
          setLinkingLoading(false);
        });
    }
  };

  const authenticationHandler = (provider, identifier) => {
    setSelectedProvider(identifier);
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        authSuccessHander(user);
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          const existingEmail = error.customData.email;
          localStorage.setItem("firebaseError", JSON.stringify(error));
          fetchSignInMethodsForEmail(auth, existingEmail)
            .then((providers) => {
              if (providers.indexOf(googleProvider.providerId) != -1) {
                googleProvider.setCustomParameters({
                  login_hint: existingEmail,
                });
                signInWithRedirect(auth, googleProvider);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };

  return (
    <section className={styles.optionsContainer}>
      <p className={styles.header}>or {buttonText()} with</p>
      <button
        className={`${styles.google} ${loading && styles.loadingState}`}
        onClick={authenticationHandler.bind(
          null,
          googleProvider,
          providerIdentifier.google
        )}
        data-testid='google'
      >
        {loading && selectedProvider === providerIdentifier.google ? (
          <Spinner variant={styles.loader} />
        ) : (
          <>
            <img src={googleIcon} alt='google' className={styles.googleIcon} />
            <span> {buttonText()} with google</span>
          </>
        )}
      </button>
      <button
        className={`${styles.fb} ${loading && styles.loadingState} ${
          loading &&
          selectedProvider === providerIdentifier.facebook &&
          styles.whiteBg
        }`}
        data-testid='fb'
        onClick={authenticationHandler.bind(
          null,
          fbProvider,
          providerIdentifier.facebook
        )}
      >
        {loading && selectedProvider === providerIdentifier.facebook ? (
          <Spinner variant={styles.loader} />
        ) : (
          <>
            <img src={fbIcon} alt='facebook' className={styles.fbIcon} />
            <span> {buttonText()} with facebook</span>
          </>
        )}
      </button>
    </section>
  );
};

export default AuthenticationOptions;
AuthenticationOptions.propTypes = {
  isRegister: PropTypes.bool,
  isLogin: PropTypes.bool,
  userType: PropTypes.string,
  googleAuth: PropTypes.func,
  facebookAuth: PropTypes.func,
};
