import React from "react";
import ItemLoader from "./ItemLoader/ItemLoader";
import styles from "../ImageShapes/ImageShapes.module.css";
import { imgShapes } from "../../../../constants";
import PropTypes from "prop-types";
import Loader from "../Loader/Loader";

const SectionLoader = (props) => {
  const { itemsNumber, isBrowseAll, browseVariant, darkColor } = props;
  const loaderArr = Array.from(Array(itemsNumber ?? 8).keys());

  if (isBrowseAll) {
    return (
      <>
        {loaderArr.map((el, i) => (
          <Loader variant={browseVariant} key={el} />
        ))}
      </>
    );
  }
  return (
    <>
      {loaderArr.map((el, i) => (
        <ItemLoader
          key={el}
          variant={styles[imgShapes[i]]}
          darkColor={darkColor}
        />
      ))}
    </>
  );
};

export default SectionLoader;
SectionLoader.propTypes = {
  itemsNumber: PropTypes.number,
  isBrowseAll: PropTypes.bool,
  browseVariant: PropTypes.string,
  darkColor: PropTypes.bool,
};
