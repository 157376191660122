import React from "react";
import PropTypes from "prop-types";
import styles from "./Backdrop.module.css";
import ReactDOM from "react-dom";

const Backdrop = (props) => {
  const { show, clickHandler, children, noPortal, className } = props;

  if (show && noPortal) {
    return <div className={styles.noPortal}>{children}</div>;
  }
  return (
    <>
      {show ? (
        <>
          {ReactDOM.createPortal(
            <div
              className={`${styles.backdrop} ${className}`}
              onClick={clickHandler}
            >
              {children}
            </div>,
            document.getElementById("backdrop-root")
          )}
        </>
      ) : null}
    </>
  );
};

export default Backdrop;

Backdrop.propTypes = {
  show: PropTypes.bool,
  noPortal: PropTypes.bool,
  clickHandler: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};
