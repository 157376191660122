import React from "react";
import styles from "./VisitBtn.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { itemEditField, editView } from "../../../../../utils/cache";
import EditWebsite from "../../EditWebsite/EditWebsite";
const VisitBtn = (props) => {
  const { noWebsiteButton, btnClass, website, itemType } = props;
  const isEditing = useReactiveVar(editView);
  const visitWebsiteHandler = () => {
    if (isEditing) {
      itemEditField("visitBtn");
      return;
    }
    if (website) {
      window.open(
        `${
          website?.includes("http://") || website?.includes("https://")
            ? website
            : `//` + website
        }`
      );
    } else {
      return;
    }
  };
  return (
    <>
      {!noWebsiteButton && (
        <button
          className={`${btnClass} ${
            !website && !isEditing ? styles.disabled : styles.enabled
          } ${isEditing ? styles.edit : undefined}`}
          target='_blank'
          rel='noreferrer'
          onClick={visitWebsiteHandler}
        >
          VISIT
          <br />
          WEBSITE
        </button>
      )}
      <EditWebsite website={website} itemType={itemType} />
    </>
  );
};

export default VisitBtn;
VisitBtn.propTypes = {
  noWebsiteButton: PropTypes.bool,
  btnClass: PropTypes.string,
  itemType: PropTypes.string,
  website: PropTypes.string,
};
