import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./AddToCollection.module.css";
import PropTypes from "prop-types";
import ErrorComponent from "../../../GlobalComponents/UI/ErrorComponent/ErrorComponent";
import CollectionImage from "../../CollectionsPopUp/CollectionImage/CollectionImage";
import Spinner from "../../../GlobalComponents/UI/Spinner/Spinner";
import { useMutation, useQuery } from "@apollo/client";
import collectionsItems from "../../../../graphql/queries/collectionsItems";
import { useParams } from "react-router-dom";
import addItemToCollection from "../../../../graphql/mutations/collections/addItemToCollection";
import collection from "../../../../graphql/queries/collection";

const AddToCollection = (props) => {
  const { addToggle, closeHandler } = props;
  const [selected, setSelected] = useState(0);
  const { id } = useParams();
  const { data, error, loading, fetchMore } = useQuery(collectionsItems, {
    variables: { collectionId: id, page: 1 },
    notifyOnNetworkStatusChange: true,
  });
  const [loadItems, setLoadItems] = useState(false);
  const [addItems, { loading: addLoading, data: addData, reset }] = useMutation(
    addItemToCollection,
    {
      refetchQueries: [collectionsItems, collection],
    }
  );

  const [selectedItems, setSelectedItems] = useState([]);
  const { hasMorePages, currentPage } =
    data?.listAllItemsExcept?.paginatorInfo ?? {};
  const { data: items } = data?.listAllItemsExcept ?? {};

  const observer = useRef();

  const itemsRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          fetchMore({
            variables: {
              page: currentPage + 1,
            },
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMorePages, currentPage, loading]
  );

  const selectHandler = (item) => {
    if (
      selectedItems.some((el) => el.collectableId === item.collectableId) &&
      selectedItems.some((el) => el.collectableType === item.collectableType)
    ) {
      const filteredItems = selectedItems.filter(
        (el) =>
          el.collectableId !== item.collectableId ||
          el.collectableType !== item.collectableType
      );
      setSelectedItems(filteredItems);
      setSelected(filteredItems.length);
    } else {
      setSelectedItems((prev) => [...prev, item]);
      setSelected(selectedItems.length + 1);
    }
  };
  useEffect(() => {
    if (!loading) setLoadItems(false);
  }, [loading]);
  useEffect(() => {
    if (addData && !loadItems) {
      addToggle();
      closeHandler();
      reset();
    }
  }, [loadItems, , addData]);

  const addHandler = () => {
    if (selectedItems.length === 0) return;
    setLoadItems(true);
    addItems({
      variables: {
        items: selectedItems,
      },
    });
  };
  return (
    <div className={styles.addContainer}>
      <h1 className={`${styles.title} ${!selected && styles.noSelect}`}>
        Selecting
      </h1>
      {selected ? (
        <p className={styles.selected}>{`(${selected}) SELECTED`}</p>
      ) : null}
      <div className={styles.collections}>
        {error && (
          <ErrorComponent
            variant={styles.errorMessage}
            error={error?.message}
          />
        )}
        {items?.map((collection, i) => (
          <div
            className={`${styles.collection} ${
              items?.length === 1 && styles.singleItem
            }`}
            key={collection?.item?.name}
            onClick={selectHandler.bind(null, {
              collectionId: id,
              collectableId: collection?.item?.id,
              collectableType: collection?.item.__typename?.toUpperCase(),
            })}
            ref={i === items?.length - 1 ? itemsRef : null}
          >
            <CollectionImage
              imgSrc={collection?.item?.featuredImage?.path}
              variant={styles.collectionImage}
              selecting={true}
              activeCollection={
                selectedItems?.some(
                  (el) => el.collectableId === collection?.item?.id
                ) &&
                selectedItems?.some(
                  (el) =>
                    el.collectableType ===
                    collection?.item.__typename?.toUpperCase()
                )
              }
            />
            {collection?.item?.__typename?.toLowerCase() === "business" ? (
              <>
                <p className={styles.businessName}>{collection.item?.name}</p>
                <p className={styles.itemName}>
                  {collection?.item?.categories?.map((cat, i) => {
                    if (i !== collection?.item?.categories?.length - 1)
                      return `${cat.name}, `;
                    else return `${cat.name}`;
                  })}
                </p>
              </>
            ) : (
              <>
                <p className={styles.businessName}>
                  {collection?.item?.business?.name}
                </p>
                <p className={styles.itemName}>{collection?.item?.name}</p>
              </>
            )}
            <p className={styles.price}>
              {collection?.item?.price
                ? `$${collection?.item?.price?.toFixed(2)}`
                : `${collection?.item?.city?.name}, ${collection?.item?.state?.abbr}`}
            </p>
          </div>
        ))}
        {((items?.length === 0 && !error) || (loading && !addLoading)) && (
          <Spinner variant={styles.loader} />
        )}
      </div>
      <button
        className={`${styles.update} ${
          selectedItems.length === 0 && styles.disabled
        }`}
        onClick={addHandler}
      >
        {addLoading || loadItems ? (
          <Spinner variant={styles.btnLoader} />
        ) : (
          "ADD TO Collection"
        )}
      </button>
    </div>
  );
};

export default AddToCollection;

AddToCollection.propTypes = {
  closeHandler: PropTypes.func,
  addToggle: PropTypes.func,
};
