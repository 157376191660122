import React from "react";
import styles from "./AddressInp.module.css";
import PropTypes from "prop-types";

const FullScreenToggle = (props) => {
  const { fullScreen } = props;
  return (
    <>
      <svg
        width='13'
        height='12'
        viewBox='0 0 13 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={`${styles.firstArrow} ${fullScreen && styles.rotateIcon}`}
      >
        <path
          d='M12.2026 0.596189L1.59603 11.2028M1.59603 11.2028L10.8326 11.1586M1.59603 11.2028L1.64023 1.96621'
          stroke='black'
          strokeWidth='1.5'
        />
      </svg>
      <svg
        width='12'
        height='13'
        viewBox='0 0 12 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={`${styles.secondArrow} ${fullScreen && styles.rotateIcon}`}
      >
        <path
          d='M0.596194 12.2026L11.2028 1.59604M11.2028 1.59604L1.96621 1.64023M11.2028 1.59604L11.1586 10.8326'
          stroke='black'
          strokeWidth='1.5'
        />
      </svg>
    </>
  );
};

export default FullScreenToggle;
FullScreenToggle.propTypes = {
  fullScreen: PropTypes.bool,
};
