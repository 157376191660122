import React from "react";
import PropTypes from "prop-types";
import styles from "./Categories.module.css";
import { useQuery } from "@apollo/client";
import mainCategories from "../../../graphql/queries/mainCategories";
import Loader from "../../GlobalComponents/UI/Loader/Loader";
const Categories = (props) => {
  const { existingCategories, setCategories } = props;
  const { data, loading, error } = useQuery(mainCategories, {
    variables: { size: 50 },
  });
  const { data: mainCategs } = data?.mainCategories ?? {};

  const selectHandler = (category) => {
    const selectedCategories = [...existingCategories];
    if (existingCategories?.some((el) => el?.id === category?.id)) {
      const filteredCategories = selectedCategories?.filter(
        (el) => el?.id !== category?.id
      );
      setCategories([...filteredCategories]);
    } else {
      setCategories((prevCategories) => [...prevCategories, category]);
    }
  };

  return (
    <div className={styles.container}>
      {loading &&
        Array.from(Array(12).keys()).map((el, i) => (
          <Loader variant={styles.category} darkColor key={i} />
        ))}
      {mainCategs?.length > 0 &&
        mainCategs?.map((category, i) => (
          <div
            className={`${styles.category} ${
              existingCategories?.some((el) => el?.id === category?.id) &&
              styles.active
            }`}
            key={`${category?.name}-${i}`}
            onClick={selectHandler.bind(null, category)}
          >
            <p className={styles.name}>{category?.name}</p>
          </div>
        ))}
    </div>
  );
};

export default Categories;
Categories.propTypes = {
  existingCategories: PropTypes.array,
  setCategories: PropTypes.func,
};
