import React from "react";
import styles from "./EditTooltip.module.css";
import PropTypes from "prop-types";
import closeIcon from "../../../../assets/images/close.svg";

const EditTooltip = (props) => {
  const { children, show, variant, anchorStyle, closeHandler } = props;

  return show ? (
    <div className={`${styles.container} ${styles[anchorStyle]} ${variant}`}>
      <img
        src={closeIcon}
        className={styles.closeIcon}
        onClick={closeHandler}
        alt='close'
      />
      <div className={styles.body}>{children}</div>
    </div>
  ) : null;
};

export default EditTooltip;
EditTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  show: PropTypes.bool,
  variant: PropTypes.string,
  anchorStyle: PropTypes.string,
  closeHandler: PropTypes.func,
};
