import { makeVar } from "@apollo/client";
import { searchFilterBy } from "../constants/search";

export const authVar = makeVar();
export const notVerifiedUserData = makeVar("");
export const consumerOnboardingVar = makeVar(-1);
export const appliedSearchFilters = makeVar([]);
export const rolesVar = makeVar([]);
export const businessId = makeVar("");
export const notAppliedSearchFilters = makeVar(searchFilterBy);
export const viewSearchMap = makeVar(false);
export const differentSearchLocationSelected = makeVar(false);
export const searchUserLocation = makeVar();
export const searchTotalCount = makeVar();
export const isEdit = makeVar(false);
export const editView = makeVar(false);
export const itemEditField = makeVar();
export const isAdmin = makeVar(false);
export const savedPath = makeVar(null);
export const brazeCustomAttributes = makeVar({});
export const businessListingStatus = makeVar({
  isStarted: false,
  isCompleted: false,
});
export const userLeftListing = makeVar(false);
export const beyotaStared = makeVar(false);
export const beyotaCompleted = makeVar(false);
export const beyotaSelected = makeVar(null);
export const viewWinners = makeVar(false);
export const savingBizInfoState = makeVar();
export const isFreePlan = makeVar();
