import React from "react";
import regStyles from "./SharedHeroHeader.module.css";
import adminStyles from "./AdminStyles.module.css";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import SharedHeaderLoader from "./SharedHeaderLoader/SharedHeaderLoader";
import { useReactiveVar } from "@apollo/client";
import { isAdmin } from "../../../../utils/cache";
import itemPlaceholder from "../../../../assets/images/itemPlaceholder.jpg";
import Picture from "../../UI/Picture";
const SharedHeroHeader = (props) => {
  const { imgSrc, itemLink, itemTitle } = props;
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  return (
    <div className={styles.heroHeader}>
      <div className={styles.whiteBg}></div>
      {!imgSrc && !itemTitle ? (
        <SharedHeaderLoader />
      ) : (
        <div className={styles.content}>
          <h1 className={styles.title}>{itemTitle}</h1>
          <div className={styles.imgBtn}>
            <Picture
              webpSrc={imgSrc ?? itemPlaceholder}
              alt={itemTitle}
              variant={styles.heroImg}
            />
            <div className={styles.learnHover}>
              <NavLink to={itemLink} className={styles.learn}>
                EXPLORE
                <br /> MORE
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SharedHeroHeader;
SharedHeroHeader.propTypes = {
  imgSrc: PropTypes.string,
  itemLink: PropTypes.string,
  itemTitle: PropTypes.string,
};
