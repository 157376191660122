import { useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import nominatedBusinesses from "../../../graphql/queries/nominatedBusinesses";
import {
  beyotaCompleted,
  beyotaSelected,
  savedPath,
  viewWinners,
} from "../../../utils/cache";
import ErrorComponent from "../../GlobalComponents/UI/ErrorComponent/ErrorComponent";
import ImageShapes from "../../GlobalComponents/UI/ImageShapes/ImageShapes";
import SectionLoader from "../../GlobalComponents/UI/SectionLoader/SectionLoader";
import AwardUpvoteBtn from "./AwardUpvoteBtn";
import styles from "./Nominations.module.css";
import firstBadge from "../../../assets/images/firstWinnerBadge.png";
import secondBadge from "../../../assets/images/secondWinnerBadge.png";
const Nominations = () => {
  const { data, loading, error, refetch } = useQuery(nominatedBusinesses);
  const [selected, setSelected] = useState();
  const { hash } = useLocation();
  const nominationsSection = useRef();
  const isCompleted = useReactiveVar(beyotaCompleted);
  const winnerAnnounced = useReactiveVar(viewWinners);
  const clickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  useEffect(() => {
    if (data) {
      const votedItem = data?.businessesAward?.find(
        (el) => el?.isVotedAward === true
      );
      beyotaSelected(votedItem);
      setSelected(votedItem);
    }
  }, [data]);

  const winnerImageStyle = (winnerRank) => {
    if (isCompleted && !winnerAnnounced) {
      return styles.dimmedBusiness;
    }
    if (winnerAnnounced) {
      if (winnerRank) {
        return;
      } else {
        return styles.dimmedBusiness;
      }
    }
  };

  const winnerSrcBadge = (winnerRank) => {
    if (winnerAnnounced) {
      switch (winnerRank) {
        case 1:
          return firstBadge;
        case 2:
          return secondBadge;

        default:
          return false;
      }
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (hash === "#nominations")
      window.scrollTo({ top: nominationsSection?.current?.offsetTop });
  }, [hash]);
  return (
    <section
      id='nominations'
      className={`${styles.nominationsSection} ${
        winnerAnnounced && styles.blackBg
      }`}
      ref={nominationsSection}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>
          <span className={styles.mainTitle}>voting finalists</span>
          {isCompleted ? (
            <div className={styles.winnersSubTitle}>
              <span className={styles.subTitle}>
                The complete list of 2022 finalists{" "}
              </span>
              <div className={styles.badge}>
                <span>VOTING CLOSED</span>
              </div>
            </div>
          ) : (
            <div className={styles.winnersSubTitle}>
              <span className={styles.subTitle}>
                Vote for your favorite finalists
              </span>
            </div>
          )}
        </h2>
        <div className={styles.nominations}>
          {error && <ErrorComponent />}
          {loading && <SectionLoader itemsNumber={8} />}
          {data?.businessesAward?.map((nominee) => (
            <div
              key={nominee?.business?.id}
              className={styles.businessContainer}
            >
              <div className={styles.imgContainer}>
                <ImageShapes
                  variant={`${styles.businessImg} ${winnerImageStyle(
                    nominee?.winner
                  )}`}
                  imgSrc={nominee?.business?.featuredImage?.path}
                  alt='owner image'
                />
                {!isCompleted && (
                  <div className={styles.btnContainer} onClick={clickHandler}>
                    <AwardUpvoteBtn
                      nominee={nominee}
                      cachedVoted={selected?.id}
                      refetch={refetch}
                    />
                  </div>
                )}
                {winnerSrcBadge(nominee?.winner) && (
                  <div className={styles.winnerBadge}>
                    <img
                      src={winnerSrcBadge(nominee?.winner)}
                      alt='winner badge'
                    />
                  </div>
                )}
              </div>
              <NavLink to={`/business/${nominee?.business?.id}`}>
                <div className={styles.businessInfo}>
                  <span
                    className={styles.name}
                  >{`${nominee?.ownerFirstName} ${nominee?.ownerLastName}`}</span>
                  <span className={styles.categories}>
                    {nominee?.business?.name}
                  </span>
                  {nominee?.business?.city && nominee?.business?.state && (
                    <span className={styles.city}>
                      {nominee?.business?.city?.name},{" "}
                      {nominee?.business?.state?.abbr}
                    </span>
                  )}
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Nominations;
