import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery } from "@apollo/client";
import Input from "../../GlobalComponents/UI/Input/Input";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import CategoriesModal from "../BusinessCategories/CategoriesModal";
import CategoryRow from "../BusinessCategories/CategoryRow";
import Backdrop from "../../GlobalComponents/UI/Backdrop/Backdrop";
import removeIcon from "../../../assets/images/remove.svg";
import imageUpload from "../../../graphql/mutations/imageUpload";
import getCategory from "../../../graphql/queries/businessOnboarding/getCategory";
import {
  ITEM_TYPES,
  ITEM_STATUS,
  MAX_MESSAGE_LENGTH,
} from "../../../constants";
import styles from "./ItemForm.module.css";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import browsePhotoStyles from "../../BusinessRegister/BusinessPhotos/BusinessPhotos.module.css";
import useImageHandler from "../../../utils/useImageHandler";
import useInput from "../../../hooks/useInput";
import {
  itemWebsiteValidation,
  descriptionValidation,
  businessNameValidation,
  priceValidation,
} from "../../../utils/validationRules";

const MAX_PHOTOS_LENGTH = 5;
export default function ItemForm({
  item,
  handleItemChange,
  handleDeleteItem,
  itemCategories,
  itemGallery,
}) {
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false);

  const [photos, setPhotos] = useState(itemGallery);
  const [photosIds, setPhotosId] = useState(
    item?.gallery?.map((image) => image?.id) || []
  );
  const [categories, setCategories] = useState(
    itemCategories?.filter((category) => category.parent === null)
  );
  const [categoriesIDs, setCategoriesIds] = useState(item?.categories || []);

  const [uploadImages, { loading: loadingImages }] = useMutation(imageUpload);
  const [getCategoryById, { fetchMore }] = useLazyQuery(getCategory);
  const [showCategoriesError, setShowCategoriesError] = useState(false);
  const [showImageError, setShowImageError] = useState(false);
  const categoriesLength = item.categories?.length;
  const {
    errorMessage,
    images,
    changeHandler,
    dragHandler,
    dropHandler,
    resizing,
    resetHandler,
  } = useImageHandler(900, 900, MAX_PHOTOS_LENGTH - photos?.length);

  const {
    value: itemWebsite,
    hasError: itemWebsiteError,
    errorMessage: itemWebsiteErrorMessage,
    valueChangeHandler: itemWebsiteHandler,
    valueBlurHandler: itemWebsiteBlurHandler,
  } = useInput(itemWebsiteValidation, item?.website);

  const {
    value: itemName,
    hasError: itemNameError,
    errorMessage: itemNameErrorMessage,
    valueChangeHandler: itemNameHandler,
    valueBlurHandler: itemNameBlurHandler,
  } = useInput(businessNameValidation, item?.name);

  const {
    value: itemDesc,
    hasError: itemDescError,
    errorMessage: itemDescErrorMessage,
    valueChangeHandler: itemDescHandler,
    valueBlurHandler: itemDescBlurHandler,
  } = useInput(descriptionValidation, item?.desc);

  const { value: foodIngredients, valueChangeHandler: foodIngredientsHandler } =
    useInput(null, item?.ingredientsAndNutritional);

  const {
    value: itemPrice,
    hasError: itemPriceError,
    errorMessage: itemPriceErrorMessage,
    valueChangeHandler: itemPriceHandler,
    valueBlurHandler: itemPriceBlurHandler,
  } = useInput(priceValidation, item?.price);

  const isFoodSelected = item.type === ITEM_TYPES.FOOD;

  useEffect(() => {
    if (images?.length > 0) {
      uploadImages({
        variables: {
          files: images.map((file) => ({
            image: file,
            path: "applications",
          })),
        },
      }).then(({ data }) => {
        const imageIds = [
          ...photosIds,
          ...data?.uploadImages?.map((image) => image.id),
        ];
        setPhotos((prevImages) => [...prevImages, ...data?.uploadImages]);
        setPhotosId(imageIds);
        handleItemChange(item.id, "gallery", imageIds);
      });
    }
  }, [images]);

  const toggleCategoriesModal = () => {
    // validate categories upon toggling
    if (openCategoriesModal && categoriesLength === 0) {
      setShowCategoriesError(true);
    } else if (showCategoriesError) {
      setShowCategoriesError(false);
    }
    setOpenCategoriesModal(!openCategoriesModal);
  };

  const deletePhotoHandler = (deleteIndex) => {
    const files = photos.filter((photo, index) => index !== deleteIndex);
    const filteredIds = photosIds.filter(
      (photo, index) => index !== deleteIndex
    );
    setPhotos(files);
    setPhotosId(filteredIds);
    if (files?.length === 0) {
      setShowImageError(true);
    }
    handleItemChange(item.id, "gallery", filteredIds);
  };

  const handleChooseCategory = (data) => {
    setCategories(data.businessCategories);
    setCategoriesIds(data.businessCategoriesIds);
    handleItemChange(item.id, "categories", data.businessCategoriesIds);
  };

  const handleSelectSubCategory = (id) => {
    if (!categoriesIDs?.includes(id)) {
      setCategoriesIds([...categoriesIDs, id]);
      handleItemChange(item.id, "categories", [...categoriesIDs, id], true);
    } else {
      const newIds = categoriesIDs.filter((categoryId) => categoryId !== id);
      setCategoriesIds(newIds);
      handleItemChange(item.id, "categories", newIds, categoriesIDs.length > 1);
    }
  };

  const fetchMoreSubCategories = (page, mainCategoryId) => {
    fetchMore({
      variables: { id: mainCategoryId, page: page + 1, size: 6 },
    }).then(({ data }) => {
      const newChildren = data?.category?.children;
      let newCategories = categories;
      newCategories = newCategories.map((category) => {
        if (category.id === mainCategoryId) {
          return {
            ...category,
            children: {
              ...category.children,
              paginatorInfo: newChildren?.paginatorInfo,
              data: [...category.children.data, ...newChildren.data],
            },
          };
        } else {
          return category;
        }
      });
      setCategories(newCategories);
    });
  };

  const handleUploadImageClick = (e) => {
    resetHandler(e);
    if (!photos.length) {
      setShowImageError(true);
      return;
    } else if (showImageError) {
      setShowImageError(false);
    }
  };

  return (
    <div>
      <div className={styles.section}>
        <div className={styles.deleteBtnWrapper}>
          <p className={styles.legend}>IS IT A PRODUCT OR SERVICE? *</p>
          <button
            className={styles.deleteItemBtn}
            onClick={() => handleDeleteItem(item.id)}
          >
            Delete item
          </button>
        </div>
        <div className={styles.typesWrapper}>
          <Input
            elementType='radio'
            hasLabel
            label='Product'
            inpContainerClass={`${styles.radioContainer} ${styles.firstRadioContainer}`}
            checked={item.type === ITEM_TYPES.PRODUCT}
            changeHandler={() =>
              handleItemChange(item.id, "type", ITEM_TYPES.PRODUCT)
            }
            name={`${item.id}-itemType`}
            id={`${item.id}-itemType`}
            customCheckBox={styles.checkMark}
            disabled={item.status === ITEM_STATUS.isEdit}
          />
          <Input
            elementType='radio'
            hasLabel
            label='Service'
            inpContainerClass={styles.radioContainer}
            checked={item.type === ITEM_TYPES.SERVICE}
            name={`${item.id}-itemType`}
            id={`${item.id}-itemType`}
            changeHandler={() =>
              handleItemChange(item.id, "type", ITEM_TYPES.SERVICE)
            }
            customCheckBox={styles.checkMark}
            disabled={item.status === ITEM_STATUS.isEdit}
          />
          <Input
            elementType='radio'
            hasLabel
            label='Food'
            inpContainerClass={styles.radioContainer}
            checked={item.type === ITEM_TYPES.FOOD}
            customCheckBox={styles.checkMark}
            name={`${item.id}-itemType`}
            id={`${item.id}-itemType`}
            changeHandler={() =>
              handleItemChange(item.id, "type", ITEM_TYPES.FOOD)
            }
            disabled={item.status === ITEM_STATUS.isEdit}
          />
        </div>
      </div>
      <div className={styles.section}>
        <Input
          hasLabel
          label={
            isFoodSelected
              ? "WHAT’S THE NAME OF YOUR FOOD ITEM"
              : "WHAT’S THE NAME OF YOUR PRODUCT OR SERVICE?"
          }
          isRequired
          placeholder='e.g. Custom Flower Bouquets'
          type='text'
          inpContainerClass={styles.textField}
          value={itemName}
          changeHandler={(e) => {
            handleItemChange(item.id, "name", e.target.value);
            itemNameHandler(e);
          }}
          blurHandler={itemNameBlurHandler}
          invalid={itemNameError}
          errorMessage={itemNameErrorMessage}
        />
      </div>
      <div className={styles.section}>
        <Input
          hasLabel
          label={
            isFoodSelected
              ? "WHAT’S THE PRICE OF THE FOOD ITEM?"
              : "WHAT’S THE PRICE OF THE PRODUCT OR SERVICE?"
          }
          isRequired
          placeholder='e.g. $24.99'
          type='text'
          inpContainerClass={styles.textField}
          value={itemPrice}
          changeHandler={(e) => {
            handleItemChange(item.id, "price", e.target.value);
            itemPriceHandler(e);
          }}
          blurHandler={itemPriceBlurHandler}
          invalid={itemPriceError}
          errorMessage={itemPriceErrorMessage}
        />
      </div>
      <div className={styles.section}>
        <Input
          hasLabel
          label={
            isFoodSelected ? "DELIVERY SERVICE LINK" : "PRODUCT & SERVICE LINK"
          }
          isRequired
          placeholder='e.g. www.postmates.com/en/id=dfjlksjfls'
          type='text'
          inpContainerClass={styles.textField}
          value={itemWebsite}
          changeHandler={(e) => {
            handleItemChange(item.id, "website", e.target.value);
            itemWebsiteHandler(e);
          }}
          blurHandler={itemWebsiteBlurHandler}
          invalid={itemWebsiteError}
          errorMessage={itemWebsiteErrorMessage}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.headerContainer}>
          <p className={styles.legend}>CHOOSE SOME CATEGORIES *</p>
          {categoriesLength > 0 && (
            <button
              className={styles.addCategoryBtn}
              onClick={toggleCategoriesModal}
            >
              Edit Categories
            </button>
          )}
        </div>
        {categoriesLength === 0 ? (
          <>
            <button
              className={styles.addCategoryBtn}
              onClick={toggleCategoriesModal}
            >
              Choose category
            </button>
            {showCategoriesError && (
              <p className={styles.selectSubCategoryError}>
                This field is required.
              </p>
            )}
          </>
        ) : (
          <>
            {categories?.map((category) => (
              <CategoryRow
                key={category.id}
                category={category}
                categoriesIDs={categoriesIDs}
                handleSelectSubCategory={handleSelectSubCategory}
                fetchMoreSubCategories={fetchMoreSubCategories}
              />
            ))}
          </>
        )}
      </div>
      <div className={styles.section}>
        <Input
          hasLabel
          label={
            isFoodSelected
              ? "ADD A DESCRIPTION ABOUT YOUR DISH"
              : "ADD A DESCRIPTION ABOUT YOUR PRODUCT"
          }
          isRequired
          placeholder='Add message here'
          elementType='textArea'
          inpContainerClass={styles.textField}
          value={itemDesc}
          changeHandler={(e) => {
            handleItemChange(item.id, "desc", e.target.value);
            itemDescHandler(e);
          }}
          max={MAX_MESSAGE_LENGTH}
          blurHandler={itemDescBlurHandler}
          invalid={itemDescError}
          errorMessage={itemDescErrorMessage}
        />
      </div>
      {isFoodSelected ? (
        <div className={styles.section}>
          <Input
            hasLabel
            label='ADD INGREDIENTS AND NUTRITIONAL FACTS ABOUT YOUR DISH'
            inpContainerClass={styles.textField}
            placeholder='Add message here'
            elementType='textArea'
            value={foodIngredients}
            changeHandler={(e) => {
              handleItemChange(
                item.id,
                "ingredientsAndNutritional",
                e.target.value
              );
              foodIngredientsHandler(e);
            }}
            max={MAX_MESSAGE_LENGTH}
          />
        </div>
      ) : (
        <div className={styles.section}>
          <Input
            hasLabel
            label={
              item.type === ITEM_TYPES.PRODUCT
                ? "SPECIFICATIONS"
                : "SHIPPING AND PRICING"
            }
            inpContainerClass={styles.textField}
            placeholder='Add message here'
            elementType='textArea'
            value={item.specification}
            changeHandler={(e) =>
              handleItemChange(item.id, "specification", e.target.value)
            }
            max={MAX_MESSAGE_LENGTH}
          />
        </div>
      )}
      <div className={styles.section}>
        <p className={styles.legend}>ADD PHOTOS BELOW *</p>
        <div className={styles.imageInputWrapper}>
          <Input
            hasLabel={false}
            fileLabel='Browse photos or drag to add here'
            elementType='file'
            type='file'
            multiple
            inpContainerClass={`${parentStyles.fullWidthContainer} ${browsePhotoStyles.dropzoneContainer} ${styles.dropZone} `}
            changeHandler={changeHandler}
            handleDrop={dropHandler}
            handleDrag={dragHandler}
            clickHandler={handleUploadImageClick}
            invalid={errorMessage ? true : false}
            errorMessage={errorMessage}
          >
            <div className={styles.previewsContainer}>
              {photos?.map((image, index) => (
                <div key={image.id} className={`${styles.imageContainer}`}>
                  <img
                    src={image.path}
                    onClick={handleItemChange?.bind(
                      null,
                      item.id,
                      "featuredImage",
                      image.id
                    )}
                    className={styles.productImg}
                  />
                  <img
                    src={removeIcon}
                    onClick={deletePhotoHandler?.bind(null, index)}
                    className={styles.remove}
                  />

                  {image.id === item.featuredImage && (
                    <div className={styles.layer} />
                  )}
                </div>
              ))}
            </div>
          </Input>
          {(resizing || loadingImages) && <Spinner />}
        </div>
        {showImageError && !photos.length && (
          <p className={styles.selectSubCategoryError}>
            This field is required.
          </p>
        )}
      </div>
      <Backdrop show={openCategoriesModal} clickHandler={toggleCategoriesModal}>
        <CategoriesModal
          toggleCategoriesModal={toggleCategoriesModal}
          chooseMultiple
          handleAddCategory={handleChooseCategory}
          selected={categories?.map((cat) => cat.name)}
        />
      </Backdrop>
    </div>
  );
}

ItemForm.propTypes = {
  handleItemChange: PropTypes.func,
  handleDeleteItem: PropTypes.func,
  item: PropTypes.object,
  itemCategories: PropTypes.array,
  itemGallery: PropTypes.array,
  handleDeleteItem: PropTypes.func,
};
