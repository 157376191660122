import { useMutation, useReactiveVar } from "@apollo/client";
import { brazeCustomAttributes } from "../utils/cache";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import logout from "../graphql/mutations/authentication/logout";
import { logoutFunc } from "../utils/logout";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const useLogout = () => {
  const existingAttributes = useReactiveVar(brazeCustomAttributes);
  const cookies = new Cookies();
  const userId = cookies.get("user-id");
  const navigate = useNavigate();
  const [signOut, { client, loading }] = useMutation(logout);
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();

  const logoutHandler = (redirect = true) => {
    // update session count
    const newSessionCount = existingAttributes?.user_session_count - 1;
    analytics.identify(userId, {
      user_session_count: newSessionCount,
    });
    if (auth.currentUser) {
      auth.signOut();
    }

    signOut()
      .then(() => {
        logoutFunc();
        client.resetStore();
        analytics.reset();
        brazeCustomAttributes({});
      })
      .finally(() => {
        if (redirect) {
          navigate("/");
        }
      });
  };

  return [logoutHandler, loading];
};
export default useLogout;
