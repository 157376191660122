import { useQuery, useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { CATEGORIES_CHILDREN_PAGE_SIZE } from "../constants";
import getUser from "../graphql/queries/getUser";
import { getNewTokenHandler } from "../utils";
import { authVar, brazeCustomAttributes, rolesVar } from "../utils/cache";
import axios from "axios";
import { logoutFunc } from "../utils/logout";
import { useNavigate } from "react-router-dom";
const useGetUser = () => {
  const cookies = new Cookies();
  const userId = cookies.get("user-id");
  const navigate = useNavigate();
  const { data, loading, fetchMore, refetch, error } = useQuery(getUser, {
    variables: {
      id: userId,
      categoriesChildrenPage: 1,
      categoriesChildrenSize: CATEGORIES_CHILDREN_PAGE_SIZE,
      businessesSize: 30,
    },
    skip: !userId,
    notifyOnNetworkStatusChange: true,
  });
  const existingAttributes = useReactiveVar(brazeCustomAttributes);

  useEffect(() => {
    if (data) {
      authVar(data?.user);
      rolesVar(data?.user?.roles);
    }
  }, [data]);
  // import user custom attributes from braze
  useEffect(async () => {
    if (userId) {
      axios
        .post(
          `${process.env.REACT_APP_BRAZE_REST_ENDPOINT}/users/export/ids`,
          {
            external_ids: [userId],
            fields_to_export: ["custom_attributes"],
          },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_BRAZE_REST_KEY}`,
            },
          }
        )
        .then(({ data }) => {
          if (existingAttributes) {
            if (!Object?.keys(existingAttributes)?.length) {
              brazeCustomAttributes(data?.users[0]?.custom_attributes);
            }
          }
        });
    }
  }, [userId, existingAttributes]);
  useEffect(async () => {
    const expirationDate = +cookies.get("expired-at");
    if (expirationDate && expirationDate < new Date()) {
      await getNewTokenHandler()
        .then(() => {
          refetch()
            .then(({ data }) => {
              authVar(data?.user);
              rolesVar(data?.user?.roles);
            })
            .catch((err) => {
              logoutFunc();
              navigate("/");
            });
        })
        .catch(() => {
          logoutFunc();
          navigate("/");
        });
    }
  }, [error]);

  return {
    userData: data?.user,
    userRole: data?.user?.roles,
    loading,
    fetchMore,
    userId,
  };
};
export default useGetUser;
