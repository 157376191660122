import React from "react";
import PropTypes from "prop-types";
import styles from "./SignUpTerms.module.css";
import TermsText from "./TermsText";

const SignUpTerms = (props) => {
  return (
    <div className={styles.termsContainer}>
      <h6 className={styles.header}>Terms & Conditions</h6>
      <TermsText />
    </div>
  );
};

export default SignUpTerms;
SignUpTerms.propTypes = {
  userData: PropTypes.object,
  isBusiness: PropTypes.bool,
};
