import React from "react";
import styles from "./ErrorComponent.module.css";
import PropTypes from "prop-types";
const ErrorComponent = (props) => {
  const { variant } = props;
  return (
    <h1 className={`${variant} ${styles.error}`}>
      Oops, Something went wrong!
    </h1>
  );
};

export default ErrorComponent;
ErrorComponent.propTypes = {
  variant: PropTypes.string,
  error: PropTypes.string,
};
