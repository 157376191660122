import { gql } from "@apollo/client";
export default gql`
  query BUSINESSES_AWARD($isSorted: Boolean) {
    businessesAward(isSorted: $isSorted) {
      __typename
      id
      business {
        id
        name
        website
        desc
        aboutOwner
        city {
          name
        }
        state {
          abbr
        }
        featuredImage {
          path
        }
      }
      ownerFirstName
      ownerLastName
      isVotedAward
      winner
      ownerImage {
        path
      }
    }
  }
`;
