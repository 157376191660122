import React from "react";
import PropTypes from "prop-types";
import useInput from "../../../hooks/useInput";
import Input from "../../GlobalComponents/UI/Input/Input";
import { businessPhoneValidation } from "../../../utils/validationRules";
import styles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import updateBusiness from "../../../graphql/mutations/updateBusiness";
import { useMutation } from "@apollo/client";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { BusinessOnboardingState } from "../../../constants";
import { savingBizInfoState } from "../../../utils/cache";

const BusinessPhone = (props) => {
  const { insertBusinessData, nextStepHandler, businessData } = props;
  const {
    value: businessPhone,
    hasError: businessPhoneError,
    errorMessage: businessPhoneErrorMessage,
    valueChangeHandler: businessPhoneHandler,
    valueBlurHandler: businessPhoneBlurHandler,
    isValid: businessPhoneValid,
  } = useInput(businessPhoneValidation, businessData?.phone, null, true);
  const [updateBiz, { loading }] = useMutation(updateBusiness);

  const submitHandler = (e) => {
    e.preventDefault();
    if (businessData?.phone === businessPhone) {
      nextStepHandler();
      return;
    }
    analytics.track("business_phone");

    savingBizInfoState({ loading: true, error: false });

    updateBiz({
      variables: {
        id: businessData?.id,
        phone: businessPhone,
        onboardingState: BusinessOnboardingState.businessEmail, // next step
      },
    })
      .then(() => {
        savingBizInfoState({ loading: false });

        insertBusinessData({
          phone: businessPhone,
        });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };
  return (
    <form className={styles.registerForm} onSubmit={submitHandler}>
      <h2 className={`${styles.altHeader} ${styles.altHeaderWithDescription}`}>
        Let customers call your business
      </h2>
      <p
        className={styles.headingDescription}
      >{`Enter the phone number for ${businessData?.name}`}</p>

      <Input
        hasLabel
        label='BUSINESS PHONE NUMBER'
        placeholder='e.g. 555-555-5555'
        elementType='phone'
        inpContainerClass={`${styles.fullWidthContainer} ${
          businessPhoneError && styles.invalid
        }`}
        value={businessPhone}
        blurHandler={businessPhoneBlurHandler}
        changeHandler={businessPhoneHandler}
        invalid={businessPhoneError ? true : false}
        errorMessage={businessPhoneErrorMessage}
      />
      <RegisterNextBtn
        variant={styles.continueBtn}
        disabled={!businessPhoneValid && businessPhone ? true : false}
        isLoading={loading}
      >
        {loading ? <Spinner /> : "SAVE & CONTINUE"}
      </RegisterNextBtn>
    </form>
  );
};
export default BusinessPhone;
BusinessPhone.propTypes = {
  insertBusinessData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  businessData: PropTypes.object,
};
