import React from "react";
import styles from "./Spinner.module.css";
import PropTypes from "prop-types";
const Spinner = (props) => {
  const { variant } = props;
  return <div className={`${variant} ${styles.loader} `}></div>;
};

export default Spinner;
Spinner.propTypes = {
  variant: PropTypes.string,
};
