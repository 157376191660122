import React from "react";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import PaymentMethodForm from "./PaymentMethodForm";
import styles from "./PaymentMethod.module.css";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
export default function PaymentMethod({ plan }) {
  return (
    <div className={styles.paymentMethodContainer}>
      <h3 className={styles.title}>Add payment method</h3>
      <Elements stripe={stripe}>
        <ElementsConsumer>
          {({ stripe, elements }) => (
            <PaymentMethodForm
              elements={elements}
              stripe={stripe}
              plan={plan}
            />
          )}
        </ElementsConsumer>
      </Elements>
    </div>
  );
}

PaymentMethod.propTypes = {
  nextStepHandler: PropTypes.func,
  plan: PropTypes.object,
  businessId: PropTypes.string,
};
