import React, { useRef, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import getUsers from "../../graphql/queries/getUsers";
import AdminTitle from "../../components/GlobalComponents/Admin/AdminTitle";
import AdminManageTeamTable from "../../components/AdminManageTeam/ManageTeamTable";
import RowLoader from "../../components/GlobalComponents/UI/RowLoader/RowLoader";
import { USER_ROLES, SORT_ORDER } from "../../constants";
import styles from "./AdminManageTeam.module.css";

export default function AdminManageTeam() {
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASC);
  const [toggleRefetch, setToggleRefetch] = useState(false);
  const {
    fetchMore,
    loading: loadingAdmins,
    data,
    refetch,
  } = useQuery(getUsers, {
    variables: {
      role: USER_ROLES.ADMIN,
      sort: sortOrder,
      text: "",
    },
  });
  const { data: admins } = data?.users ?? {};
  const { currentPage, hasMorePages } = data?.users?.paginatorInfo ?? {};

  const handleToggleRefetch = () => setToggleRefetch(!toggleRefetch);

  useEffect(() => {
    refetch({
      role: USER_ROLES.ADMIN,
      sort: sortOrder,
      text: "",
    });
  }, [toggleRefetch]);

  const observer = useRef();
  // for admins pagination
  const itemsRef = useCallback(
    (node) => {
      if (loadingAdmins) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          fetchMore({
            variables: {
              page: currentPage + 1,
              role: USER_ROLES.ADMIN,
            },
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMorePages, currentPage, loadingAdmins, sortOrder]
  );

  const handleSort = () => {
    if (sortOrder === SORT_ORDER.ASC) {
      setSortOrder(SORT_ORDER.DESC);
    } else {
      setSortOrder(SORT_ORDER.ASC);
    }
  };

  const handleAddTeamMember = () => {
    navigate("/admin-manage-team/add");
  };

  return (
    <div className={styles.adminManageTeam}>
      <AdminTitle title='Manage team' />

      <div className={styles.adminTableWrapper}>
        <AdminManageTeamTable
          admins={admins}
          handleSort={handleSort}
          sortOrder={sortOrder}
          handleToggleRefetch={handleToggleRefetch}
        />
        <div className={styles.paginatorRef} ref={itemsRef} />
        {loadingAdmins && <RowLoader rows={5} />}
      </div>

      <button className={styles.addMemberBtn} onClick={handleAddTeamMember}>
        + ADD TEAM MEMBER
      </button>
    </div>
  );
}
