import { useReactiveVar } from "@apollo/client";
import React, { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import { viewSearchMap } from "../../../../utils/cache";
import AddToCollectionButton from "../../../GlobalComponents/Buttons/AddToCollectionButton/AddToCollectionButton";
import UpVoteButton from "../../../GlobalComponents/Buttons/UpVoteButton/UpVoteButton";
import ImageShapes from "../../../GlobalComponents/UI/ImageShapes/ImageShapes";
import { BUSINESS_TYPES } from "../../../../constants";
import styles from "./Item.module.css";
import PropTypes from "prop-types";

const Item = forwardRef((props, ref) => {
  const searchByLocation = useReactiveVar(viewSearchMap);
  const { item, indx, itemsLength } = props;

  const isEcommerce = item?.businessTypes?.includes(BUSINESS_TYPES.ecommerce);
  return (
    <NavLink
      to={`/${item?.__typename?.toLowerCase()}/${item?.id}`}
      key={`${item?.name}${indx}`}
      className={styles.pick}
      ref={indx === itemsLength - 1 ? ref : null}
    >
      <div className={styles.imgContainer}>
        <ImageShapes
          variant={styles.pickImg}
          imgSrc={item?.featuredImage?.path}
          alt={item?.product}
        />
        <UpVoteButton
          variant={styles.upVote}
          voteCount={item?.votes}
          itemType={item?.__typename?.toUpperCase()}
          itemId={+item?.id}
          voted={item?.isVoted}
          item={item}
        />
        <AddToCollectionButton
          variant={styles.addToCollection}
          addedToCollection={item?.isBookmarked}
          itemType={item?.__typename?.toUpperCase()}
          itemId={+item?.id}
          item={item}
        />
      </div>
      {item?.__typename.toLowerCase() === "business" ? (
        <p className={styles.business}>{item?.name}</p>
      ) : (
        <p className={styles.business}>{item?.business?.name}</p>
      )}

      {item?.__typename.toLowerCase() === "business" &&
        (!searchByLocation ? (
          <>
            <p className={styles.product}>
              {item?.categories?.map((cat, i) => {
                if (i !== item?.categories?.length - 1) return `${cat.name}, `;
                else return `${cat.name}`;
              })}
            </p>
            {!isEcommerce && (
              <p
                className={styles.price}
              >{`${item?.city?.name}, ${item?.state?.abbr}`}</p>
            )}
          </>
        ) : (
          !isEcommerce && (
            <>
              <p className={styles.product}>{item?.addrLine1 || ""} </p>
              {item?.distance && (
                <p className={styles.price}>{`${item?.distance?.toFixed(
                  1
                )} mi away`}</p>
              )}{" "}
            </>
          )
        ))}
      {item?.__typename.toLowerCase() !== "business" &&
        (!searchByLocation ? (
          <>
            <p className={styles.product}>{item?.name}</p>
            <p className={styles.price}>
              {`$${item?.price?.toFixed(2)}
          `}
            </p>
          </>
        ) : (
          !isEcommerce && (
            <>
              <p className={styles.product}>
                {item?.business?.addrLine1 || ""}
              </p>
              {item?.distance && (
                <p className={styles.price}>
                  {`${item?.distance?.toFixed(1)} mi away
      `}
                </p>
              )}
            </>
          )
        ))}
    </NavLink>
  );
});
Item.displayName = "Item";
export default Item;
Item.propTypes = {
  item: PropTypes.object,
  indx: PropTypes.number,
  itemsLength: PropTypes.number,
};
