import React, { useCallback, useRef } from "react";
import { judgesInfo } from "../judges";
import styles from "./AboutJudge.module.css";
import PropTypes from "prop-types";
const AboutJudge = (props) => {
  const { setCurrentIndex } = props;
  const options = { threshold: 1 };
  const firstObserver = useRef();
  const secondObserver = useRef();
  const thirdObserver = useRef();
  const fourthObserver = useRef();

  const firstItemRef = useCallback((node) => {
    if (firstObserver.current) firstObserver.current.disconnect();
    firstObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setCurrentIndex(+entries[0]?.target?.id);
      }
    }, options);
    if (node) firstObserver.current.observe(node);
  }, []);
  const secondItemRef = useCallback((node) => {
    if (secondObserver.current) secondObserver.current.disconnect();
    secondObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setCurrentIndex(+entries[0]?.target?.id);
      }
    }, options);
    if (node) secondObserver.current.observe(node);
  }, []);
  const thirdItemRef = useCallback((node) => {
    if (thirdObserver.current) thirdObserver.current.disconnect();
    thirdObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setCurrentIndex(+entries[0]?.target?.id);
      }
    }, options);
    if (node) thirdObserver.current.observe(node);
  }, []);
  const fourthItemRef = useCallback((node) => {
    if (fourthObserver.current) fourthObserver.current.disconnect();
    fourthObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setCurrentIndex(+entries[0]?.target?.id);
      }
    }, options);
    if (node) fourthObserver.current.observe(node);
  }, []);

  const refHandler = (index) => {
    switch (index) {
      case 0:
        return firstItemRef;
      case 1:
        return secondItemRef;
      case 2:
        return thirdItemRef;
      case 3:
        return fourthItemRef;
    }
  };

  return (
    <div className={styles.aboutContainer}>
      {judgesInfo?.map((judge, i) => (
        <div
          id={i}
          className={styles.aboutJudge}
          key={judge?.name}
          ref={refHandler(i)}
        >
          <h3 className={styles.name}>{judge?.name}</h3>
          <span className={styles.judgeTitle}>{judge?.title}</span>
          <a
            href={judge?.linkHref}
            target='_blank'
            rel='noreferrer'
            className={styles.link}
          >
            {judge?.linkText}
          </a>
        </div>
      ))}
    </div>
  );
};

export default AboutJudge;
AboutJudge.propTypes = {
  setCurrentIndex: PropTypes.func,
};
