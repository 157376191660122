import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation, useNavigate } from "react-router-dom";
import {
  authVar,
  businessListingStatus,
  consumerOnboardingVar,
  savedPath,
  userLeftListing,
} from "./utils/cache";
import * as braze from "@braze/web-sdk";
import Cookies from "universal-cookie";

const AppLogic = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const listingStatus = useReactiveVar(businessListingStatus);
  const leftListing = useReactiveVar(userLeftListing);
  const toPath = useReactiveVar(savedPath);
  const cookies = new Cookies();
  const userId = cookies.get("user-id");
  const userData = useReactiveVar(authVar);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod") {
      analytics.page();
      ReactGA.initialize("UA-235355678-1");
      ReactGA.pageview(pathname + search);
      fbq("track", "PageView");
    }

    return () => window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (toPath && userData?.isOnboardedOnWeb) {
      navigate(toPath);
    }
    if (userData && !userData?.isOnboardedOnWeb) {
      consumerOnboardingVar(0);
    }
  }, [userData]);

  useEffect(() => {
    if (
      listingStatus?.isStarted &&
      !listingStatus?.isCompleted &&
      leftListing
    ) {
      analytics.track("unfinished_business_listing");
    }
    if (leftListing) {
      userLeftListing(false);
      businessListingStatus({
        isCompleted: false,
        isStarted: false,
      });
    }
  }, [listingStatus, pathname, leftListing]);

  useEffect(() => {
    braze.initialize(process.env.REACT_APP_BRAZE_API_KEY, {
      baseUrl: process.env.REACT_APP_BRAZE_SDK_ENDPOINT,
      safariWebsitePushId: process.env.REACT_APP_SAFARI_PUSH_ID,
      inAppMessageZIndex: 1200,
    });
    braze.automaticallyShowInAppMessages();
    braze.openSession();
  }, []);

  useEffect(() => {
    if (userId) {
      braze.changeUser(userId);
      analytics.identify(userId);
      braze.requestPushPermission();
    }
  }, [userId]);

  useEffect(() => {
    return () => analytics.reset();
  }, []);

  return null;
};

export default AppLogic;
