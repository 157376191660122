import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { mapUserRole } from "../../../utils";
import deleteUserMutation from "../../../graphql/mutations/deleteUser";
import getUsers from "../../../graphql/queries/getUsers";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { USER_ROLES } from "../../../constants";
import rowStyles from "../../GlobalComponents/Admin/AdminTableStyles/Row.module.css";
import { errorToastHandler, successToastHandler } from "../../../utils";

export default function AdminRow({ admin, handleToggleRefetch }) {
  const navigate = useNavigate();
  const [deleteUser, { loading: loadingDeleteUser }] = useMutation(
    deleteUserMutation,
    {
      refetchQueries: [
        {
          query: getUsers,
          variables: {
            role: USER_ROLES.ADMIN,
            text: "",
          },
        },
      ],
    }
  );

  const handleEdit = () => {
    navigate(`/admin-manage-team/edit/${admin.id}`);
  };

  const handleDeleteUser = () => {
    deleteUser({
      variables: {
        id: admin?.id,
      },
    })
      .then(() => {
        handleToggleRefetch();
        successToastHandler("REMOVED SUCCESSFULLY");
      })
      .catch((e) => {
        errorToastHandler();
      });
  };
  return (
    <tr className={rowStyles.row}>
      <td className={`${rowStyles.name} ${rowStyles.threeColumnsCell}`}>
        {admin?.firstName} {admin?.lastName}
      </td>
      <td className={`${rowStyles.column} ${rowStyles.threeColumnsCell}`}>
        {admin?.roles.map((role) => mapUserRole(role))}
      </td>
      <td
        className={`${rowStyles.column} ${rowStyles.alignEnd} ${rowStyles.threeColumnsCell}`}
      >
        <div className={rowStyles.buttonsWrapper}>
          {loadingDeleteUser && <Spinner />}
          <button className={rowStyles.viewButton} onClick={handleEdit}>
            Edit
          </button>
          <button
            className={rowStyles.removeButton}
            onClick={handleDeleteUser}
            disabled={loadingDeleteUser}
          >
            Remove
          </button>
        </div>
      </td>
    </tr>
  );
}

AdminRow.propTypes = {
  admin: PropTypes.object,
  handleToggleRefetch: PropTypes.func,
};
