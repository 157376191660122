/*prettier-ignore*/
import {ApolloLink,ApolloClient,InMemoryCache} from '@apollo/client'
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { RetryLink } from "apollo-link-retry";
import { authVar } from "../utils/cache";
import Cookies from "universal-cookie";
import { collection } from "./policies/collection";
import { listAllItemsExcept } from "./policies/listAllItemsExpect";
import { business } from "./policies/business";
import { product } from "./policies/product";
import { service } from "./policies/service";
import { food } from "./policies/food";
import { user } from "./policies/user";
import { search } from "./policies/search";
import { users } from "./policies/users";
import { listBusinessesToApprove } from "./policies/listBusinessesToApprove";
import { listDeclinedBusinesses } from "./policies/listDeclinedBusinesses";
import { getNewTokenHandler } from "../utils";

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});
const errorPromotionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    return data;
  });
});

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 500,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: async (error, op) => {
      const cookies = new Cookies();

      if (!!error && error.message === "Unauthorized") {
        op.setContext({
          headers: {
            Authorization: `Bearer ${cookies.get("auth-token") || ""}`,
          },
        });
        return true;
      }

      return false;
    },
  },
});

const errorLink = onError(() =>
  localStorage.setItem("errors", JSON.stringify(error.response))
); // eslint-disable-line

const authLink = setContext(async (_, { headers }) => {
  const cookies = new Cookies();
  const expirationDate = +cookies.get("expired-at");
  const accessToken = cookies.get("auth-token");
  let token;
  if (expirationDate && expirationDate < new Date()) {
    token = await getNewTokenHandler();
  } else {
    token = accessToken;
  }
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token || ""}`,
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, retryLink, errorPromotionLink, uploadLink]),
  connectToDevTools: process.env.NODE_ENV !== "production",
  cache: new InMemoryCache({
    typePolicies: {
      ...collection,
      ...business,
      ...product,
      ...service,
      ...food,
      ...user,
      Query: {
        fields: {
          userData: {
            read() {
              return authVar();
            },
          },
          ...listAllItemsExcept,
          ...search,
          ...users,
          ...listBusinessesToApprove,
          ...listDeclinedBusinesses,
        },
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
});

export { authLink, client };
