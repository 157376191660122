import { useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import PaymentMethod from "../../components/BusinessOnboarding/PaymentMethod";
import SelectTier from "../../components/BusinessOnboarding/SelectTier";
import BackButton from "../../components/GlobalComponents/Buttons/BackButton/BackButton";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";
import stripePrices from "../../graphql/queries/businessOnboarding/stripePrices";
import { authVar } from "../../utils/cache";
import styles from "./Tiers.module.css";
const Tiers = () => {
  const { data: prices, loading: loadingPrices } = useQuery(stripePrices);
  const [plan, setPlan] = useState("");
  const [showPaymentMethodStep, setShowPaymentMethodStep] = useState(false);
  const user = useReactiveVar(authVar);
  useEffect(() => {
    if (plan?.id?.length > 0) {
      setShowPaymentMethodStep(true);
    }
  }, [plan]);
  const resetHandler = () => {
    setPlan("");
    setShowPaymentMethodStep(false);
  };

  if (loadingPrices) {
    return <Spinner variant={styles.loader} />;
  }

  return (
    <div className={styles.container}>
      {showPaymentMethodStep && (
        <div className={styles.backContainer}>
          <BackButton backHandler={resetHandler} />
        </div>
      )}
      {showPaymentMethodStep ? (
        <PaymentMethod plan={plan} />
      ) : (
        <SelectTier
          setPlan={setPlan}
          planPriceId={plan?.id}
          prices={prices}
          user={user}
        />
      )}
    </div>
  );
};

export default Tiers;
