import React from "react";
import PropTypes from "prop-types";
import downArrow from "../../../../assets/images/downArrow.svg";
import { SORT_ORDER } from "../../../../constants";
import styles from "./TableHeader.module.css";

export default function TableHeader({
  columns,
  handleSort,
  className,
  sortOrder,
  isDrag,
  cellClassName,
}) {
  return (
    <thead>
      <tr className={`${styles.headerContainer} ${className}`}>
        {columns.map((column, i) => (
          <td
            key={column}
            className={`${styles.column} ${
              isDrag && i === 0 && styles.dragHeader
            } ${cellClassName}`}
          >
            {column}
          </td>
        ))}
        {handleSort && (
          <td
            onClick={handleSort}
            className={`${styles.sortByBtn} ${cellClassName}`}
          >
            Sort by
            <img
              src={downArrow}
              className={`${styles.arrowIcon} ${
                sortOrder === SORT_ORDER.DESC ? styles.rotateArrow : ""
              }`}
            />
          </td>
        )}
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  columns: PropTypes.array,
  handleSort: PropTypes.func,
  className: PropTypes.string,
  sortOrder: PropTypes.string,
  isDrag: PropTypes.bool,
  cellClassName: PropTypes.string,
};
