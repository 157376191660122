import React, { useEffect } from "react";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import styles from "./ProfileNavigation.module.css";
import ProfilePicture from "./ProfilePicture/ProfilePicture";
import useHideMenu from "../../../../../hooks/useHideMenu";
import useShowConfirmation from "../../../../../hooks/useShowConfirmation";
import ConfirmationModal from "../../../UI/ConfirmationModal";
import useLogout from "../../../../../hooks/useLogout";

const ProfileNavigation = () => {
  const { show: showMenu, setShow: setShowMenu } = useHideMenu("profile-menu");
  const toggleHandler = () => {
    setShowMenu(!showMenu);
  };
  const { showConfirmation, toggleConfirmationHandler } = useShowConfirmation();
  const [logoutHandler, loading] = useLogout();

  useEffect(() => {
    return () => setShowMenu(false);
  }, []);

  return (
    <div className={styles.profileNavigation}>
      <ConfirmationModal
        show={showConfirmation}
        cancelHandler={toggleConfirmationHandler}
        confirmHandler={logoutHandler}
        message='Are you sure you want to log out of OBWS?'
        loading={loading}
      />

      <ProfilePicture
        toggleHandler={toggleHandler}
        variant={styles.profilePicture}
      />
      {showMenu && (
        <ProfileMenu
          toggleHandler={toggleHandler}
          variant={styles.menuPosition}
          toggleConfirmationHandler={toggleConfirmationHandler}
        />
      )}
    </div>
  );
};

export default ProfileNavigation;
