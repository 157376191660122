import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import PropTypes from "prop-types";
import styles from "./SearchMap.module.css";
import { useReactiveVar } from "@apollo/client";
import {
  differentSearchLocationSelected,
  searchUserLocation,
} from "../../../utils/cache";
import SearchAsMapMoves from "./SearchAsMapMoves/SearchAsMapMoves";
import AddressInp from "./AddressInp/AddressInp";
import activeRestaurants from "../../../assets/images/activeRestaurants.svg";
import activeECommerce from "../../../assets/images/activeECommerce.svg";
import activeBrick from "../../../assets/images/activeBrickMapIcon.svg";
import restaurants from "../../../assets/images/restaurants.svg";
import eCommerce from "../../../assets/images/eCommerce.svg";
import brick from "../../../assets/images/brickMapIcon.svg";
import clusterer from "../../../assets/images/clusterer.svg";
import { BUSINESS_TYPES, ITEM_TYPES } from "../../../constants";
import BusinessInfo from "./BusinessInfo";

const SearchMap = (props) => {
  const { results, fullScreen, fullScreenHandler } = props;
  const [checked, setChecked] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const searchSelectedLocation = useReactiveVar(
    differentSearchLocationSelected
  );
  const mapRef = useRef();
  const userLocation = useReactiveVar(searchUserLocation);
  const [selectedMarker, setSelectedMarker] = useState();
  useEffect(() => {
    if (results?.length) {
      const businessResults = results?.map((itm) => {
        if (itm?.__typename === ITEM_TYPES.BUSINESS) {
          return itm;
        } else {
          return itm?.business;
        }
      });
      const filteredBusinesses = businessResults.filter(
        (obj) => !businessResults[obj.id] && (businessResults[obj.id] = true)
      );
      setBusinesses(filteredBusinesses);
    }
  }, [results]);

  const searchAsMapMovesHandler = () => {
    setChecked(!checked);
    if (mapRef && !checked) {
      differentSearchLocationSelected({
        lat: mapRef?.current?.getCenter()?.lat(),
        lng: mapRef?.current?.getCenter()?.lng(),
      });
    }
  };
  useEffect(() => {
    const resizeMapHandler = (e) => {
      if (e.code === "Escape" && fullScreen) {
        fullScreenHandler();
      }
    };
    window.addEventListener("keydown", resizeMapHandler);

    return () => window.removeEventListener("keydown", resizeMapHandler);
  }, []);

  useEffect(() => {
    if (results && !userLocation && !searchSelectedLocation) {
      if (results[0].__typename === "Business") {
        differentSearchLocationSelected({
          lat: results[0]?.lat,
          lng: results[0]?.lng,
        });
      } else {
        differentSearchLocationSelected({
          lat: results[0]?.business?.lat,
          lng: results[0]?.business?.lng,
        });
      }
    }
  }, [results, userLocation]);

  const options = useMemo(
    () => ({
      disableDefaultUI: true,
      clickableIcons: false,
      center: userLocation
        ? userLocation
        : {
            lat: 40.7128,
            lng: -74.006,
          },
    }),
    [userLocation]
  );

  const onLoad = useCallback((map) => (mapRef.current = map), []);

  const handleCenterChanged = () => {
    if (mapRef && checked) {
      searchUserLocation({
        lat: mapRef?.current?.getCenter()?.lat(),
        lng: mapRef?.current?.getCenter()?.lng(),
      });
    }
  };

  const zoomInHandler = () => {
    if (mapRef) mapRef?.current?.setZoom(mapRef?.current?.zoom + 1);
  };
  const zoomOutHandler = () => {
    if (mapRef) mapRef?.current?.setZoom(mapRef?.current?.zoom - 1);
  };

  useEffect(() => {
    if (fullScreen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "visible";
  }, [fullScreen]);

  const markerIconHandler = (bizType, bizId) => {
    if (bizId === selectedMarker) {
      switch (bizType) {
        case BUSINESS_TYPES.ecommerce:
          return activeECommerce;
        case BUSINESS_TYPES.restaurants:
          return activeRestaurants;
        case BUSINESS_TYPES.brickMortars:
          return activeBrick;

        default:
          return;
      }
    } else {
      switch (bizType) {
        case BUSINESS_TYPES.ecommerce:
          return eCommerce;
        case BUSINESS_TYPES.restaurants:
          return restaurants;
        case BUSINESS_TYPES.brickMortars:
          return brick;

        default:
          return;
      }
    }
  };

  const clickMarkerHandler = (id) => {
    if (id === selectedMarker) {
      setSelectedMarker();
    } else {
      setSelectedMarker(id);
    }
  };
  return (
    <div className={`${fullScreen ? styles.fullOverlay : undefined}`}>
      <div
        className={`${
          fullScreen ? styles.fullMapContainer : styles.mapContainer
        }`}
      >
        {!selectedMarker && (
          <div className={styles.checkContainer}>
            <SearchAsMapMoves
              searchAsMapMovesHandler={searchAsMapMovesHandler}
              checked={checked}
            />
          </div>
        )}
        <AddressInp
          fullScreenHandler={fullScreenHandler}
          zoomInHandler={zoomInHandler}
          zoomOutHandler={zoomOutHandler}
          fullScreen={fullScreen}
        />
        <GoogleMap
          mapContainerClassName={styles.containerElement}
          options={options}
          zoom={10}
          onLoad={onLoad}
          onDragEnd={handleCenterChanged}
        >
          <>
            <Marker position={userLocation} />
            <MarkerClusterer
              clusterClass={styles.clusterer}
              calculator={(businesses, num = 1) => ({
                index: 1,
                text: `${businesses?.length}`,
              })}
              styles={[
                {
                  url: clusterer,
                  textColor: "#ffffff",
                  height: 54,
                  lineHeight: 54,
                  width: 54,
                  textSize: 16,
                  fontFamily: "halyard text",
                },
              ]}
            >
              {(clusterer) => {
                if (!businesses?.length) return null;

                return businesses?.map((res, i) => (
                  <Marker
                    key={`${i}${res?.name}`}
                    onClick={clickMarkerHandler.bind(null, res?.id)}
                    position={{ lat: res?.lat, lng: res?.lng }}
                    label={{
                      text: res?.name,
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#0a0a0a",
                      className: styles.labelPosition,
                      fontFamily: "halyard text",
                    }}
                    icon={markerIconHandler(res?.businessTypes[0], res?.id)}
                    title={res?.name}
                    clusterer={clusterer}
                  >
                    {selectedMarker === res?.id && (
                      <InfoWindow
                        position={{ lat: res?.lat, lng: res?.lng }}
                        onCloseClick={setSelectedMarker.bind(null, null)}
                      >
                        <BusinessInfo
                          business={res}
                          userLocation={userLocation}
                        />
                      </InfoWindow>
                    )}
                  </Marker>
                ));
              }}
            </MarkerClusterer>
          </>
        </GoogleMap>
      </div>
    </div>
  );
};

export default SearchMap;
SearchMap.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
  fullScreen: PropTypes.bool,
  fullScreenHandler: PropTypes.func,
};
