import React from "react";
import PropTypes from "prop-types";
import Input from "../../../components/GlobalComponents/UI/Input/Input";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import styles from "./BusinessApplication.module.css";

export default function BusinessPhoneSection({
  businessPhone,
  businessPhoneHandler,
  businessPhoneBlurHandler,
  businessPhoneError,
  businessPhoneErrorMessage,
}) {
  return (
    <div className={styles.section}>
      <AdminTitle title='Business phone number' />

      <div className={styles.group}>
        <Input
          hasLabel
          label='Business phone number'
          placeholder='e.g. 555-555-5555'
          elementType='phone'
          inpContainerClass={styles.textField}
          value={businessPhone}
          changeHandler={businessPhoneHandler}
          blurHandler={businessPhoneBlurHandler}
          invalid={businessPhoneError && businessPhone}
          errorMessage={businessPhoneErrorMessage}
        />
      </div>
    </div>
  );
}

BusinessPhoneSection.propTypes = {
  businessPhone: PropTypes.string,
  businessPhoneHandler: PropTypes.func,
  businessPhoneBlurHandler: PropTypes.func,
  businessPhoneError: PropTypes.bool,
  businessPhoneErrorMessage: PropTypes.string,
};
