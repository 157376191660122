import React from "react";
import styles from "./HeaderDesc.module.css";
import PropTypes from "prop-types";

const HeaderDesc = ({ isBusiness }) => {
  return isBusiness ? (
    <div className={styles.businessHeaderWrapper}>
      <div className={styles.businessHeader}>
        Let’s create your business account
      </div>
      <span className={styles.businessDescription}>
        A business account enables you to...
      </span>
      <ul className={styles.firstList}>
        <li>Create and manage your business listing page(s)</li>
        <li>Receive additional opportunities to amplify your business</li>
        <li>Gain access to our Resource Suite which includes...</li>
        <ul className={styles.secondList}>
          <li>A database of over $1M in grant/funding opportunities</li>
          <li>Free legal services to help you protect your business</li>
          <li>Masterclass replays, and much more.</li>
        </ul>
      </ul>
    </div>
  ) : (
    <h2 className={styles.header}>Let’s sign you up</h2>
  );
};

export default HeaderDesc;
HeaderDesc.propTypes = {
  isBusiness: PropTypes.bool,
};
