import React, { useEffect, useState } from "react";
import styles from "./BusinessInfo.module.css";
import PropTypes from "prop-types";
import Tags from "../../../GlobalComponents/UI/Tags/Tags";
import UpVoteButton from "../../../GlobalComponents/Buttons/UpVoteButton/UpVoteButton";
import AddToCollectionButton from "../../../GlobalComponents/Buttons/AddToCollectionButton/AddToCollectionButton";
import { useNavigate } from "react-router-dom";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import MapSvg from "./MapSvg";
import Spinner from "../../../GlobalComponents/UI/Spinner/Spinner";
import ItemName from "../../../GlobalComponents/UI/ItemName/ItemName";
import BusinessAddress from "./BusinessAddress/BusinessAddress";
import BusinessPhone from "./BusinessPhone/BusinessPhone";
import { itemEditField } from "../../../../utils/cache";
import { useReactiveVar } from "@apollo/client";
import BusinessEmail from "./BusinessEmail/BusinessEmail";

const BusinessInfo = (props) => {
  const { businessData } = props;
  const [mainCategories, setMaincategories] = useState([]);
  const [latLngError, setLatLngError] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const [lat, setLat] = useState(false);
  const [lng, setLng] = useState(false);
  const [fullAddress, setFullAddress] = useState();
  const [country, setCountry] = useState();
  const navigate = useNavigate();
  const editToggleHandler = (socialName) => itemEditField(socialName);
  const showEditTooltip = useReactiveVar(itemEditField);

  useEffect(() => {
    if (businessData) {
      setMaincategories([
        ...businessData?.categories
          ?.map((el) => {
            if (el?.parent === null) {
              return el?.name;
            }
          })
          .filter((el) => el !== undefined),
      ]);
      if (businessData?.addrLine1) {
        setMapLoading(true);
        geocodeByAddress(businessData?.addrLine1)
          .then((results) => {
            if (
              businessData?.addrLine1?.length >
              results[0]?.formatted_address?.length
            ) {
              setFullAddress(businessData?.addrLine1);
            } else {
              setFullAddress(results[0]?.formatted_address);
            }
            setCountry(
              results[0].address_components.find((c) =>
                c.types.includes("country")
              )?.long_name || ""
            );
            getLatLng(results[0])
              .then(({ lat, lng }) => {
                setLat(lat);
                setLng(lng);
                setMapLoading(false);
                setLatLngError(false);
              })
              .catch(() => {
                setLatLngError(true);
                setMapLoading(false);
              });
          })
          .catch(() => {
            setLatLngError(true);
            setMapLoading(false);
          });
      } else {
        setMapLoading(false);
        setLatLngError(true);
      }
    }
  }, [businessData]);

  const mapViewHandler = () => {
    if (!lat || !lng) return;
    navigate(`/map?lat=${lat}&lng=${lng}`);
  };

  return (
    <div className={styles.infoContainer}>
      <div className={styles.tagsContainer}>
        {businessData?.businessTypes?.map((tag, i) => (
          <Tags tag={tag} key={i} isBusinessType />
        ))}
        {mainCategories?.map((tag, i) => (
          <Tags tag={tag} key={i} />
        ))}
      </div>
      <ItemName
        name={businessData?.name}
        itemType={businessData?.__typename?.toLowerCase()}
      />
      <BusinessAddress
        address={fullAddress}
        address2={businessData?.addrLine2}
        zip={businessData?.zip}
        city={businessData?.city?.name}
        state={businessData?.state?.name}
        country={country}
        showEditTooltip={showEditTooltip}
        editToggleHandler={editToggleHandler}
      />
      {businessData?.phone && (
        <BusinessPhone
          phone={businessData?.phone}
          editToggleHandler={editToggleHandler}
          showEditTooltip={showEditTooltip}
        />
      )}
      {businessData?.email && (
        <BusinessEmail
          existingEmail={businessData?.email}
          showEditTooltip={showEditTooltip}
          editToggleHandler={editToggleHandler}
        />
      )}
      <div className={styles.btnContainer}>
        <UpVoteButton
          variant={styles.upVote}
          voteCount={businessData?.votes}
          voted={businessData?.isVoted}
          itemId={+businessData?.id}
          itemType={businessData?.__typename?.toUpperCase()}
          item={businessData}
        />
        <button
          className={`${styles.mapBtn} ${latLngError && styles.mapDisabled} ${
            mapLoading && styles.loadingBg
          }`}
          onClick={mapViewHandler}
        >
          {mapLoading ? <Spinner variant={styles.btnLoader} /> : <MapSvg />}
        </button>
        <AddToCollectionButton
          variant={styles.addToCollection}
          voteCount={businessData?.votes}
          addedToCollection={businessData?.isBookmarked}
          itemId={+businessData?.id}
          itemType={businessData?.__typename?.toUpperCase()}
          icon='plus'
          item={businessData}
        />
      </div>
    </div>
  );
};

export default BusinessInfo;
BusinessInfo.propTypes = {
  businessData: PropTypes.object,
};
