import React from "react";
import styles from "./Settings.module.css";
import PropTypes from "prop-types";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";

const CloseAccountBtn = (props) => {
  const { loading, handleCloseAccount } = props;
  return (
    <button
      type='button'
      onClick={handleCloseAccount}
      className={`${styles.CloseAccountBtn} ${
        loading ? styles.loadingBg : styles.regularCloseBg
      }`}
    >
      {loading ? <Spinner variant={styles.loader} /> : "Close Account"}
    </button>
  );
};

export default CloseAccountBtn;
CloseAccountBtn.propTypes = {
  loading: PropTypes.bool,
  handleCloseAccount: PropTypes.func
};
