import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import styles from "./BusinessTypes.module.css";
import Input from "../../GlobalComponents/UI/Input/Input";
import businessTypes from "../../../graphql/queries/businessTypes";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import Loader from "../../GlobalComponents/UI/Loader/Loader";
import updateBusiness from "../../../graphql/mutations/updateBusiness";
import { useMutation } from "@apollo/client";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { BusinessOnboardingState } from "../../../constants";
import isEqual from "lodash.isequal";
import { savingBizInfoState } from "../../../utils/cache";
const BusinessTypes = (props) => {
  const { insertBusinessData, nextStepHandler, businessData } = props;
  const { data, loading: fetchLoading } = useQuery(businessTypes);
  const [chosenTypes, setChosenTypes] = useState(
    businessData?.businessTypes ?? []
  );
  const formIsValid = chosenTypes?.length > 0;
  const [updateBiz, { loading: updateLoading }] = useMutation(updateBusiness);
  const loading = updateLoading || fetchLoading;
  const handleCheckBoxChange = (businessType) => {
    let newTypes = [];
    if (chosenTypes?.some((type) => type === businessType))
      newTypes = chosenTypes?.filter((type) => type !== businessType);
    else newTypes = [...chosenTypes, businessType];
    setChosenTypes(newTypes);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (isEqual(chosenTypes, businessData?.businessTypes)) {
      nextStepHandler();
      return;
    }
    analytics.track("business_type");

    savingBizInfoState({ loading: true, error: false });
    updateBiz({
      variables: {
        id: businessData?.id,
        type: chosenTypes,
        onboardingState: BusinessOnboardingState.mainCategories, // next step
      },
    })
      .then(() => {
        savingBizInfoState({ loading: false });

        insertBusinessData({
          businessTypes: chosenTypes,
        });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };

  return (
    <form className={parentStyles.registerForm} onSubmit={submitHandler}>
      <h2
        className={`${parentStyles.altHeader} ${parentStyles.altHeaderWithDescription}`}
      >
        What type of business do you have?
      </h2>
      <p className={parentStyles.headingDescription}>Select all that apply *</p>
      <div className={styles.typesContainer}>
        {fetchLoading &&
          Array.from(Array(3).keys()).map((el, i) => (
            <Loader variant={styles.loader} darkColor key={i} />
          ))}
        {data?.__type?.enumValues?.map(({ name: businessType }) => (
          <Input
            key={businessType}
            elementType='checkbox'
            changeHandler={() => handleCheckBoxChange(businessType)}
            checked={chosenTypes?.some((type) => type === businessType)}
            inpContainerClass={`${styles.typePill} ${
              chosenTypes?.some((type) => type === businessType) &&
              styles.typePillChecked
            }`}
          >
            <span>
              {businessType === "BrickMortars"
                ? "Brick & Mortar"
                : businessType}
            </span>
          </Input>
        ))}
      </div>

      <RegisterNextBtn
        variant={parentStyles.continueBtn}
        disabled={!formIsValid || loading}
        isLoading={updateLoading}
      >
        {updateLoading ? <Spinner /> : "SAVE & CONTINUE"}
      </RegisterNextBtn>
    </form>
  );
};
export default BusinessTypes;
BusinessTypes.propTypes = {
  insertBusinessData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  businessData: PropTypes.object,
};
