import { useEffect, useState } from "react";

const useHideMenu = (menuClass) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const closeMenu = (e) => {
      if (e.key === "Escape") {
        setShow(false);
      }
      if (e.target.classList.contains(menuClass)) {
        return;
      }
      setShow(false);
    };
    window.addEventListener("click", closeMenu);
    window.addEventListener("keydown", closeMenu);
    return () => {
      window.removeEventListener("click", closeMenu);
      window.removeEventListener("keydown", closeMenu);
      setShow(false);
    };
  }, []);
  return {
    show,
    setShow,
  };
};

export default useHideMenu;
