import React, { useEffect, useState } from "react";
import styles from "./Countdown.module.css";
import Timer from "./Timer";
import Countdown from "react-countdown";
import VotingState from "./VotingState";
import { zeroToSingleDigit } from "../../../utils";
import { EST_BEOTYA_START_DATE } from "../../../constants";
import { beyotaStared, viewWinners } from "../../../utils/cache";
import { useReactiveVar } from "@apollo/client";

const LandingCountdown = () => {
  const [isNavHidden, setIsNavHidden] = useState(false);
  const winnersAnnounced = useReactiveVar(viewWinners);
  useEffect(() => {
    const navObserver = () => {
      if (window.scrollY >= 160) {
        setIsNavHidden(true);
      } else {
        setIsNavHidden(false);
      }
    };
    window.addEventListener("scroll", navObserver);
    return () => window.removeEventListener("scroll", navObserver);
  }, []);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <VotingState />;
    } else {
      // Render a countdown
      return (
        <Timer
          hours={zeroToSingleDigit(hours)}
          minutes={zeroToSingleDigit(minutes)}
          seconds={zeroToSingleDigit(seconds)}
          text={"Voting for the BEOTYA begins in"}
        />
      );
    }
  };

  const handleCountdownComplete = () => {
    beyotaStared(true);
  };

  return (
    <section className={styles.countdown}>
      <div
        className={`${styles.container} ${
          winnersAnnounced && styles.primaryBg
        }`}
        style={{ position: isNavHidden ? "fixed" : "static" }}
      >
        <div className={styles.content}>
          <Countdown
            date={new Date(EST_BEOTYA_START_DATE)}
            renderer={renderer}
            onComplete={handleCountdownComplete}
          />
        </div>
      </div>
    </section>
  );
};

export default LandingCountdown;
