import { useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ReactDOM from "react-dom";
import search from "../../../graphql/queries/search/search";
import { searchTotalCount, viewSearchMap } from "../../../utils/cache";
import Results from "../Results/Results";
import SearchMap from "../SearchMap/SearchMap";
import styles from "./MiddleContainer.module.css";
import FiltersContainer from "../FiltersContainer/FiltersContainer";
import PropTypes from "prop-types";
import { BUSINESS_STATUS } from "../../../constants";

const MiddleContainer = (props) => {
  const { filtersDrawer, drawerToggleHandler } = props;

  const [mapFullScreen, setMapFullScreen] = useState(false);
  const [searchTerm, setSearchTerm] = useSearchParams();
  const [applyingFilter, setApplyingFilter] = useState(false);
  const showMap = useReactiveVar(viewSearchMap);
  const { data, loading, error, fetchMore, refetch } = useQuery(search, {
    variables: {
      text: searchTerm.get("t") ?? "",
      page: 1,
      size: 20,
      filters: {
        typesAndCategories: {
          status: [BUSINESS_STATUS.APPROVED],
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: searchResults } = data?.search ?? {};
  const { currentPage, hasMorePages, total } =
    data?.search?.paginatorInfo ?? {};

  useEffect(() => {
    searchTotalCount(total);
  }, [total]);

  const mapFullScreenHandler = () => {
    setMapFullScreen(!mapFullScreen);
  };

  return (
    <>
      <div className={styles.middleContainer}>
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <FiltersContainer
              refetch={refetch}
              setApplyingFilter={setApplyingFilter}
              filtersDrawer={filtersDrawer}
              drawerToggleHandler={drawerToggleHandler}
            />
            <Results
              searchResults={searchResults}
              loading={loading}
              error={error}
              showMap={showMap}
              fetchMore={fetchMore}
              page={currentPage}
              hasMorePages={hasMorePages}
              applyingFilter={applyingFilter}
            />
          </div>
          {showMap &&
            (mapFullScreen ? (
              ReactDOM.createPortal(
                <SearchMap
                  results={searchResults}
                  fullScreen={mapFullScreen}
                  fullScreenHandler={mapFullScreenHandler}
                />,
                document.getElementById("map-root")
              )
            ) : (
              <SearchMap
                results={searchResults}
                fullScreen={mapFullScreen}
                fullScreenHandler={mapFullScreenHandler}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default MiddleContainer;
MiddleContainer.propTypes = {
  filtersDrawer: PropTypes.bool,
  drawerToggleHandler: PropTypes.func,
};
