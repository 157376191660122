import React from "react";
import PropTypes from "prop-types";
import Input from "../../../components/GlobalComponents/UI/Input/Input";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import styles from "./BusinessApplication.module.css";

export default function BusinessWebSiteSection({
  businessWebsite,
  businessWebsiteHandler,
  businessWebsiteBlurHandler,
  businessWebsiteError,
  businessWebsiteErrorMessage,
}) {
  return (
    <div className={styles.section}>
      <AdminTitle title="Website" />
      <div className={styles.group}>
        <p className={styles.legend}>business website</p>
        <Input
          hasLabel={false}
          placeholder="e.g. Jamie Washington"
          type="text"
          inpContainerClass={styles.textField}
          value={businessWebsite}
          changeHandler={businessWebsiteHandler}
          blurHandler={businessWebsiteBlurHandler}
          invalid={businessWebsiteError}
          errorMessage={businessWebsiteErrorMessage}
        />
      </div>
    </div>
  );
}

BusinessWebSiteSection.propTypes = {
  businessWebsite: PropTypes.string,
  businessWebsiteHandler: PropTypes.func,
  businessWebsiteBlurHandler: PropTypes.func,
  businessWebsiteError: PropTypes.bool,
  businessWebsiteErrorMessage: PropTypes.string,
};
