import React from "react";
import GetListedBtn from "../../GlobalComponents/Buttons/GetListedBtn/GetListedBtn";
import styles from "./Criteria.module.css";
const Criteria = () => {
  return (
    <section className={styles.criteriaSection}>
      <h2 className={styles.title}>admission criteria</h2>
      <div className={styles.firstContainer}>
        <div className={styles.first}>
          <span className={`${styles.order} ${styles.upside}`}>1.</span>
          <p>
            <span className={styles.text}>Black</span>
            <span className={styles.text}>Owned</span>
          </p>
        </div>
      </div>
      <div className={styles.secondContainer}>
        <div className={styles.second}>
          <span className={`${styles.order} ${styles.upside}`}>2.</span>
          <p>
            <span className={styles.text}>Demonstrated</span>
            <span className={styles.text}>business</span>
            <span className={styles.text}>impact</span>
          </p>
        </div>
      </div>
      <div className={styles.thirdContainer}>
        <div className={styles.third}>
          <span className={`${styles.order} ${styles.upside}`}>3.</span>
          <p>
            <span className={styles.text}>Operating for at</span>
            <span className={styles.text}>least 12 months</span>
          </p>
        </div>
      </div>
      <div className={styles.fourthContainer}>
        <div className={styles.fourth}>
          <span className={`${styles.order} ${styles.downside}`}>4.</span>
          <p>
            <span className={styles.text}>U.S. based</span>
          </p>
        </div>
      </div>
      <div className={styles.fifthContainer}>
        <div className={styles.fifth}>
          <span className={`${styles.order} ${styles.downside}`}>5.</span>
          <p>
            <span className={styles.text}>An OBWS</span>
            <span className={styles.text}>member</span>
            <GetListedBtn variant={styles.register}>
              Not a member? Join now, it’s free!
            </GetListedBtn>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Criteria;
