import React from "react";
import useInput from "../../../../../hooks/useInput";
import { businessWebsiteValidation } from "../../../../../utils/validationRules";
import styles from "./SocialsInputs.module.css";
import PropTypes from "prop-types";
import Input from "../../../../GlobalComponents/UI/Input/Input";
import ApplyEditBtn from "../../../../GlobalComponents/Buttons/ApplyEditBtn/ApplyEditBtn";
import useEditItem from "../../../../../hooks/useEditItem";
import { useParams } from "react-router-dom";
const SocialsInputs = (props) => {
  const { url, social } = props;
  const {
    value: instagramURL,
    hasError: instagramURLError,
    errorMessage: instagramURLErrorMessage,
    valueChangeHandler: instagramURLHandler,
    valueBlurHandler: instagramURLBlurHandler,
    isValid: instagramURLValid,
  } = useInput(businessWebsiteValidation, url);
  const {
    value: facebookURL,
    hasError: facebookURLError,
    errorMessage: facebookURLErrorMessage,
    valueChangeHandler: facebookURLHandler,
    valueBlurHandler: facebookURLBlurHandler,
    isValid: facebookURLValid,
  } = useInput(businessWebsiteValidation, url);
  const {
    value: twitterURL,
    hasError: twitterURLError,
    errorMessage: twitterURLErrorMessage,
    valueChangeHandler: twitterURLHandler,
    valueBlurHandler: twitterURLBlurHandler,
    isValid: twitterURLValid,
  } = useInput(businessWebsiteValidation, url);
  const {
    value: tiktokURL,
    hasError: tiktokURLError,
    errorMessage: tiktokURLErrorMessage,
    valueChangeHandler: tiktokURLHandler,
    valueBlurHandler: tiktokURLBlurHandler,
    isValid: tiktokURLValid,
  } = useInput(businessWebsiteValidation, url);

  let inp = null;
  const { id } = useParams();

  const { loading: fbLoading, editFormHandler: editFbHandler } = useEditItem(
    id,
    {
      facebook: facebookURL,
    }
  );
  const { loading: twitterLoading, editFormHandler: editTwitterHandler } =
    useEditItem(id, {
      twitter: twitterURL,
    });
  const { loading: igLoading, editFormHandler: editIgHandler } = useEditItem(
    id,
    {
      instagram: instagramURL,
    }
  );
  const { loading: tiktokLoading, editFormHandler: editTiktokHandler } =
    useEditItem(id, {
      tiktok: tiktokURL,
    });

  switch (social) {
    case "facebook":
      return (
        <form onSubmit={editFbHandler}>
          <Input
            hasLabel={true}
            label='FACEBOOK LINK'
            placeholder='e.g.
          www.facebook.com/business_name'
            type='text'
            inpContainerClass={`${styles.inpContainer} ${
              facebookURLError && styles.invalid
            }`}
            value={facebookURL}
            changeHandler={facebookURLHandler}
            blurHandler={facebookURLBlurHandler}
            invalid={facebookURLError}
            errorMessage={facebookURLErrorMessage}
          />
          <ApplyEditBtn
            disable={!facebookURLValid || facebookURL === url}
            loading={fbLoading}
          />
        </form>
      );

    case "twitter":
      return (
        <form onSubmit={editTwitterHandler}>
          <Input
            hasLabel={true}
            label='TWITTER LINK'
            placeholder='e.g. www.twitter.com/business_name'
            type='text'
            inpContainerClass={`${styles.inpContainer} ${
              twitterURLError && styles.invalid
            }`}
            value={twitterURL}
            changeHandler={twitterURLHandler}
            blurHandler={twitterURLBlurHandler}
            invalid={twitterURLError}
            errorMessage={twitterURLErrorMessage}
          />
          <ApplyEditBtn
            disable={!twitterURLValid || twitterURL === url}
            loading={twitterLoading}
          />
        </form>
      );

    case "instagram":
      return (
        <form onSubmit={editIgHandler}>
          <Input
            hasLabel={true}
            label='INSTAGRAM LINK'
            placeholder='e.g. www.instagram.com/business_name'
            type='text'
            inpContainerClass={`${styles.inpContainer} ${
              instagramURLError && styles.invalid
            }`}
            value={instagramURL}
            changeHandler={instagramURLHandler}
            blurHandler={instagramURLBlurHandler}
            invalid={instagramURLError}
            errorMessage={instagramURLErrorMessage}
          />
          <ApplyEditBtn
            disable={!instagramURLValid || instagramURL === url}
            loading={igLoading}
          />
        </form>
      );

    case "tiktok":
      return (
        <form onSubmit={editTiktokHandler}>
          <Input
            hasLabel={true}
            label='TIK TOK LINK'
            placeholder='e.g. www.tiktok.com/business_name'
            type='text'
            inpContainerClass={`${styles.inpContainer} ${
              tiktokURLError && styles.invalid
            }`}
            value={tiktokURL}
            changeHandler={tiktokURLHandler}
            blurHandler={tiktokURLBlurHandler}
            invalid={tiktokURLError}
            errorMessage={tiktokURLErrorMessage}
          />
          <ApplyEditBtn
            disable={!tiktokURLValid || tiktokURL === url}
            loading={tiktokLoading}
          />
        </form>
      );

    default:
      return null;
  }
};

export default SocialsInputs;
SocialsInputs.propTypes = {
  url: PropTypes.string,
  social: PropTypes.string,
};
