import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import styles from "./BusinessCategories.module.css";
import Input from "../../GlobalComponents/UI/Input/Input";
import mainCategories from "../../../graphql/queries/mainCategories";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import Loader from "../../GlobalComponents/UI/Loader/Loader";
import updateBusiness from "../../../graphql/mutations/updateBusiness";
import { useMutation } from "@apollo/client";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { BusinessOnboardingState } from "../../../constants";
import isEqual from "lodash.isequal";
import { savingBizInfoState } from "../../../utils/cache";

const BusinessCategories = (props) => {
  const {
    insertBusinessData,
    nextStepHandler,
    businessData,
    isCategoriesModal = false,
    chooseMultiple = true,
    selected,
  } = props;
  const { data, loading: fetchLoading } = useQuery(mainCategories, {
    variables: { size: 200, subCategoryPage: 1, subCategorySize: 6 },
  });
  const [chosenCategories, setChosenCategories] = useState(selected ?? []);
  const [formIsValid, setFormIsValid] = useState(false);
  const [updateBiz, { loading: updateLoading }] = useMutation(updateBusiness);
  const loading = fetchLoading || updateLoading;
  const handleCheckBoxChange = (businessCategory) => {
    let newCategories = [];
    if (isCategoriesModal && !chooseMultiple) {
      // prevent selecting previously selected categories
      if (
        selected &&
        selected.find((category) => category === businessCategory)
      ) {
        return;
      }
      newCategories = [businessCategory];
    } else {
      if (chosenCategories?.some((category) => category === businessCategory))
        newCategories = chosenCategories?.filter(
          (category) => category !== businessCategory
        );
      else newCategories = [...chosenCategories, businessCategory];
    }
    setChosenCategories(newCategories);
  };

  useEffect(() => {
    if (isCategoriesModal && !chooseMultiple) {
      if (chosenCategories?.length === 1 || selected?.length > 0) {
        setFormIsValid(true);
      } else {
        setFormIsValid(false);
      }
    } else if (chosenCategories?.length > 0 && chosenCategories?.length <= 3) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [chosenCategories]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isCategoriesModal) {
      const businessCategoriesNames = chosenCategories;
      const businessCategoriesIds = chosenCategories?.map(
        (chosenCategory) =>
          data?.mainCategories?.data?.find(
            (category) => category.name === chosenCategory
          )?.id || ""
      );
      const existingCategoriesIs = businessData?.categories?.map(
        (category) => category.id
      );
      if (isEqual(businessCategoriesIds, existingCategoriesIs)) {
        nextStepHandler();
        return;
      }
      savingBizInfoState({ loading: true, error: false });
      analytics.track("business_main_categories");

      updateBiz({
        variables: {
          id: businessData?.id,
          categories: businessCategoriesIds,
          onboardingState: BusinessOnboardingState.businessAddress, // next step
        },
      })
        .then(({ data }) => {
          savingBizInfoState({ loading: false });

          insertBusinessData({
            categories: data?.updateBusiness?.categories,
            businessCategoriesNames: businessCategoriesNames,
          });
          nextStepHandler();
        })
        .catch(() => {
          savingBizInfoState({
            loading: false,
            error: true,
            retry: submitHandler,
          });
        });
    } else {
      insertBusinessData({
        businessCategories: chosenCategories?.map((chosenCategory) =>
          data?.mainCategories?.data?.find(
            (category) => category.name === chosenCategory
          )
        ),
        businessCategoriesIds: chosenCategories?.map(
          (chosenCategory) =>
            data?.mainCategories?.data?.find(
              (category) => category.name === chosenCategory
            )?.id || ""
        ),
      });
      nextStepHandler();
    }
  };

  return (
    <form className={parentStyles.registerForm} onSubmit={submitHandler}>
      <h2
        className={`${parentStyles.altHeader} ${parentStyles.altHeaderWithDescription}`}
      >
        What category is your business in?
      </h2>
      <p className={parentStyles.headingDescription}>
        Help customers find your business. Add up to 3 categories that best
        describe what <strong>{businessData?.name}</strong> is *
      </p>
      <div className={styles.categoriesContainer}>
        {fetchLoading &&
          Array.from(Array(9).keys()).map((el, i) => (
            <Loader variant={styles.loader} darkColor key={i} />
          ))}
        {data?.mainCategories?.data?.map(({ id, name: businessCategory }) => (
          <Input
            key={id}
            elementType='checkbox'
            changeHandler={() => handleCheckBoxChange(businessCategory)}
            checked={chosenCategories?.some(
              (category) => category === businessCategory
            )}
            inpContainerClass={`${styles.categoryPill} ${
              chosenCategories?.some(
                (category) => category === businessCategory
              ) && styles.categoryPillChecked
            }`}
          >
            <span>{businessCategory}</span>
          </Input>
        ))}
      </div>

      <RegisterNextBtn
        variant={parentStyles.continueBtn}
        disabled={!formIsValid || loading}
        isLoading={updateLoading}
      >
        {updateLoading ? <Spinner /> : "SAVE & CONTINUE"}
      </RegisterNextBtn>
    </form>
  );
};
export default BusinessCategories;
BusinessCategories.propTypes = {
  insertBusinessData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  businessData: PropTypes.object,
  isCategoriesModal: PropTypes.bool,
  chooseMultiple: PropTypes.bool,
  selected: PropTypes.array,
};
