import { gql } from "@apollo/client";
export default gql`
  query {
    countries(size: 200) {
      data {
        id
        name
        label
      }
    }
  }
`;
