import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "../../GlobalComponents/UI/Modal/Modal";
import styles from "./CollectionsPopUp.module.css";
import plusIcon from "../../../assets/images/plusIcon.svg";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";
import getUser from "../../../graphql/queries/getUser";
import CollectionImage from "./CollectionImage/CollectionImage";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import ErrorComponent from "../../GlobalComponents/UI/ErrorComponent/ErrorComponent";
import Cookies from "universal-cookie";
import { authVar } from "../../../utils/cache";

const CollectionsPopUp = (props) => {
  const { addToCollection, createPopupToggle, item, incomingCollections } =
    props;
  const [getCollection, { loading: collectionsLoading, error }] =
    useLazyQuery(getUser);
  const [collections, setCollections] = useState([]);
  const [addTo, setAddTo] = useState([]);
  const [removeFrom, setRemoveFrom] = useState([]);
  const [itemCollections, setItemCollections] = useState([]);
  const [initCollections, setInitCollections] = useState([]);
  const [page, setPage] = useState();
  const [hasMorePages, setHasMorePages] = useState();
  const userData = useReactiveVar(authVar);
  const observer = useRef();

  useEffect(() => {
    if (incomingCollections?.length) {
      const existingCollections = incomingCollections.map((col) => {
        return {
          collectableId: item.id,
          collectionId: col?.id,
          collectableType: item.__typename?.toUpperCase(),
        };
      });
      setItemCollections(existingCollections);
      setInitCollections(existingCollections);
    }
    return () => {
      setItemCollections([]);
      setInitCollections([]);
    };
  }, [incomingCollections]);

  const collectionsRef = useCallback(
    (node) => {
      if (collectionsLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        const cookies = new Cookies();
        const userId = cookies.get("user-id");

        if (entries[0].isIntersecting && hasMorePages) {
          getCollection({ variables: { id: userId, page: page + 1 } }).then(
            ({ data }) => {
              setCollections((prevData) => [
                ...prevData,
                ...data?.user?.collections?.data,
              ]);
              setPage(data?.user?.collections?.paginatorInfo?.currentPage);
              setHasMorePages(
                data?.user?.collections?.paginatorInfo?.hasMorePages
              );
            }
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMorePages, collectionsLoading]
  );

  useEffect(() => {
    if (userData) {
      setCollections(userData?.collections?.data);
      setPage(userData?.collections?.paginatorInfo?.currentPage);
      setHasMorePages(userData?.collections?.paginatorInfo?.hasMorePages);
    }
  }, [userData]);

  const addToCollectionHandler = (collection, collectionName) => {
    let newCollections = [];
    const initialCollections = initCollections;
    if (
      itemCollections.some((el) => el.collectionId === collection.collectionId)
    ) {
      newCollections = itemCollections.filter(
        (col) => col.collectionId !== collection.collectionId
      );
    } else {
      newCollections = [...itemCollections, collection];
    }
    if (
      itemCollections.some(
        (el) => el.collectionId === collection.collectionId
      ) &&
      collectionName === "All Saved Items"
    ) {
      newCollections = [];
    }

    if (
      initialCollections.some(
        (el) => el.collectionId === collection.collectionId
      ) &&
      !removeFrom.some((el) => el.collectionId === collection.collectionId)
    ) {
      setRemoveFrom((prevCollections) => [...prevCollections, collection]);
    } else if (
      initialCollections.some(
        (el) => el.collectionId === collection.collectionId
      ) &&
      removeFrom.some((el) => el.collectionId === collection.collectionId)
    ) {
      setRemoveFrom(
        removeFrom.filter((el) => el.collectionId !== collection.collectionId)
      );
    } else if (
      !initialCollections.some(
        (el) => el.collectionId === collection.collectionId
      ) &&
      !addTo.some((el) => el.collectionId === collection.collectionId)
    ) {
      setAddTo((prevCollections) => [...prevCollections, collection]);
    } else if (
      !initialCollections.some(
        (el) => el.collectionId === collection.collectionId
      ) &&
      addTo.some((el) => el.collectionId === collection.collectionId)
    ) {
      setAddTo(
        addTo.filter((el) => el.collectionId !== collection.collectionId)
      );
    }
    if (newCollections.length === 0) {
      setRemoveFrom(initialCollections);
      setAddTo([]);
    }
    setItemCollections(newCollections);
  };

  return (
    <Modal
      variant={`${styles.popUp} ${collections?.length > 2 && styles.lessTop}`}
    >
      <header className={styles.popUpHeader}>
        <h6 className={styles.title}>Save to</h6>
        <img
          src={plusIcon}
          className={styles.add}
          alt='add'
          onClick={createPopupToggle}
        />
      </header>
      <div className={styles.brdr} />
      <div className={styles.collections}>
        {error && (
          <ErrorComponent
            variant={styles.errorMessage}
            error={error?.message}
          />
        )}
        {collections?.map((collection, i) => (
          <div
            className={`${styles.collection} ${
              collections?.length === 1 && styles.singleItem
            }`}
            key={collection?.name}
            onClick={addToCollectionHandler.bind(
              null,
              {
                collectionId: collection?.id,
                collectableId: item.id,
                collectableType: item.__typename?.toUpperCase(),
              },
              collection?.name
            )}
            ref={i === collections?.length - 1 ? collectionsRef : null}
          >
            <CollectionImage
              imgSrc={collection?.image?.path}
              variant={styles.collectionImage}
              selecting={true}
              activeCollection={itemCollections?.some(
                (el) => el.collectionId === collection.id
              )}
            />
            <p className={styles.name}>{collection?.name}</p>
          </div>
        ))}
        {((collections?.length === 0 && !error) || collectionsLoading) && (
          <Spinner variant={styles.loader} />
        )}
      </div>
      <button
        className={styles.update}
        onClick={addToCollection.bind(null, addTo, removeFrom)}
      >
        UPDATE
      </button>
    </Modal>
  );
};

export default CollectionsPopUp;
CollectionsPopUp.propTypes = {
  addToCollection: PropTypes.func,
  createPopupToggle: PropTypes.func,
  item: PropTypes.object,
  incomingCollections: PropTypes.array,
};
