import { gql } from "@apollo/client";
export default gql`
  query COLLECTION_ITEMS($collectionId: ID!, $page: Int) {
    listAllItemsExcept(collectionId: $collectionId, page: $page) {
      paginatorInfo {
        currentPage
        hasMorePages
      }
      data {
        collectionId
        collectableId
        type
        item {
          ... on Business {
            __typename
            id
            name
            label
            categories {
              name
            }
            city {
              name
            }
            state {
              abbr
            }

            isVoted
            isBookmarked
            businessTypes
            votes
            featuredImage {
              id
              path
            }
          }
          ... on Product {
            __typename
            id
            name
            desc
            price
            votes
            isVoted
            isBookmarked
            featuredImage {
              id
              path
            }
            business {
              name
              id
            }
          }
          ... on Service {
            __typename
            id
            name
            desc
            price
            votes
            isVoted
            isBookmarked
            featuredImage {
              id
              path
            }
            business {
              name
              id
            }
          }
          ... on Food {
            __typename
            id
            name
            desc
            price
            votes
            isVoted
            isBookmarked
            featuredImage {
              id
              path
            }
            business {
              name
              id
            }
          }
        }
      }
    }
  }
`;
