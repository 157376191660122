import { gql } from "@apollo/client";
export default gql`
  query SERVICE($id: ID!, $page: Int, $collectionsPage: Int) {
    service(id: $id) {
      id
      __typename
      isBookmarked
      collections(page: $collectionsPage) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          id
          name
        }
      }

      categories {
        name
      }
      featuredImage {
        id
        path
      }
      randomImages {
        path
        id
      }
      business {
        name
        desc
        addrLine1
        id
        ownerRole
        aboutOwner
        businessTypes
        categories {
          name
        }
        workingHours {
          day
          open
          close
        }
        representative {
          firstName
          id
          lastName
          desc
          avatar {
            path
          }
        }
        supplies {
          data {
            item {
              __typename
              ... on Product {
                id
                name
                desc
                price
                votes
                isVoted
                isBookmarked
                collections(page: $collectionsPage) {
                  paginatorInfo {
                    currentPage
                    hasMorePages
                  }
                  data {
                    id
                    name
                  }
                }

                featuredImage {
                  id
                  path
                }
                business {
                  name
                  id
                }
              }
              ... on Service {
                id
                name
                desc
                price
                votes
                isVoted
                isBookmarked
                collections(page: $collectionsPage) {
                  paginatorInfo {
                    currentPage
                    hasMorePages
                  }
                  data {
                    id
                    name
                  }
                }

                featuredImage {
                  id
                  path
                }
                business {
                  name
                }
              }
              ... on Food {
                id
                name
                desc
                price
                votes
                isVoted
                isBookmarked
                collections(page: $collectionsPage) {
                  paginatorInfo {
                    currentPage
                    hasMorePages
                  }
                  data {
                    id
                    name
                  }
                }

                featuredImage {
                  id
                  path
                }
                business {
                  name
                }
              }
            }
          }
        }
      }
      website
      name
      desc
      specification
      price
      votes
      isVoted
      mainCategoryServices {
        data {
          id
          name
          desc
          price
          votes
          isVoted
          isBookmarked
          collections(page: $collectionsPage) {
            paginatorInfo {
              currentPage
              hasMorePages
            }
            data {
              id
              name
            }
          }

          featuredImage {
            id
            path
          }
          business {
            name
          }
          __typename
        }
      }
      comments(page: $page) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          id
          message
          createAt
          user {
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
    }
  }
`;
