import { gql } from "@apollo/client";
export default gql`
  query getUser(
    $id: ID!
    $page: Int
    $commentsPage: Int
    $collectionsPage: Int
    $businessesPage: Int
    $businessesSize: Int
    $categoriesChildrenPage: Int
    $categoriesChildrenSize: Int
  ) {
    user(id: $id) {
      id
      createdAt
      firstName
      lastName
      email
      phone
      isBlocked
      subscribed
      isNewUser
      isOnboardedOnWeb
      stripeIdentity {
        firstName
        lastName
        number
        isVerified
        govImage {
          path
          id
        }
      }
      businesses(page: $businessesPage, size: $businessesSize) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          id
          __typename
          collections(page: $collectionsPage) {
            paginatorInfo {
              currentPage
              hasMorePages
            }
            data {
              id
              name
            }
          }

          featuredImage {
            id
            path
          }
          randomImages {
            path
            id
          }
          website
          businessTypes
          tags {
            name
          }
          name
          addrLine1
          addrLine2
          zip
          city {
            name
            id
          }
          state {
            name
            id
          }
          votes
          isVoted
          isBookmarked
          lat
          lng
          phone
          email
          facebook
          twitter
          instagram
          tiktok
          onboardingState
          desc
          aboutOwner
          categories {
            id
            name
            parent {
              id
            }
            children(
              page: $categoriesChildrenPage
              size: $categoriesChildrenSize
            ) {
              paginatorInfo {
                currentPage
                hasMorePages
              }
              data {
                id
                name
              }
            }
          }
          workingHours {
            day
            open
            close
          }
          representative {
            firstName
            id
            lastName
            desc
            avatar {
              path
            }
            stripeIdentity {
              firstName
              lastName
              number
              isVerified
              govImage {
                path
                id
              }
            }
          }
          comments(page: $commentsPage) {
            paginatorInfo {
              currentPage
              hasMorePages
            }
            data {
              id
              message
              createAt
              user {
                firstName
                lastName
                avatar {
                  path
                }
              }
            }
          }
          aboutOwner
          desc
          ownerRole
          supplies {
            data {
              item {
                __typename
                ... on Product {
                  id
                  name
                  desc
                  price
                  website
                  randomImages {
                    id
                    path
                  }
                  votes
                  isVoted
                  isBookmarked
                  specification
                  collections(page: $collectionsPage) {
                    paginatorInfo {
                      currentPage
                      hasMorePages
                    }
                    data {
                      id
                      name
                    }
                  }

                  featuredImage {
                    id
                    path
                  }
                  business {
                    name
                    id
                  }
                  categories {
                    id
                    name
                    parent {
                      id
                    }
                    children(
                      page: $categoriesChildrenPage
                      size: $categoriesChildrenSize
                    ) {
                      data {
                        id
                        name
                      }
                    }
                  }
                }
                ... on Service {
                  id
                  name
                  desc
                  price
                  website
                  randomImages {
                    id
                    path
                  }
                  votes
                  isVoted
                  isBookmarked
                  specification
                  collections(page: $collectionsPage) {
                    paginatorInfo {
                      currentPage
                      hasMorePages
                    }
                    data {
                      id
                      name
                    }
                  }

                  featuredImage {
                    id
                    path
                  }
                  business {
                    name
                  }
                  categories {
                    id
                    name
                    parent {
                      id
                    }
                    children(
                      page: $categoriesChildrenPage
                      size: $categoriesChildrenSize
                    ) {
                      data {
                        id
                        name
                      }
                    }
                  }
                }
                ... on Food {
                  id
                  name
                  desc
                  price
                  linkToOrder
                  ingredients
                  randomImages {
                    id
                    path
                  }
                  votes
                  isVoted
                  isBookmarked
                  collections(page: $collectionsPage) {
                    paginatorInfo {
                      currentPage
                      hasMorePages
                    }
                    data {
                      id
                      name
                    }
                  }

                  featuredImage {
                    id
                    path
                  }
                  business {
                    name
                  }
                  categories {
                    id
                    name
                    parent {
                      id
                    }
                    children(
                      page: $categoriesChildrenPage
                      size: $categoriesChildrenSize
                    ) {
                      data {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      avatar {
        id
        path
      }
      roles {
        id
        permissions
        name
        label
      }
      collections(page: $page) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          name
          id
          image {
            path
          }
        }
      }
      comments(page: $commentsPage) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          id
          message

          CommentOn {
            ... on Product {
              id
              name
              featuredImage {
                id
                path
              }
            }
            ... on Service {
              id
              name
              featuredImage {
                id
                path
              }
            }
            ... on Food {
              id
              name
              featuredImage {
                id
                path
              }
            }
            ... on Business {
              id
              name
              featuredImage {
                id
                path
              }
            }
          }
        }
      }
      upvotedItems {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          item {
            __typename
            ... on Product {
              id
              name
              price
              votes
              isVoted
              isBookmarked
              featuredImage {
                id
                path
              }
              business {
                name
                id
                businessTypes
              }
            }

            ... on Service {
              id
              name
              price
              votes
              isVoted
              isBookmarked
              featuredImage {
                id
                path
              }
              business {
                name
                id
                businessTypes
              }
            }
            ... on Food {
              id
              name
              price
              votes
              isVoted
              isBookmarked
              featuredImage {
                id
                path
              }
              business {
                name
                id
                businessTypes
              }
            }
            ... on Business {
              id
              name
              votes
              isVoted
              isBookmarked
              businessTypes
              featuredImage {
                id
                path
              }
              categories {
                id
                name
                parent {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
