import { gql } from "@apollo/client";
export default gql`
  mutation CREATE_COLLECTION($name: String!) {
    createCollection(name: $name) {
      id
      name
      image {
        path
      }
    }
  }
`;
