import React from "react";
import styles from "./Tags.module.css";
import PropTypes from "prop-types";
import productTag from "../../../../assets/images/bagTag.svg";
import serviceTag from "../../../../assets/images/gearTag.svg";
import ecommTag from "../../../../assets/images/ecommTag.svg";
import brickTag from "../../../../assets/images/brickTag.svg";
import foodTag from "../../../../assets/images/foodTag.svg";
import restaurantTag from "../../../../assets/images/restaurantTag.svg";
import { button, useNavigate } from "react-router-dom";
const Tags = (props) => {
  const { tag, label, clickHandler, selected, isBusinessType, dimmed } = props;
  const navigate = useNavigate();
  const handleNavigate = (tag) => {
    if (dimmed) return;
    if (isBusinessType) {
      navigate(`/search?f=${tag}`);
    } else {
      navigate(`/search?c=${tag?.replace("&", "%26")}`);
    }
  };

  switch (tag) {
    case "product":
      return (
        <button
          onClick={handleNavigate.bind(null, "PRODUCT")}
          className={`${isBusinessType ? styles.product : styles.category} ${
            styles.tag
          }`}
        >
          {isBusinessType && <img src={productTag} alt='product' />}{" "}
          <span>PRODUCT</span>
        </button>
      );
    case "service":
      return (
        <button
          onClick={handleNavigate.bind(null, "SERVICE")}
          className={`${isBusinessType ? styles.service : styles.category} ${
            styles.tag
          }`}
        >
          {isBusinessType && <img src={serviceTag} alt='service' />}{" "}
          <span>SERVICE</span>
        </button>
      );
    case "Ecommerce":
      return (
        <button
          onClick={handleNavigate.bind(null, "Ecommerce")}
          className={`${isBusinessType ? styles.eCommerce : styles.category} ${
            styles.tag
          }`}
        >
          {isBusinessType && <img src={ecommTag} alt='E-COMMERCE' />}{" "}
          <span>E-COMMERCE</span>
        </button>
      );
    case "BrickMortars":
      return (
        <button
          onClick={handleNavigate.bind(null, "BrickMortars")}
          className={`${isBusinessType ? styles.brick : styles.category} ${
            styles.tag
          }`}
        >
          {isBusinessType && <img src={brickTag} alt='BRICK & MORTAR' />}{" "}
          <span>BRICK & MORTAR</span>
        </button>
      );
    case "food":
      return (
        <button
          onClick={handleNavigate.bind(null, "FOOD")}
          className={`${isBusinessType ? styles.food : styles.category} ${
            styles.tag
          }`}
        >
          {isBusinessType && <img src={foodTag} alt='FOOD' />} <span>FOOD</span>
        </button>
      );
    case "Restaurants":
      return (
        <button
          onClick={handleNavigate.bind(null, "Restaurants")}
          className={`${isBusinessType ? styles.restaurant : styles.category} ${
            styles.tag
          }`}
        >
          {isBusinessType && <img src={restaurantTag} alt='RESTAURANT' />}{" "}
          <span>RESTAURANT</span>
        </button>
      );
    case "Chip":
      return (
        <button
          className={` ${styles.tag} ${styles.chip} ${
            selected ? styles.selectedChip : ""
          }`}
          onClick={clickHandler}
        >
          {label}
        </button>
      );

    default:
      return (
        <button
          type='button'
          onClick={handleNavigate.bind(null, tag)}
          className={`${styles.category} ${!dimmed && styles.catLink}`}
        >
          {tag?.toUpperCase()}
        </button>
      );
  }
};

export default Tags;
Tags.propTypes = {
  tag: PropTypes.string,
  label: PropTypes.string,
  clickHandler: PropTypes.func,
  selected: PropTypes.bool,
  isBusinessType: PropTypes.bool,
  dimmed: PropTypes.bool,
};
