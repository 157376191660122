import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import Input from "../../GlobalComponents/UI/Input/Input";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import updateBusinessOnboarding from "../../../graphql/mutations/updateBusiness";
import useInput from "../../../hooks/useInput";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import styles from "../BusinessOnboarding.module.css";
import { descriptionValidation } from "../../../utils/validationRules";
import {
  MIN_MESSAGE_LENGTH,
  MAX_MESSAGE_LENGTH,
  BusinessOnboardingState,
} from "../../../constants";
import { savingBizInfoState } from "../../../utils/cache";

export default function AboutFounder({
  nextStepHandler,
  insertBusinessData,
  businessData,
}) {
  const businessId = businessData?.id;
  const aboutOwner = businessData?.aboutOwner;

  const [updateBusiness, { loading: businessLoading }] = useMutation(
    updateBusinessOnboarding
  );

  const {
    value: desc,
    hasError: descError,
    errorMessage: descErrorMessage,
    valueChangeHandler: descHandler,
    valueBlurHandler: descBlurHandler,
  } = useInput(descriptionValidation, aboutOwner || "");
  const isValidDescription =
    desc.length >= MIN_MESSAGE_LENGTH && desc.length < MAX_MESSAGE_LENGTH;

  const handleSave = () => {
    if (aboutOwner === desc) {
      nextStepHandler();
      return;
    }
    savingBizInfoState({ loading: true, error: false });
    analytics.track("about_owner");

    updateBusiness({
      variables: {
        id: businessId,
        aboutOwner: desc,
        onboardingState: BusinessOnboardingState.businessItems, // next step
      },
    })
      .then(() => {
        savingBizInfoState({ loading: false });

        insertBusinessData({ aboutOwner: desc });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>About the founder</h2>
        <p className={styles.description}>
          Let customers build a connection with your business by providing a
          description about the founder.
        </p>
      </div>
      <div>
        <Input
          inpContainerClass={styles.inpContainerClass}
          hasLabel
          isRequired
          label='ADD A DESCRIPTION ABOUT YOUR FOUNDER'
          labelClass={styles.addDescTitle}
          placeholder='Add message here'
          elementType='textArea'
          value={desc}
          changeHandler={descHandler}
          blurHandler={descBlurHandler}
          invalid={descError}
          errorMessage={descErrorMessage}
          max={MAX_MESSAGE_LENGTH}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <RegisterNextBtn
          variant={parentStyles.continueBtn}
          clickHandler={handleSave}
          disabled={!isValidDescription || descError || businessLoading}
          isLoading={businessLoading}
        >
          {businessLoading ? <Spinner /> : "SAVE & CONTINUE"}
        </RegisterNextBtn>
      </div>
    </div>
  );
}

AboutFounder.propTypes = {
  nextStepHandler: PropTypes.func,
  insertBusinessData: PropTypes.func,
  businessData: PropTypes.object,
};
