import React, { useEffect, useRef, useState } from "react";
import styles from "./Comments.module.css";
import PropTypes from "prop-types";
import { dateFormatter } from "../../../../utils/formatters";
import CommentInput from "./CommentInput/CommentInput";
import { useMutation, useReactiveVar } from "@apollo/client";
import addComment from "../../../../graphql/mutations/addComment";
import Spinner from "../../UI/Spinner/Spinner";
import ErrorComponent from "../../UI/ErrorComponent/ErrorComponent";
import UserPicture from "../../UI/UserPicture/UserPicture";
import { useLocation } from "react-router-dom";
import {
  brazeCustomAttributes,
  savedPath,
  authVar,
} from "../../../../utils/cache";
import { queryToRefetch, sendEventsHandler } from "../../../../utils";
import useUpdateUser from "../../../../hooks/useUpdateUser";

const COMMENTS_HASH = "#comments";

const Comments = (props) => {
  const { data, variant, loading, error, fetchMore } = props;
  const { currentPage: page, hasMorePages } =
    data?.comments?.paginatorInfo ?? {};
  const { hash } = useLocation();
  const commentsRef = useRef();
  const { updateUser } = useUpdateUser();
  const [
    addNewComment,
    { error: newCommentError, loading: newCommentLoading },
  ] = useMutation(addComment, {
    refetchQueries: [
      {
        query: queryToRefetch(data?.__typename?.toLowerCase()),
        variables: { id: data?.id },
        notifyOnNetworkStatusChange: true,
      },
    ],
    notifyOnNetworkStatusChange: true,
  });
  const { data: listedComments } = data?.comments ?? {};
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);

  const [newComment, setNewComment] = useState("");
  const commentSection = useRef();
  const userData = useReactiveVar(authVar);
  const existingAttributes = useReactiveVar(brazeCustomAttributes);
  const commentHandler = (e) => setNewComment(e.target.value);

  useEffect(() => {
    if (data) {
      setFetchMoreLoading(false);
      if (hash === COMMENTS_HASH) {
        window.scrollTo({
          top: commentsRef?.current?.offsetTop,
          behavior: "smooth",
        });
        savedPath(null);
      }
    }
  }, [data]);

  const handleCommentSubmit = () => {
    if (newCommentLoading || !newComment) {
      return;
    }

    addNewComment({
      variables: {
        itemId: +data?.id,
        itemType: data?.__typename.toUpperCase(),
        comment: newComment,
      },
    }).finally(() => {
      commentSection?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setNewComment("");
      updateUser();
      sendEventsHandler(
        userData,
        `${data?.__typename?.toLowerCase()}_commented`,
        data
      );

      // updating comments_count attribute
      const newCommentsCount = existingAttributes?.user_comments_count + 1;
      analytics.identify(userData?.id, {
        user_comments_count: newCommentsCount,
      });
      brazeCustomAttributes({
        ...existingAttributes,
        user_comments_count: newCommentsCount,
      });
      analytics.track("comment_added");
      setNewComment("");
      updateUser();
    });
  };

  const fetchMoreComments = () => {
    if (loading) {
      return;
    }

    if (hasMorePages) {
      setFetchMoreLoading(true);
      fetchMore({
        variables: { page: page + 1 },
      });
    }
  };

  return (
    <>
      <section className={variant} id='comments' ref={commentsRef}>
        <div className={styles.commentsSection}>
          <div className={styles.container}>
            <h1 className={styles.title}>Comments</h1>
            <div className={styles.comments}>
              {loading && <Spinner variant={styles.loader} />}
              {error && <ErrorComponent error={error?.message} />}

              {listedComments?.length > 0 &&
                listedComments?.map((comment, i) => (
                  <div
                    className={styles.comment}
                    key={i}
                    ref={i === 0 ? commentSection : null}
                  >
                    <UserPicture
                      path={comment?.user?.avatar?.path}
                      variant={styles.profilePic}
                    />
                    <div className={styles.commentText}>
                      <h6 className={styles.userName}>
                        {`${
                          comment?.user?.firstName
                        } ${comment?.user?.lastName?.charAt(0)}.`}
                        <span className={styles.userComment}>
                          {comment?.message}
                        </span>
                      </h6>
                      <p className={styles.createdSince}>
                        {dateFormatter(comment?.createAt)}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            <div className={styles.viewContainer}>
              {(loading || fetchMoreLoading) && <Spinner />}
              {!loading && hasMorePages && !error && !fetchMoreLoading && (
                <button className={styles.viewAll} onClick={fetchMoreComments}>
                  VIEW MORE COMMENTS
                </button>
              )}
              {(error || newCommentError) && (
                <p className={styles.errorMessage}>
                  Oops, Something went wrong!
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
      <CommentInput
        handleCommentSubmit={handleCommentSubmit}
        newComment={newComment}
        commentChangeHandler={commentHandler}
        loading={newCommentLoading}
      />
    </>
  );
};

export default Comments;
Comments.propTypes = {
  data: PropTypes.object,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  fetchMore: PropTypes.any,
};
