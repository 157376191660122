import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useLazyQuery, useReactiveVar } from "@apollo/client";
import Input from "../../GlobalComponents/UI/Input/Input";
import { ReactComponent as RemoveIcon } from "../../../assets/images/remove.svg";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { loadStripe } from "@stripe/stripe-js";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import styles from "./UploadID.module.css";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import browsePhotoStyles from "../../BusinessRegister/BusinessPhotos/BusinessPhotos.module.css";
import imageUpload from "../../../graphql/mutations/imageUpload";
import verifyId from "../../../graphql/queries/businessOnboarding/verifyId";
import getUser from "../../../graphql/queries/getUser";
import uploadStripeVerification from "../../../graphql/mutations/uploadStripeVerification";
import updateBusinessOnboarding from "../../../graphql/mutations/updateBusiness";
import warningImg from "../../../assets/images/warningCircle.svg";
import { BusinessOnboardingState } from "../../../constants";
import {
  authVar,
  businessListingStatus,
  savingBizInfoState,
} from "../../../utils/cache";
import useImageHandler from "../../../utils/useImageHandler";
import { useNavigate } from "react-router-dom";
import { errorToastHandler, successToastHandler } from "../../../utils";
import useUpdateUser from "../../../hooks/useUpdateUser";

const MAX_PHOTOS_LENGTH = 1;
const RESIZED_WIDTH = 900;
const RESIZED_HEIGHT = 900;

export default function UploadID({
  nextStepHandler,
  insertBusinessData,
  isReUploadPage,
  businessData,
}) {
  const businessId = businessData?.id;
  const govImageId = businessData?.representative?.stripeIdentity?.govImage?.id;
  const govImagePath =
    businessData?.representative?.stripeIdentity?.govImage?.path;
  const userID = useReactiveVar(authVar)?.id ?? {};
  const [photoId, setPhotoId] = useState(govImageId || "");
  const [photoURL, setPhotoURL] = useState(govImagePath || "");
  const listingStatus = useReactiveVar(businessListingStatus);
  const [uploadImages, { loading: isLoadingImage, error: imageError }] =
    useMutation(imageUpload);
  const [updateBusiness, { loading: businessLoading, error: businessError }] =
    useMutation(
      updateBusinessOnboarding,
      isReUploadPage
        ? {
            refetchQueries: [
              {
                query: getUser,
                variables: { id: userID },
              },
            ],
          }
        : {}
    );
  const { updateUser, userLoading } = useUpdateUser();
  const loading = businessLoading || userLoading;
  const navigate = useNavigate();
  const saveButtonText = isReUploadPage ? "Resubmit" : "SAVE & CONTINUE";
  const {
    errorMessage,
    images,
    changeHandler,
    dragHandler,
    dropHandler,
    resizing,
    resetHandler,
  } = useImageHandler(RESIZED_WIDTH, RESIZED_HEIGHT, MAX_PHOTOS_LENGTH);

  useEffect(() => {
    if (images.length > 0)
      uploadImages({
        variables: {
          files: [{ image: images[images.length - 1], path: "applications" }],
        },
      }).then(({ data }) => {
        const imageURL = data?.uploadImages?.map((image) => image.path)[0];
        const imageId = data?.uploadImages?.map((image) => image.id)[0];
        setPhotoURL(imageURL);
        setPhotoId(imageId);
      });
  }, [images]);

  const deletePhotoHandler = (deleteIndex) => {
    setPhotoURL("");
    setPhotoId("");
  };

  /* TODO: this code belongs to stripe id verification step may be reverted in the future  */

  // const [savedUserId, setSavedUserId] = useState(null);
  // const [stripe, setStripe] = useState(null);
  // const [saveStripeVerification] = useMutation(uploadStripeVerification);
  // const [verifyID] = useLazyQuery(verifyId);
  // const [modalLoading, setModalLoading] = useState(false);

  // useEffect(async () => {
  //   const stripePromise = await loadStripe(
  //     process.env.REACT_APP_STRIPE_PUBLIC_KEY
  //   );
  //   setStripe(stripePromise);
  // }, []);

  // const handleVerify = async () => {
  //   if (!stripe) {
  //     return;
  //   }
  //   setModalLoading(true);
  //   verifyID()
  //     .then(({ data }) => {
  //       const { sessionId, clientSecret } = data.verificationSession;
  //       stripe.verifyIdentity(clientSecret).then((data) => {
  //         if (data?.error) {
  //           setModalLoading(false);
  //         }
  //         if (!data.error) {
  //           saveStripeVerification({ variables: { sessionId } })
  //             .then(({ data }) => {
  //               setSavedUserId(data.uploadStripeVerification.id);
  //               setModalLoading(false);
  //             })
  //             .catch(() => {
  //               errorToastHandler();
  //               setModalLoading(false);
  //             });
  //         }
  //       });
  //     })
  //     .catch(() => {
  //       errorToastHandler();
  //       setModalLoading(false);
  //     });
  // };

  const handleSave = () => {
    if (isReUploadPage) {
      updateBusiness({
        variables: {
          id: businessId,
          govImageId: photoId,
        },
      })
        .then(() => {
          successToastHandler("UPDATED SUCCESSFULLY");
          navigate("/business-dashboard");
        })
        .catch(() => errorToastHandler());
    } else {
      if (photoId === govImageId) {
        nextStepHandler();
        return;
      }
      savingBizInfoState({ loading: true, error: false });
      analytics.track("business_user_gov_id");

      updateBusiness({
        variables: {
          id: businessId,
          govImageId: photoId,
          onboardingState: BusinessOnboardingState.done, // next step
        },
      })
        .then(({ data }) => {
          savingBizInfoState({ loading: false });

          insertBusinessData({
            representative: data?.updateBusiness?.representative,
          });
          analytics.track("complete_business_listing");
          businessListingStatus({ ...listingStatus, isCompleted: true });
        })
        .catch(() => {
          savingBizInfoState({
            loading: false,
            error: true,
            retry: submitHandler,
          });
        })
        .finally(() => {
          updateUser().then(() => navigate("/business-dashboard"));
        });
    }
  };

  return (
    <div className={styles.uploadContainer}>
      <div className={styles.header}>
        <h2 className={styles.title}>Upload your ID</h2>
        <p className={styles.description}>
          Upload your state or government issued ID so we can verify your
          identity. This includes Government issued ID, Drivers License, and
          Passport
        </p>
      </div>
      <div>
        <p className={styles.legend}>ADD PHOTOS BELOW *</p>
        <div className={styles.imageInputWrapper}>
          <Input
            hasLabel={false}
            fileLabel='Browse photos or drag to add here'
            elementType='file'
            type='file'
            multiple
            inpContainerClass={`${parentStyles.fullWidthContainer} ${browsePhotoStyles.dropzoneContainer} ${styles.dropZone} `}
            changeHandler={changeHandler}
            clickHandler={resetHandler}
            handleDrop={dropHandler}
            handleDrag={dragHandler}
            invalid={imageError || errorMessage ? true : false}
            errorMessage={errorMessage}
          >
            {photoURL && (
              <div className={styles.previewsContainer}>
                <div className={`${styles.imageContainer}`}>
                  <img src={photoURL} />
                  <RemoveIcon onClick={() => deletePhotoHandler(0)} />
                </div>
              </div>
            )}
          </Input>
          {(isLoadingImage || resizing) && (
            <Spinner variant={isReUploadPage && styles.reUploadLoader} />
          )}
        </div>
      </div>
      {/* TODO: this code belongs to stripe id verification step may be reverted in the future  */}
      {/* <div className={styles.section}>
        <p className={styles.legend}>VERIFY ID</p>
        <div className={styles.warningWrapper}>
          <button
            role='link'
            disabled={!stripe || modalLoading}
            onClick={handleVerify}
            className={styles.verifyBtn}
          >
            {modalLoading ? (
              <Spinner variant={isReUploadPage && styles.reUploadLoader} />
            ) : (
              "Verify"
            )}
          </button>

          <div className={styles.warning}>
            <img
              src={warningImg}
              alt='warning'
              className={styles.warningIcon}
            />
            Please use the same ID you used above for this verification step.
            Using two different IDs will result in your application being denied
          </div>
        </div>
      </div> */}
      <div className={styles.buttonsWrapper}>
        <RegisterNextBtn
          variant={parentStyles.continueBtn}
          clickHandler={handleSave}
          disabled={
            // !savedUserId ||
            !photoURL.length || loading
          }
          isLoading={loading}
        >
          {loading ? (
            <Spinner variant={isReUploadPage && styles.reUploadLoader} />
          ) : (
            saveButtonText
          )}
        </RegisterNextBtn>
      </div>
    </div>
  );
}

UploadID.propTypes = {
  nextStepHandler: PropTypes.func,
  businessId: PropTypes.string,
  govImageId: PropTypes.string,
  govImagePath: PropTypes.string,
  insertBusinessData: PropTypes.func,
  isReUploadPage: PropTypes.bool,
  businessData: PropTypes.object,
};
