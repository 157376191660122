import React from "react";
import ApplyEditBtn from "../../../Buttons/ApplyEditBtn/ApplyEditBtn";
import EditTooltip from "../../../UI/EditTooltip/EditTooltip";
import Input from "../../../UI/Input/Input";
import styles from "../AboutSection.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { itemEditField, editView } from "../../../../../utils/cache";
import useEditItem from "../../../../../hooks/useEditItem";
import useInput from "../../../../../hooks/useInput";
import { ITEM_TYPES, MIN_MESSAGE_LENGTH } from "../../../../../constants";
import { queryToRefetch } from "../../../../../utils";

const OwnerDesc = (props) => {
  const { aboutOwner, businessId, itemType } = props;
  const showEditTooltip = useReactiveVar(itemEditField);
  const isEditing = useReactiveVar(editView);

  const {
    value: desc,
    hasError: descError,
    errorMessage: descErrorMessage,
    valueChangeHandler: descHandler,
    valueBlurHandler: descBlurHandler,
  } = useInput(null, aboutOwner);

  const { loading, editFormHandler } = useEditItem(
    businessId,
    {
      aboutOwner: desc,
    },
    "business",
    queryToRefetch(itemType?.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <EditTooltip
        show={showEditTooltip === "aboutOwner" && isEditing}
        variant={styles.descTooltip}
        anchorStyle='left'
        closeHandler={itemEditField.bind(null, "")}
      >
        <form onSubmit={editFormHandler}>
          <Input
            hasLabel
            isRequired
            label='ABOUT THE OWNER'
            placeholder='Add message here'
            type='text'
            elementType='textArea'
            inpContainerClass={`${styles.inpContainer} ${
              descError && styles.invalid
            }`}
            value={desc}
            changeHandler={descHandler}
            blurHandler={descBlurHandler}
            invalid={descError}
            errorMessage={descErrorMessage}
          />

          <ApplyEditBtn
            loading={loading}
            disable={desc?.length < MIN_MESSAGE_LENGTH || desc === aboutOwner}
          />
        </form>
      </EditTooltip>
      <div
        className={`${styles.desc}
      ${
        isEditing && itemType === ITEM_TYPES?.BUSINESS
          ? styles.edit
          : styles.noEdit
      }`}
        onClick={
          itemType === ITEM_TYPES?.BUSINESS
            ? itemEditField.bind(null, "aboutOwner")
            : null
        }
      >
        <p>{aboutOwner}</p>
      </div>
    </div>
  );
};

export default OwnerDesc;
OwnerDesc.propTypes = {
  aboutOwner: PropTypes.string,
  businessId: PropTypes.string,
  itemType: PropTypes.string,
};
