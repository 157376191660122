import React, { useState } from "react";
import styles from "./BusinessInfo.module.css";
import PropTypes from "prop-types";
import { convertFloatToTime } from "../../../../utils/formatters";
import { BUSINESS_DAYS, BUSINESS_TYPES } from "../../../../constants";
import { DirectionsRenderer } from "@react-google-maps/api";
import { errorToastHandler, isBusinessType } from "../../../../utils";
const BusinessInfo = ({ business, userLocation }) => {
  const [directions, setDirections] = useState("");
  const isEcommerce = isBusinessType(
    business?.businessTypes,
    BUSINESS_TYPES.ecommerce
  );

  const showWorkingHours = () => {
    if ((isEcommerce && business?.businessTypes?.length > 1) || !isEcommerce) {
      return true;
    }
    return false;
  };
  const renderTime = () => {
    const today = BUSINESS_DAYS[new Date()?.getDay() - 1];
    const day = business?.workingHours?.find((day) => day?.day === today);
    if (!day) {
      return "Closed";
    } else if (
      convertFloatToTime(day.open) === "00:00 am" &&
      convertFloatToTime(day.close) === "24:00 pm"
    ) {
      return "Open 24hrs a day";
    } else {
      return `${convertFloatToTime(day.open)} - ${convertFloatToTime(
        day.close
      )}`;
    }
  };
  const fetchDirections = () => {
    const destination = { lat: business?.lat, lng: business?.lng };
    if (!destination) return;
    if (!userLocation)
      errorToastHandler(
        `It looks like we can’t find your location, please check your settings.`
      );

    navigator.geolocation.getCurrentPosition((position) => {
      const service = new google.maps.DirectionsService();
      service.route(
        {
          origin: {
            lat: userLocation?.lat,
            lng: userLocation?.lng,
          },
          destination: destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === "OK" && result) {
            setDirections(result);
          } else {
            errorToastHandler(
              `Sorry, directions for your location are not available.`
            );
          }
        }
      );
    });
  };

  return (
    <div className={styles.bizContainer}>
      <header className={styles.row}>
        <img
          src={business?.featuredImage?.path}
          alt='main image'
          className={styles.bizImg}
        />
        <div className={styles.headerInfo}>
          <a
            href={`/business/${business?.id}`}
            target='_blank'
            rel='noreferrer'
          >
            {business?.name}
          </a>
          {showWorkingHours() ? (
            <div>
              <h6>{`TODAY'S HOURS`}</h6>
              <span>{renderTime()}</span>
            </div>
          ) : null}
        </div>
      </header>
      <div className={styles.brdr} />
      <h6 className={styles.center}>WHAT WE OFFER</h6>
      <div className={styles.items}>
        {business?.supplies?.data?.map((itm) => (
          <a
            className={`${styles.row} ${styles.noUnderline}`}
            key={itm?.item?.featuredImage?.id}
            href={`/${itm?.item?.__typename?.toLowerCase()}/${itm?.item?.id}`}
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={itm?.item?.featuredImage?.path}
              alt='main image'
              className={styles.itmImg}
            />
            <span>{itm?.item?.name}</span>
          </a>
        ))}
      </div>
      <div className={styles.brdr} />
      <button className={styles.directionsBtn} onClick={fetchDirections}>
        GET DIRECTIONS
      </button>
      {directions && <DirectionsRenderer directions={directions} />}
    </div>
  );
};

export default BusinessInfo;
BusinessInfo.propTypes = {
  business: PropTypes.object,
  userLocation: PropTypes.object,
};
