import React from "react";
import styles from "./AboutAward.module.css";
import rotatedArrow from "../../../assets/images/rotatedArrow.png";
import aboutImg from "../../../assets/images/aboutAwardImg.png";
import down from "../../../assets/images/awardDownArrow.png";
const AboutAward = () => {
  return (
    <section className={styles.aboutSection}>
      <h2 className={styles.title}>about the award</h2>
      <div className={styles.firstContainer}>
        <p className={styles.firstParagragh}>
          For generations, Black entrepreneurs have set the standard for
          creativity and innovation while in the midst of a centuries-long fight
          for equity and justice. And we’re just getting started.
        </p>
        <img className={styles.arrow} src={rotatedArrow} alt='arrow' />
      </div>
      <div className={styles.secondContainer}>
        <div className={styles.RightContainer}>
          <p className={styles.secondParagraph}>
            {`For Black Business Month, OBWS celebrates trailblazing Black entrepreneurs with our 3rd annual OBWS Black Entrepreneur of the Year Awards, presented by`}{" "}
            <a href='https://www.clover.com/' target='_blank' rel='noreferrer'>
              Clover
            </a>{" "}
            {`and`}{" "}
            <a
              href='https://bit.ly/RISE22Q3_Editorial_OBWS'
              target='_blank'
              rel='noreferrer'
            >
              Comcast RISE
            </a>
            {`. 

The OBWS Team is proud to join forces with our sponsors to recognize Black entrepreneurs that have made an undeniable impact on their community and industry.`}
          </p>
          <img className={styles.down} src={down} alt='arrow' />
        </div>
        <img className={styles.aboutImg} src={aboutImg} alt='arrow' />
      </div>
    </section>
  );
};

export default AboutAward;
