import React, { useState } from "react";
import PropTypes from "prop-types";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import Input from "../../GlobalComponents/UI/Input/Input";
import { USER_ROLES } from "../../../constants";
import styles from "./UserManagementFilters.module.css";

export default function UserManagementFilters({
  selectedFilterType,
  handleUserTypeChange,
  handleSearchChange,
  searchText,
}) {
  const [search, setSearch] = useState(searchText);
  const searchHandler = (e) => {
    setSearch(e.target.value);
    handleSearchChange(e);
  };
  return (
    <div className={styles.UserManagementTableContainer}>
      <div className={styles.headerWrapper}>
        <AdminTitle title="User Management" />
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.filtersWrapper}>
          <Input
            elementType="radio"
            hasLabel={true}
            label="Consumer"
            inpContainerClass={`${styles.radioContainer} ${styles.firstRadioContainer}`}
            checked={selectedFilterType === USER_ROLES.USER}
            changeHandler={() => handleUserTypeChange(USER_ROLES.USER)}
            name="Consumer"
            id="Consumer"
            customCheckBox={styles.checkMark}
          />
          <Input
            elementType="radio"
            hasLabel={true}
            label="Business"
            inpContainerClass={`${styles.radioContainer} ${styles.firstRadioContainer}`}
            checked={selectedFilterType === USER_ROLES.BUSINESS_OWNER}
            changeHandler={() =>
              handleUserTypeChange(USER_ROLES.BUSINESS_OWNER)
            }
            name="Business"
            id="Business"
            customCheckBox={styles.checkMark}
          />
          <Input
            elementType="radio"
            hasLabel={true}
            label="All"
            inpContainerClass={`${styles.radioContainer} ${styles.firstRadioContainer}`}
            checked={selectedFilterType === USER_ROLES.ALL}
            changeHandler={() => handleUserTypeChange(USER_ROLES.ALL)}
            name="All"
            id="All"
            customCheckBox={styles.checkMark}
          />
        </div>
        <Input
          hasLabel={false}
          placeholder="Search for any user"
          elementType="search"
          inpContainerClass={styles.searchField}
          value={search}
          name="search"
          id="search"
          changeHandler={searchHandler}
        />
      </div>
    </div>
  );
}

UserManagementFilters.propTypes = {
  selectedFilterType: PropTypes.array,
  handleUserTypeChange: PropTypes.func,
  handleSearchChange: PropTypes.func,
  searchText: PropTypes.string,
};
