import React from "react";
import styles from "./MenuList.module.css";
import PropTypes from "prop-types";

const MenuList = (props) => {
  const {
    cancelHandler,
    deleteToggle,
    editToggle,
    collectionName,
    addToggle,
    removeToggle,
  } = props;

  const deleteCollection = () => {
    if (collectionName === "All Saved Items") return;
    deleteToggle();
  };
  const editCollection = () => {
    if (collectionName === "All Saved Items") return;
    editToggle();
  };

  const removeFromCollection = () => {
    if (collectionName === "All Saved Items") return;
    removeToggle();
  };

  return (
    <ul className={styles.list}>
      <li
        onClick={editCollection}
        className={`${collectionName === "All Saved Items" && styles.disabled}`}
      >
        EDIT COLLECTION
      </li>
      <div className={styles.brdr} />
      <li
        onClick={removeFromCollection}
        className={`${collectionName === "All Saved Items" && styles.disabled}`}
      >
        SELECT...
      </li>
      <div className={styles.brdr} />
      <li onClick={addToggle}>ADD TO COLLECTION</li>
      <div className={styles.brdr} />
      <li
        onClick={deleteCollection}
        className={`${styles.delete} ${
          collectionName === "All Saved Items" && styles.disabled
        }`}
      >
        DELETE COLLECTION
      </li>
      <div className={styles.brdr} />
      <li onClick={cancelHandler}>CANCEL</li>
    </ul>
  );
};

export default MenuList;
MenuList.propTypes = {
  cancelHandler: PropTypes.func,
  deleteToggle: PropTypes.func,
  editToggle: PropTypes.func,
  addToggle: PropTypes.func,
  removeToggle: PropTypes.func,
  collectionName: PropTypes.string,
};
