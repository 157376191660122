import { useMutation } from "@apollo/client";
import updateBusiness from "../graphql/mutations/updateBusiness";
import updateFood from "../graphql/mutations/updateFood";
import updateProduct from "../graphql/mutations/updateProduct";
import updateService from "../graphql/mutations/updateService";
import business from "../graphql/queries/pages/business";
import food from "../graphql/queries/pages/food";
import product from "../graphql/queries/pages/product";
import service from "../graphql/queries/pages/service";
import { errorToastHandler, successToastHandler } from "../utils";
import { itemEditField } from "../utils/cache";

const EMPTY_FUNC = () => {};

const useEditItem = (
  id,
  vars,
  itemType = "business",
  queryToRefetch = business,
  onSuccess = EMPTY_FUNC
) => {
  const [update, { loading }] = useMutation(updateBusiness, {
    refetchQueries: [{ query: queryToRefetch, variables: { id } }],
    notifyOnNetworkStatusChange: true,
  });

  const [productUpdate, { loading: productLoading }] = useMutation(
    updateProduct,
    {
      refetchQueries: [{ query: product, variables: { id } }],
      notifyOnNetworkStatusChange: true,
    }
  );
  const [serviceUpdate, { loading: serviceLoading }] = useMutation(
    updateService,
    {
      refetchQueries: [{ query: service, variables: { id } }],
      notifyOnNetworkStatusChange: true,
    }
  );
  const [foodUpdate, { loading: foodLoading }] = useMutation(updateFood, {
    refetchQueries: [{ query: food, variables: { id } }],
    notifyOnNetworkStatusChange: true,
  });

  const editFormHandler = (e) => {
    e.preventDefault();
    if (loading) return;
    if (itemType === "business")
      update({ variables: { ...vars, id } })
        .then(({ data }) => {
          itemEditField("");
          successToastHandler("UPDATED SUCCESSFULLY");
          analytics.track("business_updated_listing");
          onSuccess();
        })
        .catch(() => errorToastHandler());
    if (itemType === "product")
      productUpdate({ variables: { ...vars, id } })
        .then(({ data }) => {
          itemEditField("");
          successToastHandler("UPDATED SUCCESSFULLY");
          onSuccess();
        })
        .catch(() => errorToastHandler());
    if (itemType === "service")
      serviceUpdate({ variables: { ...vars, id } })
        .then(({ data }) => {
          itemEditField("");
          successToastHandler("UPDATED SUCCESSFULLY");
          onSuccess();
        })
        .catch(() => errorToastHandler());
    if (itemType === "food")
      foodUpdate({ variables: { ...vars, id } })
        .then(({ data }) => {
          itemEditField("");
          successToastHandler("UPDATED SUCCESSFULLY");
          onSuccess();
        })
        .catch(() => errorToastHandler());
  };

  return {
    loading: productLoading || serviceLoading || foodLoading || loading,
    editFormHandler,
  };
};

export default useEditItem;
