import React, { useRef } from "react";
import PropTypes from "prop-types";
import SeeAllBtn from "../../GlobalComponents/Buttons/SeeAllBtn/SeeAllBtn";
import CommentRow from "./CommentRow";
import styles from "./CommentsSection.module.css";
import Pagination from "../../GlobalComponents/UI/Pagination";
import Cookies from "universal-cookie";
import { useLazyQuery } from "@apollo/client";
import allComments from "../../../graphql/queries/allComments";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";

export default function CommentsSection({
  comments,
  upvotedHidden,
  hasMoreComments,
}) {
  const [getAllComments, { loading, data: commentsPageItems }] =
    useLazyQuery(allComments);

  const cookies = new Cookies();
  const userId = cookies.get("user-id");
  const variables = { id: userId, size: 8 };
  const sectionRef = useRef();
  const scrollToTopHandler = () => {
    window.scrollTo({
      top: sectionRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const { lastPage } = commentsPageItems?.user?.comments?.paginatorInfo ?? {};

  const seeAllBtnRender = () => {
    if (upvotedHidden)
      return <SeeAllBtn route='/up-voted' btnText='SHOW UP VOTES' />;
    if (hasMoreComments) return <SeeAllBtn route='/comments' />;
  };
  const renderComments = () => {
    if (upvotedHidden) return commentsPageItems?.user?.comments?.data;
    else return comments?.slice(0, 4);
  };
  return (
    <div
      className={`${styles.commentContainer} ${
        upvotedHidden ? styles.fullWidth : styles.partialWidth
      }`}
      ref={sectionRef}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Comments</p>
          {seeAllBtnRender()}
        </div>
        {comments?.length === 0 && (
          <p className={styles.noItemsText}>No comments found</p>
        )}
        <div className={upvotedHidden ? styles.commentsGrid : undefined}>
          {loading && <Spinner variant={styles.loader} />}
          {comments?.length &&
            renderComments()?.map((comment, i) => (
              <CommentRow comment={comment} key={`${comment.id} ${i}`} />
            ))}
        </div>
      </div>
      {upvotedHidden && lastPage !== 1 && (
        <Pagination
          fetchHandler={getAllComments}
          restVariables={variables}
          totalPages={lastPage}
          callback={scrollToTopHandler}
        />
      )}
    </div>
  );
}

CommentsSection.propTypes = {
  comments: PropTypes.array,
  upvotedHidden: PropTypes.bool,
  hasMoreComments: PropTypes.bool,
};
