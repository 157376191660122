import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import cities from "../../../../../../graphql/queries/cities";
import countries from "../../../../../../graphql/queries/countries";
import states from "../../../../../../graphql/queries/states";
import useInput from "../../../../../../hooks/useInput";
import {
  businessAddressValidationRules,
  countryValidationRules,
  stateValidationRules,
  zipCodeValidationRules,
} from "../../../../../../utils/validationRules";
import ApplyEditBtn from "../../../../../GlobalComponents/Buttons/ApplyEditBtn/ApplyEditBtn";
import EditTooltip from "../../../../../GlobalComponents/UI/EditTooltip/EditTooltip";
import Input from "../../../../../GlobalComponents/UI/Input/Input";
import styles from "./EditAddressForm.module.css";
import PropTypes from "prop-types";
import { editView } from "../../../../../../utils/cache";
import {
  geocodeByPlaceId,
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete";
import useEditItem from "../../../../../../hooks/useEditItem";
import { useParams } from "react-router-dom";

const EditAddressForm = (props) => {
  const {
    address,
    showEditTooltip,
    editToggleHandler,
    address2,
    initialCity,
    initialState,
    initialZip,
    initialCountry,
  } = props;

  const {
    value: businessAddress,
    hasError: businessAddressError,
    errorMessage: businessAddressErrorMessage,
    valueChangeHandler: businessAddressHandler,
    valueBlurHandler: businessAddressBlurHandler,
    isValid: businessAddressValid,
  } = useInput(businessAddressValidationRules, address, null, true);

  const {
    value: optionalAddress,
    hasError: optionalAddressError,
    errorMessage: optionalAddressErrorMessage,
    valueChangeHandler: optionalAddressHandler,
    valueBlurHandler: optionalAddressBlurHandler,
    isValid: optionalAddressValid,
  } = useInput({}, address2);

  const {
    value: city,
    hasError: cityError,
    errorMessage: cityErrorMessage,
    valueChangeHandler: cityHandler,
    valueBlurHandler: cityBlurHandler,
    isValid: cityValid,
  } = useInput(null, initialCity);

  const {
    value: state,
    hasError: stateError,
    errorMessage: stateErrorMessage,
    valueChangeHandler: stateHandler,
    valueBlurHandler: stateBlurHandler,
    isValid: stateValid,
  } = useInput(stateValidationRules, initialState);

  const {
    value: zipCode,
    hasError: zipCodeError,
    errorMessage: zipCodeErrorMessage,
    valueChangeHandler: zipCodeHandler,
    valueBlurHandler: zipCodeBlurHandler,
    isValid: zipCodeValid,
  } = useInput(zipCodeValidationRules, initialZip);

  const {
    value: country,
    hasError: countryError,
    errorMessage: countryErrorMessage,
    valueChangeHandler: countryHandler,
    valueBlurHandler: countryBlurHandler,
    isValid: countryValid,
  } = useInput(countryValidationRules, initialCountry);

  const { data: countriesData } = useQuery(countries);
  const [getStates, { data: statesData, loading: statesLoading }] =
    useLazyQuery(states);
  const [getCities, { data: citiesData, loading: citiesLoading }] =
    useLazyQuery(cities);
  const isEditing = useReactiveVar(editView);
  const [formIsValid, setFormIsValid] = useState(false);
  const [addressLocation, setAddressLocation] = useState();

  const businessAddressSelectHandler = async (address, placeId) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    businessAddressHandler(place?.formatted_address);
    const placeDetails = {
      zipCode:
        place.address_components.find((c) => c.types.includes("postal_code"))
          ?.long_name || "",
      country:
        place.address_components.find((c) => c.types.includes("country"))
          ?.long_name || "",
      city:
        place.address_components.find(
          (c) =>
            c.types.includes("locality") ||
            c.types.includes("administrative_area_level_3")
        )?.long_name || "",
      state:
        place.address_components.find((c) =>
          c.types.includes("administrative_area_level_1")
        )?.long_name || "",
    };
    const countryId =
      countriesData?.countries?.data?.find((country) =>
        country?.name
          ?.toLowerCase()
          ?.includes(placeDetails?.country?.toLowerCase())
      )?.id ?? "";

    countryChangeHandler(
      { target: { value: placeDetails?.country } },
      placeDetails?.state
    );
    stateChangeHandler({ target: { value: placeDetails?.state } }, countryId);
    zipCodeHandler({ target: { value: placeDetails?.zipCode } });
    cityHandler({ target: { value: placeDetails?.city } });
    setAddressLocation(latLng);
  };
  const countryChangeHandler = (e, state) => {
    countryHandler(e);
    const countryId =
      countriesData?.countries?.data?.find((country) =>
        country?.name?.toLowerCase()?.includes(e.target.value.toLowerCase())
      )?.id ?? "";
    stateChangeHandler({ target: { value: state } }, countryId);
  };

  useEffect(() => {
    if (countriesData && initialCountry) {
      const countryId =
        countriesData?.countries?.data?.find((country) =>
          country?.name?.includes(initialCountry)
        )?.id ?? "";
      getStates({
        variables: { filters: { countries: [`${countryId}`] } },
      }).then(({ data }) => {
        const stateId = data?.states?.data?.find(
          (el) => el?.name === initialState
        )?.id;
        getCities({
          variables: {
            filters: {
              states: stateId ? [`${stateId}`] : null,
            },
          },
        });
      });
    }
  }, [countriesData, initialCountry, initialState]);

  const stateChangeHandler = (e, countryId) => {
    stateHandler(e);
    if (countryId) {
      getStates({
        variables: { filters: { countries: [`${countryId}`] } },
      }).then(({ data }) => {
        const stateId =
          data?.states?.data?.find((state) =>
            state?.name
              ?.toLowerCase()
              ?.includes(e?.target?.value?.toLowerCase())
          )?.id ?? "";
        getCities({
          variables: { filters: { states: stateId ? [`${stateId}`] : null } },
        });
      });
    } else {
      const stateId =
        statesData?.states?.data?.find((state) =>
          state?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
        )?.id ?? "";
      getCities({
        variables: { filters: { states: stateId ? [`${stateId}`] : null } },
      });
    }
  };

  useEffect(() => {
    if (
      (businessAddressValid &&
        optionalAddressValid &&
        zipCodeValid &&
        countryValid &&
        cityValid &&
        stateValid) ||
      (cityValid && stateValid && countryValid && zipCodeValid)
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    businessAddressValid,
    optionalAddressValid,
    cityValid,
    stateValid,
    zipCodeValid,
    countryValid,
  ]);

  const placesErrorHandler = (status, clearSuggestions) => {
    clearSuggestions();
  };
  const { id } = useParams();
  const { loading, editFormHandler } = useEditItem(id, {
    addrLine1: businessAddress,
    addrLine2: optionalAddress,
    city:
      citiesData?.cities?.data?.find((state) => state?.name?.includes(city))
        ?.id ?? null,
    state:
      statesData?.states?.data?.find((stat) => stat?.name?.includes(state))
        ?.id ?? null,
    zip: zipCode,
    lat: addressLocation?.lat ?? 0,
    lng: addressLocation?.lng ?? 0,
  });

  return (
    <EditTooltip
      show={showEditTooltip === "address" && isEditing}
      variant={styles.tooltip}
      anchorStyle='top'
      closeHandler={editToggleHandler.bind(null, "")}
    >
      <form onSubmit={editFormHandler}>
        <Input
          hasLabel
          isRequired
          label='BUSINESS ADDRESS'
          placeholder='e.g. 555 Magnolia Blvd.'
          elementType='places'
          inpContainerClass={`${styles.fullWidth} ${styles.relativeStyle} `}
          value={businessAddress}
          selectHandler={businessAddressSelectHandler}
          changeHandler={businessAddressHandler}
          blurHandler={businessAddressBlurHandler}
          invalid={businessAddressError}
          errorMessage={businessAddressErrorMessage}
          placesContainerClass={styles.placesContainerClass}
          placesErrorHandler={placesErrorHandler}
        />
        <Input
          hasLabel
          label='BUSINESS ADDRESS 2'
          placeholder='e.g. Suite Number 5'
          type='text'
          inpContainerClass={styles.fullWidth}
          value={optionalAddress}
          changeHandler={optionalAddressHandler}
          blurHandler={optionalAddressBlurHandler}
          invalid={optionalAddressError}
          errorMessage={optionalAddressErrorMessage}
        />
        <Input
          hasLabel
          isRequired
          label='COUNTRY'
          placeholder='e.g. United States'
          elementType='select'
          inpContainerClass={styles.fullWidth}
          options={countriesData?.countries?.data
            ?.filter(
              ({ name }) =>
                name === "United States of America" || name === "Canada"
            )
            .map((country) => ({
              ...country,
              value: country?.name?.includes("America")
                ? "United States"
                : country?.name,
              label: country?.name,
            }))}
          value={country}
          changeHandler={countryChangeHandler}
          blurHandler={countryBlurHandler}
          invalid={countryError}
          errorMessage={countryErrorMessage}
        />
        <Input
          hasLabel
          isRequired
          label='STATE'
          placeholder='e.g. California'
          elementType='select'
          inpContainerClass={styles.fullWidth}
          value={state}
          options={statesData?.states?.data?.map((state) => ({
            ...state,
            value: state?.name,
            label: state?.name,
          }))}
          changeHandler={stateChangeHandler}
          blurHandler={stateBlurHandler}
          invalid={stateError}
          errorMessage={stateErrorMessage}
        />

        <Input
          hasLabel
          isRequired
          label='CITY'
          placeholder='e.g. North Hollywood'
          elementType='select'
          inpContainerClass={styles.fullWidth}
          value={city}
          options={citiesData?.cities?.data?.map((city) => ({
            ...city,
            value: city?.name,
            label: city?.name,
          }))}
          changeHandler={cityHandler}
          blurHandler={cityBlurHandler}
          invalid={cityError}
          errorMessage={cityErrorMessage}
        />
        <Input
          hasLabel
          isRequired
          label='ZIP CODE'
          placeholder='e.g. 91402'
          type='text'
          inpContainerClass={styles.fullWidth}
          value={zipCode}
          changeHandler={zipCodeHandler}
          blurHandler={zipCodeBlurHandler}
          invalid={zipCodeError}
          errorMessage={zipCodeErrorMessage}
        />

        <ApplyEditBtn
          disable={!formIsValid}
          loading={loading || statesLoading || citiesLoading}
        />
      </form>
    </EditTooltip>
  );
};

export default EditAddressForm;
EditAddressForm.propTypes = {
  address: PropTypes.string,
  address2: PropTypes.string,
  initialCity: PropTypes.string,
  initialState: PropTypes.string,
  initialZip: PropTypes.string,
  initialCountry: PropTypes.string,
  showEditTooltip: PropTypes.string,
  editToggleHandler: PropTypes.func,
};
