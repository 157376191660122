import React from "react";
import ProfileMenu from "../../ProfileNavigation/ProfileMenu/ProfileMenu";
import ProfilePicture from "../../ProfileNavigation/ProfilePicture/ProfilePicture";
import toggle from "../../../../../../assets/images/toggle.svg";
import styles from "./ProfileInfo.module.css";
import PropTypes from "prop-types";
import { authVar } from "../../../../../../utils/cache";
import { useReactiveVar } from "@apollo/client";
import useShowConfirmation from "../../../../../../hooks/useShowConfirmation";
import ConfirmationModal from "../../../../UI/ConfirmationModal";
import useLogout from "../../../../../../hooks/useLogout";
const ProfileInfo = (props) => {
  const { closeSideDrawer, showProfileMenu, toggleHandler } = props;
  const authData = useReactiveVar(authVar);
  const { showConfirmation, toggleConfirmationHandler } = useShowConfirmation();
  const [logoutHandler, loading] = useLogout();

  return (
    <div className={styles.profile}>
      <ConfirmationModal
        show={showConfirmation}
        cancelHandler={toggleConfirmationHandler}
        confirmHandler={logoutHandler}
        message='Are you sure you want to log out of OBWS?'
        loading={loading}
      />

      <div className={styles.imgNameContainer}>
        <ProfilePicture variant={styles.profilePicture} />
        <p
          className={styles.name}
        >{`${authData?.firstName} ${authData?.lastName}`}</p>
      </div>
      <img
        src={toggle}
        className={`${styles.toggle} side-drawer`}
        alt='profile toggle'
        onClick={toggleHandler}
      />

      {showProfileMenu && (
        <ProfileMenu
          variant={styles.menuPosition}
          toggleHandler={toggleHandler}
          closeSideDrawer={closeSideDrawer}
          toggleConfirmationHandler={toggleConfirmationHandler}
        />
      )}
    </div>
  );
};

export default ProfileInfo;

ProfileInfo.propTypes = {
  closeSideDrawer: PropTypes.func,
  showProfileMenu: PropTypes.bool,
  toggleHandler: PropTypes.func,
};
