import { gql } from "@apollo/client";
export default gql`
  query (
    $text: String!
    $role: [UserRole]!
    $size: Int
    $page: Int
    $sort: SortOrder
  ) {
    users(text: $text, role: $role, size: $size, page: $page, sort: $sort) {
      paginatorInfo {
        currentPage
        hasMorePages
      }
      data {
        id
        email
        firstName
        lastName
        isBlocked
        roles {
          id
          name
          label
          permissions
        }
      }
    }
  }
`;
