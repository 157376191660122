import { gql } from "@apollo/client";
export default gql`
  mutation ADD_COMMENT($itemType: itemTypes!, $itemId: Int!, $comment: String) {
    addComment(itemType: $itemType, itemId: $itemId, comment: $comment) {
      id
      message
      createAt
      user {
        firstName
        lastName
        avatar {
          path
        }
      }
    }
  }
`;
