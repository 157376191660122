import { useReactiveVar } from "@apollo/client";
import React from "react";
import { useSearchParams } from "react-router-dom";
import {
  appliedSearchFilters,
  notAppliedSearchFilters,
} from "../../../../utils/cache";
import FilterBubbles from "../../../GlobalComponents/UI/FilterBubbles/FilterBubbles";
import styles from "./AppliedFilters.module.css";

const AppliedFilters = () => {
  const appliedFilters = useReactiveVar(appliedSearchFilters);
  const notAppliedFilters = useReactiveVar(notAppliedSearchFilters);
  const [params, setParams] = useSearchParams();

  const removeFilter = (filter) => {
    const item = appliedFilters.find((el) => el.filterType === filter);
    const newNotApplied = [...notAppliedFilters, item];
    const newAppliedFilters = appliedFilters.filter(
      (el) => el.filterType !== filter
    );

    appliedSearchFilters(newAppliedFilters);
    notAppliedSearchFilters(newNotApplied);
    const savedParams = params.getAll("f");
    const filteredParams = savedParams?.filter((el) => el !== filter);
    params.delete("f");
    filteredParams.map((el) => params.append("f", el));
    setParams(params);
  };
  return (
    <div className={styles.filters}>
      <FilterBubbles applied={true} removeFilter={removeFilter} />
    </div>
  );
};

export default AppliedFilters;
