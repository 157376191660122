import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import AuthenticationOptions from "../../GlobalComponents/Authentication/AuthenticationOptions/AuthenticationOptions";
import SignUpForm from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm";
import styles from "./RegisterForm.module.css";
import PropTypes from "prop-types";
import { USER_ROLES } from "../../../constants";
const RegisterForm = (props) => {
  const { insertUserData, nextStepHandler, userData } = props;
  const location = useLocation();
  const isBusiness = location?.pathname?.includes("business");

  return (
    <div className={styles.container}>
      {!isBusiness && (
        <div className={styles.linkContainer}>
          <NavLink
            to='/business-register'
            className={styles.businessRegisterLink}
          >
            Sign up as a business owner
          </NavLink>
        </div>
      )}
      <div className={styles.registerForm}>
        <SignUpForm
          insertUserData={insertUserData}
          nextStepHandler={nextStepHandler}
          isBusiness={isBusiness}
          userData={userData}
        />
      </div>
      <div className={styles.brdr}></div>
      <div className={styles.authOptions}>
        <AuthenticationOptions
          isRegister
          userType={
            isBusiness ? USER_ROLES.BUSINESS_OWNER[0] : USER_ROLES.USER[0]
          }
        />
      </div>
    </div>
  );
};

export default RegisterForm;
RegisterForm.propTypes = {
  insertUserData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  userData: PropTypes.object,
};
