import { gql } from "@apollo/client";
export default gql`
  mutation REMOVE_FROM_COLLECTION($items: [collectionItem]) {
    removeItemsFromCollection(items: $items) {
      __typename
      ... on Service {
        isBookmarked
      }
      ... on Food {
        isBookmarked
      }
      ... on Product {
        isBookmarked
      }
      ... on Business {
        isBookmarked
      }
    }
  }
`;
