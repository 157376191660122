import React from "react";
import styles from "./Settings.module.css";
import PropTypes from "prop-types";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";

const SaveBtn = (props) => {
  const { loading, disable } = props;
  return (
    <button
      type='submit'
      className={`${styles.apply} ${
        loading ? styles.loadingBg : styles.regularBg
      }`}
      disabled={disable}
    >
      {loading ? <Spinner variant={styles.loader} /> : "SAVE CHANGES"}
    </button>
  );
};

export default SaveBtn;
SaveBtn.propTypes = {
  loading: PropTypes.bool,
  disable: PropTypes.bool,
};
