import React from "react";
import Loader from "../Loader/Loader";
import styles from "./RowLoader.module.css";
import PropTypes from "prop-types";
const RowLoader = (props) => {
  const { rows } = props;
  const loaderArr = Array.from(Array(rows ?? 8).keys());

  return (
    <>
      {loaderArr.map((el, i) => (
        <tr className={styles.row} key={el}>
          <td>
            <Loader variant={styles.itemName} />
          </td>
          <td>
            <div className={styles.ownerContainer}>
              <Loader variant={styles.avatar} />
              <div className={styles.about}>
                <Loader variant={styles.name} />

                <Loader variant={styles.title} />
              </div>
            </div>
          </td>
          <td>
            <div className={styles.types}>
              <Loader variant={styles.chip} />
              <Loader variant={styles.chip} />
            </div>
          </td>
          <td className={styles.btnsCol}>
            <div className={styles.btnsContainer}>
              <Loader variant={styles.link} />
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};

export default RowLoader;
RowLoader.propTypes = { rows: PropTypes.number };
