import { gql } from "@apollo/client";
export default gql`
  mutation CreateBusiness(
    $businessName: String!
    $featuredImage: Int
    $state: Int
    $city: Int
    $gallery: [ID!]
    $categories: [ID!]
    $types: [BusinessType!]
    $phone: String
    $email: Email
    $zip: String
    $addrLine1: String
    $addrLine2: String
    $lat: Float
    $lng: Float
    $website: String
    $facebook: String
    $instagram: String
    $twitter: String
    $tiktok: String
    $desc: String
    $aboutOwner: String
    $workingHours: [WorkingHours]
    $onboardingState: BusinessOnboardingState
  ) {
    createBusiness(
      businessName: $businessName
      featuredImage: $featuredImage
      state: $state
      city: $city
      gallery: $gallery
      categories: $categories
      types: $types
      phone: $phone
      email: $email
      zip: $zip
      addrLine1: $addrLine1
      addrLine2: $addrLine2
      lat: $lat
      lng: $lng
      website: $website
      facebook: $facebook
      instagram: $instagram
      twitter: $twitter
      tiktok: $tiktok
      desc: $desc
      aboutOwner: $aboutOwner
      workingHours: $workingHours
      onboardingState: $onboardingState
    ) {
      id
      featuredImage {
        id
        path
      }
      randomImages {
        path
        id
      }
      website
      businessTypes
      workingHours {
        day
        open
        close
      }
      name
      addrLine1
      addrLine2
      zip
      city {
        name
        id
      }
      state {
        name
        id
      }

      lat
      lng
      phone
      email
      facebook
      twitter
      instagram
      tiktok
      onboardingState
      desc
      aboutOwner
      categories {
        id
        name
        parent {
          id
        }
        children {
          data {
            id
            name
          }
        }
      }
      aboutOwner
      desc
    }
  }
`;
