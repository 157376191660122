import { useMutation } from "@apollo/client";
import React from "react";
import Cookies from "universal-cookie";
import SignUpPicture from "../../components/GlobalComponents/Authentication/SignUpPicture/SignUpPicture";
import updateUserAvatar from "../../graphql/mutations/updateUserAvatar";
import { useNavigate } from "react-router-dom";
import useUpdateUser from "../../hooks/useUpdateUser";
import styles from "./OwnerProfilePic.module.css";

const OwnerProfilePic = () => {
  const [updateOwner, { loading }] = useMutation(updateUserAvatar);
  const userId = new Cookies()?.get("user-id");
  const navigate = useNavigate();
  const { updateUser, userLoading } = useUpdateUser();

  const submitHandler = (avatarId) => {
    if (avatarId) {
      updateOwner({
        variables: { id: userId, avatar: avatarId },
      }).then(() => {
        updateUser().then(() => navigate("/business-onboarding"));
      });
    } else {
      navigate("/business-onboarding");
    }
  };

  return (
    <div className={styles.container}>
      <SignUpPicture
        submitHandler={submitHandler}
        isBusiness
        requestLoading={loading || userLoading}
      />
    </div>
  );
};

export default OwnerProfilePic;
