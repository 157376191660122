import React, { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import Header from "../BusinessOwnerLayout/Header/Header";
import Sidebar from "../../UI/Sidebar";
import styles from "./AdminLayout.module.css";
import { authVar, isAdmin } from "../../../../utils/cache";

export default function AdminLayout() {
  const userData = useReactiveVar(authVar);

  useEffect(() => {
    isAdmin(true);
    return () => isAdmin(false);
  }, []);

  const sidebarItems = [
    { label: "User Management", path: "/admin" },
    { label: "Listing Management", path: "/listing-management" },
    { label: "Editor", path: "/admin-editor" },
    { label: "Manage team", path: "/admin-manage-team" },
  ];

  return (
    <div className={styles.adminLayout}>
      <ToastContainer />
      <Header title='Admin' className={styles.header} />
      <div className={styles.adminContainer}>
        <Sidebar
          sidebarItems={sidebarItems}
          title='LOGGED IN'
          name={userData?.firstName || ""}
        />
        <div className={styles.mainContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

AdminLayout;
