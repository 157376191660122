import { gql } from "@apollo/client";
export default gql`
  query ($filters: CityFilters) {
    cities(filters: $filters, size: 9999) {
      data {
        id
        name
        label
      }
    }
  }
`;
