import React from "react";
import styles from "./Modal.module.css";
import PropTypes from "prop-types";
const Modal = (props) => {
  const { variant, children } = props;
  const propagationHandler = (e) => e.stopPropagation();

  return (
    <div className={`${styles.modal} ${variant}`} onClick={propagationHandler}>
      {children}
    </div>
  );
};

export default Modal;
Modal.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
