import React from "react";
import Input from "../../../GlobalComponents/UI/Input/Input";
import styles from "./Suggested.module.css";
import PropTypes from "prop-types";

const Suggested = (props) => {
  const { openNowHandler, openNow } = props;

  return (
    <>
      <h1 className={styles.title}>Suggested</h1>
      <Input
        elementType='checkbox'
        hasLabel={true}
        label='Open Now'
        inpContainerClass={styles.inpContainer}
        checked={openNow}
        changeHandler={openNowHandler}
        customCheckBox={styles.checkMark}
      />
    </>
  );
};

export default Suggested;
Suggested.propTypes = {
  openNowHandler: PropTypes.func,
  openNow: PropTypes.bool,
};
