import { gql } from "@apollo/client";
export default gql`
  mutation createFood(
    $businessId: Int!
    $featuredImage: Int
    $name: String!
    $desc: String!
    $price: Float!
    $orderLink: String
    $gallery: [ID!]!
    $categories: [ID!]!
    $ingredientsAndNutritional: String
  ) {
    createFood(
      businessId: $businessId
      featuredImage: $featuredImage
      name: $name
      desc: $desc
      price: $price
      orderLink: $orderLink
      gallery: $gallery
      categories: $categories
      ingredientsAndNutritional: $ingredientsAndNutritional
    ) {
      id
    }
  }
`;
