import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import useInput from "../../../../hooks/useInput";
import {
  emailValidationRules,
  nameValidationRules,
  passwordValidationRules,
  fullNameValidationRules,
} from "../../../../utils/validationRules";
import {
  getUserNames,
  errorToastHandler,
  successToastHandler,
} from "../../../../utils";
import AdminTitle from "../../../GlobalComponents/Admin/AdminTitle";
import Input from "../../../GlobalComponents/UI/Input/Input";
import RegisterNextBtn from "../../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import Spinner from "../../../GlobalComponents/UI/Spinner/Spinner";
import getUser from "../../../../graphql/queries/getUser";
import getUsers from "../../../../graphql/queries/getUsers";
import registerAdmin from "../../../../graphql/mutations/registerAdmin";
import updateUserMutation from "../../../../graphql/mutations/updateUser";
import { USER_ROLES } from "../../../../constants";
import parentStyles from "../../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import styles from "./UserForm.module.css";

export default function UserForm() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [emailNetworkError, setEmailNetworkError] = useState("");
  const isEdit = pathname.includes("edit");
  const refetchQuery = {
    query: getUsers,
    variables: {
      role: USER_ROLES.ADMIN,
      text: "",
    },
  };
  const [fetchUser, { loading: loadingUser }] = useLazyQuery(getUser);
  const [updateUser, { loading: loadingUserUpdate }] = useMutation(
    updateUserMutation,
    {
      refetchQueries: [refetchQuery],
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
    }
  );
  const [addAdmin, { loading: loadingAddAdmin }] = useMutation(registerAdmin, {
    refetchQueries: [refetchQuery],
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
  });

  const {
    value: name,
    hasError: nameError,
    errorMessage: nameErrorMessage,
    valueChangeHandler: nameHandler,
    valueBlurHandler: nameBlurHandler,
    isValid: nameValid,
  } = useInput(
    isEdit ? nameValidationRules : fullNameValidationRules,
    userData?.name
  );

  const {
    value: email,
    hasError: emailError,
    errorMessage: emailErrorMessage,
    valueChangeHandler: emailHandler,
    valueBlurHandler: emailBlurHandler,
    isValid: emailValid,
  } = useInput(emailValidationRules, userData?.email);

  const {
    value: password,
    hasError: passwordError,
    errorMessage: passwordErrorMessage,
    valueChangeHandler: passwordHandler,
    valueBlurHandler: passwordBlurHandler,
    isValid: passwordValid,
  } = useInput(passwordValidationRules, userData?.password);

  const isValidForm = isEdit
    ? emailValid && nameValid
    : emailValid && nameValid && passwordValid;
  const submitButtonText = isEdit ? `+ edit team member` : `+ add team member`;

  useEffect(() => {
    if (emailNetworkError.length) {
      setEmailNetworkError("");
    }
  }, [email]);

  useEffect(() => {
    if (id && isEdit) {
      fetchUser({
        variables: {
          id,
        },
      }).then(({ data }) => {
        setUserData({
          name: `${data?.user?.firstName} ${data?.user?.lastName}`,
          email: data?.user?.email,
        });
      });
    }
  }, [id]);

  const handleSubmit = (e) => {
    const { firstName, lastName } = getUserNames(name);
    e.preventDefault();
    if (isEdit) {
      updateUser({
        variables: {
          id,
          firstName,
          lastName,
          email,
        },
      })
        .then(() => {
          successToastHandler("UPDATED SUCCESSFULLY");
          navigate("/admin-manage-team");
        })
        .catch((e) => {
          errorToastHandler();
          setEmailNetworkError(
            "This email might be already used please try another email"
          );
        });
    } else {
      addAdmin({
        variables: {
          firstName,
          lastName,
          email,
          password,
        },
      })
        .then(() => {
          successToastHandler("ADDED SUCCESSFULLY");
          navigate("/admin-manage-team");
        })
        .catch((e) => {
          errorToastHandler();
          setEmailNetworkError(
            "This email might be already used please try another email"
          );
        });
    }
  };

  if (loadingUser) {
    return <Spinner />;
  }

  return (
    <div className={styles.userForm}>
      <AdminTitle title={isEdit ? "Edit Team Member" : "Add Team Member"} />
      <form className={styles.form}>
        <div className={styles.group}>
          <Input
            hasLabel
            label='MEMBER NAME'
            isRequired
            placeholder='e.g. Jamie Washington'
            type='text'
            inpContainerClass={styles.textField}
            value={name}
            changeHandler={nameHandler}
            blurHandler={nameBlurHandler}
            invalid={nameError}
            errorMessage={nameErrorMessage}
          />
        </div>
        <div className={styles.group}>
          <Input
            hasLabel
            label='MEMBER EMAIL'
            isRequired
            placeholder='jamie@obws.com'
            type='text'
            inpContainerClass={styles.textField}
            value={email}
            changeHandler={emailHandler}
            blurHandler={emailBlurHandler}
            invalid={emailError || emailNetworkError.length > 0}
            errorMessage={emailNetworkError || emailErrorMessage}
          />
        </div>
        {!isEdit && (
          <div className={styles.group}>
            <Input
              hasLabel
              label='MEMBER PASSWORD'
              isRequired
              placeholder='a4Qfjklv*fslk'
              type='text'
              inpContainerClass={styles.textField}
              value={password}
              changeHandler={passwordHandler}
              blurHandler={passwordBlurHandler}
              invalid={passwordError}
              errorMessage={passwordErrorMessage}
            />
          </div>
        )}
        <div className={styles.submitBtnWrapper}>
          <RegisterNextBtn
            variant={parentStyles.continueBtn}
            clickHandler={handleSubmit}
            isLoading={loadingUserUpdate || loadingAddAdmin}
            disabled={!isValidForm}
          >
            {loadingUserUpdate || loadingAddAdmin ? (
              <Spinner />
            ) : (
              submitButtonText
            )}
          </RegisterNextBtn>
        </div>
      </form>
    </div>
  );
}
