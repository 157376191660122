import bag from "../assets/images/bag.svg";
import gear from "../assets/images/gear.svg";
import brick from "../assets/images/brick.svg";
import ecomm from "../assets/images/ecomm.svg";
import food from "../assets/images/food.svg";
import restaurants from "../assets/images/restaurant.svg";

export const searchSortArr = [
  { name: "Upvotes", label: "VOTES" },
  { name: "Relevance", label: "RELEVANCE" },
  { name: "From A - Z", label: "ALPHABETIC" },
  { name: "$$ Low to High", label: "PRICE_ASC" },
  { name: "$$ High to Low", label: "PRICE_DESC" },
  { name: "Near Me", label: "NEAR_ME" },
];

export const searchTypeFilter = [
  { name: "All", label: [] },
  { name: "Items", label: ["PRODUCT", "SERVICE", "FOOD"] },
  { name: "Businesses", label: ["BrickMortars", "Ecommerce", "Restaurants"] },
];

export const searchFilterBy = [
  { icon: bag, name: "PRODUCT", filterType: "PRODUCT" },
  { icon: gear, name: "SERVICE", filterType: "SERVICE" },
  { icon: brick, name: "BRICK & MORTAR", filterType: "BrickMortars" },
  { icon: ecomm, name: "E-COMMERCE", filterType: "Ecommerce" },
  { icon: food, name: "FOOD", filterType: "FOOD" },
  { icon: restaurants, name: "RESTAURANTS", filterType: "Restaurants" },
];
