import { gql } from "@apollo/client";
export default gql`
  query DISCOVER_MOST_POPULAR($page: Int) {
    discoverMostPopular {
      __typename
      ... on Product {
        id
        name
        desc
        price
        votes
        isVoted
        isBookmarked
        collections(page: $page) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }

        featuredImage {
          id
          path
        }
        business {
          name
          id
        }
      }
      ... on Service {
        id
        name
        desc
        price
        votes
        isVoted
        isBookmarked
        collections(page: $page) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }

        featuredImage {
          id
          path
        }
        business {
          name
          id
        }
      }
      ... on Food {
        id
        name
        desc
        price
        votes
        isVoted
        isBookmarked
        collections(page: $page) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }

        featuredImage {
          id
          path
        }
        business {
          name
          id
        }
      }
    }
  }
`;
