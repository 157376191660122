import React from "react";
import styles from "./SocialLinks.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { itemEditField, editView, isEdit } from "../../../../utils/cache";
import SocialsInputs from "./SocialsInputs/SocialsInputs";
import EditTooltip from "../../../GlobalComponents/UI/EditTooltip/EditTooltip";
const SocialLinks = (props) => {
  const { links } = props;
  const isEditing = useReactiveVar(editView);
  const editPage = useReactiveVar(isEdit);
  const selectToEditHandler = (socialName) => itemEditField(socialName);
  const selectedToEdit = useReactiveVar(itemEditField);

  if (isEditing) {
    return (
      <div
        className={`${styles.links} ${editPage ? styles.edit : styles.noEdit}`}
      >
        {links?.map((link, i) => (
          <div key={i} className={styles.social}>
            <img
              className={styles.icon}
              src={link?.icon}
              alt='social link'
              onClick={selectToEditHandler.bind(null, link?.social)}
            />
            <EditTooltip
              show={link?.social === selectedToEdit}
              variant={styles.tooltip}
              anchorStyle='right'
              closeHandler={selectToEditHandler.bind(null, "")}
            >
              <SocialsInputs social={link?.social} url={link.socialLink} />
            </EditTooltip>
          </div>
        ))}
      </div>
    );
  }
  if (!links.length && !isEditing) return null;
  return (
    <div
      className={`${styles.links} ${editPage ? styles.edit : styles.noEdit}`}
    >
      {links?.map(
        (link, i) =>
          link?.socialLink && (
            <a
              href={
                link?.socialLink?.includes("http://") ||
                link?.socialLink?.includes("https://")
                  ? link?.socialLink
                  : `//` + link?.socialLink
              }
              target='_blank'
              rel='noreferrer'
              key={i}
            >
              <img className={styles.icon} src={link?.icon} alt='social link' />
            </a>
          )
      )}
    </div>
  );
};

export default SocialLinks;
SocialLinks.propTypes = { links: PropTypes.arrayOf(PropTypes.object) };
