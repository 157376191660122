import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import styles from "./ResendTemplate.module.css";
import { useMutation } from "@apollo/client";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import resetPasswordRequest from "../../../graphql/mutations/authentication/resetPasswordRequest";
import PropTypes from "prop-types";

const ResendTemplate = (props) => {
  const { email } = props;
  const [resetRequest, { loading, error }] = useMutation(resetPasswordRequest);
  const [changingWord, setChangingWord] = useState("sent");
  const navigate = useNavigate();
  const clickHandler = () => {
    resetRequest({ variables: { email } }).then(() =>
      setChangingWord("resent")
    );
  };
  const successHandler = () => {
    navigate("/login");
  };
  return (
    <div className={styles.resendContainer}>
      <h6 className={styles.header}>Update New Password</h6>
      <p className={styles.subTitle}>
        We
        <span className={`${styles.resent} ${error && "notifyAnimation"}`}>
          {changingWord}
        </span>
        a link to your email to reset your password
      </p>
      <button className={styles.resendBtn} onClick={clickHandler}>
        Didn’t get it? Resend link here
      </button>
      <NavLink to='/login' className={styles.loginLink}>
        <RegisterNextBtn
          variant={styles.continueBtn}
          isLoading={loading}
          onClick={successHandler}
        >
          {loading ? <Spinner /> : "CONFIRM"}
        </RegisterNextBtn>
      </NavLink>
    </div>
  );
};

export default ResendTemplate;
ResendTemplate.propTypes = {
  email: PropTypes.string,
};
