import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./AboutBusiness.module.css";
import PropTypes from "prop-types";
import Spinner from "../../UI/Spinner/Spinner";
import ErrorComponent from "../../UI/ErrorComponent/ErrorComponent";
import BusinessHoursSection from "../../Sections/BusinessHoursSection";
import { isBusinessType } from "../../../../utils";
import { BUSINESS_TYPES } from "../../../../constants";

const AboutBusiness = (props) => {
  const { data, error, loading } = props;

  const { businessTypes } = data?.business ?? [];
  const isBrickMortarBusiness = isBusinessType(
    businessTypes,
    BUSINESS_TYPES.brickMortars
  );
  const isEcommerce = isBusinessType(businessTypes, BUSINESS_TYPES.ecommerce);
  return (
    <div className={styles.aboutBusiness}>
      <h6 className={styles.title}>ABOUT THE Business</h6>
      {loading && <Spinner variant={styles.loader} />}
      {error && <ErrorComponent error={error?.message} />}
      {data && (
        <>
          <h2 className={styles.businessName}>{data?.business?.name}</h2>
          <p className={styles.address}>{data?.business?.addrLine1}</p>
          <div className={styles.desc}>
            <p>{data?.business?.desc}</p>
          </div>
          {isBrickMortarBusiness && !isEcommerce && (
            <BusinessHoursSection
              workingHours={data?.business?.workingHours}
              isBusinessPage={false}
            />
          )}
          <NavLink
            to={`/business/${data?.business?.id}`}
            className={styles.visit}
          >
            <button className={styles.visitBtn}>VISIT BUSINESS PAGE</button>
          </NavLink>
        </>
      )}
    </div>
  );
};

export default AboutBusiness;
AboutBusiness.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};
