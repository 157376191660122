import React, { useEffect, useRef } from "react";
import ConsumerOnboarding from "../../../../Onboarding/Consumer/ConsumerOnboarding";
import EditWebsite from "../../EditWebsite/EditWebsite";
import Tooltip from "../../Tooltip/Tooltip";
import regStyles from "../ProductCarousel.module.css";
import editStyles from "../EditPageStyles.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import {
  consumerOnboardingVar,
  editView,
  itemEditField,
} from "../../../../../utils/cache";

const VisitBtn = (props) => {
  const { website, isEdit, itemType } = props;
  const childRef = useRef();
  const onboardingStep = useReactiveVar(consumerOnboardingVar);
  const onBoardingRef = useRef();
  const selectedToEdit = useReactiveVar(itemEditField);
  const isEditing = useReactiveVar(editView);

  const styles = isEdit ? editStyles : regStyles;
  useEffect(() => {
    if (onboardingStep === 4)
      window.scrollTo({
        top: onBoardingRef?.current?.offsetTop,
        behavior: "smooth",
      });
  }, [onboardingStep]);
  const handleNextStep = () => {
    consumerOnboardingVar(onboardingStep + 1);
  };
  const visitWebsiteHandler = () => {
    if (isEditing) {
      itemEditField("visitBtn");
      return;
    }
    if (website) {
      analytics.track("buynow_selected");

      window.open(
        `${
          website?.includes("http://") || website?.includes("https://")
            ? website
            : `//` + website
        }`
      );
    } else {
      return;
    }
  };

  return (
    <>
      <button
        className={`${styles.buy} ${
          website ? styles.enabled : styles.disabled
        } ${onboardingStep === 4 && styles.onBoardingBtn}`}
        onClick={visitWebsiteHandler}
        ref={onBoardingRef}
      >
        BUY NOW
        <Tooltip
          show={onboardingStep === 4}
          anchorVariant='top-right'
          customClass={styles.productTooltip}
          handleTooltipClose={(e) => childRef?.current?.handleClose(e)}
        >
          <ConsumerOnboarding
            ref={childRef}
            onboardingStep={onboardingStep + 1}
            heading='Purchase directly from vendor'
            description='Click on Buy Now and you’ll be directed to the businesses’ website so that you can make the purchase and support.'
            handleNext={handleNextStep}
          />
        </Tooltip>
      </button>
      <EditWebsite website={website} itemType={itemType} />
    </>
  );
};

export default VisitBtn;
VisitBtn.propTypes = {
  website: PropTypes.string,
  itemType: PropTypes.string,
  isEdit: PropTypes.bool,
};
