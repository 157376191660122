import React from "react";
import regStyles from "./SearchList.module.css";
import adminStyles from "./AdminStyles.module.css";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import addSign from "../../../../../assets/images/add.png";
const SearchList = (props) => {
  const {
    searchItems,
    searchTerm,
    listToggleHandler,
    clearText,
    cursor,
    isEditorSearch,
    selectHandler,
  } = props;
  const { pathname } = useLocation();

  const stopDefault = (e) => {
    e.preventDefault();
  };

  const navigate = (e, item) => {
    if (isEditorSearch) {
      e.preventDefault();
      selectHandler(item);
    }
    clearText();
    listToggleHandler();
  };
  const styles = isEditorSearch ? adminStyles : regStyles;
  return (
    <div className={styles.listContainer}>
      {searchItems?.length === 0 ? (
        <p className={styles.empty}>{`No Items Found For “${searchTerm}”`}</p>
      ) : (
        <>
          {searchItems?.map((itm, i) => (
            <NavLink
              to={
                isEditorSearch
                  ? pathname
                  : { pathname: "/search", search: `t=${itm?.name}` }
              }
              key={`${itm?.name}${i}`}
              onMouseDown={stopDefault}
              onClick={(e) => navigate(e, itm)}
              className={`${styles.link} ${cursor === i && styles.active}`}
            >
              <span> {itm?.name}</span>
              {isEditorSearch && (
                <img src={addSign} className={styles.add} alt='add' />
              )}
            </NavLink>
          ))}
          {!isEditorSearch && (
            <NavLink
              to={{ pathname: "/search", search: `t=${searchTerm}` }}
              className={styles.searchLink}
              onMouseDown={stopDefault}
              onClick={navigate}
            >
              {`View all for “${searchTerm}”`}
            </NavLink>
          )}
        </>
      )}
    </div>
  );
};

export default SearchList;
SearchList.propTypes = {
  searchItems: PropTypes.arrayOf(PropTypes.object),
  searchTerm: PropTypes.string,
  listToggleHandler: PropTypes.func,
  clearText: PropTypes.func,
  selectHandler: PropTypes.func,
  cursor: PropTypes.number,
  isEditorSearch: PropTypes.bool,
};
