import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../components/AdminListingManagement/Header";
import ItemTableBody from "../../components/GlobalComponents/Admin/ItemTableBody/ItemTableBody";
import { BUSINESS_STATUS } from "../../constants";
import search from "../../graphql/queries/search/search";
const ITEMS_SIZE = 20;
const ListingManagement = () => {
  const [selectedLabel, setSelectedLabel] = useState("Business");
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState([
    "BrickMortars",
    "Ecommerce",
    "Restaurants",
  ]);
  const [searchSort, setSearchSort] = useState();
  const [applyingFilter, setApplyingFilter] = useState(false);
  const { data, loading, error, fetchMore, refetch } = useQuery(search, {
    variables: {
      text: searchTerm,
      page: 1,
      size: ITEMS_SIZE,
      filters: {
        typesAndCategories: {
          types: filter,
          status: [BUSINESS_STATUS.APPROVED],
        },
      },
      sortBy: { type: searchSort },
    },
    notifyOnNetworkStatusChange: true,
  });
  const tableCols = [
    `${selectedLabel} NAME`,
    "BUSINESS OWNER",
    `TYPE OF ${selectedLabel}`,
  ];
  const { data: items } = data?.search ?? {};
  const { currentPage, hasMorePages } = data?.search?.paginatorInfo ?? {};

  const searchFilters = [
    {
      label: "Business",
      searchFilter: ["BrickMortars", "Ecommerce", "Restaurants"],
    },
    { label: "Product", searchFilter: ["PRODUCT"] },
    { label: "Service", searchFilter: ["SERVICE"] },
    { label: "Food", searchFilter: ["FOOD"] },
  ];

  useEffect(() => {
    setApplyingFilter(true);
    refetch({
      text: searchTerm,
      page: 1,
      size: ITEMS_SIZE,
      filters: {
        typesAndCategories: {
          types: filter,
          status: [BUSINESS_STATUS.APPROVED],
        },
      },
      sortBy: { type: searchSort },
    }).then(() => setApplyingFilter(false));
  }, [filter, searchSort]);

  const observer = useRef();

  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          fetchMore({ variables: { page: currentPage + 1 } });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMorePages, loading]
  );

  const getSearchTerm = (val) => {
    setSearchTerm(val);
  };

  const selectedFilterHandler = (item) => {
    setSelectedLabel(item?.label);
    setFilter(item?.searchFilter);
    setApplyingFilter(true);
  };
  return (
    <div>
      <Header
        selectedLabel={selectedLabel}
        filter={filter}
        selectedFilterHandler={selectedFilterHandler}
        searchFilters={searchFilters}
        itemsSize={ITEMS_SIZE}
        getSearchTerm={getSearchTerm}
      />
      <ItemTableBody
        items={items}
        loading={loading}
        error={error}
        cols={tableCols}
        ref={lastItemRef}
        applyingFilter={applyingFilter}
        setSearchSort={setSearchSort}
      />
    </div>
  );
};

export default ListingManagement;
