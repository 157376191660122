import { gql } from "@apollo/client";
export default gql`
  mutation CONTACT_US(
    $email: Email!
    $name: String!
    $subject: String!
    $content: String!
  ) {
    contactUs(email: $email, name: $name, subject: $subject, content: $content)
  }
`;
