import React, { useEffect, useState } from "react";
import styles from "./SignUpForm.module.css";
import PropTypes from "prop-types";
import Input from "../../UI/Input/Input";
import useInput from "../../../../hooks/useInput";
import {
  confirmPasswordRules,
  emailValidationRules,
  nameValidationRules,
  passwordValidationRules,
} from "../../../../utils/validationRules";
import RegisterNextBtn from "../RegisterNextBtn/RegisterNextBtn";
import emailAvailability from "../../../../graphql/queries/emailAvailability";
import { useLazyQuery, useMutation } from "@apollo/client";
import Spinner from "../../UI/Spinner/Spinner";
import { notVerifiedUserData } from "../../../../utils/cache";
import useLogout from "../../../../hooks/useLogout";
import Cookies from "universal-cookie";
import businessRegister from "../../../../graphql/mutations/authentication/businessRegister";
import { useNavigate } from "react-router-dom";
import HeaderDesc from "./HeaderDesc";

const SignUpForm = (props) => {
  const [checkForEmail, { loading: checkLoading }] =
    useLazyQuery(emailAvailability);
  const [termsChecked, setTermsChecked] = useState(false);

  const [emailExists, setEmailExists] = useState(false);
  const [emailExistMessage, setEmailExistMessage] = useState("");
  const { insertUserData, nextStepHandler, userData, isBusiness } = props;
  const [registerBusiness, { loading: businessLoading, error: businessError }] =
    useMutation(businessRegister);
  const navigate = useNavigate();
  const toggleTermsHandler = () => {
    setTermsChecked(!termsChecked);
  };

  const {
    value: firstName,
    hasError: firstNameError,
    errorMessage: firstNameErrorMessage,
    valueChangeHandler: firstNameHandler,
    valueBlurHandler: firstNameBlurHandler,
    isValid: firsNameValid,
  } = useInput(nameValidationRules, userData?.firstName);

  const {
    value: lastName,
    hasError: lastNameError,
    errorMessage: lastNameErrorMessage,
    valueChangeHandler: lastNameHandler,
    valueBlurHandler: lastNameBlurHandler,
    isValid: lastNameValid,
  } = useInput(nameValidationRules, userData?.lastName);

  const {
    value: email,
    hasError: emailError,
    errorMessage: emailErrorMessage,
    valueChangeHandler: emailHandler,
    valueBlurHandler: emailBlurHandler,
    isValid: emailValid,
  } = useInput(emailValidationRules, userData?.email);

  const {
    value: password,
    hasError: passwordError,
    errorMessage: passwordErrorMessage,
    valueChangeHandler: passwordHandler,
    valueBlurHandler: passwordBlurHandler,
    isValid: passwordValid,
  } = useInput(passwordValidationRules, userData?.password);

  const {
    value: rePassword,
    hasError: rePasswordError,
    errorMessage: confirmPasswordErrorMessage,
    valueChangeHandler: rePasswordHandler,
    valueBlurHandler: rePasswordBlurHandler,
    isValid: confirmPasswordValid,
  } = useInput(confirmPasswordRules, userData?.password, password && password);

  const [logoutHandler, loading] = useLogout();
  const cookies = new Cookies();
  const userId = cookies.get("user-id");
  const isLoading = loading || checkLoading || businessLoading;
  const formIsValid =
    firsNameValid &&
    lastNameValid &&
    emailValid &&
    passwordValid &&
    confirmPasswordValid &&
    !emailExists &&
    termsChecked;

  useEffect(() => {
    if (emailExists) {
      setEmailExists(false);
      setEmailExistMessage(null);
    }
  }, [email]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (userId) {
      logoutHandler(false);
    }

    checkForEmail({ variables: { email } }).then((res) => {
      if (!res.data.checkEmailAvailability) {
        setEmailExists(true);
        setEmailExistMessage(
          "This Email Address is already associated with an account."
        );
        return;
      } else {
        let user_type;
        if (isBusiness) {
          user_type = "business user";
          analytics.track("user_start_signup", {
            user_name: `${firstName} ${lastName}`,
            email,
            user_type,
          });

          registerBusiness({
            variables: {
              firstName,
              lastName,
              password,
              email,
            },
          }).then(({ data }) => {
            analytics.track("terms_accepted");
            navigate("/confirm-email", { state: { from: "business" } });
          });
        } else {
          user_type = "consumer user";
          nextStepHandler();
          insertUserData({
            firstName,
            lastName,
            email,
            password,
          });
        }

        setEmailExists(false);
        notVerifiedUserData({ email, password });
        setEmailExistMessage(null);
      }
    });
  };

  return (
    <form className={styles.registerForm} onSubmit={submitHandler}>
      <HeaderDesc isBusiness={isBusiness} />
      <Input
        hasLabel
        isRequired
        label='FIRST NAME'
        placeholder='e.g. Isaiah'
        type='text'
        inpContainerClass={`${styles.halfWidthContainer} ${
          firstNameError && styles.invalid
        }`}
        value={firstName}
        changeHandler={firstNameHandler}
        blurHandler={firstNameBlurHandler}
        invalid={firstNameError}
        errorMessage={firstNameErrorMessage}
      />
      <Input
        hasLabel
        isRequired
        label='LAST NAME'
        placeholder='e.g. Isaiah'
        type='text'
        inpContainerClass={`${styles.halfWidthContainer} ${
          lastNameError && styles.invalid
        }`}
        value={lastName}
        changeHandler={lastNameHandler}
        blurHandler={lastNameBlurHandler}
        invalid={lastNameError}
        errorMessage={lastNameErrorMessage}
      />
      <Input
        hasLabel
        isRequired
        label='EMAIL'
        placeholder='e.g. yourname@gmail.com'
        type='email'
        inpContainerClass={`${styles.fullWidthContainer} ${
          (emailError || emailExists) && styles.invalid
        }`}
        value={email}
        changeHandler={emailHandler}
        blurHandler={emailBlurHandler}
        invalid={emailError || emailExists}
        errorMessage={emailExistMessage ? emailExistMessage : emailErrorMessage}
      />
      <Input
        hasLabel
        isRequired
        label='PASSWORD'
        placeholder='Enter password'
        type='password'
        inpContainerClass={`${styles.fullWidthContainer} ${
          passwordError && styles.invalid
        }`}
        value={password}
        changeHandler={passwordHandler}
        blurHandler={passwordBlurHandler}
        invalid={passwordError}
        errorMessage={passwordErrorMessage}
      />
      <Input
        hasLabel
        isRequired
        label='CONFIRM PASSWORD'
        placeholder='Confirm password'
        type='password'
        inpContainerClass={`${styles.fullWidthContainer} ${
          rePasswordError && styles.invalid
        }`}
        value={rePassword}
        changeHandler={rePasswordHandler}
        blurHandler={rePasswordBlurHandler}
        invalid={rePasswordError}
        errorMessage={confirmPasswordErrorMessage}
      />
      <div className={styles.termsContainer}>
        <Input
          elementType='checkbox'
          hasLabel
          label={
            <>
              I AGREE TO{" "}
              <a href='/terms-conditions' rel='noreferrer' target='_blank'>
                TERMS & CONDITIONS
              </a>{" "}
              *
            </>
          }
          inpContainerClass={styles.checkContainer}
          checked={termsChecked}
          changeHandler={toggleTermsHandler}
          customCheckBox={styles.checkMark}
        />
        {businessError && (
          <p className={styles.errorMessage}>
            Oops, something went wrong please try again!
          </p>
        )}
      </div>
      <RegisterNextBtn
        variant={styles.continueBtn}
        disabled={!formIsValid}
        isLoading={isLoading}
      >
        {isLoading ? <Spinner /> : "CONTINUE"}
      </RegisterNextBtn>
    </form>
  );
};
export default SignUpForm;
SignUpForm.propTypes = {
  insertUserData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  isBusiness: PropTypes.bool,
  userData: PropTypes.object,
};
