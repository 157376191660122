import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import EditorSearchBar from "../../GlobalComponents/Layouts/SearchBar/EditorSearchBar/EditorSearchBar";
import styles from "./Header.module.css";
import PropTypes from "prop-types";
const Header = (props) => {
  const {
    selectedLabel,
    filter,
    selectedFilterHandler,
    searchFilters,
    itemsSize,
    getSearchTerm,
  } = props;
  const navigate = useNavigate();
  const navigateHandler = () => navigate("");

  return (
    <div className={styles.header}>
      <div className={styles.firstRow}>
        <AdminTitle title='Listing Management' />
        <EditorSearchBar
          inpContainer={styles.searchInp}
          placeholder='Search for any business, product, or service'
          isEditorSearch
          selectHandler={navigateHandler}
          filters={filter}
          hideList
          itemsSize={itemsSize}
          initText=''
          getSearchTerm={getSearchTerm}
        />
      </div>
      <div className={styles.toggleContainer}>
        {searchFilters?.map((item) => (
          <button
            className={`${styles.labelBtn} ${
              selectedLabel === item?.label ? styles.active : styles.notActive
            }`}
            key={item?.label}
            onClick={selectedFilterHandler.bind(null, item)}
          >
            {item?.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Header;
Header.propTypes = {
  selectedLabel: PropTypes.string,
  filter: PropTypes.array,
  searchFilters: PropTypes.array,
  selectedFilterHandler: PropTypes.func,
  itemsSize: PropTypes.number,
  getSearchTerm: PropTypes.func,
};
