import React, { useEffect, useRef, useState } from "react";
import styles from "./VotingState.module.css";
import votedState from "../../../../assets/images/landingVoted.png";
import Countdown from "react-countdown";
import Timer from "../Timer";
import { zeroToSingleDigit } from "../../../../utils";
import {
  EST_BEOTYA_COMPLETE_DATE,
  EST_BEOTYA_START_DATE,
} from "../../../../constants";
import { useReactiveVar } from "@apollo/client";
import {
  beyotaSelected,
  beyotaStared,
  beyotaCompleted,
} from "../../../../utils/cache";
import CompleteState from "./CompleteState";

const DAY_IN_MILLIESECONDS = 86400000;
const BEOTYA_DAYS = [
  { day: 1, date: "2022-08-27T00:00:00.000-04:00" },
  { day: 2, date: "2022-08-28T00:00:00.000-04:00" },
  { day: 3, date: "2022-08-29T00:00:00.000-04:00" },
  { day: 4, date: "2022-08-30T00:00:00.000-04:00" },
];

const VotingState = () => {
  const [dayTimeObject, setTimeObject] = useState();
  const isStarted = useReactiveVar(beyotaStared);
  const isCompleted = useReactiveVar(beyotaCompleted);
  const isSelected = useReactiveVar(beyotaSelected);
  useEffect(() => {
    const now = +new Date();
    const start = +new Date(EST_BEOTYA_START_DATE);
    const complete = +new Date(EST_BEOTYA_COMPLETE_DATE);
    if (start <= now && complete > now) {
      beyotaStared(true);
    } else if (complete < now) {
      beyotaCompleted(true);
      beyotaStared(false);
    }
  }, []);
  useEffect(() => {
    if (isStarted) {
      const todayEst = new Date(
        new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
      ).getDate();
      const beotyaDay = BEOTYA_DAYS.find(
        (el) => new Date(el.date).getDate() === todayEst + 1
      );
      setTimeObject(beotyaDay);
    }
  }, [isStarted]);
  const ref = useRef();

  useEffect(() => {
    if (dayTimeObject?.date) {
      ref.current?.start();
    }
  }, [dayTimeObject]);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <div className={styles.placeholder} />;
    } else {
      // Render a countdown
      return (
        <Timer
          hours={zeroToSingleDigit(hours)}
          minutes={zeroToSingleDigit(minutes)}
          seconds={zeroToSingleDigit(seconds)}
          text={"Vote again in"}
        />
      );
    }
  };
  const handleCompletedTimer = () => {
    if (dayTimeObject?.day === 4) {
      beyotaCompleted(true);
      setTimeObject(null);
      beyotaSelected(null);
      return;
    }
    const newDate = +new Date(dayTimeObject?.date) + DAY_IN_MILLIESECONDS;
    const newDay = dayTimeObject?.day + 1;
    setTimeObject({ day: newDay, date: newDate });
    beyotaSelected(null);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.votingDayContainer}>
        {!isCompleted && (
          <span className={styles.day}>{`day ${dayTimeObject?.day} of 4`}</span>
        )}
        {!isCompleted && <span className={styles.text}>Voting open</span>}
      </div>
      {isSelected && !isCompleted && (
        <div className={styles.container}>
          <span className={styles.text}>Thanks for your vote</span>
          <img src={votedState} alt='votedState' />
        </div>
      )}
      {(isCompleted || !isSelected) && (
        <CompleteState isCompleted={isCompleted} />
      )}
      {dayTimeObject?.date && (
        <div
          style={{
            display:
              isSelected && dayTimeObject?.day !== 4 ? "initial" : "none",
          }}
        >
          <Countdown
            date={new Date(dayTimeObject?.date)}
            renderer={renderer}
            ref={ref}
            onComplete={handleCompletedTimer}
          />
        </div>
      )}
    </div>
  );
};

export default VotingState;
