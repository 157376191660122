import React from "react";
import styles from "./EditBtns.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { editView, isAdmin, itemEditField } from "../../../../utils/cache";
import { NavLink } from "react-router-dom";

const EditBtns = (props) => {
  const { variant, isBusiness, id } = props;
  const isEditing = useReactiveVar(editView);

  const toggleEditHandler = () => {
    editView(!isEditing);
    itemEditField(null);
  };
  const admin = useReactiveVar(isAdmin);
  return (
    <div className={`${variant} ${styles.btnsContainer}`}>
      {isBusiness && (
        <NavLink
          to={`${
            admin
              ? `/listing-management/working-hours/${id}`
              : `/working-hours/${id}`
          }`}
          className={` ${styles.workingHours} ${
            isEditing ? styles.active : styles.notActive
          }`}
          onClick={toggleEditHandler}
        >
          Working Hours
        </NavLink>
      )}

      <button
        className={` ${styles.editBtn} ${
          isEditing ? styles.active : styles.notActive
        }`}
        onClick={toggleEditHandler}
      >
        {isEditing ? "SAVE" : "EDIT"}
      </button>
    </div>
  );
};

export default EditBtns;
EditBtns.propTypes = {
  variant: PropTypes.string,
  isBusiness: PropTypes.bool,
  id: PropTypes.string,
};
