import React, { useState, useRef, useEffect } from "react";
import styles from "./ShareButton.module.css";
import PropTypes from "prop-types";
import Tooltip from "../../UI/Tooltip/Tooltip";
import ConsumerOnboarding from "../../../Onboarding/Consumer/ConsumerOnboarding";
import { useReactiveVar } from "@apollo/client";
import { consumerOnboardingVar } from "../../../../utils/cache";

const ShareButton = (props) => {
  const { variant, storyBookAction, shareHandler } = props;
  const [clicked, setClicked] = useState(false);
  const childRef = useRef();
  const onboardingStep = useReactiveVar(consumerOnboardingVar);
  const onBoardingRef = useRef();
  const handleNextStep = () => {
    consumerOnboardingVar(onboardingStep + 1);
  };

  const handleClick = () => {
    setClicked(true);
    storyBookAction && storyBookAction();
    shareHandler && shareHandler();
  };
  useEffect(() => {
    if (onboardingStep === 5) {
      window.scrollTo({
        top: onBoardingRef?.current?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [onboardingStep]);
  return (
    <>
      <button
        onClick={handleClick}
        className={`${styles.shareBtn} ${variant} 
      ${clicked ? styles.clicked : ""} ${
          onboardingStep === 5 && styles.onBoardingBtn
        }`}
        aria-label='share'
        ref={onBoardingRef}
      >
        <svg
          width='36'
          height='36'
          viewBox='0 0 36 36'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M17.8208 27.6714C17.8208 16.1305 17.8208 12.9436 17.8208 1.40273M17.8208 1.40273L11.2537 8.69958M17.8208 1.40273L24.388 8.69958'
            stroke='#0A0A0A'
            strokeWidth='1.77313'
            strokeLinecap='square'
            strokeLinejoin='round'
          />
          <path
            d='M1.40295 17.8208L1.40295 34.2387L34.2388 34.2387L34.2388 17.8208'
            stroke='#0A0A0A'
            strokeWidth='1.77313'
          />
        </svg>
      </button>
      <Tooltip
        show={onboardingStep === 5}
        anchorVariant='bottom'
        handleTooltipClose={(e) => childRef?.current?.handleClose(e)}
      >
        <ConsumerOnboarding
          ref={childRef}
          onboardingStep={onboardingStep + 1}
          heading='Share with your friends'
          description='Use the share button to share items with your friends, and help further circulate the Black dollar.'
          handleNext={handleNextStep}
        />
      </Tooltip>
    </>
  );
};

export default ShareButton;

ShareButton.propTypes = {
  variant: PropTypes.string,
  storyBookAction: PropTypes.func,
  shareHandler: PropTypes.func,
};
