import React, { useCallback, useRef } from "react";
import AppliedFilters from "./AppliedFilters/AppliedFilters";
import styles from "./Results.module.css";
import PropTypes from "prop-types";
import Item from "./Item/Item";
import { useReactiveVar } from "@apollo/client";
import { appliedSearchFilters } from "../../../utils/cache";
import ErrorComponent from "../../GlobalComponents/UI/ErrorComponent/ErrorComponent";
import SectionLoader from "../../GlobalComponents/UI/SectionLoader/SectionLoader";

const Results = (props) => {
  const {
    searchResults,
    showMap,
    loading,
    error,
    page,
    hasMorePages,
    fetchMore,
    applyingFilter,
  } = props;

  const applied = useReactiveVar(appliedSearchFilters);

  const observer = useRef();

  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          fetchMore({ variables: { page: page + 1 } });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMorePages, loading]
  );

  return (
    <div className={styles.resultsSection}>
      {applied.length > 0 && <AppliedFilters />}
      <div
        className={` ${showMap ? styles.withMap : styles.resultsContainer} `}
      >
        {error && <ErrorComponent />}
        {searchResults?.length > 0 &&
          !applyingFilter &&
          searchResults?.map((itm, i) => (
            <Item
              item={itm}
              key={`${itm?.name}${i}`}
              indx={i}
              ref={lastItemRef}
              itemsLength={searchResults?.length}
            />
          ))}
        {searchResults?.length === 0 && !error && !loading && (
          <p className={styles.empty}>NO ITEMS TO DISPLAY</p>
        )}
        {loading && <SectionLoader itemsNumber={4} />}
      </div>
    </div>
  );
};

export default Results;
Results.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.object),
  showMap: PropTypes.bool,
  loading: PropTypes.bool,
  applyingFilter: PropTypes.bool,
  error: PropTypes.object,
  page: PropTypes.number,
  hasMorePages: PropTypes.bool,
  fetchMore: PropTypes.func,
};
