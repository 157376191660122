import React from "react";
import PropTypes from "prop-types";
import Input from "../../../components/GlobalComponents/UI/Input/Input";
import styles from "./BusinessApplication.module.css";
export default function BusinessOwnerSection({
  ownerName,
  ownerNameHandler,
  ownerNameBlurHandler,
  ownerNameError,
  ownerNameErrorMessage,
  ownerEmail,
  ownerEmailHandler,
  ownerEmailBlurHandler,
  ownerEmailError,
  ownerEmailErrorMessage,
}) {
  return (
    <div className={styles.section}>
      <div className={styles.group}>
        <Input
          hasLabel
          label='Business owner'
          isRequired
          placeholder='e.g. Jamie Washington'
          type='text'
          inpContainerClass={styles.textField}
          value={ownerName}
          changeHandler={ownerNameHandler}
          blurHandler={ownerNameBlurHandler}
          invalid={ownerNameError}
          errorMessage={ownerNameErrorMessage}
        />
      </div>
      <div className={styles.group}>
        <Input
          hasLabel
          label='Email'
          isRequired
          placeholder='e.g. Jamie Washington'
          type='text'
          inpContainerClass={styles.textField}
          value={ownerEmail}
          changeHandler={ownerEmailHandler}
          blurHandler={ownerEmailBlurHandler}
          invalid={ownerEmailError}
          errorMessage={ownerEmailErrorMessage}
        />
      </div>
    </div>
  );
}

BusinessOwnerSection.propTypes = {
  ownerName: PropTypes.string,
  ownerNameHandler: PropTypes.func,
  ownerNameBlurHandler: PropTypes.func,
  ownerNameError: PropTypes.bool,
  ownerNameErrorMessage: PropTypes.string,
  ownerEmail: PropTypes.string,
  ownerEmailHandler: PropTypes.func,
  ownerEmailBlurHandler: PropTypes.func,
  ownerEmailError: PropTypes.bool,
  ownerEmailErrorMessage: PropTypes.string,
};
