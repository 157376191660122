import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import PropTypes from "prop-types";
import facebook from "../../../assets/images/facebook.svg";
import twitter from "../../../assets/images/twitter.svg";
import insta from "../../../assets/images/insta.svg";
import tiktok from "../../../assets/images/tiktok.svg";
import ImgsSlider from "../../GlobalComponents/UI/ImgsSlider/ImgsSlider";
import BusinessInfo from "./BusinessInfo/BusinessInfo";
import SocialLinks from "./SocialLinks/SocialLinks";
import Loader from "./Loader/Loader";
import { useReactiveVar } from "@apollo/client";
import { editView, isAdmin, isEdit } from "../../../utils/cache";
import EditBtns from "../../GlobalComponents/Buttons/EditBtns/EditBtns";
import HeaderBackBtn from "../../GlobalComponents/Admin/HeaderBackBtn";

const Header = (props) => {
  const [businessImages, setBusinessImages] = useState([]);
  const [businessSocial, setBusinessSocial] = useState([]);
  const [itemType, setItemType] = useState("");
  const { businessData, loading, isEditPage } = props;
  const editBusiness = useReactiveVar(isEdit);
  const isEditing = useReactiveVar(editView);
  const admin = useReactiveVar(isAdmin);
  useEffect(() => {
    if (businessData) {
      setBusinessImages([
        businessData?.featuredImage,
        ...businessData?.randomImages,
      ]);
      setBusinessSocial([
        {
          socialLink: businessData?.facebook,
          icon: facebook,
          social: "facebook",
        },
        { socialLink: businessData?.twitter, icon: twitter, social: "twitter" },
        {
          socialLink: businessData?.instagram,
          icon: insta,
          social: "instagram",
        },
        { socialLink: businessData?.tiktok, icon: tiktok, social: "tiktok" },
      ]);
      setItemType(businessData?.__typename?.toLowerCase());
    }
  }, [businessData]);
  return (
    <section
      className={`${styles.headerSection} ${
        editBusiness ? styles.edit : styles.regular
      } `}
    >
      {!loading && isEditPage && (
        <EditBtns variant={styles.editBtn} isBusiness id={businessData?.id} />
      )}
      {admin && !isEditing && <HeaderBackBtn isEdit backRoute={-1} />}
      <div className={styles.whiteBg} />
      <div className={styles.wrapper}>
        {businessImages.length <= 0 || loading ? (
          <Loader />
        ) : (
          <div
            className={`${styles.content} ${
              editBusiness ? styles.editContentView : styles.regularContent
            }`}
          >
            <ImgsSlider
              sliderImages={businessImages}
              website={businessData?.website}
              containerClass={styles.container}
              elementClass={styles.element}
              imgClass={styles.busImg}
              btnClass={styles.visit}
              itemType={itemType}
            />
            <SocialLinks links={businessSocial} itemType={itemType} />
            <BusinessInfo businessData={businessData} itemType={itemType} />
          </div>
        )}
      </div>
    </section>
  );
};

export default Header;
Header.propTypes = {
  businessData: PropTypes.object,
  loading: PropTypes.bool,
  isEditPage: PropTypes.bool,
};
