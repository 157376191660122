import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import CollectionImage from "../../Collections/CollectionsPopUp/CollectionImage/CollectionImage";
import styles from "./CollectionCard.module.css";

export default function CollectionCard({ collection }) {
  return (
    <NavLink
      to={`/collections/${collection?.id}`}
      key={collection?.id}
      className={styles.collectionCard}
    >
      <div className={styles.collection}>
        <CollectionImage
          imgSrc={collection?.image?.path}
          variant={styles.collectionImage}
        />
        <p className={styles.name}>{collection?.name}</p>
      </div>
    </NavLink>
  );
}

CollectionCard.propTypes = {
  collection: PropTypes.object,
};
