import React, { useState, useEffect } from "react";
import styles from "../SearchBar.module.css";
import PropTypes from "prop-types";
import Input from "../../../UI/Input/Input";
import searchIcon from "../../../../../assets/images/searchIcon.svg";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import search from "../../../../../graphql/queries/search/search";
import SearchList from "../SearchList/SearchList";
import { BUSINESS_STATUS } from "../../../../../constants";
const LIST_ITEMS_SIZE = 3;
const EditorSearchBar = (props) => {
  const {
    variant,
    inpContainer,
    placeholder,
    closeSideDrawer,
    isEditorSearch,
    selectHandler,
    filters,
    hideList,
    itemsSize,
    initText,
    getSearchTerm,
  } = props;
  const [searchText, setSearchText] = useState("");
  const [showList, setShowList] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [cursor, setCursor] = useState(-1);
  const [getSearch, { loading, data }] = useLazyQuery(search, {
    variables: {
      text: searchText,
      size: itemsSize ? itemsSize : LIST_ITEMS_SIZE,
      page: 1,
      filters: {
        typesAndCategories: {
          types: filters,
          status: [BUSINESS_STATUS.APPROVED],
        },
      },
    },
  });
  useEffect(() => {
    if (searchText.length > 1) {
      const delayDebounceFn = setTimeout(() => {
        getSearch().then(({ data }) => setSearchItems(data?.search?.data));
        getSearchTerm(searchText);
      }, 500);
      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [searchText, filters, initText]);

  const searchHandler = (e) => {
    setSearchText(e.target.value);
    setCursor(-1);
    setShowList(false);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length > 1 && !loading && data) {
        setShowList(true);
      } else {
        setShowList(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText, loading, data]);

  const showListHandler = () => {
    if (searchText.length > 1 && !loading) setShowList(true);
  };
  const hideListHandler = () => {
    setShowList(false);
  };

  const clearText = () => {
    setSearchText("");
    closeSideDrawer && closeSideDrawer();
  };

  const keyDownHandler = (e) => {
    if (
      e.key === "Enter" &&
      searchText &&
      cursor > -1 &&
      cursor < searchItems?.length
    ) {
      const item = searchItems[cursor];
      selectHandler(item);
      clearText();
    }
    if (e.key === "ArrowDown" && showList && cursor < searchItems?.length) {
      setCursor((prev) => prev + 1);
    }
    if (e.key === "ArrowUp" && showList && cursor > -1) {
      setCursor((prev) => prev - 1);
    }
  };
  return (
    <Input
      type='text'
      placeholder={
        placeholder ? placeholder : "Search for anything black-owned..."
      }
      className={variant}
      value={searchText}
      changeHandler={searchHandler}
      inpContainerClass={inpContainer}
      blurHandler={hideListHandler}
      focusHandler={showListHandler}
      keyDownHandler={keyDownHandler}
    >
      <img
        src={searchIcon}
        alt='search'
        className={isEditorSearch ? styles.adminSearchIcon : styles.searchIcon}
      />
      {showList && !hideList && (
        <SearchList
          searchItems={searchItems}
          loading={loading}
          searchTerm={searchText}
          listToggleHandler={hideListHandler}
          clearText={clearText}
          cursor={cursor}
          isEditorSearch={isEditorSearch}
          selectHandler={selectHandler}
        />
      )}
    </Input>
  );
};

export default EditorSearchBar;

EditorSearchBar.propTypes = {
  variant: PropTypes.string,
  inpContainer: PropTypes.string,
  placeholder: PropTypes.string,
  closeSideDrawer: PropTypes.func,
  selectHandler: PropTypes.func,
  isEditorSearch: PropTypes.bool,
  filters: PropTypes.array,
  hideList: PropTypes?.bool,
  itemsSize: PropTypes.number,
  initText: PropTypes.string,
  getSearchTerm: PropTypes.func,
};
