import React, { useState, useEffect } from "react";
import Input from "../../../GlobalComponents/UI/Input/Input";
import styles from "../Nominations.module.css";
import PropTypes from "prop-types";
import { useMutation, useReactiveVar } from "@apollo/client";
import upvoteBusinessAward from "../../../../graphql/mutations/upvoteBusinessAward";
import Spinner from "../../../GlobalComponents/UI/Spinner/Spinner";
import { errorToastHandler } from "../../../../utils";
import Backdrop from "../../../GlobalComponents/UI/Backdrop/Backdrop";
import RegisterPopUp from "../../../GlobalComponents/UI/RegisterPopUp/RegisterPopUp";
import { authVar, beyotaSelected } from "../../../../utils/cache";
import { useNavigate } from "react-router-dom";
const AwardUpvoteBtn = (props) => {
  const { nominee, cachedVoted, refetch } = props;
  const [showBackdrop, setShowBackdrop] = useState(false);
  const isAuth = useReactiveVar(authVar);
  const isSelected = useReactiveVar(beyotaSelected);
  const navigate = useNavigate();
  const [upvoteNomination, { loading }] = useMutation(upvoteBusinessAward, {
    update: (cache, res) => {
      cache.modify({
        id: `${nominee?.__typename}:${nominee?.id}`,
        fields: {
          isVotedAward(cachedIsVoted) {
            return !cachedIsVoted;
          },
        },
      });
      cache.modify({
        id: `${nominee?.__typename}:${cachedVoted}`,
        fields: {
          isVotedAward(cachedIsVoted) {
            return !cachedIsVoted;
          },
        },
      });
    },
  });
  useEffect(() => {
    if (!isSelected) {
      refetch();
    }
  }, [isSelected]);

  const toggleBackdrop = (e) => {
    setShowBackdrop(!showBackdrop);
    e.stopPropagation();
    e.preventDefault();
  };

  const upvoteHandler = () => {
    if (!isAuth) {
      setShowBackdrop(true);
      navigate("/awards2022#nominations");
      return;
    }

    upvoteNomination({ variables: { businessAwardId: nominee?.id } }).catch(
      (err) => errorToastHandler()
    );
  };
  return (
    <>
      <Backdrop show={showBackdrop} clickHandler={toggleBackdrop}>
        <RegisterPopUp
          closeBackdrop={toggleBackdrop}
          heading={"Sign up to Vote!"}
        />
      </Backdrop>

      {!loading ? (
        <Input
          elementType='radio'
          inpContainerClass={`${styles.checkboxContainer} ${
            loading && styles.loadingContainer
          }`}
          name='award upvote'
          checked={nominee?.isVotedAward}
          id={nominee?.name}
          changeHandler={upvoteHandler}
          customCheckBox={styles.checkMark}
        />
      ) : (
        <div className={styles.loaderContainer}>
          <Spinner variant={styles.loader} />
        </div>
      )}
    </>
  );
};

export default AwardUpvoteBtn;
AwardUpvoteBtn.propTypes = {
  nominee: PropTypes.object,
  cachedVoted: PropTypes.string,
  refetch: PropTypes.func,
};
