import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "../../components/GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import { businessWebsiteValidation } from "../../utils/validationRules";
import useInput from "../../hooks/useInput";
import Input from "../../components/GlobalComponents/UI/Input/Input";


const BusinessSocials = (props) => {
  const { instagram, facebook , twitter, tiktok, updateVariables } = props;
  const {
    value: instagramURL,
    hasError: instagramURLError,
    errorMessage: instagramURLErrorMessage,
    valueChangeHandler: instagramURLHandler,
    valueBlurHandler: instagramURLBlurHandler,
    isValid: instagramURLValid,
  } = useInput(businessWebsiteValidation, instagram);
  const {
    value: facebookURL,
    hasError: facebookURLError,
    errorMessage: facebookURLErrorMessage,
    valueChangeHandler: facebookURLHandler,
    valueBlurHandler: facebookURLBlurHandler,
    isValid: facebookURLValid,
  } = useInput(businessWebsiteValidation,facebook);
  const {
    value: twitterURL,
    hasError: twitterURLError,
    errorMessage: twitterURLErrorMessage,
    valueChangeHandler: twitterURLHandler,
    valueBlurHandler: twitterURLBlurHandler,
    isValid: twitterURLValid,
  } = useInput(businessWebsiteValidation, twitter);
  const {
    value: tiktokURL,
    hasError: tiktokURLError,
    errorMessage: tiktokURLErrorMessage,
    valueChangeHandler: tiktokURLHandler,
    valueBlurHandler: tiktokURLBlurHandler,
    isValid: tiktokURLValid,
  } = useInput(businessWebsiteValidation, tiktok);

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    if (
      (!instagramURLValid && instagramURL) ||
      (!facebookURLValid && facebookURL) ||
      (!twitterURLValid && twitterURL) ||
      (!tiktokURLValid && tiktokURL)
    ) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
      updateVariables({
          instagram: instagramURL,
          facebook: facebookURL,
          twitter: twitterURL,
          tiktok: tiktokURL
      })
    }
  }, [
    instagramURLValid,
    facebookURLValid,
    twitterURLValid,
    tiktokURLValid,
    instagramURL,
    facebookURL,
    twitterURL,
    tiktokURL,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
    nextStepHandler();
    insertUserData({
      instagramURL,
      facebookURL,
      twitterURL,
      tiktokURL,
    });
  };

  return (
    <>
      <Input
        hasLabel={true}
        label="INSTAGRAM LINK"
        placeholder="e.g. www.instagram.com/business_name"
        type="text"
        inpContainerClass={`${styles.fullWidthContainer} ${
          instagramURLError && styles.invalid
        }`}
        value={instagramURL}
        changeHandler={instagramURLHandler}
        blurHandler={instagramURLBlurHandler}
        invalid={instagramURLError}
        errorMessage={instagramURLErrorMessage}
      />
      <Input
        hasLabel={true}
        label="FACEBOOK LINK"
        placeholder="e.g. www.facebook.com/business_name"
        type="text"
        inpContainerClass={`${styles.fullWidthContainer} ${
          facebookURLError && styles.invalid
        }`}
        value={facebookURL}
        changeHandler={facebookURLHandler}
        blurHandler={facebookURLBlurHandler}
        invalid={facebookURLError}
        errorMessage={facebookURLErrorMessage}
      />
      <Input
        hasLabel={true}
        label="TWITTER LINK"
        placeholder="e.g. www.twitter.com/business_name"
        type="text"
        inpContainerClass={`${styles.fullWidthContainer} ${
          twitterURLError && styles.invalid
        }`}
        value={twitterURL}
        changeHandler={twitterURLHandler}
        blurHandler={twitterURLBlurHandler}
        invalid={twitterURLError}
        errorMessage={twitterURLErrorMessage}
      />
      <Input
        hasLabel={true}
        label="TIK TOK LINK"
        placeholder="e.g. www.tiktok.com/business_name"
        type="text"
        inpContainerClass={`${styles.fullWidthContainer} ${
          tiktokURLError && styles.invalid
        }`}
        value={tiktokURL}
        changeHandler={tiktokURLHandler}
        blurHandler={tiktokURLBlurHandler}
        invalid={tiktokURLError}
        errorMessage={tiktokURLErrorMessage}
      />
    </>
  );
};
export default BusinessSocials;
BusinessSocials.propTypes = {
  instagram: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  tiktok: PropTypes.string,
  updateVariables: PropTypes.func

};
