import React from "react";
import PropTypes from "prop-types";
import useInput from "../../../hooks/useInput";
import Input from "../../GlobalComponents/UI/Input/Input";
import { businessWebsiteValidation } from "../../../utils/validationRules";
import styles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import updateBusiness from "../../../graphql/mutations/updateBusiness";
import { useMutation } from "@apollo/client";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { BusinessOnboardingState } from "../../../constants";
import { savingBizInfoState } from "../../../utils/cache";

const BusinessWebsite = (props) => {
  const { insertBusinessData, nextStepHandler, businessData } = props;
  const {
    value: businessWebsite,
    hasError: businessWebsiteError,
    errorMessage: businessWebsiteErrorMessage,
    valueChangeHandler: businessWebsiteHandler,
    valueBlurHandler: businessWebsiteBlurHandler,
    isValid: businessWebsiteValid,
  } = useInput(businessWebsiteValidation, businessData?.website);
  const [updateBiz, { loading }] = useMutation(updateBusiness);

  const formIsValid = businessWebsiteValid || !businessWebsite;

  const submitHandler = (e) => {
    e.preventDefault();
    if (businessData?.website === businessWebsite) {
      nextStepHandler();
      return;
    }
    savingBizInfoState({ loading: true, error: false });
    analytics.track("business_website");

    updateBiz({
      variables: {
        id: businessData?.id,
        website: businessWebsite,
        onboardingState: BusinessOnboardingState.businessTypes, // next step
      },
    })
      .then(() => {
        savingBizInfoState({ loading: false });

        insertBusinessData({
          website: businessWebsite,
        });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };

  return (
    <form className={styles.registerForm} onSubmit={submitHandler}>
      <h2 className={`${styles.altHeader} ${styles.altHeaderWithDescription}`}>
        Do you have a business website?
      </h2>
      <p className={styles.headingDescription}>
        Tell customers where they can learn more about your business
      </p>

      <Input
        hasLabel={true}
        label='BUSINESS WEBSITE'
        placeholder='e.g. www.yourwebsite.com'
        type='text'
        inpContainerClass={`${styles.fullWidthContainer} ${
          businessWebsiteError && styles.invalid
        }`}
        value={businessWebsite}
        changeHandler={businessWebsiteHandler}
        blurHandler={businessWebsiteBlurHandler}
        invalid={businessWebsiteError}
        errorMessage={businessWebsiteErrorMessage}
      />
      <RegisterNextBtn
        variant={styles.continueBtn}
        disabled={!formIsValid}
        isLoading={loading}
      >
        {loading ? <Spinner /> : "SAVE & CONTINUE"}
      </RegisterNextBtn>
    </form>
  );
};
export default BusinessWebsite;
BusinessWebsite.propTypes = {
  insertBusinessData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  businessData: PropTypes.object,
};
