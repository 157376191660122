import React, { useState, useEffect } from "react";
import { useReactiveVar, useQuery, useLazyQuery } from "@apollo/client";
import { useParams, useLocation, Navigate } from "react-router-dom";
import {
  authVar,
  businessListingStatus,
  savingBizInfoState,
  userLeftListing,
} from "../../utils/cache";
import fetchBusiness from "../../graphql/queries/pages/business";
import BackButton from "../../components/GlobalComponents/Buttons/BackButton/BackButton";
import BusinessRegisterSteps from "../../components/BusinessRegister/BusinessRegisterSteps/BusinessRegisterSteps";
import BusinessGettingStarted from "../../components/BusinessOnboarding/BusinessGettingStarted";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";
import UploadID from "../../components/BusinessOnboarding/UploadID";
import { BusinessOnboardingState } from "../../constants";
import styles from "./BusinessOnboarding.module.css";
import { isOnlineBusiness } from "../../utils";
import getCategories from "../../graphql/queries/mainCategories";
const BusinessRegister = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const [getBusiness, { loading: loadingBusiness, client }] = useLazyQuery(
    fetchBusiness,
    { notifyOnNetworkStatusChange: true }
  );
  const listingStatus = useReactiveVar(businessListingStatus);
  const isNewBusiness = pathname === "/create-business";
  const { data: mainCategories } = useQuery(getCategories, {
    variables: { size: 200, subCategoryPage: 1, subCategorySize: 6 },
  });
  const [businessData, setBusinessData] = useState();
  const [registerStepIndex, setRegisterStepIndex] = useState(0);
  const businessId = businessData ? businessData.id : id;
  const userSavedData = useReactiveVar(authVar);
  const nextStepHandler = () => {
    setRegisterStepIndex((registerIndex) => registerIndex + 1);
  };
  const updateBusiness = () => {
    if (businessId) {
      getBusiness({
        variables: {
          id: businessId,
          categoriesChildrenPage: 1,
          categoriesChildrenSize: 6,
        },
      }).then(({ data }) => {
        if (!businessData) {
          mapOnboardingState(
            data.business.onboardingState,
            data.business.representative.isNewUser
          );
        }
        mapBusinessData(data.business);
      });
    }
  };

  const mapOnboardingState = (onboardingState, isNewUser = true) => {
    if (!isNewUser || onboardingState === BusinessOnboardingState.disapprove) {
      setRegisterStepIndex(0);
      return;
    }
    const stepIndex = Object.keys(BusinessOnboardingState).findIndex(
      (el) => el === onboardingState
    );
    if (onboardingState === BusinessOnboardingState?.paymentMethod) {
      setRegisterStepIndex(stepIndex - 1);
    } else {
      setRegisterStepIndex(stepIndex);
    }
  };
  useEffect(() => {
    if (!businessData && businessId) {
      updateBusiness();
    }
  }, [businessData]);

  useEffect(() => {
    const businesses = userSavedData?.businesses?.data;
    if (businesses?.length === 1 && !businessData && !isNewBusiness) {
      const currentBusiness = businesses[0];
      mapBusinessData(currentBusiness);
      mapOnboardingState(
        currentBusiness.onboardingState,
        currentBusiness.representative.isNewUser
      );
    }
  }, [userSavedData]);
  const insertBusinessData = (data) => {
    setBusinessData({ ...businessData, ...data });
  };
  const previousStepHandler = () => {
    // remove subcategories
    if (registerStepIndex === 10) {
      const mappedMainCategories = businessData?.categories?.map((category) =>
        mainCategories?.mainCategories?.data?.find(
          (el) => el?.id === category?.id
        )
      );
      const selectedMainCategories = mappedMainCategories?.filter((el) => el);
      const businessCategoriesNames = selectedMainCategories?.map(
        (category) => category?.name
      );
      const businessCategories = selectedMainCategories?.map(
        (category) => category?.id
      );
      insertBusinessData({ businessCategoriesNames, businessCategories });
    }
    setRegisterStepIndex((registerIndex) => registerIndex - 1);
  };

  const mapBusinessData = (data) => {
    setBusinessData({
      ...data,
      businessCategoriesNames: data?.categories?.map(
        (category) => category.name
      ),
      isOnline: isOnlineBusiness(data?.addrLine1, data?.businessTypes),
      city: data?.city?.id,
      state: data?.state?.id,
    });
  };

  useEffect(() => {
    businessListingStatus({ ...listingStatus, isStarted: true });
    return () => {
      userLeftListing(true);
      savingBizInfoState(null);
    };
  }, []);

  if (userSavedData && !userSavedData?.subscribed && registerStepIndex === 0) {
    return <Navigate to='/select-tier' />;
  }
  if (!userSavedData || (id && !businessData))
    return <Spinner variant={styles.pageLoader} />;

  return (
    <div className={styles.registerPage}>
      <div className={styles.progressBarContainer}>
        <div
          className={styles.filledProgressBar}
          style={{ width: `${(registerStepIndex / 14) * 100}%` }}
        />
      </div>
      <div className={styles.registerContainer}>
        {registerStepIndex >= 0 && registerStepIndex < 9 && (
          <BusinessRegisterSteps
            registerStepIndex={registerStepIndex}
            insertBusinessData={insertBusinessData}
            nextStepHandler={nextStepHandler}
            businessData={businessData}
            previousStepHandler={previousStepHandler}
          />
        )}
        <div className={styles.restSteps}>
          {registerStepIndex > 13 && registerStepIndex <= 14 && (
            <div className={styles.btnContainer}>
              <BackButton
                variant={styles.backBtn}
                backHandler={previousStepHandler}
              />
            </div>
          )}

          {registerStepIndex > 8 && registerStepIndex <= 13 && (
            <BusinessGettingStarted
              nextStepHandler={nextStepHandler}
              previousStepHandler={previousStepHandler}
              registerStepIndex={registerStepIndex}
              insertBusinessData={insertBusinessData}
              businessData={businessData}
              mapOnboardingState={mapOnboardingState}
            />
          )}

          {registerStepIndex === 14 && (
            <UploadID
              nextStepHandler={nextStepHandler}
              businessData={businessData}
              insertBusinessData={insertBusinessData}
              handleUpdateBusiness={updateBusiness}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessRegister;
