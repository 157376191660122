import { gql } from "@apollo/client";

export default gql`
  mutation deleteService($id: ID!) {
    deleteService(id: $id) {
      id
      business {
        id
      }
    }
  }
`;
