import React, { useEffect, useState } from "react";
import Input from "../../UI/Input/Input";
import RegisterNextBtn from "../RegisterNextBtn/RegisterNextBtn";
import styles from "./SignUpPicture.module.css";
import PropTypes from "prop-types";
import imageUpload from "../../../../graphql/mutations/imageUpload";
import { useMutation } from "@apollo/client";
import Spinner from "../../UI/Spinner/Spinner";
import useImageHandler from "../../../../utils/useImageHandler";

const SignUpPicture = (props) => {
  const { submitHandler, isBusiness, requestLoading } = props;
  const [profilePicture, setProfilePicture] = useState("");
  const [preview, setPreview] = useState("");
  const [uploadProfilePicture, { loading, data, error }] =
    useMutation(imageUpload);

  const {
    errorMessage,
    images,
    changeHandler,
    dragHandler,
    dropHandler,
    resizing,
  } = useImageHandler(315, 315, 1);

  const isLoading = requestLoading || loading || resizing;

  useEffect(() => {
    if (images.length > 0) {
      const newFiles = images.map((file) => ({
        image: file,
        path: "applications",
      }));
      uploadProfilePicture({ variables: { files: newFiles } }).then(
        ({ data }) => {
          setPreview(data.uploadImages[0].path);
        }
      );
    }
  }, [images]);

  const removeHandler = () => {
    setPreview("");
    setProfilePicture("");
  };

  const clickHandler = () => {
    if (isBusiness && preview) {
      submitHandler(data.uploadImages[0].id);
    } else {
      submitHandler();
    }
  };

  return (
    <div className={styles.registerProfilePicture}>
      <h6 className={styles.header}>Upload profile picture</h6>
      <p className={styles.inpLabel}>
        Upload a photo of you or drag it into the circle
      </p>
      {preview ? (
        <img
          src={preview}
          alt='profile picture'
          className={styles.profileImage}
        />
      ) : (
        <Input
          elementType='file'
          value={profilePicture}
          changeHandler={changeHandler}
          inpContainerClass={`${styles.inpContainer} ${
            (error || errorMessage) && styles.invalid
          }`}
          type='file'
          fileLabel='DRAG AND DROP PHOTO HERE'
          handleDrag={dragHandler}
          handleDrop={dropHandler}
          invalid={error || errorMessage ? true : false}
          errorMessage={errorMessage}
          isUploadProfile
        />
      )}
      <div className={styles.btns}>
        {preview && (
          <button className={styles.uploadBtn} onClick={removeHandler}>
            REMOVE PHOTO
          </button>
        )}
        <RegisterNextBtn
          variant={styles.nextBtn}
          clickHandler={clickHandler}
          isLoading={isLoading}
        >
          {isLoading ? <Spinner /> : "NEXT"}
        </RegisterNextBtn>
      </div>
    </div>
  );
};

export default SignUpPicture;
SignUpPicture.propTypes = {
  submitHandler: PropTypes.func,
  requestLoading: PropTypes.bool,
  isBusiness: PropTypes.bool,
};
