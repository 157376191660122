import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./AddressInp.module.css";
import Input from "../../../GlobalComponents/UI/Input/Input";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import FullScreenToggle from "./FullScreenToggle";
import plus from "../../../../assets/images/boldPlus.svg";
import minus from "../../../../assets/images/boldMinus.svg";
import { searchUserLocation } from "../../../../utils/cache";
const AddressInp = (props) => {
  const { fullScreenHandler, zoomOutHandler, zoomInHandler, fullScreen } =
    props;

  const [selectedAddress, setSelectedAddress] = useState("");
  const [addressLocation, setAddressLocation] = useState();
  const [error, setError] = useState(false);

  const businessAddressHandler = (e) => {
    if (e !== null && e !== undefined) setSelectedAddress(e);
    setError("");
  };
  const addressSelectHandler = async (address, placeId) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    businessAddressHandler(place?.formatted_address);
    setAddressLocation(latLng);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    searchUserLocation(addressLocation);
    setSelectedAddress("");
    setError("");
  };
  const placesErrorHandler = (status, clearSuggestions) => {
    setError("Location Not Found");
    clearSuggestions();
  };
  return (
    <div className={styles.controlsFormContainer} onSubmit={submitHandler}>
      <div className={styles.controls}>
        <button onClick={fullScreenHandler}>
          <FullScreenToggle fullScreen={fullScreen} />
        </button>
        <button onClick={zoomInHandler}>
          <img src={plus} className={styles.plus} alt='zoom in' />
        </button>
        <button onClick={zoomOutHandler}>
          <img src={minus} className={styles.minus} alt='zoom out' />
        </button>
      </div>
      <form className={styles.formContainer}>
        <Input
          hasLabel={false}
          placeholder='Search by city, state or zip code'
          elementType='places'
          inpContainerClass={styles.inpContainer}
          value={selectedAddress}
          selectHandler={addressSelectHandler}
          changeHandler={businessAddressHandler}
          placesContainerClass={styles.placesContainerClass}
          placesErrorHandler={placesErrorHandler}
          invalid={error ? true : false}
          errorMessage={error ? error : null}
        />
        <button className={styles.submitBtn} type='submit'>
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default AddressInp;
AddressInp.propTypes = {
  fullScreenHandler: PropTypes.func,
  zoomInHandler: PropTypes.func,
  zoomOutHandler: PropTypes.func,
  fullScreen: PropTypes.bool,
};
