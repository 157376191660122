import React from "react";
import styles from "./CloseIconBtn.module.css";
import PropTypes from "prop-types";
import closeIcon from "../../../../assets/images/closeIcon.png";
const CloseIconBtn = (props) => {
  const { variant, clickHandler } = props;
  return (
    <button className={`${styles.closeBtn} ${variant}`} onClick={clickHandler}>
      <img src={closeIcon} alt='closeIcon' className={styles.close} />
    </button>
  );
};

export default CloseIconBtn;
CloseIconBtn.propTypes = {
  variant: PropTypes.string,
  clickHandler: PropTypes.func,
};
