import React from "react";
import PropTypes from "prop-types";
import TableHeader from "../../GlobalComponents/Admin/AdminTableHeader";
import AdminRow from "./AdminRow";
import tableStyles from "../../GlobalComponents/Admin/AdminTableStyles/Table.module.css";

export default function AdminManageTeamTable({
  admins,
  handleSort,
  sortOrder,
  handleToggleRefetch,
}) {
  return (
    <table className={tableStyles.tableContainer}>
      <TableHeader
        columns={["USER NAME", "ROLE"]}
        handleSort={handleSort}
        sortOrder={sortOrder}
        cellClassName={tableStyles.threeColumnsCell}
      />
      <tbody>
        {admins?.map((admin) => (
          <AdminRow
            admin={admin}
            key={admin.id}
            handleToggleRefetch={handleToggleRefetch}
          />
        ))}
      </tbody>
    </table>
  );
}

AdminManageTeamTable.propTypes = {
  admins: PropTypes.array,
  handleSort: PropTypes.func,
  sortOrder: PropTypes.string,
  handleToggleRefetch: PropTypes.func,
};
