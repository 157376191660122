import React from "react";
import PropTypes from "prop-types";
import Input from "../../../components/GlobalComponents/UI/Input/Input";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import styles from "./BusinessApplication.module.css";
export default function BusinessNameSection({
  businessName,
  businessNameHandler,
  businessNameBlurHandler,
  businessNameError,
  businessNameErrorMessage,
}) {
  return (
    <div className={styles.section}>
      <AdminTitle title='Business name' />
      <div className={styles.group}>
        <Input
          hasLabel
          label='your business name'
          isRequired
          placeholder='e.g. Jamie Washington'
          type='text'
          inpContainerClass={styles.textField}
          value={businessName}
          changeHandler={businessNameHandler}
          blurHandler={businessNameBlurHandler}
          invalid={businessNameError}
          errorMessage={businessNameErrorMessage}
        />
      </div>
    </div>
  );
}

BusinessNameSection.propTypes = {
  businessName: PropTypes.string,
  businessNameHandler: PropTypes.func,
  businessNameBlurHandler: PropTypes.func,
  businessNameError: PropTypes.bool,
  businessNameErrorMessage: PropTypes.string,
};
