import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import styles from "./AddToCollectionButton.module.css";
import { useMutation, useReactiveVar } from "@apollo/client";
import Spinner from "../../UI/Spinner/Spinner";
import { authVar } from "../../../../utils/cache";
import SvgIcon from "./SvgIcon/SvgIcon";
import Backdrop from "../../UI/Backdrop/Backdrop";
import RegisterPopUp from "../../UI/RegisterPopUp/RegisterPopUp";
import CollectionsPopUp from "../../../Collections/CollectionsPopUp/CollectionsPopUp";
import addItemToCollection from "../../../../graphql/mutations/collections/addItemToCollection";
import CreateNewCollection from "../../../Collections/CollectionsPopUp/CreateNewCollection/CreateNewCollection";
import removeItemFromCollection from "../../../../graphql/mutations/collections/removeItemFromCollection";
import useUpdateUser from "../../../../hooks/useUpdateUser";
import { ITEM_TYPES } from "../../../../constants";
import { sendEventsHandler, errorToastHandler } from "../../../../utils";

const AddToCollectionButton = (props) => {
  const { variant, storyBookAction, addedToCollection, item } = props;
  const [addToCollection, { loading: addLoading }] = useMutation(
    addItemToCollection,
    {
      update: (cache, res) => {
        cache.modify({
          id: `${item?.__typename}:${item?.id}`,
          fields: {
            isBookmarked() {
              return res?.data?.addItemsToCollection[0]?.isBookmarked;
            },
          },
        });
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const [removeFromCollection, { loading: removeLoading }] = useMutation(
    removeItemFromCollection,
    {
      update: (cache, res) => {
        cache.modify({
          id: `${item?.__typename}:${item?.id}`,
          fields: {
            isBookmarked() {
              return res?.data?.removeItemsFromCollection[0]?.isBookmarked;
            },
          },
        });
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const { updateUser, userLoading } = useUpdateUser();

  const [active, setActive] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showCollections, setShowCollections] = useState(false);
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [itemCollections, setItemCollections] = useState([]);
  const toggleBackdrop = (e) => {
    setShowBackdrop(!showBackdrop);
    e.stopPropagation();
    e.preventDefault();
    setShowCollections(false);
    setShowCreateCollection(false);
  };

  const isAuth = useReactiveVar(authVar);

  useEffect(() => {
    setActive(addedToCollection);
  }, [addedToCollection]);
  useEffect(() => {
    if (item) {
      setItemCollections(item?.collections?.data);
    }
  }, [item]);

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowBackdrop(true);
    if (isAuth) {
      setShowCollections(true);
      storyBookAction && storyBookAction();
    }
  };

  const toggleCreateNewCollection = () => {
    setShowCreateCollection(!showCreateCollection);
    setShowCollections(false);
  };

  const AddItem = (addToArr, removeFrom) => {
    setShowCollections(false);
    setShowBackdrop(false);

    if (addToArr?.length > 0)
      addToCollection({
        variables: {
          items: addToArr,
        },
      })
        .then(({ data }) => {
          setActive(data?.addItemsToCollection[0]?.isBookmarked);
          setItemCollections(data?.addItemsToCollection[0]?.collections?.data);
          setShowCreateCollection(false);
          if (item?.__typename === ITEM_TYPES.BUSINESS) {
            analytics.track("business_listing_saved");
          } else if (
            item?.__typename === ITEM_TYPES.PRODUCT ||
            item?.__typename === ITEM_TYPES.SERVICE
          ) {
            analytics.track("product_or_service_saved");
          }
          sendEventsHandler(
            isAuth,
            `${item?.__typename?.toLowerCase()}_saved`,
            item
          );

          updateUser();
        })
        .catch(() => errorToastHandler());
    if (removeFrom?.length > 0)
      removeFromCollection({ variables: { items: removeFrom } })
        .then(({ data }) => {
          setActive(data?.removeItemsFromCollection[0]?.isBookmarked);
          setItemCollections(
            data?.removeItemsFromCollection[0]?.collections?.data
          );
          setShowCreateCollection(false);
          updateUser();
        })
        .catch(() => errorToastHandler());
  };

  return (
    <>
      <Backdrop show={showBackdrop} clickHandler={toggleBackdrop}>
        {!isAuth && <RegisterPopUp closeBackdrop={toggleBackdrop} />}
        {showCollections && (
          <CollectionsPopUp
            addToCollection={AddItem}
            item={item}
            createPopupToggle={toggleCreateNewCollection}
            incomingCollections={itemCollections}
          />
        )}
        {showCreateCollection && (
          <CreateNewCollection addToCollection={AddItem} item={item} />
        )}
      </Backdrop>
      <button
        onClick={handleClick}
        className={`${styles.addToCollectionBtn} ${variant} ${
          active && styles.active
        } ${(addLoading || removeLoading || userLoading) && styles.loadingBg} `}
        aria-label='add to collection'
      >
        {addLoading || removeLoading || userLoading ? (
          <Spinner variant={styles.loader} />
        ) : (
          <SvgIcon active={active} />
        )}
      </button>
    </>
  );
};

export default memo(AddToCollectionButton);

AddToCollectionButton.propTypes = {
  variant: PropTypes.string,
  storyBookAction: PropTypes.func,
  addToCollectionHandler: PropTypes.func,
  addedToCollection: PropTypes.bool,
  itemId: PropTypes.number,
  itemType: PropTypes.string,
  icon: PropTypes.string,
  item: PropTypes.any,
};
