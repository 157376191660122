import React from "react";
import styles from "./Tooltip.module.css";
import closeIcon from "../../../../assets/images/close.svg";
import PropTypes from "prop-types";
const Tooltip = (props) => {
  const {
    show,
    children,
    noCloseButton,
    anchorVariant,
    handleTooltipClose,
    customClass,
  } = props;
  const fetchAnchorClass = (anchorVariant) => {
    switch (anchorVariant) {
      case "top":
        return styles.topAnchor;
      case "top-right":
        return `${styles.topAnchor} ${styles.topRightAnchor}`;
      case "bottom":
        return styles.bottomAnchor;
      default:
        return "";
    }
  };

  return show ? (
    <div
      className={`${styles.tooltipContainer} ${
        anchorVariant?.includes("bottom") && styles.arrowDownTooltip
      } ${fetchAnchorClass(anchorVariant)} ${customClass}`}
    >
      {!noCloseButton && (
        <img
          src={closeIcon}
          className={styles.closeIcon}
          onClick={handleTooltipClose}
          alt='close'
        />
      )}
      <div className={styles.tooltipBody}>{children}</div>
    </div>
  ) : null;
};

export default Tooltip;
Tooltip.propTypes = {
  show: PropTypes.bool,
  customClass: PropTypes.string,
  anchorVariant: PropTypes.string,
  handleTooltipClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  noCloseButton: PropTypes.bool,
};
