import React from "react";
import { useReactiveVar } from "@apollo/client";
import { authVar } from "../../utils/cache";
import ProfileHeader from "../../components/ConsumerProfile/ProfileHeader";
import UpVotedSection from "../../components/ConsumerProfile/UpVotedSection";
import CommentsSection from "../../components/ConsumerProfile/CommentsSection";
import CollectionsSection from "../../components/ConsumerProfile/CollectionsSection";
import styles from "./ConsumerProfile.module.css";
import PropTypes from "prop-types";
import { Navigate } from "react-router";
export default function Profile({ hideUpvoted, hideComments }) {
  const user = useReactiveVar(authVar);

  return (
    <div className={styles.profileContainer}>
      <ProfileHeader firstName={user?.firstName} lastName={user?.lastName} />
      <div className={styles.middleSection}>
        {!hideUpvoted && (
          <UpVotedSection
            upVotedItems={user?.upvotedItems?.data}
            hasMoreItems={user?.upvotedItems?.data?.length > 4}
            commentsHidden={hideComments}
          />
        )}
        {!hideComments && (
          <CommentsSection
            comments={user?.comments?.data}
            hasMoreComments={user?.comments?.data?.length > 4}
            upvotedHidden={hideUpvoted}
          />
        )}
      </div>
      <CollectionsSection collections={user?.collections?.data} />
    </div>
  );
}
Profile.propTypes = {
  hideUpvoted: PropTypes.bool,
  hideComments: PropTypes.bool,
};
