import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tags from "../../GlobalComponents/UI/Tags/Tags";
import styles from "./CategoryRow.module.css";

export default function CategoryRow({
  category,
  fetchMoreSubCategories,
  handleSelectSubCategory,
  categoriesIDs,
  handleDeleteCategory,
}) {
  const { currentPage: page, hasMorePages } =
    category?.children?.paginatorInfo ?? {};

  const handleViewMore = () => {
    if (hasMorePages) {
      fetchMoreSubCategories(page, category.id);
    }
  };
  return (
    <div className={styles.categoryRow}>
      <div className={styles.deleteBtnWrapper}>
        <p className={styles.categoryName}>{category.name}</p>
        {handleDeleteCategory && (
          <button
            className={styles.deleteCategoryBtn}
            onClick={() => handleDeleteCategory(category.id)}
          >
            Delete category
          </button>
        )}
      </div>
      <p className={styles.selectCategory}>SELECT CATEGORIES</p>
      <div className={styles.subCategoriesList}>
        {category.children.data.map((subCategory) => (
          <Tags
            tag="Chip"
            key={subCategory.id}
            label={subCategory.name}
            clickHandler={() => handleSelectSubCategory(subCategory.id)}
            selected={categoriesIDs.includes(subCategory.id)}
          />
        ))}
      </div>
      {hasMorePages && (
        <button className={styles.viewMoreBtn} onClick={handleViewMore}>
          View More +
        </button>
      )}
    </div>
  );
}

CategoryRow.propTypes = {
  category: PropTypes.object,
  fetchMoreSubCategories: PropTypes.func,
  handleSelectSubCategory: PropTypes.func,
  categoriesIDs: PropTypes.array,
  handleDeleteCategory: PropTypes.func,
};
