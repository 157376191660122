import { gql } from "@apollo/client";
export default gql`
  mutation verify($email: Email!, $code: String!) {
    verifyEmail(email: $email, code: $code) {
      id
      firstName
      lastName
      email
      createdAt
      roles {
        id
        permissions
        name
        label
      }
    }
  }
`;
