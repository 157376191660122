import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useHideMenu from "../../../../../../hooks/useHideMenu";
import { authVar } from "../../../../../../utils/cache";
import Spinner from "../../../../UI/Spinner/Spinner";
import styles from "./Businesses.module.css";
import BusinessesList from "./BusinessesList/BusinessesList";
import PropTypes from "prop-types";
import { BusinessOnboardingState } from "../../../../../../constants";
import { isBusinessCompleted } from "../../../../../../utils";
const Businesses = (props) => {
  const { setBusinessID } = props;
  const [selectedBusiness, setSelectedBusiness] = useState();
  const { id } = useParams();
  const { data } = useReactiveVar(authVar)?.businesses ?? {};
  const completedBusiness = data?.filter((business) =>
    isBusinessCompleted(business?.onboardingState)
  );

  const { show: showList, setShow: setShowList } = useHideMenu(
    "sidebar-business-list"
  );

  useEffect(() => {
    setBusinessID(id);
    if (data) {
      const business = data?.find((el) => el.id === id) ?? data[0];
      setSelectedBusiness(business?.name);
    }
  }, [data, id]);
  const showListHandler = () => setShowList(!showList);

  return (
    <div className={styles.businesses}>
      <h1
        className={`${styles.title} ${showList ? styles.hide : styles.show} `}
      >
        BUSINESS
      </h1>
      {showList ? (
        <BusinessesList
          listToggle={showListHandler}
          data={completedBusiness}
          setSelectedBusiness={setSelectedBusiness}
        />
      ) : (
        <button className={styles.showBtn} onClick={showListHandler}>
          {!data ? (
            <Spinner variant={styles.loader} />
          ) : (
            <div className={`sidebar-business-list ${styles.mainBusiness}`}>
              <h1 className={`sidebar-business-list ${styles.mainText}`}>
                {selectedBusiness}
              </h1>
              <svg
                width='8.8'
                height='10.12'
                viewBox='0 0 12 11'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className={`${styles.arrow} sidebar-business-list`}
              >
                <path
                  d='M6.06223 1.13156e-07L6.06223 8.85644M6.06223 8.85644L11.123 4.41838M6.06223 8.85644L1.00141 4.41838'
                  stroke='#0A0A0A'
                  strokeWidth='2'
                />
              </svg>
            </div>
          )}
        </button>
      )}
    </div>
  );
};

export default Businesses;
Businesses.propTypes = {
  setBusinessID: PropTypes.func,
};
