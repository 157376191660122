import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import Input from "../../GlobalComponents/UI/Input/Input";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import updateBusinessOnboarding from "../../../graphql/mutations/updateBusiness";
import useInput from "../../../hooks/useInput";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import styles from "../BusinessOnboarding.module.css";
import { descriptionValidation } from "../../../utils/validationRules";
import {
  MIN_MESSAGE_LENGTH,
  MAX_MESSAGE_LENGTH,
  BusinessOnboardingState,
} from "../../../constants";
import { savingBizInfoState } from "../../../utils/cache";

export default function AboutBusiness({
  nextStepHandler,
  insertBusinessData,
  businessData,
}) {
  const businessId = businessData?.id;
  const businessDescription = businessData?.desc;
  const [updateBusiness, { loading: businessLoading }] = useMutation(
    updateBusinessOnboarding
  );

  const {
    value: desc,
    hasError: descError,
    errorMessage: descErrorMessage,
    valueChangeHandler: descHandler,
    valueBlurHandler: descBlurHandler,
  } = useInput(descriptionValidation, businessDescription || "");

  const handleSave = () => {
    if (businessDescription === desc) {
      nextStepHandler();
      return;
    }
    savingBizInfoState({ loading: true, error: false });
    analytics.track("about_business");

    updateBusiness({
      variables: {
        id: businessId,
        desc: desc,
        onboardingState: BusinessOnboardingState.aboutFounder, // next step
      },
    })
      .then(() => {
        savingBizInfoState({ loading: false });

        insertBusinessData({ desc });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };
  const isValidDescription =
    desc.length >= MIN_MESSAGE_LENGTH && desc.length < MAX_MESSAGE_LENGTH;
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>About your business</h2>
        <p className={styles.description}>
          Let customers know what your business is about by writing a
          description that covers the essential info necessary.
        </p>
      </div>
      <div>
        <Input
          inpContainerClass={styles.inpContainerClass}
          hasLabel
          isRequired
          label='ADD A DESCRIPTION ABOUT YOUR BUSINESS'
          labelClass={styles.addDescTitle}
          placeholder='Add message here'
          elementType='textArea'
          value={desc}
          changeHandler={descHandler}
          blurHandler={descBlurHandler}
          invalid={descError}
          errorMessage={descErrorMessage}
          max={MAX_MESSAGE_LENGTH}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <RegisterNextBtn
          variant={parentStyles.continueBtn}
          disabled={!isValidDescription || descError || businessLoading}
          clickHandler={handleSave}
          isLoading={businessLoading}
        >
          {businessLoading ? <Spinner /> : "SAVE & CONTINUE"}
        </RegisterNextBtn>
      </div>
    </div>
  );
}

AboutBusiness.propTypes = {
  nextStepHandler: PropTypes.func,
  insertBusinessData: PropTypes.func,
  businessData: PropTypes.object,
};
