import React from "react";
import styles from "../ProcessAndBenefits.module.css";
import TitleSlider from "../Slider";

const Benefits = () => {
  return (
    <div className={styles.processContainer}>
      <TitleSlider text={"THE Benefits"} />
      <div className={styles.content}>
        <h3 className={styles.mainDesc}>
          <span>$25,000 in prize dollars to</span>
          <span> reinvest into their business</span>
        </h3>
        <h3 className={styles.mainDesc}>
          <span>OBWS paid business membership </span>
          <span className={styles.subDesc}>
            <span>This includes access to free legal services,</span>
            <span> monthly resources, and additional</span>
            <span>
              opportunities to promote their business to our community of over
              one million fans
            </span>
          </span>
        </h3>
        <h3 className={styles.mainDesc}>
          <span>And of course bragging rights!</span>
        </h3>
      </div>
    </div>
  );
};

export default Benefits;
