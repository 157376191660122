import React, { useState } from "react";
import styles from "./SignInButton.module.css";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

const SignInButton = (props) => {
  const { variant, closeSideNav } = props;
  const { pathname } = useLocation();

  const btnStyle = () => {
    let classes = [styles.loginBtn, variant];
    if (pathname === "/awards2022") {
      classes = [...classes, styles.landingColor];
    } else {
      classes = [...classes, styles.regColor];
    }
    return classes?.join(" ");
  };

  const btnText = () => {
    if (pathname === "/awards2022") {
      return "sign in to vote";
    } else {
      return "Sign in";
    }
  };
  return (
    <div
      className={`${
        pathname === "/awards2022" ? styles.awardsWidth : styles.regWidth
      } ${styles.container}`}
    >
      <NavLink to='/login' className={btnStyle()} onClick={closeSideNav}>
        {btnText()}
      </NavLink>
      <div className={styles.brdr} />
      <NavLink
        to='/business-register'
        className={btnStyle()}
        onClick={closeSideNav}
      >
        LIST YOUR BUSINESS
      </NavLink>
    </div>
  );
};

export default SignInButton;
SignInButton.propTypes = {
  variant: PropTypes.string,
  closeSideNav: PropTypes.func,
};
