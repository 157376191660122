import React, { useCallback, useEffect, useRef } from "react";
import styles from "./BusinessDashboard.module.css";
import { NavLink, Navigate } from "react-router-dom";
import arrow from "../../assets/images/whiteArrow.svg";
import SectionLoader from "../../components/GlobalComponents/UI/SectionLoader/SectionLoader";
import { BusinessOnboardingState, USER_TYPES } from "../../constants";
import { checkUserRole, warningToastHandler } from "../../utils";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";
import { toast } from "react-toastify";
import useGetUser from "../../hooks/useGetUser";
import Picture from "../../components/GlobalComponents/UI/Picture";
import placeholder from "../../assets/images/itemPlaceholder.jpg??placeholder";
const BusinessDashboard = () => {
  const { userData, loading, fetchMore } = useGetUser();
  const { data } = userData?.businesses ?? {};
  const { hasMorePages, currentPage } =
    userData?.businesses?.paginatorInfo ?? {};
  const { subscribed, roles } = userData ?? {};
  const isAdminUser = checkUserRole(roles, USER_TYPES.admin);

  const handleDeclinedBusiness = (e) => {
    e.preventDefault();
    warningToastHandler(
      <p>
        Your application to OBWS was denied. Please check your email for more
        information, or
        <NavLink to='/business-support' className={styles.support}>
          Click here
        </NavLink>{" "}
        to contact support.
      </p>,
      { closeOnClick: true }
    );
  };
  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  const mapBusiness = (business) => {
    if (
      business?.onboardingState !== BusinessOnboardingState.done &&
      business?.onboardingState !== BusinessOnboardingState.approve &&
      business?.onboardingState !== BusinessOnboardingState.disapprove &&
      business?.onboardingState !== BusinessOnboardingState.idDeclined
    ) {
      return (
        <NavLink
          to={`/business-onboarding/${business?.id}`}
          className={`${styles.business} 
`}
          key={business.id}
        >
          <p className={styles.awaiting}>Complete Your Profile</p>
          <Picture
            fallback={business?.featuredImage?.path ?? placeholder}
            alt={business?.name}
            variant={`${styles.businessImage}`}
            isDarkColor
          />
          <div className={styles.imageOverlay}>
            <button className={styles.uploadIdBtn}>
              COMPLETE BUSINESS PROFILE
              <img src={arrow} alt='upload Id' className={styles.arrow} />
            </button>
          </div>

          <p className={styles.name}>{business?.name}</p>
        </NavLink>
      );
    } else if (
      /* TODO: this code belongs to stripe id verification step may be reverted in the future  */

      // !business?.representative?.stripeIdentity?.isVerified ||
      business?.onboardingState === BusinessOnboardingState.idDeclined &&
      !isAdminUser
    ) {
      return (
        <NavLink
          to={`/upload-id/${business.id}`}
          className={`${styles.business} 
            `}
          key={business.id}
        >
          <p className={styles.invalidIdLabel}>INVALID ID</p>
          <Picture
            fallback={business?.featuredImage?.path ?? placeholder}
            alt={business?.name}
            variant={`${styles.businessImage}`}
            isDarkColor
          />
          <div className={styles.imageOverlay}>
            <button className={styles.uploadIdBtn}>
              RE-UPLOAD ID
              <img src={arrow} alt='upload Id' className={styles.arrow} />
            </button>
          </div>
          <p className={styles.name}>{business?.name}</p>
        </NavLink>
      );
    } else if (
      business?.onboardingState === BusinessOnboardingState.disapprove
    ) {
      return (
        <NavLink
          to={`/business-onboarding/${business?.id}`}
          className={`${styles.business} 
        `}
          key={business.id}
        >
          <p className={styles.declinedLabel} onClick={handleDeclinedBusiness}>
            Business declined
          </p>
          <Picture
            fallback={business?.featuredImage?.path ?? placeholder}
            alt={business?.name}
            variant={`${styles.businessImage}`}
            isDarkColor
          />
          <div className={styles.imageOverlay}>
            <button className={styles.uploadIdBtn}>
              UPDATE INFO
              <img src={arrow} alt='upload Id' className={styles.arrow} />
            </button>
          </div>

          <p className={styles.name}>{business?.name}</p>
        </NavLink>
      );
    } else if (business?.onboardingState === BusinessOnboardingState.done) {
      return (
        <NavLink
          to={`/edit-business/${business.id}`}
          className={`${styles.business} 
      `}
          key={business.id}
        >
          <p className={styles.awaiting}>Awaiting Validation</p>
          <Picture
            fallback={business?.featuredImage?.path ?? placeholder}
            alt={business?.name}
            variant={`${styles.businessImage}`}
            isDarkColor
          />
          <p className={styles.name}>{business?.name}</p>
        </NavLink>
      );
    } else {
      return (
        <NavLink
          to={`/edit-business/${business.id}`}
          className={`${styles.business} ${styles.approved}
      `}
          key={business.id}
        >
          <Picture
            fallback={business?.featuredImage?.path ?? placeholder}
            alt={business?.name}
            variant={`${styles.businessImage}`}
            isDarkColor
          />
          <p className={styles.name}>{business?.name}</p>
        </NavLink>
      );
    }
  };
  const observer = useRef();
  // for businesses pagination
  const itemsRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          fetchMore({
            variables: {
              businessesPage: currentPage + 1,
            },
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMorePages, currentPage, loading]
  );
  if (data?.length === 0 && !subscribed) {
    return <Navigate to='/select-tier' />;
  }

  if (data?.length === 0) {
    return <Navigate to='/business-onboarding' />;
  }
  if (loading) {
    return <Spinner variant={styles.pageLoader} />;
  }
  return (
    <div className={styles.mainDashboard}>
      <h1
        className={`${styles.title} ${
          data?.find((el) => el.approved === false)
            ? styles.regPadding
            : styles.allApproved
        }`}
      >
        Choose your business
      </h1>
      <div
        className={`${styles.businessesContainer} ${
          data?.length > 3 ? styles.adminContainer : styles.businessContainer
        }`}
      >
        {data && (
          <>
            {data?.map((business, i) => mapBusiness(business))}{" "}
            {hasMorePages && (
              <div className={styles.paginatorRef} ref={itemsRef} />
            )}{" "}
          </>
        )}
        {loading && <SectionLoader itemsNumber={4} darkColor />}
      </div>

      {data && (
        <NavLink
          to='/create-business'
          className={`${styles.addBtn} ${
            data?.length >= 3 && !checkUserRole(roles, USER_TYPES.admin)
              ? styles.dimmed
              : styles.regBtn
          }`}
        >
          + ADD BUSINESS
        </NavLink>
      )}
    </div>
  );
};

export default BusinessDashboard;
