import React from "react";
import styles from "./ItemPrice.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { useParams } from "react-router-dom";
import useInput from "../../../../../../hooks/useInput";
import { editView, itemEditField } from "../../../../../../utils/cache";
import useEditItem from "../../../../../../hooks/useEditItem";
import EditTooltip from "../../../../UI/EditTooltip/EditTooltip";
import Input from "../../../../UI/Input/Input";
import ApplyEditBtn from "../../../../Buttons/ApplyEditBtn/ApplyEditBtn";
import { businessNameValidation } from "../../../../../../utils/validationRules";

const ItemPrice = (props) => {
  const { price, itemType } = props;
  const {
    value: itemPrice,
    hasError: itemPriceError,
    errorMessage: itemPriceErrorMessage,
    valueChangeHandler: itemPriceHandler,
    valueBlurHandler: itemPriceBlurHandler,
    isValid: itemPriceValid,
  } = useInput(businessNameValidation, price?.toString());

  const isEditing = useReactiveVar(editView);
  const { id } = useParams();
  const showEditTooltip = useReactiveVar(itemEditField);

  const { loading, editFormHandler } = useEditItem(
    id,
    {
      price: +itemPrice,
    },
    itemType
  );

  return (
    <div
      className={`${isEditing ? styles.edit : styles.noEdit} ${
        showEditTooltip === "price" ? styles.highZ : undefined
      }`}
    >
      <h6 className={styles.price} onClick={itemEditField.bind(null, "price")}>
        ${price?.toFixed(2)}
      </h6>
      <EditTooltip
        show={showEditTooltip === "price" && isEditing}
        variant={styles.tooltip}
        anchorStyle='top'
        closeHandler={itemEditField.bind(null, "")}
      >
        <form onSubmit={editFormHandler}>
          <Input
            hasLabel
            isRequired
            label={`YOUR ${itemType?.toUpperCase()} PRICE`}
            placeholder='e.g. $24.99'
            type='number'
            inpContainerClass={`${styles.inpContainer} ${
              itemPriceError && styles.invalid
            }`}
            value={itemPrice}
            changeHandler={itemPriceHandler}
            blurHandler={itemPriceBlurHandler}
            invalid={itemPriceError}
            errorMessage={itemPriceErrorMessage}
          />
          <ApplyEditBtn
            disable={!itemPriceValid || price === +itemPrice}
            loading={loading}
          />
        </form>
      </EditTooltip>
    </div>
  );
};

export default ItemPrice;
ItemPrice.propTypes = {
  price: PropTypes.number,
  itemType: PropTypes.string,
};
