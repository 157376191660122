import React, { Fragment } from "react";
import { NavLink, useParams } from "react-router-dom";
import styles from "./BusinessesList.module.css";
import PropTypes from "prop-types";
const BusinessesList = (props) => {
  const { listToggle, data, setSelectedBusiness } = props;
  const { id } = useParams();

  const toggleBusiness = (business) => {
    listToggle();
    setSelectedBusiness(business);
  };
  return (
    <div className={`${styles.list} sidebar-business-list`}>
      <div className={styles.listItems}>
        {data?.map(({ name, id: businessId }, i) => (
          <Fragment key={`${name}${i}`}>
            <NavLink
              to={`/edit-business/${businessId}`}
              className={styles.link}
              onClick={toggleBusiness.bind(null, name)}
            >
              <div className={styles.linkContainer}>
                <p
                  className={`${styles.text} ${
                    id === businessId ? styles.activeText : styles.notActive
                  }`}
                >
                  {name}
                </p>
                {+id === businessId ? (
                  <svg
                    width='16'
                    height='11'
                    viewBox='0 0 16 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className={styles.mark}
                  >
                    <path
                      d='M0.700195 4.9L5.8002 10L14.8002 1'
                      stroke='#226F54'
                    />
                  </svg>
                ) : null}
              </div>
            </NavLink>
            {i !== data?.length - 1 ? <div className={styles.brdr} /> : null}
          </Fragment>
        ))}
      </div>
      <div className={styles.fullBrdr} />
      <NavLink
        className={`${styles.addLink} ${
          data?.length >= 3 ? styles.dimmed : styles.regBtn
        }`}
        to='/create-business'
      >
        + ADD BUSINESS
      </NavLink>
    </div>
  );
};

export default BusinessesList;
BusinessesList.propTypes = {
  listToggle: PropTypes.func,
  data: PropTypes.array,
  setSelectedBusiness: PropTypes.func,
};
