import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./ListingSection.module.css";
import PropTypes from "prop-types";
import CollectionImage from "../CollectionsPopUp/CollectionImage/CollectionImage";
import { NavLink, Navigate } from "react-router-dom";
import ErrorComponent from "../../GlobalComponents/UI/ErrorComponent/ErrorComponent";
import { useLazyQuery } from "@apollo/client";
import getUser from "../../../graphql/queries/getUser";
import Cookies from "universal-cookie";
import plus from "../../../assets/images/whitePlus.svg";
import Backdrop from "../../GlobalComponents/UI/Backdrop/Backdrop";
import CreateNewCollection from "../CollectionsPopUp/CreateNewCollection/CreateNewCollection";
import SectionLoader from "../../GlobalComponents/UI/SectionLoader/SectionLoader";

const ListingSection = (props) => {
  const [getCollection, { data, loading, error }] = useLazyQuery(getUser);
  const [collections, setCollections] = useState([]);
  const { currentPage: page, hasMorePages } =
    data?.user?.collections?.paginatorInfo ?? {};
  const [createNew, setCreateNew] = useState(false);
  const observer = useRef();
  const cookies = new Cookies();
  const userId = cookies.get("user-id");

  const collectionsRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          getCollection({ variables: { id: userId, page: page + 1 } }).then(
            ({ data }) => {
              setCollections((prevData) => [
                ...prevData,
                ...data?.user?.collections?.data,
              ]);
            }
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMorePages, loading]
  );
  useEffect(() => {
    if (userId) {
      getCollection({ variables: { id: userId } }).then(({ data }) => {
        setCollections(data?.user?.collections?.data);
      });
    }
    return () => setCollections([]);
  }, []);

  const createNewToggle = () => setCreateNew(!createNew);
  const addNewHandler = (collection) => {
    setCreateNew(!createNew);
    setCollections((prev) => [...prev, collection]);
  };

  if (!userId) {
    return <Navigate to='/' />;
  }
  return (
    <>
      <Backdrop show={createNew} clickHandler={createNewToggle}>
        <CreateNewCollection closeHandler={addNewHandler} />
      </Backdrop>
      <section className={styles.listing}>
        <h6 className={styles.title}>My Collections</h6>
        <div className={styles.collections}>
          {error && <ErrorComponent error={error?.message} />}
          {collections?.map((collection, i) => (
            <NavLink
              ref={i === collections?.length - 1 ? collectionsRef : null}
              to={`/collections/${collection?.id}`}
              key={collection?.id}
            >
              <div className={styles.collection}>
                <CollectionImage
                  imgSrc={collection?.image?.path}
                  variant={styles.collectionImage}
                />
                <p className={styles.name}>{collection?.name}</p>
              </div>
            </NavLink>
          ))}
          {((collections?.length === 0 && !error) || loading) && (
            <SectionLoader itemsNumber={8} />
          )}
        </div>
        <div className={styles.addBtn}>
          <button className={styles.btn} onClick={createNewToggle}>
            <img src={plus} className={styles.plus} alt='add' />
          </button>
          <p className={styles.btnText} onClick={createNewToggle}>
            ADD NEW COLLECTION
          </p>
        </div>
      </section>
    </>
  );
};

export default ListingSection;
ListingSection.propTypes = {
  items: PropTypes.array,
  collectionName: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};
