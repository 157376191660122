import { useQuery } from "@apollo/client";
import React, { useRef, useEffect } from "react";
import { useParams, Navigate, useLocation } from "react-router-dom";
import Header from "../../components/Business/Header/Header";
import WhatWeOffer from "../../components/GlobalComponents/Sections/WhatWeOffer/WhatWeOffer";
import AboutSection from "../../components/GlobalComponents/Sections/AboutSection/AboutSection";
import Comments from "../../components/GlobalComponents/Sections/Comments/Comments";
import BusinessHoursSection from "../../components/GlobalComponents/Sections/BusinessHoursSection";
import { isBusinessType } from "../../utils";
import { BUSINESS_TYPES } from "../../constants";
import business from "../../graphql/queries/pages/business";
import styles from "./Business.module.css";

const Business = () => {
  const { id } = useParams();
  const { hash } = useLocation();
  const founder = useRef();
  const { data, loading, error, fetchMore } = useQuery(business, {
    variables: { id },
  });
  const { businessTypes } = data?.business ?? [];
  const isEcommerce = isBusinessType(businessTypes, BUSINESS_TYPES.ecommerce);
  useEffect(() => {
    if (hash === "#founder")
      window.scrollTo({ top: founder?.current?.offsetTop });
  }, []);

  if (!data?.business && !loading) return <Navigate to='/' />;
  return (
    <div className={styles.businessPage}>
      <Header businessData={data?.business} loading={loading} />
      <div className={styles.middleContainer}>
        <div className={styles.aboutOwner} id='founder' ref={founder}>
          <AboutSection
            data={data?.business}
            loading={loading}
            error={error}
            variant={styles.aboutSection}
          />
        </div>
        <div className={styles.rightContainer}>
          {((isEcommerce && businessTypes?.length > 1) || !isEcommerce) && (
            <BusinessHoursSection
              workingHours={data?.business?.workingHours}
              isBusinessPage={true}
            />
          )}
          <Comments
            data={data?.business}
            loading={loading}
            error={error}
            variant={styles.comments}
            fetchMore={fetchMore}
          />
        </div>
      </div>

      {data?.business?.supplies?.data?.length > 0 && (
        <WhatWeOffer
          items={data?.business?.supplies?.data?.slice(0, 5)}
          loading={loading}
          error={error}
          title='What we offer'
          isSeeAll={true}
          sectionClass={styles.whatWeOfferSection}
        />
      )}
    </div>
  );
};

export default Business;
