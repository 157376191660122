import { gql } from "@apollo/client";
export default gql`
  query SEARCH(
    $text: String!
    $filters: SearchFilters
    $sortBy: searchSortFilters
    $page: Int
    $size: Int
    $lat: Float
    $lng: Float
    $collectionPage: Int
  ) {
    search(
      text: $text
      filters: $filters
      sortBy: $sortBy
      page: $page
      size: $size
    ) {
      paginatorInfo {
        currentPage
        hasMorePages
        total
      }
      data {
        ... on Business {
          __typename
          id
          name
          label
          distance(lat: $lat, lng: $lng)
          lat
          lng
          addrLine1
          collections(page: $collectionPage) {
            paginatorInfo {
              currentPage
              hasMorePages
            }
            data {
              id
              name
            }
          }
          supplies {
            data {
              item {
                __typename
                ... on Product {
                  featuredImage {
                    id
                    path
                  }
                  id
                  name
                }
                ... on Service {
                  featuredImage {
                    id
                    path
                  }
                  id
                  name
                }
                ... on Food {
                  featuredImage {
                    id
                    path
                  }
                  id
                  name
                }
              }
            }
          }

          onboardingState
          categories {
            name
            parent {
              name
            }
          }
          city {
            name
          }
          state {
            abbr
          }
          businessTypes
          categories {
            name
          }
          workingHours {
            day
            open
            close
          }

          representative {
            firstName
            id
            lastName
            desc
            avatar {
              path
            }
          }
          isVoted
          isBookmarked
          businessTypes
          votes
          featuredImage {
            id
            path
          }
        }
        ... on Product {
          __typename
          id
          name
          collections(page: $collectionPage) {
            paginatorInfo {
              currentPage
              hasMorePages
            }
            data {
              id
              name
            }
          }

          desc
          price
          votes
          isVoted
          isBookmarked
          distance(lat: $lat, lng: $lng)
          featuredImage {
            id
            path
          }
          business {
            featuredImage {
              id
              path
            }
            workingHours {
              day
              open
              close
            }
            supplies {
              data {
                item {
                  __typename
                  ... on Product {
                    featuredImage {
                      id
                      path
                    }
                    id
                    name
                  }
                  ... on Service {
                    featuredImage {
                      id
                      path
                    }
                    id
                    name
                  }
                  ... on Food {
                    featuredImage {
                      id
                      path
                    }
                    id
                    name
                  }
                }
              }
            }
            name
            id
            lat
            lng
            addrLine1
            businessTypes
            categories {
              name
              parent {
                name
              }
            }
            representative {
              firstName
              id
              lastName
              desc
              avatar {
                path
              }
            }
          }
          categories {
            name
            parent {
              name
            }
          }
        }
        ... on Service {
          __typename
          id
          name
          collections(page: $collectionPage) {
            paginatorInfo {
              currentPage
              hasMorePages
            }
            data {
              id
              name
            }
          }

          desc
          price
          votes
          isVoted
          isBookmarked
          distance(lat: $lat, lng: $lng)
          featuredImage {
            id
            path
          }
          business {
            featuredImage {
              id
              path
            }
            workingHours {
              day
              open
              close
            }
            supplies {
              data {
                item {
                  __typename
                  ... on Product {
                    featuredImage {
                      id
                      path
                    }
                    id
                    name
                  }
                  ... on Service {
                    featuredImage {
                      id
                      path
                    }
                    id
                    name
                  }
                  ... on Food {
                    featuredImage {
                      id
                      path
                    }
                    id
                    name
                  }
                }
              }
            }
            name
            id
            lat
            lng
            addrLine1
            businessTypes
            categories {
              name
              parent {
                name
              }
            }
            representative {
              firstName
              id
              lastName
              desc
              avatar {
                path
              }
            }
          }
          categories {
            name
            parent {
              name
            }
          }
        }
        ... on Food {
          __typename
          id
          name
          desc
          collections(page: $collectionPage) {
            paginatorInfo {
              currentPage
              hasMorePages
            }
            data {
              id
              name
            }
          }

          price
          votes
          isVoted
          distance(lat: $lat, lng: $lng)
          isBookmarked
          featuredImage {
            id
            path
          }
          business {
            featuredImage {
              id
              path
            }
            workingHours {
              day
              open
              close
            }
            supplies {
              data {
                item {
                  __typename
                  ... on Product {
                    featuredImage {
                      id
                      path
                    }
                    id
                    name
                  }
                  ... on Service {
                    featuredImage {
                      id
                      path
                    }
                    id
                    name
                  }
                  ... on Food {
                    featuredImage {
                      id
                      path
                    }
                    id
                    name
                  }
                }
              }
            }
            name
            id
            lat
            lng
            addrLine1
            businessTypes
            categories {
              name
              parent {
                name
              }
            }
            representative {
              firstName
              id
              lastName
              desc
              avatar {
                path
              }
            }
          }
          categories {
            name
            parent {
              name
            }
          }
        }
      }
    }
  }
`;
