import React, { useState } from "react";
import Input from "../../../GlobalComponents/UI/Input/Input";
import styles from "./SearchAsMapMoves.module.css";
import PropTypes from "prop-types";

const SearchAsMapMoves = (props) => {
  const { checked, searchAsMapMovesHandler } = props;

  return (
    <Input
      elementType='checkbox'
      hasLabel={true}
      label='SEARCH AS MAP MOVES'
      inpContainerClass={styles.inpContainer}
      checked={checked}
      changeHandler={searchAsMapMovesHandler}
      customCheckBox={styles.checkMark}
    />
  );
};
SearchAsMapMoves;

export default SearchAsMapMoves;
SearchAsMapMoves.propTypes = {
  checked: PropTypes.bool,
  searchAsMapMovesHandler: PropTypes.func,
};
