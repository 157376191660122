import React from "react";
import PropTypes from "prop-types";
import ProfilePicture from "../../GlobalComponents/Layouts/Navigation/ProfileNavigation/ProfilePicture/ProfilePicture";
import styles from "./ProfileHeader.module.css";

export default function ProfileHeader({ firstName, lastName }) {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.whiteBg} />
      <ProfilePicture withInitials variant={styles.profileAvatar} />
      <p className={styles.userName}>
        {firstName} {lastName?.charAt(0)}.
      </p>
    </div>
  );
}
ProfileHeader.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};
