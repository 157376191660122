import React from "react";
import Awards from "./Awards";
import Benefits from "./Benefits";
import Process from "./Process";
import styles from "./ProcessAndBenefits.module.css";
const ProcessAndBenefits = () => {
  return (
    <section className={styles.container}>
      <div className={styles.firstSection}>
        <Process />
      </div>
      <div className={styles.secondSection}>
        <Awards />
      </div>
      <div className={styles.thirdSection}>
        <Benefits />
      </div>
    </section>
  );
};

export default ProcessAndBenefits;
