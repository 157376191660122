import React from "react";

import PropTypes from "prop-types";

import styles from "./BusinessGettingStarted.module.css";
import GettingStaredSidebar from "../GettingStartedSidebar";
import BusinessHours from "../BusinessHours";
import BusinessCategories from "../BusinessCategories";
import AboutBusiness from "../AboutBusiness";
import AboutFounder from "../AboutFounder";
import ProductsAndServices from "../ProductsAndServices";
import BackButton from "../../GlobalComponents/Buttons/BackButton/BackButton";

export default function BusinessGettingStarted({
  nextStepHandler,
  registerStepIndex,
  businessData,
  previousStepHandler,
  insertBusinessData,
  mapOnboardingState,
}) {
  return (
    <div className={styles.gettingStartedContainer}>
      <GettingStaredSidebar registerStepIndex={registerStepIndex} />
      <div className={styles.content}>
        <div className={styles.btnContainer}>
          <BackButton
            variant={styles.backBtn}
            backHandler={previousStepHandler}
          />
        </div>
        {registerStepIndex === 9 && (
          <BusinessHours
            nextStepHandler={nextStepHandler}
            insertBusinessData={insertBusinessData}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 10 && (
          <BusinessCategories
            nextStepHandler={nextStepHandler}
            insertBusinessData={insertBusinessData}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 11 && (
          <AboutBusiness
            nextStepHandler={nextStepHandler}
            insertBusinessData={insertBusinessData}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 12 && (
          <AboutFounder
            nextStepHandler={nextStepHandler}
            insertBusinessData={insertBusinessData}
            businessData={businessData}
          />
        )}
        {registerStepIndex === 13 && (
          <ProductsAndServices
            nextStepHandler={nextStepHandler}
            businessData={businessData}
            insertBusinessData={insertBusinessData}
          />
        )}
      </div>
    </div>
  );
}

BusinessGettingStarted.propTypes = {
  businessTypes: PropTypes.array,
  nextStepHandler: PropTypes.func,
  registerStepIndex: PropTypes.number,
  businessData: PropTypes.object,
  businessItems: PropTypes.array,
  previousStepHandler: PropTypes.func,
  mapOnboardingState: PropTypes.func,
  insertBusinessData: PropTypes.func,
};
