import React from "react";
import regStyles from "./Navigation.module.css";
import adminStyles from "./AdminStyles.module.css";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Logo from "../Logo/Logo";
import NavigationHeader from "../NavigationHeader/NavigationHeader";
import SearchBar from "../SearchBar/SearchBar";
import SignInButton from "../../Buttons/SignInButton/SignInButton";
import ProfileNavigation from "./ProfileNavigation/ProfileNavigation";
import SideToggle from "./SideToggle/SideToggle";
import Cookies from "universal-cookie";
import { useReactiveVar } from "@apollo/client";
import { consumerOnboardingVar, isAdmin } from "../../../../utils/cache";

const Navigation = (props) => {
  const { sideDrawer, showSideDrawer, show } = props;
  const { pathname } = useLocation();
  const cookies = new Cookies();
  const userId = cookies.get("user-id");
  const onboardingStep = useReactiveVar(consumerOnboardingVar);
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;

  const navigationClassName = () => {
    let classes = [styles.navigation];
    if (sideDrawer) {
      classes = [...classes, styles.drawerBg];
    } else {
      classes = [...classes, styles.normalBg];
    }
    if (onboardingStep === 0 || onboardingStep === 1) {
      classes = [...classes, styles.onBoarding];
    } else {
      classes = [...classes, styles.regular];
    }
    if (pathname === "/awards2022") {
      classes = [...classes, styles.landing];
    }
    return classes.join(" ");
  };
  const inpContainerClass = () => {
    if (pathname === "/awards2022") {
      return styles.landingInpContainer;
    }
    return styles.inpContainer;
  };

  const searchInputClass = () => {
    if (pathname === "/awards2022") {
      return styles.landingSearch;
    }
    return styles.searchBar;
  };

  const collectionsIconStyle = () => {
    let classes = [styles.collections];
    if (pathname?.includes("/collections")) {
      classes = [...classes, styles.bookmarked];
    } else {
      classes = [...classes, styles.notBookmarked];
    }
    if (pathname === "/awards2022") {
      classes = [...classes, styles.landingCollection];
    } else {
      classes = [...classes, styles.regCollection];
    }
    return classes.join(" ");
  };

  return (
    <nav className={navigationClassName()}>
      <div className={styles.navHeader}>
        <NavLink to='/'>
          <Logo variant={styles.logo} />
        </NavLink>
        <SearchBar
          variant={searchInputClass()}
          inpContainer={inpContainerClass()}
          showNav={show}
        />
        {userId && (
          <NavLink to='/collections'>
            <svg
              width='24'
              height='38'
              viewBox='0 0 24 38'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className={collectionsIconStyle()}
            >
              <path d='M0 0H24V38L12 27L0 38V0Z' strokeWidth='2' />
            </svg>
          </NavLink>
        )}
        {!admin && (
          <div className={styles.authState}>
            {userId ? <ProfileNavigation /> : <SignInButton />}
          </div>
        )}
        <SideToggle toggleClicked={showSideDrawer} show={sideDrawer} />
      </div>
      <div className={styles.navItems}>
        <NavigationHeader />
      </div>
    </nav>
  );
};

export default Navigation;
Navigation.propTypes = {
  showSideDrawer: PropTypes.func,
  sideDrawer: PropTypes.bool,
  show: PropTypes.bool,
};
