import React from "react";
import PropTypes from "prop-types";
import useInput from "../../../hooks/useInput";
import Input from "../../GlobalComponents/UI/Input/Input";
import { emailValidationRules } from "../../../utils/validationRules";
import styles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import { useMutation } from "@apollo/client";
import updateBusiness from "../../../graphql/mutations/updateBusiness";
import { BusinessOnboardingState } from "../../../constants";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { savingBizInfoState } from "../../../utils/cache";

export default function BusinessEmail({
  insertBusinessData,
  nextStepHandler,
  businessData,
}) {
  const {
    value: businessEmail,
    hasError: businessEmailError,
    errorMessage: businessEmailErrorMessage,
    valueChangeHandler: businessEmailHandler,
    valueBlurHandler: businessEmailBlurHandler,
    isValid: businessEmailValid,
  } = useInput(emailValidationRules, businessData?.email ?? "");
  const [updateBiz, { loading }] = useMutation(updateBusiness);

  const submitHandler = (e) => {
    e.preventDefault();
    analytics.track("business_email");
    if (businessData?.email === businessEmail) {
      nextStepHandler();
      return;
    }
    savingBizInfoState({ loading: true, error: false });

    updateBiz({
      variables: {
        id: businessData?.id,
        email: businessEmail,
        onboardingState: BusinessOnboardingState.businessWebsite, // next step
      },
    })
      .then(() => {
        savingBizInfoState({ loading: false });

        insertBusinessData({
          email: businessEmail,
        });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };

  return (
    <form className={styles.registerForm} onSubmit={submitHandler}>
      <h2 className={styles.altHeader}>Do you have a business email ?</h2>

      <Input
        hasLabel
        isRequired
        label='YOUR BUSINESS EMAIL'
        placeholder='e.g. jamie@obws.com'
        type='text'
        inpContainerClass={`${styles.fullWidthContainer} ${
          businessEmailError && styles.invalid
        }`}
        value={businessEmail}
        changeHandler={businessEmailHandler}
        blurHandler={businessEmailBlurHandler}
        invalid={businessEmailError}
        errorMessage={businessEmailErrorMessage}
      />
      <RegisterNextBtn
        variant={styles.continueBtn}
        disabled={!businessEmailValid}
        isLoading={loading}
      >
        {loading ? <Spinner /> : "SAVE & CONTINUE"}
      </RegisterNextBtn>
    </form>
  );
}
BusinessEmail.propTypes = {
  insertBusinessData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  businessData: PropTypes.object,
};
