import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Input from "../../../GlobalComponents/UI/Input/Input";
import styles from "./Categories.module.css";
import PropTypes from "prop-types";
import Spinner from "../../../GlobalComponents/UI/Spinner/Spinner";
import ErrorComponent from "../../../GlobalComponents/UI/ErrorComponent/ErrorComponent";
import mainCategories from "../../../../graphql/queries/mainCategories";
import { useSearchParams } from "react-router-dom";

const Categories = (props) => {
  const { categoryChangeHandler, selectedCategories, setSelectedCategories } =
    props;
  const [params, setParams] = useSearchParams();
  const { data, loading, error } = useQuery(mainCategories, {
    variables: { size: 50 },
  });
  const { data: categories } = data?.mainCategories ?? {};

  useEffect(() => {
    const categoryParam = params.get("c");
    if (
      params.getAll("c").length === 1 &&
      categoryParam !== "All Categories" &&
      categories?.length > 0
    ) {
      const category = categories?.find((el) => el?.name === categoryParam);
      categoryChangeHandler(category);
    } else if (
      params.getAll("c").length > 1 &&
      categoryParam !== "All Categories" &&
      categories?.length > 0
    ) {
      setSelectedCategories(params.getAll("c"));
    }
  }, [categories]);

  useEffect(() => {
    if (selectedCategories.length > 1) {
      params.delete("c");
      selectedCategories.map((cat) => params.append("c", cat));
      setParams(params);
    } else if (selectedCategories.length === 1) {
      const category = categories?.find(
        (el) => el?.id === selectedCategories[0]
      );
      if (category?.name !== params.get("c")) {
        params.set("c", category?.name);
        setParams(params);
      }
    }
  }, [selectedCategories]);

  return (
    <div className={styles.categoriesContainer}>
      <h1 className={styles.title}>Categories</h1>
      <div className={styles.categories}>
        {loading && <Spinner variant={styles.loader} />}
        {error && <ErrorComponent variant={styles.errorMessage} />}
        {data && (
          <div className={styles.categoriesList}>
            <div>
              <Input
                elementType='checkbox'
                hasLabel={true}
                label={"All"}
                value={null}
                name={null}
                inpContainerClass={styles.inpContainer}
                checked={selectedCategories?.length === 0}
                changeHandler={categoryChangeHandler.bind(null, null)}
                customCheckBox={styles.checkMark}
              />
            </div>
            {categories?.map((cat, i) => (
              <div key={cat?.name}>
                <Input
                  elementType='checkbox'
                  hasLabel={true}
                  label={cat?.name}
                  value={cat?.name}
                  name={cat?.id}
                  inpContainerClass={styles.inpContainer}
                  checked={selectedCategories?.some((el) => el === cat?.id)}
                  changeHandler={categoryChangeHandler.bind(null, cat)}
                  customCheckBox={styles.checkMark}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Categories;
Categories.propTypes = {
  categoryChangeHandler: PropTypes.func,
  setSelectedCategories: PropTypes.func,
  selectedCategories: PropTypes.array,
};
