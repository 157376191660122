import { gql } from "@apollo/client";
export default gql`
  query ($filters: StateFilters) {
    states(filters: $filters, size: 200) {
      data {
        id
        name
        label
      }
    }
  }
`;
