import React, { useState } from "react";
import styles from "./SideDrawer.module.css";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../Logo/Logo";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import SearchBar from "../../SearchBar/SearchBar";
import NavigationHeader from "../../NavigationHeader/NavigationHeader";
import SignInButton from "../../../Buttons/SignInButton/SignInButton";
import Cookies from "universal-cookie";

const SideDrawer = (props) => {
  const { show, closeSideDrawer } = props;
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const cookies = new Cookies();
  const userId = cookies.get("user-id");
  const { pathname } = useLocation();

  const toggleHandler = () => {
    setShowProfileMenu(!showProfileMenu);
  };
  const closeHandler = () => {
    closeSideDrawer();
    setShowProfileMenu(false);
  };
  return (
    <div
      className={`${styles.sideDrawer} ${
        show ? styles.open : styles.close
      } side-drawer ${
        pathname === "/awards2022" ? styles.darkBg : styles.lightBg
      }`}
    >
      <div className={styles.logoContainer}>
        <NavLink to='/'>
          <Logo
            variant={`${styles.logo} ${
              userId ? styles.authorized : styles.notAuthorized
            }`}
          />
        </NavLink>
      </div>
      <div className={styles.sideDrawerContent}>
        {userId && (
          <ProfileInfo
            closeSideDrawer={closeSideDrawer}
            toggleHandler={toggleHandler}
            showProfileMenu={showProfileMenu}
          />
        )}
        <SearchBar
          variant={`${styles.searchBar} side-drawer`}
          inpContainer={styles.inpContainer}
          placeholder='Search...'
          closeSideDrawer={closeHandler}
          showNav={show}
        />
        <NavigationHeader closeSideNav={closeHandler} />
        {!userId && <SignInButton closeSideNav={closeHandler} />}
      </div>
    </div>
  );
};

export default SideDrawer;
SideDrawer.propTypes = {
  show: PropTypes.bool,
  closeSideDrawer: PropTypes.func,
};
