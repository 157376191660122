import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ItemTableBody.module.css";
import { NavLink } from "react-router-dom";
import UserPicture from "../../UI/UserPicture/UserPicture";
import Tags from "../../UI/Tags/Tags";
import { ITEM_TYPES, SORT_ORDER } from "../../../../constants";
import deleteIcon from "../../../../assets/images/delete.png";
import dragIcon from "../../../../assets/images/dragIcon.png";
import useDragDrop from "../../../../hooks/useDragDrop";
import { ascCompare, descCompare } from "../../../../utils";
import RowLoader from "../../UI/RowLoader/RowLoader";
import AdminTableHeader from "../AdminTableHeader";

const ItemTableBody = forwardRef((props, ref) => {
  const {
    items,
    loading,
    setItems,
    cols,
    isDelete,
    isDrag,
    loadingRows,
    applyingFilter,
    setSearchSort,
  } = props;
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASC);
  const { dragEnter, dragStart, drop, dragOver } = useDragDrop(items, setItems);
  const itemType = (item) => {
    if (
      item?.__typename === ITEM_TYPES?.BUSINESS &&
      item?.businessTypes?.length > 0
    ) {
      return item?.businessTypes[0];
    } else if (item?.__typename !== ITEM_TYPES?.BUSINESS) {
      return item?.__typename.toLowerCase();
    }
    return [];
  };
  useEffect(() => {
    if (setSearchSort) {
      if (sortOrder === SORT_ORDER.ASC) {
        setSearchSort(SORT_ORDER?.ASCSearchFilter);
      } else {
        setSearchSort(SORT_ORDER.DESCSearchFilter);
      }
    }
  }, [sortOrder]);
  const itemCategory = (item) => {
    const mainCategories = item?.categories
      ?.map((el) => {
        if (el?.parent === null) {
          return el?.name;
        }
      })
      .filter((el) => el !== undefined);
    if (mainCategories?.length > 0) {
      return mainCategories[0];
    }
    return [];
  };

  const ownerInfo = (item) => {
    if (item?.__typename === ITEM_TYPES?.BUSINESS) {
      return {
        firstName: item?.representative?.firstName,
        lastName: item?.representative?.lastName,
        path: item?.representative?.avatar?.path,
      };
    }
    return {
      firstName: item?.business?.representative?.firstName,
      lastName: item?.business?.representative?.lastName,
      path: item?.business?.representative?.avatar?.path,
    };
  };
  const deleteItem = (indx) => {
    const filteredItems = items?.filter((itm, i) => i !== indx);
    setItems([...filteredItems]);
  };

  const handleSort = () => {
    const itemsInstance = [...items];
    if (sortOrder === SORT_ORDER.ASC) {
      itemsInstance.sort(descCompare.bind(null, "name"));
      setSortOrder(SORT_ORDER.DESC);
    } else {
      itemsInstance.sort(ascCompare.bind(null, "name"));
      setSortOrder(SORT_ORDER.ASC);
    }
    if (setItems) setItems(itemsInstance);
  };
  return (
    <table className={styles.table}>
      <AdminTableHeader
        columns={cols}
        handleSort={handleSort}
        className={styles.header}
        sortOrder={sortOrder}
        isDrag={isDrag}
      />
      <tbody>
        {items?.length > 0 &&
          !applyingFilter &&
          items?.map((itm, i) => (
            <tr
              key={`${itm?.name}-${i}`}
              className={styles.row}
              draggable
              onDragStart={dragStart.bind(null, i)}
              onDragEnter={dragEnter.bind(null, i)}
              onDragEnd={drop}
              onDragOver={dragOver}
              ref={i === items?.length - 1 ? ref : null}
            >
              <td className={styles.nameCol}>
                {isDrag && (
                  <img src={dragIcon} className={styles.drag} alt='drag' />
                )}
                <p className={styles.itemName}>{itm?.name}</p>
              </td>
              <td>
                <div className={styles.ownerContainer}>
                  <UserPicture
                    path={ownerInfo(itm)?.path}
                    variant={styles.avatar}
                  />
                  <div className={styles.about}>
                    <p className={styles.name}>
                      {`${ownerInfo(itm)?.firstName} ${
                        ownerInfo(itm)?.lastName
                      }`}
                    </p>
                    <p className={styles.title}>FOUNDER / CEO</p>
                  </div>
                </div>
              </td>
              <td>
                <div className={styles.types}>
                  {itemType(itm).length > 0 && (
                    <Tags tag={itemType(itm)} isBusinessType />
                  )}
                  {itemCategory(itm).length > 0 && (
                    <Tags
                      tag={itemCategory(itm)}
                      dimmed={itm?.__typename !== ITEM_TYPES?.BUSINESS}
                    />
                  )}
                </div>
              </td>
              <td className={styles.btnsCol}>
                <div className={styles.btnsContainer}>
                  <NavLink
                    to={`/listing-management/edit-${itm?.__typename?.toLowerCase()}/${
                      itm?.id
                    }`}
                    className={styles.link}
                  >
                    {`VIEW ${itm?.__typename?.toUpperCase()} LISTING`}
                  </NavLink>
                  {isDelete && (
                    <img
                      src={deleteIcon}
                      className={styles.delete}
                      alt='delete'
                      onClick={deleteItem.bind(null, i)}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        {loading && <RowLoader rows={loadingRows} />}
      </tbody>
    </table>
  );
});
ItemTableBody.displayName = "ItemTableBody";

export default ItemTableBody;
ItemTableBody.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  isDelete: PropTypes.bool,
  isDrag: PropTypes.bool,
  error: PropTypes.object,
  setItems: PropTypes.func,
  cols: PropTypes.array,
  loadingRows: PropTypes.number,
  applyingFilter: PropTypes.bool,
  setSearchSort: PropTypes.func,
};
