export const service = {
  Service: {
    fields: {
      comments: {
        keyArgs: false,
        merge(existing, incoming) {
          if (!incoming) return existing;
          if (!existing) return incoming;
          const { data, ...rest } = incoming;
          let result = rest;
          result.data = [...existing.data, ...data];
          return result;
        },
      },
    },
  },
};
