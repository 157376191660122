import React from "react";
import { NavLink } from "react-router-dom";
import regStyles from "./SharedSignUpAd.module.css";
import adminStyles from "./AdminStyles.module.css";
import adImg from "../../../../assets/images/smallerSignAd.png";
import largeAdImg from "../../../../assets/images/signUpAd.png";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { isAdmin } from "../../../../utils/cache";
import GetListedBtn from "../../Buttons/GetListedBtn/GetListedBtn";
const SharedSignUpAd = (props) => {
  const { text, btnText, btnLink } = props;
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  return (
    <section className={styles.signUpAd}>
      <div className={styles.container}>
        <p className={styles.text}>
          {text ? text : "Ready to promote your Black-owned business?"}
        </p>
        <div className={styles.overFlowContainer}>
          <img src={adImg} alt='sign up ad' className={styles.adImg} />
          <img
            src={largeAdImg}
            alt='sign up ad'
            className={styles.largeAdImg}
          />
          <GetListedBtn variant={styles.learn} btnLink={btnLink}>
            {btnText ? btnText : "Get Started"}
          </GetListedBtn>
        </div>
      </div>
    </section>
  );
};

export default SharedSignUpAd;
SharedSignUpAd.propTypes = {
  text: PropTypes.string,
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
};
