import React, { forwardRef } from "react";
import Slider from "react-slick";
import styles from "./ImagesSlider.module.css";
import PropTypes from "prop-types";
import Picture from "../../../GlobalComponents/UI/Picture";
import firstWinnerBadge from "../../../../assets/images/firstWinnerBadge.png";
import secondWinnerBadge from "../../../../assets/images/secondWinnerBadge.png";
const ImagesSlider = forwardRef((props, ref) => {
  const { setCurrentIndex, currentIndex, winnersInfo } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    arrows: false,
    className: styles["slick-list"],
    afterChange: (index) => setCurrentIndex(index),
  };

  return (
    <div className={styles.images}>
      <Slider {...settings} ref={ref}>
        {winnersInfo?.map((winner, i) => (
          <div className={styles.imgContainer} key={winner?.business?.name}>
            <img
              className={styles.sponsor}
              src={i === 0 ? firstWinnerBadge : secondWinnerBadge}
              alt='badge'
            />
            <Picture
              variant={`${styles.judgeImg} ${styles[`img-${i + 1}`]}`}
              fallback={winner?.ownerImage?.path}
              alt={winner?.business?.name}
              loaderStyles={`${styles.loaderStyle} ${styles.judgeImg}`}
            />
          </div>
        ))}
      </Slider>
      <div className={styles.customScrollbar}>
        <span className={styles.scrollbarText}>
          Winner {currentIndex + 1} of 2
        </span>
        <div className={styles.track}>
          <div
            className={styles.thumb}
            style={{ height: `${(currentIndex + 1) * 50}%` }}
          />
        </div>
      </div>
    </div>
  );
});

ImagesSlider.displayName = "ImagesSlider";
export default ImagesSlider;
ImagesSlider.propTypes = {
  setCurrentIndex: PropTypes.func,
  currentIndex: PropTypes.number,
  winnersInfo: PropTypes.array,
};
