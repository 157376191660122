import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import regStyles from "./ProductCarousel.module.css";
import editStyles from "./EditPageStyles.module.css";
import { useReactiveVar } from "@apollo/client";
import EditImages from "../EditImages/EditImages";
import VisitBtn from "./VisitBtn/VisitBtn";
import { itemEditField } from "../../../../utils/cache";
import itemPlaceholder from "../../../../assets/images/itemPlaceholder.jpg";
import Picture from "../Picture";
const ProductCarousel = (props) => {
  const { variant, carouselImages, website, isEdit, itemType } = props;
  const styles = isEdit ? editStyles : regStyles;
  const [mainImage, setMainImage] = useState(carouselImages[0]?.path);
  const selectedToEdit = useReactiveVar(itemEditField);

  useEffect(() => {
    if (carouselImages) setMainImage(carouselImages[0]?.path);
  }, [carouselImages]);

  const changMainImage = (img) => {
    setMainImage(img);
  };

  return (
    <div
      className={`${styles.carouselContainer}
    ${
      selectedToEdit === "itemImages" || selectedToEdit === "visitBtn"
        ? styles.highlighted
        : styles.edit
    } 
    `}
    >
      <div className={styles.productImages}>
        {carouselImages?.map((image, i) => (
          <Picture
            onClick={changMainImage.bind(null, image?.path)}
            key={i}
            fallback={image?.path ?? itemPlaceholder}
            variant={styles.carousalImg}
            alt='product'
          />
        ))}
      </div>
      <div className={styles.mainImageContainer}>
        <Picture
          fallback={mainImage ?? itemPlaceholder}
          variant={styles.mainImg}
          alt='product'
          onClick={itemEditField.bind(null, "itemImages")}
        />
        <VisitBtn isEdit={isEdit} website={website} itemType={itemType} />
      </div>
      <EditImages sliderImages={carouselImages} itemType={itemType} />
    </div>
  );
};

export default ProductCarousel;

ProductCarousel.propTypes = {
  variant: PropTypes.string,
  carouselImages: PropTypes.arrayOf(PropTypes.object),
  website: PropTypes.string,
  itemType: PropTypes.string,
  isEdit: PropTypes.bool,
};
