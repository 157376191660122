import React from "react";
import { Navigate } from "react-router-dom";
import { USER_TYPES } from "../../constants";
import useGetUser from "../../hooks/useGetUser";
import { checkUserRole } from "../../utils";

const JoinRedirection = () => {
  const { userData, userRole, loading } = useGetUser();

  const isConsumerUser = checkUserRole(userRole, USER_TYPES.standard);

  if (loading) {
    return <div />;
  }

  if (userData && !isConsumerUser) {
    return <Navigate to='/business-dashboard' />;
  }

  return <Navigate to='/business-register' />;
};

export default JoinRedirection;
