import { gql } from "@apollo/client";
export default gql`
  query SERVICES_MOST_POPULAR($pageNum: Int) {
    mostPopularServices {
      __typename
      id
      name
      desc
      price
      votes
      isVoted
      isBookmarked
      collections(page: $pageNum) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          id
          name
        }
      }

      featuredImage {
        id
        path
      }
      business {
        name
        id
      }
    }
  }
`;
