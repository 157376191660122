export const collection = {
  Collection: {
    fields: {
      collectable: {
        keyArgs: false,
        merge(existing, incoming) {
          if (!incoming) return existing;
          if (!existing) return incoming;
          const { data, ...rest } = incoming;
          let result = rest;
          result.data = [...existing.data, ...data];
          // filter duplicates
          result.data = result.data.filter(
            (obj) =>
              !result.data[obj.item.__ref] &&
              (result.data[obj.item.__ref] = true)
          );
          return result;
        },
      },
    },
  },
};
