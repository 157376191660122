import React, { useState } from "react";
import styles from "./AuthLayout.module.css";
import Navigation from "../Navigation/Navigation";
import SideDrawer from "../Navigation/SideDrawer/SideDrawer";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const AuthLayout = () => {
  const [backdrop, setBackdrop] = useState(false);
  const [sideDrawer, setSideDrawer] = useState(false);

  const showSideDrawer = () => {
    setBackdrop(!backdrop);
    setSideDrawer(!sideDrawer);
  };
  const closeBackdrop = () => {
    setBackdrop(false);
    setSideDrawer(false);
  };

  return (
    <div className={`${styles.layout} ${showSideDrawer && styles.noScroll}`}>
      <ToastContainer />
      <Navigation
        backdrop={backdrop}
        showSideDrawer={showSideDrawer}
        closeBackdrop={closeBackdrop}
        sideDrawer={sideDrawer}
        closed={closeBackdrop}
      />
      <div className={`${sideDrawer && styles.marginDev}`}></div>
      <SideDrawer show={sideDrawer} closeSideDrawer={closeBackdrop} />
      <Outlet />
    </div>
  );
};

export default AuthLayout;
