import { gql } from "@apollo/client";
export default gql`
  mutation SOCIAL_LOGIN($token: String!) {
    loginWithSocial(token: $token) {
      accessToken
      refreshToken
      permissions
      roles {
        id
        permissions
        name
        label
      }
      user {
        ... on User {
          id
          email
          isOnboardedOnWeb
          firstName
          lastName
          subscribed
          isNewUser
          avatar {
            id
            path
          }
        }
      }
    }
  }
`;
