import { useReactiveVar } from "@apollo/client";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  appliedSearchFilters,
  notAppliedSearchFilters,
} from "../../../../utils/cache";
import FilterBubbles from "../../../GlobalComponents/UI/FilterBubbles/FilterBubbles";
import styles from "./Filters.module.css";
import PropTypes from "prop-types";
const Filters = (props) => {
  const { setTypeToggle, typeToggle } = props;
  const appliedFilters = useReactiveVar(appliedSearchFilters);
  const notAppliedFilters = useReactiveVar(notAppliedSearchFilters);
  const [params, setParams] = useSearchParams();
  const applyFilter = (filter) => {
    const item = notAppliedFilters.find((el) => el.filterType === filter);
    const newAppliedFilters = [...appliedFilters, item];
    const newNotApplied = notAppliedFilters.filter(
      (el) => el.filterType !== filter
    );
    appliedSearchFilters(newAppliedFilters);
    notAppliedSearchFilters(newNotApplied);
    if (typeToggle?.length) {
      params.delete("f");
    }
    if (params.get("f") !== filter) {
      params.append("f", filter);
      setParams(params);
    }
    setTypeToggle([]);
  };

  useEffect(() => {
    if (
      params.getAll("f")?.length &&
      !params.getAll("f").some((el) => el === "BUSINESS")
    ) {
      const newFilters = params
        .getAll("f")
        ?.map((filter) =>
          notAppliedFilters.find((el) => el.filterType === filter)
        );
      const newNotApplied = notAppliedFilters.map((filt) => {
        if (!params.getAll("f")?.some((el) => el === filt?.filterType)) {
          return filt;
        }
      });
      notAppliedSearchFilters(newNotApplied.filter(Boolean));
      appliedSearchFilters(newFilters);
    }
  }, []);

  return (
    <div className={styles.filtersContainer}>
      <h1 className={styles.title}>Filter by</h1>
      <FilterBubbles applyFilter={applyFilter} />
    </div>
  );
};

export default Filters;
Filters.propTypes = {
  setTypeToggle: PropTypes.func,
  typeToggle: PropTypes.array,
};
