import React from "react";
import PropTypes from "prop-types";
import EditTooltip from "../../../../GlobalComponents/UI/EditTooltip/EditTooltip";
import Input from "../../../../GlobalComponents/UI/Input/Input";
import ApplyEditBtn from "../../../../GlobalComponents/Buttons/ApplyEditBtn/ApplyEditBtn";
import { useReactiveVar } from "@apollo/client";
import useInput from "../../../../../hooks/useInput";
import { emailValidationRules } from "../../../../../utils/validationRules";
import useEditItem from "../../../../../hooks/useEditItem";
import { useParams } from "react-router-dom";
import { editView } from "../../../../../utils/cache";
import styles from "./BusinessEmail.module.css";
const BusinessEmail = (props) => {
  const { existingEmail, showEditTooltip, editToggleHandler } = props;
  const {
    value: email,
    hasError: emailError,
    errorMessage: emailErrorMessage,
    valueChangeHandler: emailHandler,
    valueBlurHandler: emailBlurHandler,
    isValid: emailValid,
  } = useInput(emailValidationRules, existingEmail);

  const isEditing = useReactiveVar(editView);

  const { id } = useParams();

  const { loading, editFormHandler } = useEditItem(id, {
    email,
  });

  return (
    <div
      className={`${styles.emailContainer} ${
        isEditing ? styles.edit : undefined
      }
      ${showEditTooltip === "email" ? styles.highZ : undefined}`}
    >
      <p
        className={styles.email}
        onClick={editToggleHandler.bind(null, "email")}
      >
        {existingEmail}
      </p>
      <svg
        width='42'
        height='28'
        viewBox='0 0 42 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onClick={editToggleHandler.bind(null, "email")}
      >
        <path
          d='M40.5394 0.644531C41.0602 0.644531 41.4827 1.06698 41.4827 1.58779L41.4827 27.0581C41.4827 27.579 41.0602 28.0014 40.5394 28.0014L1.86221 28.0014C1.34139 28.0014 0.918949 27.579 0.918949 27.0581L0.918946 1.5878C0.918946 1.06697 1.3414 0.644535 1.86221 0.644535L40.5394 0.644531ZM37.8566 2.53106L4.54468 2.53106L21.2006 15.9443L37.8566 2.53106ZM39.5957 3.54802L21.7901 17.8751C21.4453 18.1508 20.9557 18.1508 20.6109 17.8751L2.80522 3.54802L2.80522 26.1151L39.5957 26.115L39.5957 3.54802V3.54802Z'
          fill='#0a0a0a'
        />
      </svg>
      <EditTooltip
        show={showEditTooltip === "email" && isEditing}
        variant={styles.tooltip}
        anchorStyle='top'
        closeHandler={editToggleHandler.bind(null, "")}
      >
        <form onSubmit={editFormHandler}>
          <Input
            hasLabel
            isRequired
            label='EMAIL'
            placeholder='e.g. yourname@gmail.com'
            type='email'
            inpContainerClass={`${styles.inpContainer} ${
              emailError && styles.invalid
            }`}
            value={email}
            changeHandler={emailHandler}
            blurHandler={emailBlurHandler}
            invalid={emailError}
            errorMessage={emailErrorMessage}
          />

          <ApplyEditBtn
            disable={!emailValid || existingEmail === email}
            loading={loading}
          />
        </form>
      </EditTooltip>
    </div>
  );
};

export default BusinessEmail;
BusinessEmail.propTypes = {
  existingEmail: PropTypes.string,
  showEditTooltip: PropTypes.string,
  editToggleHandler: PropTypes.func,
};
