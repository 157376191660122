import { gql } from "@apollo/client";
export default gql`
  query DIFFERENT_BUSINESSES_MOST_POPULAR($type: BusinessType, $pageNum: Int) {
    mostPopularBusinessesWith(type: $type) {
      __typename
      id
      name
      label
      isVoted
      isBookmarked
      addrLine1
      collections(page: $pageNum) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          id
          name
        }
      }

      votes
      categories {
        name
      }
      city {
        name
      }
      state {
        abbr
      }

      featuredImage {
        id
        path
      }
    }
  }
`;
