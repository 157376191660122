import React from "react";
import Input from "../../UI/Input/Input";
import downArrow from "../../../../assets/images/downArrow.svg";
import PropTypes from "prop-types";
import { pageOptions } from "../../../../constants";
import styles from "./SelectPageInp.module.css";
const SelectPageInp = (props) => {
  const { page, pageChangeHandler, customStyle } = props;
  return (
    <Input
      elementType='select'
      hasLabel
      label='PAGE'
      options={pageOptions}
      inpContainerClass={`${styles.selectContainer} ${customStyle}`}
      value={page}
      changeHandler={pageChangeHandler}
    >
      <img className={styles.down} src={downArrow} alt='select' />
    </Input>
  );
};

export default SelectPageInp;
SelectPageInp.propTypes = {
  page: PropTypes.string,
  pageChangeHandler: PropTypes.func,
  customStyle: PropTypes.string,
};
