import React from "react";
import styles from "./SideToggle.module.css";
import PropTypes from "prop-types";
const SideToggle = (props) => {
  const { show, toggleClicked } = props;
  return (
    <div className={`${styles.sideToggle} side-drawer`} onClick={toggleClicked}>
      <div className='side-drawer'></div>
      <div className='side-drawer'></div>
      <div className='side-drawer'></div>
    </div>
  );
};

export default SideToggle;
SideToggle.propTypes = { show: PropTypes.bool, toggleClicked: PropTypes.func };
