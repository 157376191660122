import React from "react";
import styles from "./WorkingHours.module.css";
import { useParams, Navigate } from "react-router-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import business from "../../graphql/queries/pages/business";
import BusinessHours from "../../components/BusinessOnboarding/BusinessHours";
import HeaderBackBtn from "../../components/GlobalComponents/Admin/HeaderBackBtn";
import { isAdmin } from "../../utils/cache";

const WorkingHours = () => {
  const { id } = useParams();
  const { data } = useQuery(business, { variables: { id } });
  const admin = useReactiveVar(isAdmin);

  if (!id || id === "undefined") return <Navigate to='/business-dashboard' />;

  return (
    <div className={styles.workingHours}>
      {admin && <HeaderBackBtn isEdit backRoute={-1} />}
      <div className={`${styles.container} ${admin && styles.adminPadding}`}>
        <BusinessHours businessData={data?.business} isEdit />
      </div>
    </div>
  );
};

export default WorkingHours;
