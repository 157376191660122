import React from "react";
import styles from "../ProcessAndBenefits.module.css";
import TitleSlider from "../Slider";
import clover from "../../../../assets/images/clover.png";
import comcast from "../../../../assets/images/comcast.png";

const Awards = () => {
  return (
    <div className={styles.processContainer}>
      <TitleSlider text={"THE AWARDS"} />
      <div className={styles.content}>
        <h3 className={styles.mainDesc}>
          <span>Black Entrepreneur of the Year</span>
          <div className={styles.textLogoContainer}>
            <span className={styles.subDesc}>$15,000 award from </span>
            <a href='https://www.clover.com/' target='_blank' rel='noreferrer'>
              <img className={styles.clover} src={clover} alt='clover' />
            </a>
          </div>
        </h3>
        <h3 className={styles.mainDesc}>
          <span>Social Entrepreneur of the Year</span>
          <div className={styles.textLogoContainer}>
            <span className={styles.subDesc}>$10,000 award from </span>
            <a
              href='https://bit.ly/RISE22Q3_Editorial_OBWS'
              target='_blank'
              rel='noreferrer'
            >
              <img className={styles.comcast} src={comcast} alt='comcast' />
            </a>
          </div>
        </h3>
      </div>
    </div>
  );
};

export default Awards;
