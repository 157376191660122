import Cookies from "universal-cookie";
import {
  USER_TYPES,
  BUSINESS_TYPES,
  BusinessOnboardingState,
  ITEM_TYPES,
} from "../constants";
import business from "../graphql/queries/pages/business";
import food from "../graphql/queries/pages/food";
import product from "../graphql/queries/pages/product";
import service from "../graphql/queries/pages/service";
import { toast } from "react-toastify";

export const checkUserRole = (roles, roleId) => {
  return roles?.find((role) => role.id === roleId) ? true : false;
};

export const isBusinessType = (businessTypes, businessTypeName) => {
  return businessTypes && businessTypes?.includes(businessTypeName);
};

export const queryToRefetch = (itemType) => {
  switch (itemType) {
    case "business":
      return business;
    case "product":
      return product;
    case "service":
      return service;
    case "food":
      return food;
    default:
      return;
  }
};

export const getNewTokenHandler = async () => {
  const cookies = new Cookies();
  const refToken = cookies.get("refresh-token");
  const endpoint = `${process.env.REACT_APP_API_URL}/graphql`;
  const headers = {
    "content-type": "application/json",
  };
  const graphMutation = `mutation refreshToken{
    refreshToken(refreshToken:${JSON.stringify(refToken)}){
      accessToken
      refreshToken
    }
  }`;

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ query: graphMutation }),
  };
  const response = await fetch(endpoint, options);
  const data = await response.json();
  const newExpirationDate = new Date().getTime() + 3600000;
  const { accessToken } = data?.data?.refreshToken ?? {};
  const { refreshToken } = data?.data?.refreshToken ?? {};
  cookies.set("expired-at", newExpirationDate, { path: "/" });

  if (refreshToken)
    cookies.set("refresh-token", refreshToken, {
      path: "/",
    });
  if (accessToken)
    cookies.set("auth-token", accessToken, {
      path: "/",
    });
  return accessToken;
};
export const mapUserRole = (role) => {
  switch (role?.id) {
    case USER_TYPES.business:
      return "Business Owner";
    case USER_TYPES.admin:
      return "Admin";
    default:
      return "Consumer";
  }
};

export const successToastHandler = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const errorToastHandler = (message, options) => {
  toast.error(message ? message : "SOMETHING WENT WRONG", {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  });
};

export const warningToastHandler = (message, options) => {
  toast.warning(message, {
    position: "top-right",
    autoClose: false,
    hideProgressBar: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...options,
  });
};

export const ascCompare = (key, a, b) => {
  if (a[key] > b[key]) return 1;
  if (a[key] < b[key]) return -1;
  return 0;
};
export const descCompare = (key, a, b) => {
  if (a[key] < b[key]) return 1;
  if (a[key] > b[key]) return -1;
  return 0;
};
export const getUserNames = (name) => {
  const names = name.trim().split(" ");
  const namesLength = names.length;

  return {
    firstName: names[0] || "",
    lastName: namesLength > 1 ? names[namesLength - 1] : "",
  };
};

export const isBusinessCompleted = (status) => {
  if (
    status === BusinessOnboardingState.approve ||
    status === BusinessOnboardingState.done
  ) {
    return true;
  } else {
    return false;
  }
};

export const isFreePlanselected = (businesses, isSubscribed) => {
  return (
    businesses?.some(
      (el) =>
        el.onboardingState === BusinessOnboardingState.done ||
        el.onboardingState === BusinessOnboardingState.approve ||
        el.onboardingState === BusinessOnboardingState.disapprove ||
        el.onboardingState === BusinessOnboardingState.idDeclined
    ) && !isSubscribed
  );
};

export const isOnlineBusiness = (address, businessTypes) => {
  if (
    !address &&
    businessTypes?.some((el) => el === BUSINESS_TYPES.ecommerce)
  ) {
    return true;
  } else {
    return false;
  }
};

// sending segment/braze events
export const sendEventsHandler = (userData, event, item) => {
  analytics.track(event, {
    user_id: userData?.id,
    first_name: userData?.firstName,
    business_id:
      item?.__typename === ITEM_TYPES?.BUSINESS ? item?.id : item?.business?.id,
    business_name:
      item?.__typename === ITEM_TYPES?.BUSINESS
        ? item?.name
        : item?.business?.name,
    [`${item?.__typename?.toLowerCase()}_name`]: item?.name,
    [`${item?.id?.toLowerCase()}_id`]: item?.name,
  });
};

export const zeroToSingleDigit = (digit) => {
  const digitToString = digit.toString();
  if (digitToString?.length === 1) {
    return `0${digitToString}`;
  }
  return digitToString;
};
