import { useQuery, useReactiveVar } from "@apollo/client";
import React from "react";
import BrowseAllSection from "../../components/GlobalComponents/Sections/BrowseAllSection/BrowseAllSection";
import EditorPicksSection from "../../components/GlobalComponents/Sections/EditorPicksSection/EditorPicksSection";
import MostPopularSection from "../../components/GlobalComponents/Sections/MostPopularSection/MostPopularSection";
import SharedHeroHeader from "../../components/GlobalComponents/Sections/SharedHeroHeader/SharedHeroHeader";
import SharedSignUpAd from "../../components/GlobalComponents/Sections/SharedSignUpAd/SharedSignUpAd";
import mostPopularDifferentBusinesses from "../../graphql/queries/mostPopular/mostPopularDifferentBusinesses";
import regStyles from "./ECommerce.module.css";
import adminStyles from "../../assets/styles/shared/AdminEditor.module.css";
import { isAdmin } from "../../utils/cache";
import RecentlyListed from "../../components/GlobalComponents/Sections/RecentlyListed";
import { PAGES_NAMES } from "../../constants";

const ECommerce = () => {
  const { data, loading, error } = useQuery(mostPopularDifferentBusinesses, {
    variables: { type: "Ecommerce" },
  });
  const { mostPopularBusinessesWith: mostPopular } = data ?? {};
  const heroBusiness = mostPopular && mostPopular[0];
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;

  return (
    <div className={styles.eCommercePage}>
      <SharedHeroHeader
        imgSrc={heroBusiness?.featuredImage?.path}
        itemLink={`/business/${heroBusiness?.id}`}
        itemTitle={heroBusiness?.name}
      />
      <RecentlyListed pageName={PAGES_NAMES.ECOMMERCE} />

      <BrowseAllSection pageName={PAGES_NAMES.ECOMMERCE} />
      <MostPopularSection
        mostPopular={mostPopular?.slice(1)}
        isShared={true}
        loading={loading}
        error={error}
        pageName={PAGES_NAMES.ECOMMERCE}
      />

      <EditorPicksSection pageName={PAGES_NAMES.ECOMMERCE} />
      <SharedSignUpAd />
    </div>
  );
};

export default ECommerce;
