import React, { useEffect, useMemo, useRef, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import PropTypes from "prop-types";
import SearchList from "./SearchList/SearchList";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./SearchBar.module.css";
import Tooltip from "../../UI/Tooltip/Tooltip";
import searchIcon from "../../../../assets/images/searchIcon.svg";
import landingSearchIcon from "../../../../assets/images/landingSearchIcon.png";

import ConsumerOnboarding from "../../../Onboarding/Consumer/ConsumerOnboarding";
import { consumerOnboardingVar } from "../../../../utils/cache";
import { useReactiveVar } from "@apollo/client";
import Cookies from "universal-cookie";

const APPROVED_BUSINESS_STATUS = 10;

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const SearchBar = (props) => {
  const { variant, inpContainer, placeholder, closeSideDrawer } = props;
  const { pathname } = useLocation();
  const childRef = useRef();
  const onboardingStep = useReactiveVar(consumerOnboardingVar);
  const handleNextStep = () => consumerOnboardingVar(onboardingStep + 1);
  const cookies = new Cookies();
  const userId = cookies.get("user-id");

  const [cursor, setCursor] = useState(-1);
  const [showList, setShowList] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [autocompleteState, setAutocompleteState] = useState({});

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        getSources() {
          return [
            {
              sourceId: "items",
              getItemInputValue({ state }) {
                return state.query;
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
                      query,
                      params: {
                        hitsPerPage: 8,
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                return item.url;
              },
            },
          ];
        },
      }),
    []
  );
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [searchItems, setSearchItems] = useState([]);

  useEffect(() => {
    if (
      searchText?.length > 1 &&
      searchText?.trim(" ") &&
      autocompleteState?.status === "idle"
    ) {
      setShowList(true);
    } else {
      setShowList(false);
    }
  }, [searchText, autocompleteState]);

  useEffect(() => {
    autocompleteState.isOpen &&
      autocompleteState.collections.map((collection) => {
        const { items } = collection;
        const approvedItems = items?.filter(
          (item) => item?.status === APPROVED_BUSINESS_STATUS
        );
        setSearchItems(approvedItems);
      });
  }, [autocompleteState]);
  const clearText = () => {
    setSearchText("");
    closeSideDrawer && closeSideDrawer();
    setCursor(-1);
  };

  const navigateHandler = () => {
    if (searchText.trim(" ") && searchItems?.length > 0) {
      navigate(`/search?t=${searchText}`);
      clearText();
    }
  };

  const keyDownHandler = (e) => {
    if (e.key === "Enter" && cursor === -1) {
      navigateHandler();
    }
    if (
      e.key === "Enter" &&
      searchText &&
      cursor > -1 &&
      cursor < searchItems?.length
    ) {
      const item = searchItems[cursor];
      navigate(`/search?t=${item?.name}`);
      clearText();
    }
    if (e.key === "Enter" && searchText && cursor === searchItems?.length) {
      navigate(`/search?t=${searchText}`);
      clearText();
    }
    if (e.key === "ArrowDown" && showList && cursor < searchItems?.length) {
      setCursor((prev) => prev + 1);
    }
    if (e.key === "ArrowUp" && showList && cursor > -1) {
      setCursor((prev) => prev - 1);
    }
  };
  const showListHandler = (e) => {
    if (
      searchText?.length > 1 &&
      searchText.trim(" ") &&
      autocompleteState?.status === "idle"
    )
      setShowList(true);
  };
  const hideListHandler = () => {
    setShowList(false);
  };
  const searchChangeHandler = (e) => {
    autocomplete.getInputProps({}).onChange(e);
    autocomplete.getInputProps({}).value = e.target.value;
    setSearchText(e.target.value);
  };

  return (
    <div className={inpContainer} {...autocomplete.getRootProps({})}>
      <input
        placeholder={
          placeholder ? placeholder : "Search for anything Black-owned..."
        }
        className={variant}
        ref={inputRef}
        value={searchText}
        onChange={searchChangeHandler}
        onBlur={hideListHandler}
        onFocus={showListHandler}
        onKeyDown={keyDownHandler}
      />
      <img
        src={pathname === "/awards2022" ? landingSearchIcon : searchIcon}
        alt='search'
        className={styles.searchIcon}
        onClick={navigateHandler}
      />
      <Tooltip
        show={onboardingStep === 1 && pathname === "/"}
        anchorVariant='top'
        customClass={styles.searchBarTooltip}
        handleTooltipClose={(e) => childRef?.current?.handleClose(e)}
      >
        <ConsumerOnboarding
          ref={childRef}
          onboardingStep={onboardingStep + 1}
          heading='Search for anything'
          description='Search for anything that you can think of, by using our search feature.'
          handleNext={handleNextStep}
        />
      </Tooltip>

      {showList && (
        <SearchList
          searchItems={searchItems}
          searchTerm={searchText}
          listToggleHandler={hideListHandler}
          clearText={clearText}
          cursor={cursor}
        />
      )}
    </div>
  );
};

export default SearchBar;
SearchBar.propTypes = {
  variant: PropTypes.string,
  inpContainer: PropTypes.string,
  placeholder: PropTypes.string,
  closeSideDrawer: PropTypes.func,
};
