import React, { useState, useEffect } from "react";
import styles from "./SavingInfoState.module.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useReactiveVar } from "@apollo/client";
import { savingBizInfoState } from "../../../utils/cache";

const SavingInfoState = () => {
  const state = useReactiveVar(savingBizInfoState);
  const [percentage, setPercentage] = useState(100);
  useEffect(() => {
    if (state?.loading) {
      setPercentage(10);
    } else {
      setPercentage(100);
    }
  }, [state]);

  if (state?.loading) {
    return (
      <div className={styles.container}>
        <span className={`${styles.text} ${styles.black}`}>Saving...</span>
        <div className={styles.wrapper}>
          <CircularProgressbar
            strokeWidth={50}
            value={percentage}
            styles={buildStyles({
              strokeLinecap: "butt",
              trailColor: `rgba(10, 10, 10, 0.2)`,
              pathColor: "#0a0a0a",
              pathTransitionDuration: 2,
            })}
          />
        </div>
      </div>
    );
  }

  if (!state?.error && !state?.loading && state) {
    return (
      <div className={styles.container}>
        <span className={`${styles.text} ${styles.black}`}>Progress saved</span>
        <svg
          width='24'
          height='16'
          viewBox='0 0 26 19'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M1 9.47059L8.68 17L25 1' stroke='#32D071' strokeWidth='2' />
        </svg>
      </div>
    );
  }
  if (state?.error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.textBtnContainer}>
          <span className={`${styles.text} ${styles.danger}`}>
            Save failed.{" "}
          </span>
          <button
            className={`${styles.tryBtn} ${styles.danger}`}
            onClick={state?.retry}
          >
            Try again?
          </button>
        </div>
        <svg
          width='24'
          height='21'
          viewBox='0 0 24 21'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <line
            x1='12'
            y1='9'
            x2='12'
            y2='14'
            stroke='#EE4655'
            strokeWidth='2'
          />
          <circle cx='12' cy='16' r='0.5' fill='#EE4655' stroke='#EE4655' />
          <path
            d='M1.72318 20L12 2.01556L22.2768 20H1.72318Z'
            stroke='#EE4655'
            strokeWidth='2'
          />
        </svg>
      </div>
    );
  }
  return null;
};

export default SavingInfoState;
