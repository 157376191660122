import React from "react";
import styles from "./CollectionImage.module.css";
import PropTypes from "prop-types";
import ImageShapes from "../../../GlobalComponents/UI/ImageShapes/ImageShapes";

const CollectionImage = (props) => {
  const { imgSrc, variant, activeCollection, selecting } = props;
  if (imgSrc && selecting) {
    return (
      <div className={styles.container}>
        <ImageShapes imgSrc={imgSrc} variant={variant} />
        {activeCollection ? (
          <>
            <div className={styles.layer} />
            <button className={`${styles.activeBtn} ${styles.btn}`}></button>
          </>
        ) : (
          <button className={`${styles.notActiveBtn} ${styles.btn}`}></button>
        )}
      </div>
    );
  }
  if (!selecting && imgSrc) {
    return <ImageShapes imgSrc={imgSrc} variant={variant} />;
  }
  if (!selecting && !imgSrc) {
    return (
      <div className={`${styles.placeholder} ${variant}`}>
        <svg
          width='21'
          height='21'
          viewBox='0 0 21 21'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0 10.0352L20.0698 10.0352'
            stroke='#0A0A0A'
            strokeOpacity='0.2'
            strokeWidth='1.24588'
          />
          <path
            d='M10.0369 20.0703L10.0369 0.000542883'
            stroke='#0A0A0A'
            strokeOpacity='0.2'
            strokeWidth='1.24588'
          />
        </svg>
      </div>
    );
  }
  return (
    <div className={styles.placeholder}>
      {activeCollection ? (
        <>
          <div className={styles.layer} />
          <button className={`${styles.activeBtn} ${styles.btn}`}></button>
        </>
      ) : (
        <button className={`${styles.notActiveBtn} ${styles.btn}`}></button>
      )}

      <svg
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0 10.0352L20.0698 10.0352'
          stroke='#0A0A0A'
          strokeOpacity='0.2'
          strokeWidth='1.24588'
        />
        <path
          d='M10.0369 20.0703L10.0369 0.000542883'
          stroke='#0A0A0A'
          strokeOpacity='0.2'
          strokeWidth='1.24588'
        />
      </svg>
    </div>
  );
};

export default CollectionImage;
CollectionImage.propTypes = {
  imgSrc: PropTypes.string,
  variant: PropTypes.string,
  activeCollection: PropTypes.bool,
  selecting: PropTypes.bool,
};
