export const nameValidationRules = {
  required: true,
  nameLength: true,
  nameSpecial: true,
  limitLength: true,
};
export const collectionNameRules = {
  required: true,
  collectionLength: true,
};
export const emailValidationRules = { required: true, isEmail: true };
export const passwordValidationRules = {
  required: true,
  passwordNoSpecialCharacters: true,
  passwordLength: true,
  passwordContainSpaces: true,
};
export const confirmPasswordRules = {
  required: true,
  passwordMatch: true,
};
export const confirmCodeValidation = {
  required: true,
};
export const businessNameValidation = {
  required: true,
  limitLength: true,
};
export const businessPhoneValidation = {
  validPhone: true,
};
export const userPhoneValidation = {
  validPhone: true,
};
export const businessWebsiteValidation = {
  validWebsite: true,
  limitLength: true,
};
export const itemWebsiteValidation = {
  validWebsite: true,
  limitLength: true,
  required: true,
};
export const businessAddressValidationRules = { required: true };
export const stateValidationRules = { required: true };
export const zipCodeValidationRules = { required: true, validZipCode: true };
export const countryValidationRules = { required: true };
export const isRequired = { required: true };
export const descriptionValidation = { required: true, validDescription: true };
export const priceValidation = { required: true, validPrice: true };
export const fullNameValidationRules = {
  required: true,
  isFullName: true,
  nameLength: true,
  nameSpecial: true,
  limitLength: true,
};
export const businessOwnerNameRules = {
  required: true,
  isFullName: true,
  ownerLength: true,
  nameSpecial: true,
};
