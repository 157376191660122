import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useLazyQuery } from "@apollo/client";
import {
  geocodeByPlaceId,
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete";
import states from "../../../graphql/queries/states";
import cities from "../../../graphql/queries/cities";
import countries from "../../../graphql/queries/countries";
import Input from "../../../components/GlobalComponents/UI/Input/Input";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import styles from "./BusinessApplication.module.css";
import { isBusinessType } from "../../../utils";
import { BUSINESS_TYPES } from "../../../constants";

export default function BusinessAddressSection({
  businessAddress,
  businessAddressError,
  businessAddressErrorMessage,
  businessAddressHandler,
  businessAddressBlurHandler,
  optionalAddress,
  optionalAddressError,
  optionalAddressErrorMessage,
  optionalAddressHandler,
  optionalAddressBlurHandler,
  city,
  cityError,
  cityErrorMessage,
  cityHandler,
  cityBlurHandler,
  state,
  stateError,
  stateErrorMessage,
  stateHandler,
  stateBlurHandler,
  zipCode,
  zipCodeError,
  zipCodeErrorMessage,
  zipCodeHandler,
  zipCodeBlurHandler,
  country,
  countryError,
  countryErrorMessage,
  countryHandler,
  countryBlurHandler,
  businessData,
  isOnline,
  toggleIsOnlineHandler,
  setAddressLocation,
}) {
  const { data: countriesData, loading: countryLoading } = useQuery(countries);
  const [getStates, { data: statesData, loading: stateLoading }] =
    useLazyQuery(states);
  const [getCities, { data: citiesData, loading: cityLoading }] =
    useLazyQuery(cities);

  const [addLoading, setAddLoading] = useState(false);

  const isLoadingAddress =
    countryLoading || stateLoading || cityLoading || addLoading;
  const isEcommerceOnly =
    businessData?.businessTypes?.length === 1 &&
    isBusinessType(businessData?.businessTypes, BUSINESS_TYPES.ecommerce);
  useEffect(() => {
    if (businessData?.addrLine1) {
      setAddLoading(true);
      geocodeByAddress(businessData?.addrLine1)
        .then((results) => {
          const country =
            results[0].address_components.find((c) =>
              c.types.includes("country")
            )?.long_name || "";
          countryChangeHandler({ target: { value: country } });
          const countryId =
            countriesData?.countries?.data?.find((ctr) =>
              ctr?.name?.includes(country)
            )?.id ?? "";
          return countryId;
        })
        .then((countryId) => {
          getStates({
            variables: { filters: { countries: [`${countryId}`] } },
          }).then(({ data }) => {
            const stateName = data?.states?.data?.find(
              (el) => +el?.id === +businessData?.state?.id
            )?.name;
            stateChangeHandler({ target: { value: stateName } });
          });
          getCities({
            variables: {
              filters: {
                states: businessData?.state
                  ? [`${businessData?.state?.id}`]
                  : null,
              },
            },
          }).then(({ data }) => {
            const cityName = data?.cities?.data?.find(
              (el) => +el?.id === +businessData?.city?.id
            )?.name;
            cityHandler({ target: { value: cityName } });
            setAddLoading(false);
          });
        });
    }
  }, [businessData, countriesData]);

  const businessAddressSelectHandler = async (address, placeId) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    businessAddressHandler(place?.formatted_address);

    const placeDetails = {
      zipCode:
        place.address_components.find((c) => c.types.includes("postal_code"))
          ?.long_name || "",
      country:
        place.address_components.find((c) => c.types.includes("country"))
          ?.long_name || "",
      city:
        place.address_components.find(
          (c) =>
            c.types.includes("locality") ||
            c.types.includes("administrative_area_level_3")
        )?.long_name || "",
      state:
        place.address_components.find((c) =>
          c.types.includes("administrative_area_level_1")
        )?.long_name || "",
    };

    countryChangeHandler({ target: { value: placeDetails?.country } });
    stateChangeHandler({ target: { value: placeDetails?.state } });
    zipCodeHandler({ target: { value: placeDetails?.zipCode } });
    cityHandler({ target: { value: placeDetails?.city } });
    setAddressLocation(latLng);
  };
  const countryChangeHandler = (e) => {
    countryHandler(e);
    const countryId =
      countriesData?.countries?.data?.find((country) =>
        country?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      )?.id ?? "";
    getStates({
      variables: { filters: { countries: [`${countryId}`] } },
    });
  };

  const stateChangeHandler = (e) => {
    stateHandler(e);
    const stateId =
      statesData?.states?.data?.find((state) =>
        state?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      )?.id ?? "";
    getCities({
      variables: { filters: { states: stateId ? [`${stateId}`] : null } },
    });
  };

  const placesErrorHandler = (status, clearSuggestions) => {
    clearSuggestions();
  };

  return (
    <div className={styles.section}>
      <AdminTitle title='Business Address' />
      <p className={styles.description}>
        Enter the address for customers to find you
      </p>
      {isLoadingAddress ? (
        <Spinner />
      ) : (
        <div className={styles.addressFormContainer}>
          <div className={styles.group}>
            <Input
              hasLabel
              label='BUSINESS ADDRESS'
              isRequired={!isEcommerceOnly}
              placeholder='e.g. 555 Magnolia Blvd.'
              elementType='places'
              inpContainerClass={styles.textField}
              value={businessAddress}
              selectHandler={businessAddressSelectHandler}
              changeHandler={businessAddressHandler}
              blurHandler={businessAddressBlurHandler}
              invalid={!isOnline && businessAddressError}
              errorMessage={businessAddressErrorMessage}
              placesContainerClass={styles.placesContainerClass}
              placesErrorHandler={placesErrorHandler}
            />
          </div>
          <div className={styles.group}>
            <Input
              hasLabel
              label='BUSINESS ADDRESS 2'
              placeholder='e.g. Suite Number 5'
              type='text'
              inpContainerClass={styles.textField}
              value={optionalAddress}
              changeHandler={optionalAddressHandler}
              blurHandler={optionalAddressBlurHandler}
              invalid={!isOnline && optionalAddressError}
              errorMessage={optionalAddressErrorMessage}
            />
          </div>
          <div className={styles.flexWrapper}>
            <div className={styles.group}>
              <Input
                hasLabel
                label='COUNTRY'
                isRequired={!isEcommerceOnly}
                placeholder='e.g. United States'
                elementType='select'
                inpContainerClass={`${styles.textField} ${styles.halfWidthField}`}
                options={countriesData?.countries?.data
                  ?.filter(
                    ({ name }) =>
                      name === "United States of America" || name === "Canada"
                  )
                  .map((country) => ({
                    ...country,
                    value: country?.name?.includes("America")
                      ? "United States"
                      : country?.name,
                    label: country?.name,
                  }))}
                value={country}
                changeHandler={countryChangeHandler}
                blurHandler={countryBlurHandler}
                invalid={!isOnline && countryError}
                errorMessage={countryErrorMessage}
              />
            </div>
            <div>
              <Input
                hasLabel
                label='STATE'
                isRequired={!isEcommerceOnly}
                placeholder='e.g. California'
                elementType='select'
                inpContainerClass={`${styles.textField} ${styles.halfWidthField}`}
                value={state}
                options={statesData?.states?.data?.map((state) => ({
                  ...state,
                  value: state?.name,
                  label: state?.name,
                }))}
                changeHandler={stateChangeHandler}
                blurHandler={stateBlurHandler}
                invalid={!isOnline && stateError}
                errorMessage={stateErrorMessage}
              />
            </div>
          </div>
          <div className={styles.flexWrapper}>
            <div className={styles.group}>
              <Input
                hasLabel
                label='CITY'
                isRequired={!isEcommerceOnly}
                placeholder='e.g. North Hollywood'
                elementType='select'
                inpContainerClass={`${styles.textField} ${styles.halfWidthField}`}
                value={city}
                options={citiesData?.cities?.data?.map((city) => ({
                  ...city,
                  value: city?.name,
                  label: city?.name,
                }))}
                changeHandler={cityHandler}
                blurHandler={cityBlurHandler}
                invalid={!isOnline && cityError}
                errorMessage={cityErrorMessage}
              />
            </div>
            <div className={styles.group}>
              <Input
                hasLabel
                label='ZIP CODE'
                isRequired={!isEcommerceOnly}
                placeholder='e.g. 91402'
                type='text'
                inpContainerClass={`${styles.textField} ${styles.halfWidthField}`}
                value={zipCode}
                changeHandler={zipCodeHandler}
                blurHandler={zipCodeBlurHandler}
                invalid={!isOnline && zipCodeError}
                errorMessage={zipCodeErrorMessage}
              />
            </div>
          </div>

          {isEcommerceOnly && (
            <Input
              elementType='checkbox'
              hasLabel={true}
              label='My business is online only'
              inpContainerClass={styles.inpContainer}
              checked={isOnline}
              changeHandler={toggleIsOnlineHandler}
              customCheckBox={styles.checkMark}
            />
          )}
        </div>
      )}
    </div>
  );
}

BusinessAddressSection.propTypes = {
  businessAddress: PropTypes.string,
  businessAddressError: PropTypes.bool,
  businessAddressErrorMessage: PropTypes.string,
  businessAddressHandler: PropTypes.func,
  businessAddressBlurHandler: PropTypes.func,
  optionalAddress: PropTypes.string,
  optionalAddressError: PropTypes.bool,
  optionalAddressErrorMessage: PropTypes.string,
  optionalAddressHandler: PropTypes.func,
  optionalAddressBlurHandler: PropTypes.func,
  city: PropTypes.string,
  cityError: PropTypes.bool,
  cityErrorMessage: PropTypes.string,
  cityHandler: PropTypes.func,
  cityBlurHandler: PropTypes.func,
  state: PropTypes.string,
  stateError: PropTypes.bool,
  stateErrorMessage: PropTypes.string,
  stateHandler: PropTypes.func,
  stateBlurHandler: PropTypes.func,
  zipCode: PropTypes.string,
  zipCodeError: PropTypes.bool,
  zipCodeErrorMessage: PropTypes.string,
  zipCodeHandler: PropTypes.func,
  zipCodeBlurHandler: PropTypes.func,
  country: PropTypes.string,
  countryError: PropTypes.bool,
  countryErrorMessage: PropTypes.string,
  countryHandler: PropTypes.func,
  countryBlurHandler: PropTypes.func,
  businessData: PropTypes.object,
  isOnline: PropTypes.bool,
  toggleIsOnlineHandler: PropTypes.func,
  setAddressLocation: PropTypes.func,
};
