import { gql } from "@apollo/client";

export default gql`
  mutation UPDATE_USER(
    $id: ID!
    $firstName: String
    $lastName: String
    $phone: String
    $email: Email
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      email: $email
    ) {
      id
      firstName
      lastName
    }
  }
`;
