import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styles from "./Sidebar.module.css";

export default function Sidebar({ sidebarItems, title, name }) {
  return (
    <div className={styles.sidebar}>
      <div className={styles.upperSection}>
        <p className={styles.title}> {title}</p>
        <p className={styles.name}>{name}</p>
      </div>
      <div className={styles.itemsList}>
        {sidebarItems?.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            className={({ isActive }) =>
              isActive
                ? `${styles.active} ${styles.item}`
                : `${styles.link} ${styles.item}`
            }
          >
            {item.label}
          </NavLink>
        ))}
      </div>
      <NavLink to='/' className={styles.visitLink}>
        <div className={styles.visitContainer}>
          <p className={styles.visitText}>Visit obws.com</p>
          <svg
            width='31'
            height='13'
            viewBox='0 0 33 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M-5.68248e-07 7.5L31 7.5M31 7.5L24.1111 1M31 7.5L24.1111 14'
              stroke='#1977F3'
              strokeWidth='1.47222'
            />
          </svg>
        </div>
      </NavLink>
    </div>
  );
}

Sidebar.propTypes = {
  sidebarItems: PropTypes.array,
  title: PropTypes.string,
  name: PropTypes.string,
};
