import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./UpVoteButton.module.css";
import { useMutation, useReactiveVar } from "@apollo/client";
import upVote from "../../../../graphql/mutations/upVote";
import Spinner from "../../UI/Spinner/Spinner";
import { authVar, brazeCustomAttributes } from "../../../../utils/cache";
import { numberFormatter } from "../../../../utils/formatters";
import RegisterPopUp from "../../UI/RegisterPopUp/RegisterPopUp";
import Backdrop from "../../UI/Backdrop/Backdrop";
import { errorToastHandler, sendEventsHandler } from "../../../../utils";
import useUpdateUser from "../../../../hooks/useUpdateUser";

const UpVoteButton = (props) => {
  const [vote, setVote] = useState();
  const [active, setActive] = useState(false);
  const { variant, storyBookAction, voteCount, itemType, itemId, voted, item } =
    props;
  const existingAttributes = useReactiveVar(brazeCustomAttributes);

  const [upVoteMutation, { loading, error }] = useMutation(upVote, {
    update: (cache, res) => {
      if (itemType) {
        cache.modify({
          id: `${itemType}:${itemId}`,
          fields: {
            votes() {
              return res.data?.upvoteItem;
            },
            isVoted(cachedIsVoted) {
              return !cachedIsVoted;
            },
          },
        });
      }
    },
  });
  const { updateUser } = useUpdateUser();
  const isAuth = useReactiveVar(authVar);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const toggleBackdrop = (e) => {
    setShowBackdrop(!showBackdrop);
    e.stopPropagation();
    e.preventDefault();
  };
  useEffect(() => {
    setActive(voted);
    setVote(voteCount);
  }, [voteCount, voted]);

  // updating votes_count attribute
  const updateUserVotes = () => {
    let newVotesCount;
    if (active) {
      newVotesCount = existingAttributes?.user_upvotes_count - 1;
    } else {
      newVotesCount = existingAttributes?.user_upvotes_count + 1;
    }

    brazeCustomAttributes({
      ...existingAttributes,
      user_upvotes_count: newVotesCount,
    });
    analytics.identify(isAuth?.id, {
      user_upvotes_count: newVotesCount,
    });
  };

  const revertUserVotes = () => {
    let newVotesCount;
    if (active) {
      newVotesCount = existingAttributes?.user_upvotes_count + 1;
    } else {
      newVotesCount = existingAttributes?.user_upvotes_count - 1;
    }

    brazeCustomAttributes({
      ...existingAttributes,
      user_upvotes_count: newVotesCount,
    });
    analytics.identify(isAuth?.id, {
      user_upvotes_count: newVotesCount,
    });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isAuth) {
      setShowBackdrop(true);
      return;
    }

    if (loading) {
      return;
    }
    updateUserVotes();
    upVoteMutation({
      variables: { itemType, itemId },
    })
      .then(({ data }) => {
        if (!active) {
          analytics.track("number_of_upvote");
          sendEventsHandler(isAuth, `${itemType?.toLowerCase()}_upvoted`, item);
        }
        setActive(!active);
        setVote(data?.upvoteItem);
        storyBookAction && storyBookAction();
        updateUser();
      })
      .catch((err) => {
        console.log(err);
        revertUserVotes();
        errorToastHandler();
      });
  };

  return (
    <>
      <Backdrop show={showBackdrop} clickHandler={toggleBackdrop}>
        <RegisterPopUp closeBackdrop={toggleBackdrop} />
      </Backdrop>
      <button
        onClick={handleClick}
        className={`${variant} ${styles.upVoteBtn}  ${
          loading && styles.loadingBg
        }  ${active ? styles.active : ""}`}
        aria-label='up vote'
      >
        {loading ? (
          <Spinner variant={styles.loader} />
        ) : (
          <>
            <svg
              width='9'
              height='9'
              viewBox='0 0 9 9'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.50995 8.40816L4.50995 1.2653M4.50995 1.2653L0.428314 4.84467M4.50995 1.2653L8.59158 4.84467'
                stroke='#0A0A0A'
                strokeWidth='1.08337'
              />
            </svg>
            <p className={styles.vote}>{numberFormatter(vote)}</p>
          </>
        )}
      </button>
    </>
  );
};

export default UpVoteButton;

UpVoteButton.propTypes = {
  variant: PropTypes.string,
  storyBookAction: PropTypes.func,
  voteCount: PropTypes.number,
  voteHandler: PropTypes.func,
  voted: PropTypes.bool,
  itemType: PropTypes.string,
  itemId: PropTypes.number,
  item: PropTypes.object,
};
