import { gql } from "@apollo/client";
export default gql`
  query PRICE_RANGE {
    itemsWithPriceRange {
      ... on Product {
        price
      }
      ... on Service {
        price
      }
      ... on Food {
        price
      }
      ... on Business {
        priceRange
      }
    }
  }
`;
