import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import {
  MAX_MESSAGE_LENGTH,
  MIN_MESSAGE_LENGTH,
  STRING_MAX_LENGTH,
} from "../constants";

export const inputValidation = (rules, value) => {
  let isValid = true;
  let errorMessage = "";

  if (rules.required) {
    isValid = value ? value?.toString().trim() !== "" && isValid : false;

    errorMessage = "This field is required.";
  }
  if (rules.nameLength && isValid) {
    const lengthCheck = value.length > 2 && value.length < 20;
    isValid = lengthCheck && isValid;

    errorMessage = "The Name must be between 2 and 20 characters.";
  }
  if (rules.ownerLength && isValid) {
    const lengthCheck = value.length > 2 && value.length < 30;
    isValid = lengthCheck && isValid;

    errorMessage = "Business Owner Name must be between 2 and 30 characters.";
  }
  if (rules.collectionLength && isValid) {
    const lengthCheck = value.length > 0 && value.length < 20;
    isValid = lengthCheck && isValid;

    errorMessage = "Collection name must be less than 20 characters.";
  }
  if (rules.nameSpecial && isValid) {
    const pattern = /^[a-zA-Z.'\s\-]{2,30}$/;
    isValid = pattern.test(String(value).toLowerCase()) && isValid;

    errorMessage =
      "Only alphabets, and some special characters (hyphen, space, apostrophe, and full stop) are allowed.";
  }

  if (rules.validPhone && isValid) {
    const phoneCheck =
      isValidPhoneNumber(value) && isPossiblePhoneNumber(value);
    isValid = phoneCheck && isValid;

    errorMessage = "The Phone Number must have a correct format.";
  }

  if (rules.validZipCode && isValid) {
    const zipCodePattern = /^[a-z0-9A-Z][a-zA-Z0-9\s\-]{0,10}[a-z0-9]$/;
    isValid = zipCodePattern.test(String(value)) && isValid;

    errorMessage = "The Zip Code must have a correct format.";
  }

  if (rules.validWebsite && isValid) {
    const websitePattern =
      /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
    isValid =
      (websitePattern.test(String(value)) && isValid) || (!value && isValid);

    errorMessage = "The Website must have a correct format.";
  }

  if (rules.isEmail && isValid) {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = pattern.test(String(value).toLowerCase()) && isValid;
    errorMessage = "The Email Address must have a valid format.";
  }

  if (rules.passwordNoSpecialCharacters && isValid) {
    const pattern = /\W|_/;
    isValid = pattern.test(value) && isValid;
    errorMessage = "The Password must contain at least one special character.";
  }

  if (rules.passwordLength && isValid) {
    const pattern = /^.{9,31}$/;
    isValid = pattern.test(value) && isValid;
    errorMessage = "The Password must be between 8 and 32 characters";
  }
  if (rules.passwordContainSpaces && isValid) {
    isValid = !/\s/g.test(value) && isValid;
    errorMessage = "The Password cannot contain spaces.";
  }
  if (rules.passwordMatch && isValid) {
    isValid = value === rules.matchValue && isValid;
    errorMessage = "Passwords must match.";
  }
  if (rules.validDescription && isValid) {
    isValid =
      value.length >= MIN_MESSAGE_LENGTH &&
      value.length < MAX_MESSAGE_LENGTH &&
      isValid;
    errorMessage = "Must be between 50 and 400 characters";
  }
  if (rules.isFullName && isValid) {
    isValid = value?.trim().split(" ").length > 1 && isValid;
    errorMessage = "The last name is required";
  }
  if (rules.validPrice && isValid) {
    isValid = !isNaN(value) && +value > 0;
    errorMessage = "Please enter a valid price";
  }
  if (rules.limitLength && isValid) {
    isValid = value?.length <= STRING_MAX_LENGTH && isValid;
    errorMessage = "Maximum Website length is 255 characters.";
  }

  return { isValid, errorMessage };
};
