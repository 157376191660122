import { gql } from "@apollo/client";
export default gql`
  query stripePrices {
    stripePrices {
      id
      active
      name
      currency
      desc
      type
      features
      recurringInterval
      intervalCount
      trialDays
      amount
    }
  }
`;
