import { gql } from "@apollo/client";
export default gql`
  mutation ADD_TO_COLLECTION($items: [collectionItem], $collectionPage: Int) {
    addItemsToCollection(items: $items) {
      __typename
      ... on Service {
        isBookmarked
        collections(page: $collectionPage) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }
      }
      ... on Food {
        isBookmarked
        collections(page: $collectionPage) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }
      }
      ... on Product {
        isBookmarked
        collections(page: $collectionPage) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }
      }
      ... on Business {
        isBookmarked
        collections(page: $collectionPage) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }
      }
    }
  }
`;
