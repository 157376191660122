import React from "react";
import regStyles from "./DiscoverHeroHeader.module.css";
import adminStyles from "./AdminStyles.module.css";
import discoverHeaderImg from "../../../../assets/images/discoverHeroImg.png";
import discoverHero from "../../../../assets/images/discoverHero.png";
import discoverHeaderWebp from "../../../../assets/images/discoverHeroImg.webp";
import discoverHeroWebp from "../../../../assets/images/discoverHero.webp";
import { useReactiveVar } from "@apollo/client";
import { isAdmin } from "../../../../utils/cache";
import Picture from "../../UI/Picture";
const DiscoverHeroHeader = () => {
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  return (
    <section className={styles.headerSection}>
      <div className={styles.headerContainer}>
        <div className={styles.bgBlack} />
        <h6 className={styles.text}>Find Anything Black-Owned. Anytime.</h6>
        <Picture
          fallback={discoverHeaderImg}
          webpSrc={discoverHeaderWebp}
          alt='discover header img'
          variant={styles.headerImg}
          loaderStyles={`${styles.headerLoader} ${styles.headerImg}`}
        />
        <Picture
          fallback={discoverHero}
          webpSrc={discoverHeroWebp}
          alt='discover header img'
          variant={styles.smallerImg}
          loadingPlaceholder={
            <div
              className={`${styles.smallerImgLoader} ${styles.smallPlaceholder}`}
            />
          }
        />
      </div>
    </section>
  );
};

export default DiscoverHeroHeader;
