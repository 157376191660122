import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  geocodeByPlaceId,
  getLatLng,
  geocodeByAddress,
} from "react-places-autocomplete";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import useInput from "../../../hooks/useInput";
import styles from "./BusinessAddress.module.css";
import Input from "../../GlobalComponents/UI/Input/Input";
import {
  businessAddressValidationRules,
  stateValidationRules,
  zipCodeValidationRules,
  countryValidationRules,
} from "../../../utils/validationRules";
import states from "../../../graphql/queries/states";
import cities from "../../../graphql/queries/cities";
import countries from "../../../graphql/queries/countries";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { isBusinessType } from "../../../utils";
import { BusinessOnboardingState, BUSINESS_TYPES } from "../../../constants";
import updateBusiness from "../../../graphql/mutations/updateBusiness";
import { savingBizInfoState } from "../../../utils/cache";

const BusinessAddress = (props) => {
  const { insertBusinessData, nextStepHandler, businessData } = props;
  const { data: countriesData, loading: countryLoading } = useQuery(countries);
  const [getStates, { data: statesData, loading: stateLoading }] =
    useLazyQuery(states);
  const [getCities, { data: citiesData, loading: cityLoading }] =
    useLazyQuery(cities);
  const {
    value: businessAddress,
    hasError: businessAddressError,
    errorMessage: businessAddressErrorMessage,
    valueChangeHandler: businessAddressHandler,
    valueBlurHandler: businessAddressBlurHandler,
    isValid: businessAddressValid,
  } = useInput(
    businessAddressValidationRules,
    businessData?.addrLine1,
    null,
    true
  );

  const {
    value: optionalAddress,
    hasError: optionalAddressError,
    errorMessage: optionalAddressErrorMessage,
    valueChangeHandler: optionalAddressHandler,
    valueBlurHandler: optionalAddressBlurHandler,
    isValid: optionalAddressValid,
  } = useInput({}, businessData?.addrLine2);

  const {
    value: city,
    hasError: cityError,
    errorMessage: cityErrorMessage,
    valueChangeHandler: cityHandler,
    valueBlurHandler: cityBlurHandler,
    isValid: cityValid,
  } = useInput(null, businessData?.city);

  const {
    value: state,
    hasError: stateError,
    errorMessage: stateErrorMessage,
    valueChangeHandler: stateHandler,
    valueBlurHandler: stateBlurHandler,
    isValid: stateValid,
  } = useInput(stateValidationRules, businessData?.state);

  const {
    value: zipCode,
    hasError: zipCodeError,
    errorMessage: zipCodeErrorMessage,
    valueChangeHandler: zipCodeHandler,
    valueBlurHandler: zipCodeBlurHandler,
    isValid: zipCodeValid,
  } = useInput(zipCodeValidationRules, businessData?.zip);

  const {
    value: country,
    hasError: countryError,
    errorMessage: countryErrorMessage,
    valueChangeHandler: countryHandler,
    valueBlurHandler: countryBlurHandler,
    isValid: countryValid,
  } = useInput(countryValidationRules, businessData?.country);

  const [isOnline, setIsOnline] = useState(businessData?.isOnline ?? false);
  const [addressLocation, setAddressLocation] = useState();
  const [addLoading, setAddLoading] = useState(false);
  const toggleIsOnlineHandler = () => setIsOnline(!isOnline);
  const [updateBiz, { loading: updateLoading }] = useMutation(updateBusiness);

  const formIsValid =
    (businessAddressValid &&
      optionalAddressValid &&
      zipCodeValid &&
      countryValid &&
      cityValid &&
      stateValid &&
      !isOnline) ||
    isOnline;

  const isLoading =
    countryLoading ||
    updateLoading ||
    stateLoading ||
    cityLoading ||
    addLoading;
  const isEcommerceOnly =
    businessData?.businessTypes?.length === 1 &&
    isBusinessType(businessData?.businessTypes, BUSINESS_TYPES.ecommerce);

  useEffect(() => {
    if (businessData?.addrLine1) {
      setAddLoading(true);
      geocodeByAddress(businessData?.addrLine1)
        .then((results) => {
          const country =
            results[0].address_components.find((c) =>
              c.types.includes("country")
            )?.long_name || "";
          countryChangeHandler({ target: { value: country } });
          const countryId =
            countriesData?.countries?.data?.find((ctr) =>
              ctr?.name?.includes(country)
            )?.id ?? "";
          return countryId;
        })
        .then((countryId) => {
          getStates({
            variables: { filters: { countries: [`${countryId}`] } },
          }).then(({ data }) => {
            const stateName = data?.states?.data?.find(
              (el) => +el?.id === +businessData?.state
            )?.name;
            stateChangeHandler({ target: { value: stateName } });
          });
          getCities({
            variables: {
              filters: {
                states: businessData?.state ? [`${businessData?.state}`] : null,
              },
            },
          }).then(({ data }) => {
            const cityName = data?.cities?.data?.find(
              (el) => +el?.id === +businessData?.city
            )?.name;
            cityHandler({ target: { value: cityName } });
            setAddLoading(false);
          });
        });
      setAddressLocation({ lat: businessData?.lat, lng: businessData?.lng });
    }
  }, [businessData, countriesData]);

  const businessAddressSelectHandler = async (address, placeId) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    businessAddressHandler(place?.formatted_address);

    const placeDetails = {
      zipCode:
        place.address_components.find((c) => c.types.includes("postal_code"))
          ?.long_name || "",
      country:
        place.address_components.find((c) => c.types.includes("country"))
          ?.long_name || "",
      city:
        place.address_components.find(
          (c) =>
            c.types.includes("locality") ||
            c.types.includes("administrative_area_level_3")
        )?.long_name || "",
      state:
        place.address_components.find((c) =>
          c.types.includes("administrative_area_level_1")
        )?.long_name || "",
    };
    const countryId =
      countriesData?.countries?.data?.find((country) =>
        country?.name
          ?.toLowerCase()
          ?.includes(placeDetails?.country?.toLowerCase())
      )?.id ?? "";
    countryChangeHandler(
      { target: { value: placeDetails?.country } },
      placeDetails?.state
    );
    stateChangeHandler({ target: { value: placeDetails?.state } }, countryId);
    zipCodeHandler({ target: { value: placeDetails?.zipCode } });
    cityHandler({ target: { value: placeDetails?.city } });
    setAddressLocation(latLng);
  };
  const countryChangeHandler = (e, state) => {
    countryHandler(e);
    const countryId =
      countriesData?.countries?.data?.find((country) =>
        country?.name?.toLowerCase()?.includes(e.target.value.toLowerCase())
      )?.id ?? "";
    stateChangeHandler({ target: { value: state } }, countryId);
  };
  const stateChangeHandler = (e, countryId) => {
    stateHandler(e);
    if (countryId) {
      getStates({
        variables: { filters: { countries: [`${countryId}`] } },
      }).then(({ data }) => {
        const stateId =
          data?.states?.data?.find((state) =>
            state?.name
              ?.toLowerCase()
              ?.includes(e?.target?.value?.toLowerCase())
          )?.id ?? "";
        getCities({
          variables: { filters: { states: stateId ? [`${stateId}`] : null } },
        });
      });
    } else {
      const stateId =
        statesData?.states?.data?.find((state) =>
          state?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
        )?.id ?? "";
      getCities({
        variables: { filters: { states: stateId ? [`${stateId}`] : null } },
      });
    }
  };

  const isInfoChanged = (stateId, cityId) => {
    if (
      businessData?.addrLine1 === businessAddress &&
      businessData?.addrLine2 === optionalAddress &&
      businessData?.lat === addressLocation?.lat &&
      businessData?.lng === addressLocation?.lng &&
      businessData?.city === cityId &&
      businessData?.state === stateId &&
      businessData?.zip === zipCode &&
      businessData?.isOnline === isOnline
    ) {
      return false;
    } else {
      return true;
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const cityId = citiesData?.cities?.data?.find((cty) =>
      cty?.name?.toLowerCase()?.includes(city?.toLowerCase())
    )?.id;
    const stateId = statesData?.states?.data?.find((stat) =>
      stat?.name?.toLowerCase()?.includes(state?.toLowerCase())
    )?.id;
    const vars = {
      addrLine1: businessAddress,
      addrLine2: optionalAddress,
      lat: addressLocation?.lat,
      lng: addressLocation?.lng,
      city: cityId,
      state: stateId,
      zip: zipCode,
    };
    if (!isInfoChanged(stateId, cityId)) {
      nextStepHandler();
      return;
    }
    savingBizInfoState({ loading: true, error: false });
    analytics.track("business_address");

    updateBiz({
      variables: {
        id: businessData?.id,
        ...vars,
        onboardingState: BusinessOnboardingState.businessImages, // next step
      },
    })
      .then(() => {
        savingBizInfoState({ loading: false });

        insertBusinessData({
          ...vars,
          country,
          isOnline,
        });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };
  const placesErrorHandler = (status, clearSuggestions) => {
    clearSuggestions();
  };

  return (
    <form className={parentStyles.registerForm} onSubmit={submitHandler}>
      <h2
        className={`${parentStyles.altHeader} ${parentStyles.altHeaderWithDescription}`}
      >
        What’s your business address?
      </h2>
      <p className={parentStyles.headingDescription}>
        Enter the address for customers to find you
      </p>

      <Input
        hasLabel
        isRequired={!isEcommerceOnly}
        label='BUSINESS ADDRESS'
        placeholder='e.g. 555 Magnolia Blvd.'
        elementType='places'
        inpContainerClass={`${parentStyles.fullWidthContainer} ${
          styles.relativeStyle
        } ${businessAddressError && parentStyles.invalid}`}
        value={businessAddress}
        selectHandler={businessAddressSelectHandler}
        changeHandler={businessAddressHandler}
        blurHandler={businessAddressBlurHandler}
        invalid={businessAddressError}
        errorMessage={businessAddressErrorMessage}
        placesContainerClass={styles.placesContainerClass}
        placesErrorHandler={placesErrorHandler}
      />
      <Input
        hasLabel
        label='BUSINESS ADDRESS 2'
        placeholder='e.g. Suite Number 5'
        type='text'
        inpContainerClass={`${parentStyles.fullWidthContainer} ${
          optionalAddressError && parentStyles.invalid
        }`}
        value={optionalAddress}
        changeHandler={optionalAddressHandler}
        blurHandler={optionalAddressBlurHandler}
        invalid={optionalAddressError}
        errorMessage={optionalAddressErrorMessage}
      />
      <Input
        hasLabel
        isRequired={!isEcommerceOnly}
        label='COUNTRY'
        placeholder='e.g. United States'
        elementType='select'
        inpContainerClass={`${parentStyles.halfWidthContainer} ${
          countryError && parentStyles.invalid
        }`}
        options={countriesData?.countries?.data
          ?.filter(
            ({ name }) =>
              name === "United States of America" || name === "Canada"
          )
          .map((country) => ({
            ...country,
            value: country?.name?.includes("America")
              ? "United States"
              : country?.name,
            label: country?.name,
          }))}
        value={country}
        changeHandler={countryChangeHandler}
        blurHandler={countryBlurHandler}
        invalid={countryError}
        errorMessage={countryErrorMessage}
      />
      <Input
        hasLabel
        isRequired={!isEcommerceOnly}
        label='STATE'
        placeholder='e.g. California'
        elementType='select'
        inpContainerClass={`${parentStyles.halfWidthContainer} ${
          stateError && parentStyles.invalid
        }`}
        value={state}
        options={statesData?.states?.data?.map((state) => ({
          ...state,
          value: state?.name,
          label: state?.name,
        }))}
        changeHandler={stateChangeHandler}
        blurHandler={stateBlurHandler}
        invalid={stateError}
        errorMessage={stateErrorMessage}
      />

      <Input
        hasLabel
        isRequired={!isEcommerceOnly}
        label='CITY'
        placeholder='e.g. North Hollywood'
        elementType='select'
        inpContainerClass={`${parentStyles.halfWidthContainer} ${
          cityError && parentStyles.invalid
        }`}
        value={city}
        options={citiesData?.cities?.data?.map((city) => ({
          ...city,
          value: city?.name,
          label: city?.name,
        }))}
        changeHandler={cityHandler}
        blurHandler={cityBlurHandler}
        invalid={cityError}
        errorMessage={cityErrorMessage}
      />
      <Input
        hasLabel
        isRequired={!isEcommerceOnly}
        label='ZIP CODE'
        placeholder='e.g. 91402'
        type='text'
        inpContainerClass={`${parentStyles.halfWidthContainer} ${
          zipCodeError && parentStyles.invalid
        }`}
        value={zipCode}
        changeHandler={zipCodeHandler}
        blurHandler={zipCodeBlurHandler}
        invalid={zipCodeError}
        errorMessage={zipCodeErrorMessage}
      />
      <RegisterNextBtn
        variant={parentStyles.continueBtn}
        disabled={!formIsValid && !isOnline}
        isLoading={isLoading}
      >
        {isLoading ? <Spinner /> : "CONTINUE"}
      </RegisterNextBtn>
      {isEcommerceOnly && (
        <Input
          elementType='checkbox'
          hasLabel
          isRequired={!isEcommerceOnly}
          label='My business is online only'
          inpContainerClass={styles.inpContainer}
          checked={isOnline}
          changeHandler={toggleIsOnlineHandler}
          customCheckBox={styles.checkMark}
        />
      )}
    </form>
  );
};
export default BusinessAddress;
BusinessAddress.propTypes = {
  insertBusinessData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  businessData: PropTypes.object,
};
