import React, { useRef, useCallback } from "react";
import { NavLink, Navigate, useParams } from "react-router-dom";
import AddToCollectionButton from "../../GlobalComponents/Buttons/AddToCollectionButton/AddToCollectionButton";
import ErrorComponent from "../../GlobalComponents/UI/ErrorComponent/ErrorComponent";
import ImageShapes from "../../GlobalComponents/UI/ImageShapes/ImageShapes";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import UpVoteButton from "../../GlobalComponents/Buttons/UpVoteButton/UpVoteButton";
import styles from "./SavedItems.module.css";
import { useQuery } from "@apollo/client";
import collection from "../../../graphql/queries/collection";
import SectionLoader from "../../GlobalComponents/UI/SectionLoader/SectionLoader";

const SavedItems = () => {
  const { id } = useParams();
  const { loading, error, data, fetchMore } = useQuery(collection, {
    variables: { id, page: 1 },
    notifyOnNetworkStatusChange: true,
  });
  const observer = useRef();
  const { currentPage, hasMorePages } =
    data?.collection?.collectable?.paginatorInfo ?? {};
  const { data: items } = data?.collection?.collectable ?? {};
  const { name } = data?.collection ?? {};

  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          fetchMore({ variables: { page: currentPage + 1 } });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMorePages, loading]
  );

  return (
    <section className={styles.sectionClass}>
      <div className={styles.editorPicksContainer}>
        <div className={styles.header}>
          <h3 className={styles.sectionTitle}>{name}</h3>
        </div>

        <div className={styles.picks}>
          {error && <ErrorComponent />}
          {items &&
            items.map((item, i) => (
              <NavLink
                to={`/${item?.item?.__typename?.toLowerCase()}/${
                  item?.item?.id
                }`}
                key={i}
                className={styles.pick}
                ref={i === items.length - 1 ? lastItemRef : null}
              >
                <div className={styles.imgContainer}>
                  <ImageShapes
                    variant={styles.pickImg}
                    imgSrc={item?.item?.featuredImage?.path}
                    alt={item?.item?.product}
                  />
                  <UpVoteButton
                    variant={styles.upVote}
                    voteCount={item?.item?.votes}
                    itemType={item?.item?.__typename?.toUpperCase()}
                    itemId={+item?.item?.id}
                    voted={item?.item?.isVoted}
                    item={item?.item}
                  />
                  <AddToCollectionButton
                    variant={styles.addToCollection}
                    addedToCollection={item?.item?.isBookmarked}
                    itemType={item?.item?.__typename?.toUpperCase()}
                    itemId={+item?.item?.id}
                    item={item?.item}
                  />
                </div>
                {item?.item?.__typename.toLowerCase() === "business" ? (
                  <>
                    <p className={styles.business}>{item?.item?.name}</p>
                    <p className={styles.product}>
                      {item?.item?.categories?.map((cat, i) => {
                        if (i !== item?.item?.categories?.length - 1)
                          return `${cat.name}, `;
                        else return `${cat.name}`;
                      })}
                    </p>
                  </>
                ) : (
                  <>
                    <p className={styles.business}>
                      {item?.item?.business?.name}
                    </p>
                    <p className={styles.product}>{item?.item?.name}</p>
                  </>
                )}
                <p className={styles.price}>
                  {item?.item?.price && `$${item?.item?.price?.toFixed(2)}`}
                </p>
              </NavLink>
            ))}
          {((items?.length === 0 && !error && !name) || loading) && (
            <SectionLoader itemsNumber={4} />
          )}
        </div>
        {items?.length === 0 && !error && (
          <p className={styles.empty}>NO ITEMS TO DISPLAY</p>
        )}
      </div>
    </section>
  );
};

export default SavedItems;
