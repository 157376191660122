import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useInput from "../../../hooks/useInput";
import Input from "../../GlobalComponents/UI/Input/Input";
import { businessWebsiteValidation } from "../../../utils/validationRules";
import styles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import { useMutation } from "@apollo/client";
import updateBusiness from "../../../graphql/mutations/updateBusiness";
import { BusinessOnboardingState } from "../../../constants";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { savingBizInfoState } from "../../../utils/cache";

const BusinessSocials = (props) => {
  const { insertBusinessData, nextStepHandler, businessData } = props;
  const {
    value: instagramURL,
    hasError: instagramURLError,
    errorMessage: instagramURLErrorMessage,
    valueChangeHandler: instagramURLHandler,
    valueBlurHandler: instagramURLBlurHandler,
    isValid: instagramURLValid,
  } = useInput(businessWebsiteValidation, businessData?.instagram);
  const {
    value: facebookURL,
    hasError: facebookURLError,
    errorMessage: facebookURLErrorMessage,
    valueChangeHandler: facebookURLHandler,
    valueBlurHandler: facebookURLBlurHandler,
    isValid: facebookURLValid,
  } = useInput(businessWebsiteValidation, businessData?.facebook);
  const {
    value: twitterURL,
    hasError: twitterURLError,
    errorMessage: twitterURLErrorMessage,
    valueChangeHandler: twitterURLHandler,
    valueBlurHandler: twitterURLBlurHandler,
    isValid: twitterURLValid,
  } = useInput(businessWebsiteValidation, businessData?.twitter);
  const {
    value: tiktokURL,
    hasError: tiktokURLError,
    errorMessage: tiktokURLErrorMessage,
    valueChangeHandler: tiktokURLHandler,
    valueBlurHandler: tiktokURLBlurHandler,
    isValid: tiktokURLValid,
  } = useInput(businessWebsiteValidation, businessData?.tiktok);

  const formIsValid =
    (instagramURLValid && instagramURL) ||
    (facebookURLValid && facebookURL) ||
    (twitterURLValid && twitterURL) ||
    (tiktokURLValid && tiktokURL) ||
    (!instagramURL && !facebookURL && !twitterURL && !tiktokURL);
  const [updateBiz, { loading }] = useMutation(updateBusiness);

  const submitHandler = (e) => {
    e.preventDefault();
    if (
      businessData?.facebook === facebookURL &&
      businessData?.twitter === twitterURL &&
      businessData?.instagram === instagramURL &&
      businessData?.tiktok === tiktokURL
    ) {
      nextStepHandler();
      return;
    }
    savingBizInfoState({ loading: true, error: false });
    analytics.track("business_social_links");

    updateBiz({
      variables: {
        id: businessData?.id,
        facebook: facebookURL,
        twitter: twitterURL,
        instagram: instagramURL,
        tiktok: tiktokURL,
        onboardingState: BusinessOnboardingState.businessHours, // next step
      },
    })
      .then(() => {
        savingBizInfoState({ loading: false });

        insertBusinessData({
          facebook: facebookURL,
          twitter: twitterURL,
          instagram: instagramURL,
          tiktok: tiktokURL,
        });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };

  return (
    <form className={styles.registerForm} onSubmit={submitHandler}>
      <h2 className={`${styles.altHeader} ${styles.altHeaderWithDescription}`}>
        Add social media links
      </h2>
      <p className={styles.headingDescription}>
        Add your social media links so businesses can learn more about your
        business and potentially give a follow!
      </p>

      <Input
        hasLabel={true}
        label='INSTAGRAM LINK'
        placeholder='e.g. www.instagram.com/business_name'
        type='text'
        inpContainerClass={`${styles.fullWidthContainer} ${
          instagramURLError && styles.invalid
        }`}
        value={instagramURL}
        changeHandler={instagramURLHandler}
        blurHandler={instagramURLBlurHandler}
        invalid={instagramURLError}
        errorMessage={instagramURLErrorMessage}
      />
      <Input
        hasLabel={true}
        label='FACEBOOK LINK'
        placeholder='e.g. www.facebook.com/business_name'
        type='text'
        inpContainerClass={`${styles.fullWidthContainer} ${
          facebookURLError && styles.invalid
        }`}
        value={facebookURL}
        changeHandler={facebookURLHandler}
        blurHandler={facebookURLBlurHandler}
        invalid={facebookURLError}
        errorMessage={facebookURLErrorMessage}
      />
      <Input
        hasLabel={true}
        label='TWITTER LINK'
        placeholder='e.g. www.twitter.com/business_name'
        type='text'
        inpContainerClass={`${styles.fullWidthContainer} ${
          twitterURLError && styles.invalid
        }`}
        value={twitterURL}
        changeHandler={twitterURLHandler}
        blurHandler={twitterURLBlurHandler}
        invalid={twitterURLError}
        errorMessage={twitterURLErrorMessage}
      />
      <Input
        hasLabel={true}
        label='TIK TOK LINK'
        placeholder='e.g. www.tiktok.com/business_name'
        type='text'
        inpContainerClass={`${styles.fullWidthContainer} ${
          tiktokURLError && styles.invalid
        }`}
        value={tiktokURL}
        changeHandler={tiktokURLHandler}
        blurHandler={tiktokURLBlurHandler}
        invalid={tiktokURLError}
        errorMessage={tiktokURLErrorMessage}
      />
      <RegisterNextBtn
        variant={styles.continueBtn}
        isLoading={loading}
        disabled={!formIsValid}
      >
        {loading ? <Spinner /> : "SAVE & CONTINUE"}
      </RegisterNextBtn>
    </form>
  );
};
export default BusinessSocials;
BusinessSocials.propTypes = {
  insertBusinessData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  businessData: PropTypes.object,
};
