import { gql } from "@apollo/client";
export default gql`
  query BUSINESSES_MOST_POPULAR($page: Int) {
    mostPopularBusinesses {
      __typename
      id
      name
      label
      categories {
        name
      }
      city {
        name
      }
      state {
        abbr
      }
      addrLine1
      isVoted
      isBookmarked
      collections(page: $page) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          id
          name
        }
      }
      businessTypes
      votes
      featuredImage {
        id
        path
      }
    }
  }
`;
