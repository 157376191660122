import React from "react";
import styles from "./Loader.module.css";
import PropTypes from "prop-types";
const Loader = (props) => {
  const { variant, darkColor } = props;
  return (
    <div
      className={`${variant} ${styles.loading} ${
        darkColor ? styles.customBg : styles.regBg
      }`}
    />
  );
};

export default Loader;
Loader.propTypes = {
  variant: PropTypes.string,
  ignoreLoadingStyle: PropTypes.bool,
  darkColor: PropTypes.bool,
};
