import React, { useState, useRef, useCallback, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import search from "../../../graphql/queries/search/search";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import TableHeader from "../../GlobalComponents/Admin/AdminTableHeader";
import Input from "../../GlobalComponents/UI/Input/Input";
import RowLoader from "../../GlobalComponents/UI/RowLoader/RowLoader";
import BusinessRequestRow from "./BusinessRequestRow";
import {
  BUSINESS_SEARCH_TYPES,
  SORT_ORDER,
  BUSINESS_STATUS,
} from "../../../constants";
import styles from "./RequestsTable.module.css";

const ITEMS_SIZE = 20;
const DEBOUNCE_VALUE = 2000;

export default function RequestsTable() {
  const { pathname } = useLocation();

  const [sortOrder, setSortOrder] = useState(undefined);
  const [text, setText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [toggleRefetch, setToggleRefetch] = useState(false);
  const isDeclinedRequestsPage = pathname.includes("declined-requests");
  const searchQueryVariables = {
    text: text,
    page: 1,
    size: ITEMS_SIZE,
    filters: {
      typesAndCategories: {
        types: BUSINESS_SEARCH_TYPES,
        status: isDeclinedRequestsPage
          ? [BUSINESS_STATUS.DISAPPROVED, BUSINESS_STATUS.ID_DECLINED]
          : [BUSINESS_STATUS.UNDER_REVIEW],
      },
    },
    ...(sortOrder ? { sortBy: { type: sortOrder } } : {}),
  };

  const {
    data,
    loading: loadingBusinesses,
    fetchMore,
    refetch,
  } = useQuery(search, {
    variables: searchQueryVariables,
    notifyOnNetworkStatusChange: true,
  });

  const { data: businesses } = data?.search ?? {};
  const { currentPage, hasMorePages } = data?.search?.paginatorInfo ?? {};
  const observer = useRef();

  useEffect(() => {
    refetch(searchQueryVariables);
  }, [toggleRefetch]);

  // for users pagination
  const paginationRef = useCallback(
    (node) => {
      if (loadingBusinesses) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          fetchMore({
            variables: {
              page: currentPage + 1,
              sort: sortOrder,
            },
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMorePages, currentPage, loadingBusinesses, sortOrder]
  );

  const handleSort = () => {
    if (sortOrder === SORT_ORDER.ASCSearchFilter) {
      setSortOrder(undefined);
    } else {
      setSortOrder(SORT_ORDER.ASCSearchFilter);
    }
  };

  const handleSearchChange = (e) => {
    if (setText.length > 1) {
      setTimeout(() => {
        setText(e.target.value);
      }, DEBOUNCE_VALUE);
    } else {
      setText(e.target.value);
    }
  };

  const searchHandler = (e) => {
    setSearchText(e.target.value);
    handleSearchChange(e);
  };

  const handleToggleRefetch = () => setToggleRefetch(!toggleRefetch);

  return (
    <div className={styles.requestsContainer}>
      <div className={styles.header}>
        <AdminTitle
          title={isDeclinedRequestsPage ? "Declined Listings" : "Requests"}
        />
        <Input
          hasLabel={false}
          placeholder='Search for any business'
          elementType='search'
          inpContainerClass={styles.searchField}
          value={searchText}
          name='search'
          id='search'
          changeHandler={searchHandler}
        />
      </div>

      <table className={styles.requestsTable}>
        <TableHeader
          columns={["BUSINESS NAME", "BUSINESS OWNER", "TYPE OF BUSINESS"]}
          handleSort={handleSort}
          sortOrder={sortOrder}
        />
        {businesses?.length > 0 && (
          <tbody>
            {businesses?.map((business) => (
              <BusinessRequestRow
                business={business}
                key={business.id}
                handleToggleRefetch={handleToggleRefetch}
              />
            ))}
          </tbody>
        )}
      </table>
      <div className={styles.paginatorRef} ref={paginationRef} />
      {loadingBusinesses && <RowLoader rows={5} />}
    </div>
  );
}
