import React from "react";
import regStyles from "./MostPopularSection.module.css";
import adminStyles from "./AdminStyles.module.css";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ImageShapes from "../../UI/ImageShapes/ImageShapes";
import UpVoteButton from "../../Buttons/UpVoteButton/UpVoteButton";
import AddToCollectionButton from "../../Buttons/AddToCollectionButton/AddToCollectionButton";
import SeeAllBtn from "../../Buttons/SeeAllBtn/SeeAllBtn";
import ErrorComponent from "../../UI/ErrorComponent/ErrorComponent";
import SectionLoader from "../../UI/SectionLoader/SectionLoader";
import { pagesFilters, BUSINESS_TYPES } from "../../../../constants";
import { useReactiveVar } from "@apollo/client";
import { isAdmin } from "../../../../utils/cache";

const MostPopularSection = (props) => {
  const { mostPopular, isShared, loading, error, pageName } = props;
  const pageFilter = pagesFilters[pageName];
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  const isEcommerce = pageName === BUSINESS_TYPES.ecommerce;

  const itemPriceAddress = (item) => {
    if (item?.price) {
      return `$${item?.price?.toFixed(2)}`;
    } else if (!isEcommerce) {
      return `${item?.city?.name}, ${item?.state?.abbr}`;
    } else {
      return "";
    }
  };

  return (
    <section
      className={`${styles.popularSection} ${isShared && styles.shardSection}`}
    >
      <div className={styles.popularContainer}>
        <div className={styles.header}>
          <h3 className={styles.sectionTitle}>Most Popular</h3>
          <SeeAllBtn
            route={`/search?s=Most Popular${pageFilter && `&f=${pageFilter}`}`}
          />
        </div>
        <div
          className={mostPopular?.length > 0 ? styles.items : styles.notLoaded}
        >
          {loading && <SectionLoader itemsNumber={8} />}
          {error && <ErrorComponent />}
          {mostPopular?.map((item, i) => (
            <NavLink
              to={`/${item?.__typename?.toLowerCase()}/${item?.id}`}
              key={`${item?.name}-${i}`}
              className={`${styles.item} ${styles[`item${i}`]}`}
            >
              <div
                className={`${styles.imgContainer} ${
                  styles[`imgContainer${i}`]
                }`}
              >
                <ImageShapes
                  variant={`${i === 0 ? styles.firstItemImg : styles.itemImg}`}
                  imgSrc={item?.featuredImage?.path}
                  alt={item?.product}
                />
                <UpVoteButton
                  variant={`${i === 0 ? styles.firstUpVote : styles.upVote}`}
                  voteCount={item?.votes}
                  itemType={item?.__typename.toUpperCase()}
                  itemId={+item?.id}
                  voted={item?.isVoted}
                  item={item}
                />
                <AddToCollectionButton
                  variant={`${
                    i === 0
                      ? styles.firstAddToCollection
                      : styles.addToCollection
                  }`}
                  addedToCollection={item?.isBookmarked}
                  itemType={item?.__typename.toUpperCase()}
                  itemId={+item?.id}
                  item={item}
                />
              </div>
              {item?.__typename.toLowerCase() === "business" ? (
                <>
                  <p className={styles.business}>{item?.name}</p>
                  <p className={styles.product}>
                    {item?.categories?.map((cat, i) => {
                      if (i !== item?.categories?.length - 1)
                        return `${cat.name}, `;
                      else return `${cat.name}`;
                    })}
                  </p>
                </>
              ) : (
                <>
                  <p className={styles.business}>{item?.business?.name}</p>
                  <p className={styles.product}>{item?.name}</p>
                </>
              )}

              <p className={styles.price}>{itemPriceAddress(item)}</p>
            </NavLink>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MostPopularSection;
MostPopularSection.propTypes = {
  mostPopular: PropTypes.array,
  sectionClass: PropTypes.string,
  reversedClass: PropTypes.string,
  isShared: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  pageName: PropTypes.string,
};
