import React from "react";
import styles from "./HeroHeader.module.css";
import awardYear from "../../../assets/images/awardYear.png";
import clover from "../../../assets/images/clover.png";
import landingHead from "../../../assets/images/landingHead.png";
import Picture from "../../GlobalComponents/UI/Picture";
const HeroHeader = () => {
  return (
    <header className={styles.hero}>
      <h1 className={styles.title}>
        <span className={`${styles.third} ${styles.titlePad}`}>
          The 3rd Annual OBWS
        </span>
        <span className={`${styles.main} ${styles.titlePad}`}>Black </span>
        <span className={styles.entrepreneur}> Entrepreneur</span>
        <span className={styles.resposiveTitle}>of the Year Award.</span>
        <div className={styles.resPresented}>
          <span className={styles.presentedText}>Presented by</span>
          <a href='https://www.clover.com/' target='_blank' rel='noreferrer'>
            <img className={styles.clover} src={clover} alt='clover' />
          </a>
        </div>
      </h1>
      <div className={styles.heroContainer}>
        <Picture
          variant={styles.heroImg}
          fallback={landingHead}
          alt='landingHead'
        />
        <div className={styles.desc}>
          <div className={styles.restTitle}>
            <h1 className={styles.title}>
              <span className={styles.main}>of the Year</span>
              <span className={styles.entrepreneur}>Award.</span>
            </h1>
            <div className={styles.presented}>
              <a
                href='https://www.clover.com/'
                target='_blank'
                rel='noreferrer'
              >
                <img className={styles.clover} src={clover} alt='clover' />
              </a>
              <span className={styles.presentedText}>Presented by</span>
            </div>
            <img className={styles.awardYear} src={awardYear} alt='2022' />
          </div>
          <p className={styles.descText}>
            This year during Black Business Month, Official Black Wall Street
            celebrates trailblazing Black entrepreneurs & their businesses.
          </p>
        </div>
      </div>
    </header>
  );
};

export default HeroHeader;
