import React from "react";
import PropTypes from "prop-types";
import { convertFloatToTime } from "../../../../utils/formatters";
import { BUSINESS_DAYS } from "../../../../constants";
import styles from "./BusinessHoursSection.module.css";

export default function BusinessHoursSection({ workingHours, isBusinessPage }) {
  const renderTime = (dayName) => {
    const day = workingHours?.find((day) => day.day === dayName);
    if (!day) {
      return "Closed";
    } else if (
      convertFloatToTime(day.open) === "00:00 am" &&
      convertFloatToTime(day.close) === "24:00 pm"
    ) {
      return "Open 24hrs a day";
    } else {
      return `${convertFloatToTime(day.open)} - ${convertFloatToTime(
        day.close
      )}`;
    }
  };

  return (
    <div
      className={`${styles.businessHorsContainer} ${
        isBusinessPage ? styles.businessContainer : ""
      }`}
    >
      <p className={`${isBusinessPage ? styles.businessTitle : styles.title}`}>
        Store hours
      </p>
      <div className={styles.daysList}>
        {BUSINESS_DAYS.map((day) => (
          <div key={day} className={styles.day}>
            <p>{day}</p>
            <p className={styles.time}>{renderTime(day)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

BusinessHoursSection.propTypes = {
  workingHours: PropTypes.array,
  isBusinessPage: PropTypes.bool,
};
