import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useReactiveVar } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import ItemForm from "./ItemForm";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import {
  ITEM_TYPES,
  EMPTY_ITEM,
  ITEM_STATUS,
  BusinessOnboardingState,
  USER_TYPES,
} from "../../../constants";
import styles from "../BusinessOnboarding.module.css";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import createFood from "../../../graphql/mutations/createFood";
import createProduct from "../../../graphql/mutations/createProduct";
import createService from "../../../graphql/mutations/createService";
import updateFood from "../../../graphql/mutations/updateFood";
import updateProduct from "../../../graphql/mutations/updateProduct";
import updateService from "../../../graphql/mutations/updateService";
import deleteProduct from "../../../graphql/mutations/deleteProduct";
import deleteService from "../../../graphql/mutations/deleteService";
import deleteFood from "../../../graphql/mutations/deleteFood";
import updateBusinessOnboarding from "../../../graphql/mutations/updateBusiness";

import {
  itemWebsiteValidation,
  businessNameValidation,
  descriptionValidation,
  priceValidation,
} from "../../../utils/validationRules";
import { inputValidation } from "../../../utils/inputValidation";
import createBusiness from "../../../graphql/mutations/createBusiness";
import {
  authVar,
  businessListingStatus,
  isAdmin,
  rolesVar,
  savingBizInfoState,
} from "../../../utils/cache";
import business from "../../../graphql/queries/pages/business";
import { checkUserRole, successToastHandler } from "../../../utils";
import useUpdateUser from "../../../hooks/useUpdateUser";

const PRODUCTS_LIMIT = 5;

export default function ProductsAndServices({
  insertBusinessData,
  businessData,
  nextStepHandler,
}) {
  const businessItems = businessData?.supplies?.data?.map((item) => item.item);
  const businessId = businessData?.id;
  const [items, setItems] = useState([{ ...EMPTY_ITEM, id: 1 }]);
  const [createFoodItem, { loading: createFoodLoading }] = useMutation(
    createFood,
    { refetchQueries: [business] }
  );
  const [createProductItem, { loading: createProductLoading }] = useMutation(
    createProduct,
    { refetchQueries: [business] }
  );
  const [createServiceItem, { loading: createServiceLoading }] = useMutation(
    createService,
    { refetchQueries: [business] }
  );

  const [updateProductItem, { loading: updateProductLoading }] =
    useMutation(updateProduct);
  const [updateServiceItem, { loading: updateServiceLoading }] =
    useMutation(updateService);
  const [updateFoodItem, { loading: updateFoodLoading }] =
    useMutation(updateFood);

  const [deleteProductItem, { loading: deleteProductLoading }] =
    useMutation(deleteProduct);
  const [deleteServiceItem, { loading: deleteServiceLoading }] =
    useMutation(deleteService);
  const [deleteFoodItem, { loading: deleteFoodLoading }] =
    useMutation(deleteFood);

  const [updateBusiness, { loading: businessLoading }] = useMutation(
    updateBusinessOnboarding
  );
  const [createBusinessItem, { loading: createBusinessLoading }] =
    useMutation(createBusiness);

  const { updateUser, userLoading } = useUpdateUser();
  const userData = useReactiveVar(authVar);
  const listingStatus = useReactiveVar(businessListingStatus);

  const isLoading =
    createFoodLoading ||
    createProductLoading ||
    createServiceLoading ||
    businessLoading ||
    updateProductLoading ||
    updateServiceLoading ||
    updateFoodLoading ||
    deleteProductLoading ||
    deleteServiceLoading ||
    deleteFoodLoading ||
    createBusinessLoading ||
    userLoading;

  const navigate = useNavigate();
  const location = useLocation();
  const admin = useReactiveVar(isAdmin);
  const roles = useReactiveVar(rolesVar);

  useEffect(() => {
    if (businessItems?.length > 0) {
      const mappedItems = businessItems.map((item) => ({
        id: item.id,
        type: item.__typename,
        name: item.name,
        price: item.price,
        website:
          item.__typename === ITEM_TYPES.FOOD ? item.orderLink : item.website,
        gallery:
          item.randomImages?.length > 0
            ? item.randomImages?.map((image) => image.id)
            : item.featuredImage?.id
            ? [item.featuredImage?.id]
            : [],

        featuredImage: item?.featuredImage?.id,
        categories: item?.categories?.map((category) => category.id) || [],
        desc: item.desc,
        ingredientsAndNutritional: item.ingredients || "",
        specification: item.specification || "",
        isSubCategorySelected: item?.categories?.length > 1, // if categories has children selected
        status: ITEM_STATUS.isEdit,
      }));
      setItems(mappedItems);
    }
  }, [businessData]);

  const handleItemChange = (id, fieldName, val, isSubCategory = false) => {
    let newItems = items;

    newItems = newItems.map((item) => {
      if (item.id === id) {
        if (fieldName === "categories" && isSubCategory) {
          return { ...item, [fieldName]: val, isSubCategorySelected: true };
        } else if (fieldName === "categories" && !isSubCategory) {
          return { ...item, [fieldName]: val, isSubCategorySelected: false };
        } else if (fieldName === "gallery") {
          return { ...item, [fieldName]: val, featuredImage: val[0] };
        } else return { ...item, [fieldName]: val };
      } else return item;
    });
    setItems(newItems);
  };
  const handleAddItem = () => {
    const itemsLength = items.length;
    const newItems = [...items, { ...EMPTY_ITEM, id: itemsLength + 1 }];
    setItems(newItems);
  };

  const validateItem = (item) => {
    const { isValid: isValidWebsite } = inputValidation(
      itemWebsiteValidation,
      item?.website
    );
    const { isValid: isValidName } = inputValidation(
      businessNameValidation,
      item?.name
    );
    const { isValid: isValidDescription } = inputValidation(
      descriptionValidation,
      item?.desc
    );
    const { isValid: isValidPrice } = inputValidation(
      priceValidation,
      item?.price
    );
    const hasCategories = item?.categories?.length > 0;
    const hasPhotos = item?.gallery?.length > 0;

    return (
      isValidWebsite &&
      isValidName &&
      isValidDescription &&
      hasCategories &&
      hasPhotos &&
      isValidPrice
    );
  };

  const hasValidItem = () =>
    items.every(
      (item) =>
        (item?.type && validateItem(item)) ||
        item?.status === ITEM_STATUS.isDeleted
    );

  const isAllItemsDeleted = () =>
    items.every((item) => item.status === ITEM_STATUS.isDeleted);
  const handleDeleteItem = (itemId) => {
    const newItems = items.map((item) => {
      if (item.id === itemId) {
        return { ...item, status: ITEM_STATUS.isDeleted };
      } else {
        return item;
      }
    });
    setItems(newItems);
  };
  const addItemHandler = (id = businessId) => {
    savingBizInfoState({ loading: true, error: false });
    Promise.all(
      items.map((item) => {
        if (validateItem(item)) {
          const variables = {
            businessId: +id,
            featuredImage: item.featuredImage,
            name: item.name,
            desc: item.desc,
            price: +item.price,
            website: item.website,
            gallery: item?.gallery?.filter((id) => id !== item.featuredImage),
            categories: item.categories,
            specification: item.specification || "",
          };
          const foodVariables = {
            businessId: businessId,
            featuredImage: item.featuredImage,
            name: item.name,
            desc: item.desc,
            price: +item.price,
            orderLink: item.website,
            gallery: item.gallery.filter((id) => id !== item.featuredImage),
            categories: item.categories,
            ingredientsAndNutritional: item.ingredientsAndNutritional,
          };

          switch (item.type) {
            case ITEM_TYPES.PRODUCT:
              switch (item.status) {
                case ITEM_STATUS.isDeleted:
                  return deleteProductItem({
                    variables: { id: item.id },
                  });
                case ITEM_STATUS.isEdit:
                  return updateProductItem({
                    variables: { ...variables, id: item.id },
                  });
                default:
                  return createProductItem({
                    variables: { ...variables },
                  });
              }
            case ITEM_TYPES.SERVICE:
              switch (item.status) {
                case ITEM_STATUS.isDeleted:
                  return deleteServiceItem({
                    variables: { id: item.id },
                  });
                case ITEM_STATUS.isEdit:
                  return updateServiceItem({
                    variables: { ...variables, id: item.id },
                  });
                default:
                  return createServiceItem({
                    variables: { ...variables },
                  });
              }

            default:
              switch (item.status) {
                case ITEM_STATUS.isDeleted:
                  return deleteFoodItem({
                    variables: { id: item.id },
                  });
                case ITEM_STATUS.isEdit:
                  return updateFoodItem({
                    variables: { ...foodVariables, id: item.id },
                  });
                default:
                  return createFoodItem({
                    variables: { ...foodVariables },
                  });
              }
          }
        }
      })
    )
      .then(() => {
        const userHasId = userData?.stripeIdentity?.govImage?.id;
        if (
          location.pathname.includes("/business-onboarding") ||
          location.pathname.includes("/create-business")
        ) {
          let newOnboardingState;
          analytics.track("business_listings");

          if (userHasId || checkUserRole(roles, USER_TYPES.admin)) {
            newOnboardingState = BusinessOnboardingState.done;
            analytics.track("complete_business_listing");
            businessListingStatus({ ...listingStatus, isCompleted: true });
          } else {
            newOnboardingState = BusinessOnboardingState.ownerId;
          }

          updateBusiness({
            variables: {
              id: businessId,
              onboardingState: newOnboardingState, // next step
            },
          })
            .then(({ data }) => {
              savingBizInfoState({ loading: false });
              insertBusinessData({ supplies: data?.updateBusiness?.supplies });
              if (
                data?.updateBusiness?.onboardingState ===
                BusinessOnboardingState?.done
              ) {
                updateUser().then(() => navigate("/business-dashboard"));
              } else if (
                data?.updateBusiness?.onboardingState ===
                BusinessOnboardingState?.ownerId
              ) {
                nextStepHandler();
              }
            })
            .catch(() => {
              savingBizInfoState({
                loading: false,
                error: true,
                retry: addItemHandler.bind(businessId),
              });
            });
        } else {
          successToastHandler("ADDED SUCCESSFULLY");
          updateUser().then(() => {
            if (admin) {
              navigate(`/listing-management/edit-business/${businessId}`);
            } else {
              navigate("/business-dashboard");
            }
          });
        }
      })

      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: addItemHandler.bind(businessId),
        });
      });
  };

  const showAddMore = () =>
    items.filter((item) => item.status !== ITEM_STATUS.isDeleted)?.length <
    PRODUCTS_LIMIT;

  return (
    <div className={styles.container}>
      <div className={`${styles.header} ${styles.productsHeader}`}>
        <h2 className={styles.title}>Products and services</h2>
        <p className={styles.description}>
          Add your products and services so that your business can get
          discovered and you can start earning money today! Upload your 5 best
          products!
        </p>
      </div>
      <div>
        {items.map((item, index) => {
          if (item.status !== ITEM_STATUS.isDeleted) {
            return (
              <ItemForm
                item={item}
                key={`${item.id}-${index}`}
                handleItemChange={handleItemChange}
                itemCategories={
                  businessItems?.length > 0
                    ? businessItems[index]?.categories
                    : []
                }
                itemGallery={
                  businessItems?.length > 0
                    ? businessItems[index]?.randomImages?.length > 0
                      ? // ToDo merge featured image to randomImages array
                        businessItems[index]?.randomImages
                      : businessItems[index]?.featuredImage
                      ? [businessItems[index]?.featuredImage]
                      : []
                    : []
                }
                handleDeleteItem={handleDeleteItem}
              />
            );
          }
        })}
      </div>

      {showAddMore() && (
        <button className={styles.addItemBtn} onClick={handleAddItem}>
          Add another item
        </button>
      )}

      <div className={styles.buttonsWrapper}>
        <RegisterNextBtn
          variant={parentStyles.continueBtn}
          clickHandler={addItemHandler.bind(null, businessId)}
          disabled={!hasValidItem() || isAllItemsDeleted() || isLoading}
          isLoading={isLoading}
        >
          {isLoading ? <Spinner /> : "SAVE & CONTINUE"}
        </RegisterNextBtn>
      </div>
    </div>
  );
}

ProductsAndServices.propTypes = {
  businessData: PropTypes.any,
  nextStepHandler: PropTypes.func,
  insertBusinessData: PropTypes.func,
};
