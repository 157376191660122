import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import AddToCollectionButton from "../../Buttons/AddToCollectionButton/AddToCollectionButton";
import ErrorComponent from "../../UI/ErrorComponent/ErrorComponent";
import ImageShapes from "../../UI/ImageShapes/ImageShapes";
import UpVoteButton from "../../Buttons/UpVoteButton/UpVoteButton";
import styles from "./WhatWeOffer.module.css";
import PropTypes from "prop-types";
import SeeAllBtn from "../../Buttons/SeeAllBtn/SeeAllBtn";
import SectionLoader from "../../UI/SectionLoader/SectionLoader";
import { useMutation, useReactiveVar } from "@apollo/client";
import {
  authVar,
  brazeCustomAttributes,
  editView,
  isAdmin,
} from "../../../../utils/cache";
import removeIcon from "../../../../assets/images/remove.svg";
import EditBtns from "../../Buttons/EditBtns/EditBtns";
import deleteFood from "../../../../graphql/mutations/deleteFood";
import deleteProduct from "../../../../graphql/mutations/deleteProduct";
import deleteService from "../../../../graphql/mutations/deleteService";
import { ITEM_TYPES } from "../../../../constants";
import business from "../../../../graphql/queries/pages/business";
import ConfirmationModal from "../../UI/ConfirmationModal";
import useShowConfirmation from "../../../../hooks/useShowConfirmation";
import { successToastHandler } from "../../../../utils";
const WhatWeOffer = (props) => {
  const {
    items,
    loading,
    error,
    title,
    isSeeAll,
    sectionClass,
    isEditBusiness,
  } = props;
  const isEditing = useReactiveVar(editView);
  const admin = useReactiveVar(isAdmin);
  const userData = useReactiveVar(authVar);
  const existingAttributes = useReactiveVar(brazeCustomAttributes);

  const { id: businessId } = useParams();
  const itemsLength = items?.length || 0;

  const [selectedToDelete, setSelectedToDelete] = useState();

  const itemLink = (item) => {
    const itemType = item?.item?.__typename?.toLowerCase();
    if (admin) return `/listing-management/edit-${itemType}/${item?.item?.id}`;
    else if (isEditBusiness) return `/edit-${itemType}/${item?.item?.id}`;
    else return `/${itemType}/${item?.item?.id}`;
  };
  const [deleteFoodItem, { loading: foodLoading }] = useMutation(deleteFood, {
    refetchQueries: [business],
  });
  const [deleteProductItem, { loading: productLoading }] = useMutation(
    deleteProduct,
    {
      refetchQueries: [business],
    }
  );
  const [deleteServiceItem, { loading: serviceLoading }] = useMutation(
    deleteService,
    {
      refetchQueries: [business],
    }
  );

  const { showConfirmation, toggleConfirmationHandler } = useShowConfirmation();

  const businessesAttirbuteHandler = (itemName) => {
    const selectedBusiness = existingAttributes?.businesses?.find(
      (el) => el?.business_id === businessId
    );
    const filteredBusinesses = existingAttributes?.businesses?.filter(
      (el) => el?.business_id !== businessId
    );
    const listings = [...selectedBusiness?.listing_name];
    const listingIndex = listings.indexOf(itemName);
    const newListingNames = listings.filter((lis, i) => i !== listingIndex);
    const editedBusiness = {
      ...selectedBusiness,
      listing_name: newListingNames,
    };
    const businessAttribute = [...filteredBusinesses, editedBusiness];

    brazeCustomAttributes({
      ...existingAttributes,
      businesses: [...businessAttribute],
    });
    analytics.identify(userData?.id, {
      businesses: [...businessAttribute],
    });
  };

  const handleDeleteItem = (e) => {
    switch (selectedToDelete?.itemType) {
      case ITEM_TYPES.FOOD:
        deleteFoodItem({ variables: { id: selectedToDelete?.id } }).then(() => {
          toggleConfirmationHandler();
          successToastHandler("DELETED SUCCESSFULLY");
          businessesAttirbuteHandler(selectedToDelete?.name);
        });
        break;
      case ITEM_TYPES.PRODUCT:
        deleteProductItem({ variables: { id: selectedToDelete?.id } }).then(
          () => {
            toggleConfirmationHandler();
            successToastHandler("DELETED SUCCESSFULLY");
            businessesAttirbuteHandler(selectedToDelete?.name);
          }
        );
        break;
      case ITEM_TYPES.SERVICE:
        deleteServiceItem({ variables: { id: selectedToDelete?.id } }).then(
          () => {
            toggleConfirmationHandler();
            successToastHandler("DELETED SUCCESSFULLY");
            businessesAttirbuteHandler(selectedToDelete?.name);
          }
        );
      default:
        return;
    }
  };

  const handleSelectedItem = (e, itemType, id, name) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedToDelete({ itemType, id, name });
    businessesAttirbuteHandler(name);

    toggleConfirmationHandler();
  };
  return (
    <section className={sectionClass}>
      <ConfirmationModal
        show={showConfirmation}
        cancelHandler={toggleConfirmationHandler}
        confirmHandler={handleDeleteItem}
        message={
          itemsLength === 1
            ? "You must have at least one item tied to your business. Please add an additional item to delete the selected item"
            : `Are you sure you want to delete the selected ${selectedToDelete?.itemType?.toLowerCase()}? This action cannot be reversed.`
        }
        loading={foodLoading || productLoading || serviceLoading}
        noActionModal={itemsLength === 1 ? true : false}
      />

      <div className={styles.popularContainer}>
        <div className={styles.header}>
          <h3
            className={`${styles.sectionTitle}
            ${isEditing ? styles.edit : undefined}`}
          >
            {isEditBusiness ? "Products & services" : title}
          </h3>
          {isSeeAll && itemsLength > 5 && <SeeAllBtn route='/' />}
          {isEditBusiness && (
            <div className={styles.editAdds}>
              <h3
                className={`${styles.itemsNo}
            ${isEditing ? styles.edit : undefined}`}
              >
                {itemsLength}/5 products & Services uploaded
              </h3>
              {!loading && <EditBtns variant={styles.editBtn} />}
            </div>
          )}
        </div>

        <div
          className={`${styles.picks} 
        ${itemsLength === 5 ? styles.colStyle : styles.rowStyle}`}
        >
          {error && <ErrorComponent />}
          {loading && <SectionLoader itemsNumber={4} />}
          {itemsLength > 0 &&
            items?.map((item, i) => (
              <NavLink
                to={itemLink(item)}
                key={`${item?.item?.name}-${i}`}
                className={` ${isEditing ? styles.edit : undefined} ${
                  styles.item
                } 
                ${itemsLength === 5 && styles[`item${i}`]}`}
              >
                <div
                  className={`${styles.imgContainer} ${
                    itemsLength === 5 && styles[`imgContainer${i}`]
                  }`}
                >
                  <ImageShapes
                    variant={`${
                      i === 4 && itemsLength === 5
                        ? styles.firstItemImg
                        : styles.itemImg
                    }`}
                    imgSrc={item?.item?.featuredImage?.path}
                    alt={item?.item?.product}
                  />
                  <UpVoteButton
                    variant={`${i === 4 ? styles.firstUpVote : styles.upVote}`}
                    voteCount={item?.item?.votes}
                    itemType={item?.item?.__typename?.toUpperCase()}
                    itemId={+item?.item?.id}
                    voted={item?.item?.isVoted}
                    item={item?.item}
                  />
                  <AddToCollectionButton
                    variant={`${
                      i === 4
                        ? styles.firstAddToCollection
                        : styles.addToCollection
                    }`}
                    addedToCollection={item?.item?.isBookmarked}
                    itemType={item?.item?.__typename?.toUpperCase()}
                    itemId={+item?.item?.id}
                    item={item?.item}
                  />

                  {isEditing && (
                    <img
                      src={removeIcon}
                      className={styles.remove}
                      onClick={(e) =>
                        handleSelectedItem(
                          e,
                          item?.item?.__typename,
                          item?.item?.id,
                          item?.item?.name
                        )
                      }
                    />
                  )}
                </div>
                {item?.item?.__typename.toLowerCase() === "business" ? (
                  <>
                    <p className={styles.business}>{item?.item?.name}</p>
                    <p className={styles.product}>
                      {item?.item?.categories?.map((cat, i) => {
                        if (i !== item?.item?.categories?.length - 1)
                          return `${cat.name}, `;
                        else return `${cat.name}`;
                      })}
                    </p>
                  </>
                ) : (
                  <>
                    <p className={styles.business}>
                      {item?.item?.business?.name}
                    </p>
                    <p className={styles.product}>{item?.item?.name}</p>
                  </>
                )}
                <p className={styles.price}>
                  {item?.item?.price
                    ? `$${item?.item?.price?.toFixed(2)}`
                    : `${item?.item?.city?.name}, ${item?.item?.state?.abbr}`}
                </p>
              </NavLink>
            ))}
          {isEditBusiness && items.length < 5 && (
            <NavLink
              to={`${
                admin
                  ? `/listing-management/add-item/${businessId}`
                  : `/add-item/${businessId}`
              }`}
              className={`${styles.addLink} 
            ${isEditing ? styles.edit : undefined}`}
            >
              ADD PRODUCT
            </NavLink>
          )}
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffer;
WhatWeOffer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  error: PropTypes.bool,
  isSeeAll: PropTypes.bool,
  isEditBusiness: PropTypes.bool,
  title: PropTypes.string,
  sectionClass: PropTypes.string,
};
