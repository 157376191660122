export const search = {
  search: {
    keyArgs: ["filters", "sortBy", "text"],
    merge(existing, incoming) {
      if (!incoming) return existing;
      if (!existing) return incoming;
      const { data, ...rest } = incoming;
      let result = rest;
      result.data = [...existing.data, ...data];
      // filter duplicates
      result.data = result.data.filter(
        (obj) => !result.data[obj.__ref] && (result.data[obj.__ref] = true)
      );

      return result;
    },
  },
};
