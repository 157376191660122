import React from "react";
import PropTypes from "prop-types";
import styles from "./AdminTitle.module.css";

export default function AdminTitle({ title, className }) {
  return <h2 className={`${styles.title} ${className}`}>{title}</h2>;
}

AdminTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};
