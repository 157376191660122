import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import search from "../../../graphql/queries/search/search";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import infoCircle from "../../../assets/images/infoCircle.svg";
import styles from "./RequestsSection.module.css";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { BUSINESS_SEARCH_TYPES, BUSINESS_STATUS } from "../../../constants";

export default function RequestsSection() {
  const navigate = useNavigate();
  const { data, loading: loadingRequests } = useQuery(search, {
    variables: {
      text: "",
      page: 1,
      size: 1,
      filters: {
        typesAndCategories: {
          types: BUSINESS_SEARCH_TYPES,
          status: [BUSINESS_STATUS.UNDER_REVIEW],
        },
      },
    },
  });
  const { total: requestsCount } = data?.search?.paginatorInfo || 0;

  const handleReviewRequests = () => {
    navigate("/admin/requests");
  };

  return (
    <div className={styles.requestsSection}>
      <div className={styles.titleWrapper}>
        <AdminTitle title='Requests' />
        <NavLink className={styles.viewDeclined} to='/admin/declined-requests'>
          View declined
        </NavLink>
      </div>
      <div className={styles.requestsSummary}>
        {loadingRequests ? (
          <Spinner variant={styles.loader} />
        ) : (
          <div className={styles.infoCircleWrapper}>
            <img
              src={infoCircle}
              alt='requests'
              className={styles.infoCircle}
            />
            <p className={styles.summary}>
              {`You have "${requestsCount}" new requests.`}
            </p>
          </div>
        )}
        <button className={styles.reviewNowBtn} onClick={handleReviewRequests}>
          Review now
        </button>
      </div>
    </div>
  );
}
