import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./AdminEditor.module.css";
const AdminEditor = () => {
  return (
    <div className={styles.adminEditor}>
      <Outlet />
    </div>
  );
};

export default AdminEditor;
