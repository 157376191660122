import { useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  searchUserLocation,
  viewSearchMap,
  searchTotalCount,
} from "../../../utils/cache";
import PropTypes from "prop-types";
import styles from "./Header.module.css";
import { filtersToDisplay, pagesName } from "../../../constants";

const Header = (props) => {
  const { showFilters, showFilterHandler } = props;
  const [searchTerm, setSearchTerm] = useSearchParams();
  const searchByLocation = useReactiveVar(viewSearchMap);
  const count = useReactiveVar(searchTotalCount);
  const [headerTitle, setHeaderTitle] = useState("");
  const options = {
    enableHighAccuracy: true,
  };
  useEffect(() => {
    if (searchTerm.get("t")) {
      setHeaderTitle(searchTerm.get("t"));
    } else if (searchTerm.get("c")) {
      if (searchTerm.getAll("c")?.length > 1) {
        setHeaderTitle("Selected categories");
      } else {
        setHeaderTitle(searchTerm.get("c"));
      }
    } else if (searchTerm.get("s")) {
      setHeaderTitle(searchTerm.get("s"));
    } else if (searchTerm.get("f")) {
      if (searchTerm.getAll("f")?.length > 1) {
        setHeaderTitle("Selected filters");
      } else {
        setHeaderTitle(filtersToDisplay[searchTerm.get("f")]);
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm.get("t")) {
      setHeaderTitle(searchTerm.get("t"));
    }
  }, [searchTerm]);

  const geolocationSuccess = (pos) => {
    const crd = pos.coords;
    viewSearchMap(!searchByLocation);
    searchUserLocation({ lat: crd.latitude, lng: crd.longitude });
  };

  const geolocationError = () => {
    viewSearchMap(!searchByLocation);
  };

  const searchByLocationHandler = () => {
    if (!searchByLocation)
      navigator.geolocation.getCurrentPosition(
        geolocationSuccess,
        geolocationError,
        options
      );
    else viewSearchMap(!searchByLocation);
  };
  const filtersToggleHandler = () => showFilterHandler(!showFilters);
  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.info}>
          <h1 className={styles.term}>{`${
            searchTerm.get("t")
              ? `search results for “${headerTitle}”`
              : `${headerTitle?.toLowerCase()}`
          }`}</h1>
          {count ? <p className={styles.count}>{`${count} RESULTS`}</p> : null}
        </div>
        <div className={styles.btnsContainer}>
          <button
            className={`${styles.location} ${
              searchByLocation ? styles.active : styles.notActive
            }`}
            onClick={searchByLocationHandler}
          >
            SEARCH BY LOCATION
          </button>
          <button className={styles.filters} onClick={filtersToggleHandler}>
            FILTERS & SORT BY
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
Header.propTypes = {
  showFilters: PropTypes.bool,
  showFilterHandler: PropTypes.func,
};
