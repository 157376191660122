import React from "react";
import { FIRST_COLUMN_FAQ, SECOND_COLUMN_FAQ } from "./faq";
import styles from "./MembershipFaq.module.css";
const MembershipFaq = () => {
  return (
    <section className={styles.container} id='faq'>
      <div className={styles.content}>
        <h2 className={styles.sectionTitle}>Membership FAQs</h2>
        <div className={styles.faqContainer}>
          <div className={styles.firstCol}>
            {FIRST_COLUMN_FAQ?.map((faq) => (
              <div className={styles.faq} key={faq.question}>
                <p className={styles.question}>{faq.question}</p>
                <p className={styles.answer}>{faq.answer}</p>
              </div>
            ))}
          </div>
          <div className={styles.secondCol}>
            {SECOND_COLUMN_FAQ?.map((faq) => (
              <div className={styles.faq} key={faq.question}>
                <p className={styles.question}>{faq.question}</p>
                <p className={styles.answer}>{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MembershipFaq;
