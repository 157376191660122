import React from "react";
import styles from "./PrevBtn.module.css";
import PropTypes from "prop-types";

const PrevBtn = (props) => {
  const { className, style, onClick, variant } = props;

  return (
    <div
      className={`${className} ${styles.prevBtn} ${variant}`}
      style={{
        ...style,
      }}
      onClick={onClick}
    >
      <svg
        width="19"
        height="21"
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8798 10.6602L0.795791 10.6602M0.795791 10.6602L9.85789 20.5242M0.795791 10.6602L9.85788 0.796171"
          stroke="#0A0A0A"
          strokeWidth="0.834871"
        />
      </svg>
    </div>
  );
};
export default PrevBtn;
PrevBtn.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.func,
};
