import { useQuery, useReactiveVar } from "@apollo/client";
import React from "react";
import BrowseAllSection from "../../components/GlobalComponents/Sections/BrowseAllSection/BrowseAllSection";
import EditorPicksSection from "../../components/GlobalComponents/Sections/EditorPicksSection/EditorPicksSection";
import MostPopularSection from "../../components/GlobalComponents/Sections/MostPopularSection/MostPopularSection";
import SharedHeroHeader from "../../components/GlobalComponents/Sections/SharedHeroHeader/SharedHeroHeader";
import SharedSignUpAd from "../../components/GlobalComponents/Sections/SharedSignUpAd/SharedSignUpAd";
import foodMostPopular from "../../graphql/queries/mostPopular/foodMostPopular";
import { isAdmin } from "../../utils/cache";
import regStyles from "./Food.module.css";
import adminStyles from "../../assets/styles/shared/AdminEditor.module.css";
import RecentlyListed from "../../components/GlobalComponents/Sections/RecentlyListed";
import { PAGES_NAMES } from "../../constants";

const Food = () => {
  const { data, loading, error } = useQuery(foodMostPopular);
  const { mostPopularFood: mostPopular } = data ?? {};
  const heroFood = mostPopular && mostPopular[0];
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;

  return (
    <div className={styles.foodPage}>
      <SharedHeroHeader
        imgSrc={heroFood?.featuredImage?.path}
        itemLink={`/food/${heroFood?.id}`}
        itemTitle={heroFood?.name}
      />
      <RecentlyListed pageName={PAGES_NAMES.FOOD} />

      <BrowseAllSection pageName={PAGES_NAMES.FOOD} />
      <MostPopularSection
        mostPopular={mostPopular?.slice(1)}
        isShared={true}
        loading={loading}
        error={error}
        pageName={PAGES_NAMES.FOOD}
      />

      <EditorPicksSection pageName={PAGES_NAMES.FOOD} />
      <SharedSignUpAd />
    </div>
  );
};

export default Food;
