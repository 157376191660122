import { useRef } from "react";

const useDragDrop = (items, setItems) => {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (position) => {
    dragItem.current = position;
  };
  const dragEnter = (position) => {
    dragOverItem.current = position;
  };
  const drop = (e) => {
    const itemsInstance = [...items];
    const dragItemContent = itemsInstance[dragItem.current];
    itemsInstance.splice(dragItem.current, 1);
    itemsInstance.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setItems(itemsInstance);
  };
  const dragOver = (e) => e.preventDefault();

  return {
    dragStart,
    dragEnter,
    drop,
    dragOver,
  };
};
export default useDragDrop;
