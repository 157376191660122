import { gql } from "@apollo/client";
export default gql`
  mutation CLOSE_MY_ACCOUNT {
    closeMyAccount {
      id
      firstName
      lastName
      isClosed
    }
  }
`;
