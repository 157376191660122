import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useReactiveVar } from "@apollo/client";
import { convertTimeToFloat } from "../../../utils/formatters";
import styles from "../BusinessOnboarding.module.css";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import BusinessHoursDay from "./BusinessHoursDay";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import updateBusinessOnboarding from "../../../graphql/mutations/updateBusiness";
import business from "../../../graphql/queries/pages/business";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import { BusinessOnboardingState, BUSINESS_TYPES } from "../../../constants";
import {
  errorToastHandler,
  isBusinessType,
  successToastHandler,
} from "../../../utils";
import { useNavigate } from "react-router-dom";
import { isAdmin, savingBizInfoState } from "../../../utils/cache";

const HOURS_FORMAT = "HH:mm:ss";

export default function BusinessHours({
  nextStepHandler,
  isEdit,
  insertBusinessData,
  businessData,
}) {
  const initHours = businessData?.workingHours;
  const businessId = businessData?.id;
  const businessTypes = businessData?.businessTypes;
  const [workingHours, setWorkingHours] = useState([
    { dayName: "Monday", from: null, to: null, open: false, closed: false },
    { dayName: "Tuesday", from: null, to: null, open: false, closed: false },
    {
      dayName: "Wednesday",
      from: null,
      to: null,
      open: false,
      closed: false,
    },
    {
      dayName: "Thursday",
      from: null,
      to: null,
      open: false,
      closed: false,
    },
    { dayName: "Friday", from: null, to: null, open: false, closed: false },
    {
      dayName: "Saturday",
      from: null,
      to: null,
      open: false,
      closed: false,
    },
    { dayName: "Sunday", from: null, to: null, open: false, closed: false },
  ]);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [showDayError, setShowDayError] = useState(false);
  const navigate = useNavigate();
  const [updateBusiness, { loading: businessLoading, error: businessError }] =
    useMutation(updateBusinessOnboarding, {
      refetchQueries: [{ query: business, variables: { id: businessId } }],
    });

  const isValidForm = () => {
    return workingHours.every(
      (day) => day.open || day.closed || (day.from !== null && day.to !== null)
    );
  };
  const [error, setError] = useState(false);
  const admin = useReactiveVar(isAdmin);
  useEffect(() => {
    if (businessError) {
      setError(true);
    }
  }, [businessError]);

  useEffect(() => {
    if (initHours?.length > 0) {
      const existingHours = workingHours?.map((dayTime) => {
        if (initHours.some((el) => el.day === dayTime.dayName)) {
          const returnedDay = initHours.find(
            (el) => el.day === dayTime.dayName
          );

          const isOpen = !returnedDay?.open && returnedDay?.close === 24;
          return {
            dayName: returnedDay.day,
            from: isOpen ? null : returnedDay?.open,
            to: isOpen ? null : returnedDay?.close,
            open: isOpen,
            closed: false,
          };
        }

        return {
          dayName: dayTime.dayName,
          from: null,
          to: null,
          open: false,
          closed: true,
        };
      });
      setWorkingHours(existingHours);
    }
  }, [initHours]);
  const handleHoursChange = (direction, dayName, value) => {
    const timeFloat = value
      ? convertTimeToFloat(value.format(HOURS_FORMAT))
      : null;

    let newWorkingHours = [...workingHours];
    setWorkingHours([]);
    newWorkingHours = newWorkingHours.map((day) => {
      if (day.dayName === dayName) {
        let newDay = { ...day };
        if (day.closed) {
          newDay = { ...newDay, closed: false };
        }
        if (day.open) {
          newDay = { ...newDay, open: false };
        }

        return {
          ...newDay,
          [direction]: timeFloat,
        };
      }
      return { ...day };
    });

    setWorkingHours([...newWorkingHours]);
    setIsEcommerce(false);
    setError(false);
  };

  const handleAvailabilityChange = (status, value, dayName) => {
    let newWorkingHours = [...workingHours];
    const otherStatus = status === "open" ? "closed" : "open";
    const newDay = {
      from: null,
      to: null,
      [status]: !value,
      [otherStatus]: value,
      dayName,
    };
    const newDays = newWorkingHours.map((el) => {
      if (el.dayName === dayName) {
        return newDay;
      }
      return el;
    });
    setWorkingHours(newDays);
    setIsEcommerce(false);
    setError(false);
  };

  const handleEcommerceBusiness = useCallback(() => {
    const newWorkingHours = [...workingHours];
    newWorkingHours.forEach((day) => {
      day.open = true;
      day.closed = false;
      day.from = null;
      day.to = null;
    });
    setWorkingHours(newWorkingHours);
    setIsEcommerce(true);
    setError(false);
  }, [setWorkingHours, workingHours]);

  const handleSubmit = () => {
    let result = [];
    let savedHours = [];
    if (!isValidForm()) {
      setShowDayError(true);
      return;
    }
    if (isValidForm()) {
      workingHours.forEach((day) => {
        if (day.closed) {
          return;
        }
        if (day.open) {
          const currentDay = { day: day.dayName, from: 0, to: 24 };
          const savedDay = { day: day.dayName, open: 0, close: 24 };
          result = [...result, currentDay];
          savedHours = [...savedHours, savedDay];
        }
        if (day.from !== null && day.to !== null) {
          const currentDay = { day: day.dayName, from: day.from, to: day.to };
          const savedDay = { day: day.dayName, open: day.from, close: day.to };
          result = [...result, currentDay];
          savedHours = [...savedHours, savedDay];
        }
      });
    }
    if (isEdit) {
      updateBusiness({
        variables: {
          id: businessId,
          workingHours: result,
        },
      })
        .then(() => {
          successToastHandler("UPDATED SUCCESSFULLY");
          if (admin) {
            navigate(`/listing-management/edit-business/${businessId}`);
          } else {
            navigate(`/edit-business/${businessId}`);
          }
        })
        .catch(() => errorToastHandler());
      return;
    }
    savingBizInfoState({ loading: true, error: false });
    analytics.track("business_hours");

    updateBusiness({
      variables: {
        id: businessId,
        workingHours: result,
        onboardingState: BusinessOnboardingState.businessCategories, // next step
      },
    })
      .then(({ data }) => {
        savingBizInfoState({ loading: false });

        insertBusinessData({
          workingHours: data?.updateBusiness?.workingHours,
        });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Business hours</h3>
        <p className={styles.description}>
          Add your business hours so customers know when you’re open *
        </p>
      </div>
      <div className={styles.daysList}>
        {workingHours.map((day) => (
          <BusinessHoursDay
            day={day}
            key={day.dayName}
            handleAvailabilityChange={handleAvailabilityChange}
            handleHoursChange={handleHoursChange}
            isEcommerce={isEcommerce}
            showDayError={showDayError}
          />
        ))}
      </div>
      {error && (
        <p className={styles.error}>
          Please make sure that close time is after opening.
        </p>
      )}

      <div className={styles.buttonsWrapper}>
        <RegisterNextBtn
          variant={parentStyles.continueBtn}
          disabled={businessLoading}
          clickHandler={handleSubmit}
          isLoading={businessLoading}
        >
          {businessLoading ? <Spinner /> : "SAVE & CONTINUE"}
        </RegisterNextBtn>
        {businessTypes &&
          !isBusinessType(businessTypes, BUSINESS_TYPES.brickMortars) && (
            <button
              className={styles.haveEcommerceBtn}
              onClick={handleEcommerceBusiness}
            >
              I have an ecommerce business
            </button>
          )}
      </div>
    </div>
  );
}

BusinessHours.propTypes = {
  nextStepHandler: PropTypes.func,
  isEdit: PropTypes.bool,
  insertBusinessData: PropTypes.func,
  businessData: PropTypes.object,
};
