import { useReactiveVar } from "@apollo/client";
import React, { useEffect } from "react";
import { beyotaCompleted, viewWinners } from "../../../../../utils/cache";
import Countdown from "react-countdown";
import Timer from "../../Timer";
import { EST_BEOTYA_VIEW_WINNERS } from "../../../../../constants";
import { zeroToSingleDigit } from "../../../../../utils";
import styles from "../VotingState.module.css";
const CompleteState = () => {
  const isCompleted = useReactiveVar(beyotaCompleted);
  const isViewWinners = useReactiveVar(viewWinners);

  useEffect(() => {
    const now = +new Date();
    const view = +new Date(EST_BEOTYA_VIEW_WINNERS);
    if (view <= now) {
      viewWinners(true);
    }
  }, []);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <>
          <span className={`${styles.text} ${styles.announcement}`}>
            Voting has concluded. Congratulations to our 2022 award winners!{" "}
          </span>
          <a
            href='#winners'
            className={`${styles.text} ${styles.announcement}`}
          >
            See Winners
          </a>
        </>
      );
    } else {
      // Render a countdown
      return (
        <Timer
          hours={zeroToSingleDigit(hours)}
          minutes={zeroToSingleDigit(minutes)}
          seconds={zeroToSingleDigit(seconds)}
          text={"Winners announced in "}
        />
      );
    }
  };

  const handleCompletedTimer = () => {
    viewWinners(true);
  };

  if (!isCompleted && !isViewWinners) {
    return (
      <a href='#nominations' className={styles.text}>
        Cast your vote now
      </a>
    );
  }

  return (
    <div>
      <div
        style={{ display: isCompleted && isViewWinners ? "initial" : "none" }}
      >
        <Countdown
          date={new Date(EST_BEOTYA_VIEW_WINNERS)}
          renderer={renderer}
          onComplete={handleCompletedTimer}
        />
      </div>
      {isCompleted && !isViewWinners && (
        <span className={styles.text}>
          Winners announced August 30 at 4:00 pm EST
        </span>
      )}
    </div>
  );
};

export default CompleteState;
