import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import membershipSubscribe from "../../../graphql/mutations/membershipSubscribe";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import styles from "./PaymentMethodForm.module.css";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import {
  authVar,
  businessListingStatus,
  savingBizInfoState,
} from "../../../utils/cache";
import Cookies from "universal-cookie";
import Tap from "@tapfiliate/tapfiliate-js";

export default function PaymentMethodForm({ elements, stripe, plan }) {
  const navigate = useNavigate();
  const userId = new Cookies().get("user-id");
  const [subscribeMember, { loading: loadingSubscription }] = useMutation(
    membershipSubscribe,
    {
      update: (cache, res) => {
        cache.modify({
          id: `User:${userId}`,
          fields: {
            subscribed() {
              return true;
            },
          },
        });
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const [stripeLoading, setStripeLoading] = useState(false);
  const listingStatus = useReactiveVar(businessListingStatus);

  const isLoading = loadingSubscription || stripeLoading;
  const user = useReactiveVar(authVar);
  const [subscriptionError, setSubscriptionError] = useState("");
  const elementOptions = {
    style: {
      base: {
        color: "black",
        fontSize: "20px",
        fontFamily: '"Open Sans", sans-serif',
        fontSmoothing: "antialiased",

        "::placeholder": {
          color: "black",
          fontSize: "20px",
          fontWeight: "unset",
          fontFamily: "var(--title-font-family)",
        },
      },
      invalid: {
        color: "red",
        ":focus": {
          color: "red",
        },
      },
    },
  };

  const handleSubmit = async (event, elements, stripe) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setStripeLoading(true);
    savingBizInfoState({ loading: true, error: false });

    const cardNumber = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(cardNumber);
    if (result.error) {
      setSubscriptionError(result.error.message);
      setStripeLoading(false);
      savingBizInfoState({
        loading: false,
        error: true,
        retry: handleSubmit,
      });
    } else {
      setSubscriptionError("");
      setStripeLoading(false);
      subscribeMember({
        variables: {
          cardToken: result.token.id,
          price: plan?.id,
          applyCoupon: false,
        },
      }).then(({ data }) => {
        if (process.env.REACT_APP_ENV === "prod") {
          Tap.init("35661-e23a0a", { integration: "stripe" });
          Tap.conversion(plan?.id, plan?.amount / 100, {
            customer_id: data?.membershipSubscribe?.stripeCustomerId,
          });
          fbq("track", "membership-paid");
        }
        savingBizInfoState({ loading: false });
        analytics.track("subscription_payment_verified");

        businessListingStatus({ ...listingStatus, isCompleted: true });
        navigate("/owner-picture");
      });
    }
  };
  return (
    <form
      onSubmit={(e) => handleSubmit(e, elements, stripe)}
      stripe={stripe}
      elements={elements}
      className={styles.paymentMethodForm}
    >
      <div className={styles.field}>
        <p className={styles.label}>CARD NUMBER</p>
        <CardNumberElement
          className={styles.elementStyle}
          options={{
            ...elementOptions,
            iconStyle: "solid",
            showIcon: true,
          }}
        />
      </div>
      <div className={styles.flexWrapper}>
        <div className={styles.field}>
          <p className={styles.label}>EXP DATE</p>
          <CardExpiryElement
            className={`${styles.elementStyle} ${styles.smallWidthElement}`}
            options={elementOptions}
          />
        </div>
        <div className={styles.field}>
          <p className={styles.label}>CVC</p>
          <CardCvcElement
            className={`${styles.elementStyle} ${styles.smallWidthElement}`}
            options={elementOptions}
          />
        </div>
      </div>
      {subscriptionError.length > 0 && (
        <p className={styles.error}>{subscriptionError}</p>
      )}
      <div className={styles.buttonsWrapper}>
        <RegisterNextBtn
          variant={parentStyles.continueBtn}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {isLoading ? <Spinner /> : "Finish"}
        </RegisterNextBtn>
      </div>
    </form>
  );
}

PaymentMethodForm.propTypes = {
  elements: PropTypes.object,
  stripe: PropTypes.object,
  businessId: PropTypes.string,
  plan: PropTypes.object,
};
