import React, { useEffect, useState } from "react";
import regStyles from "./ProductInfo.module.css";
import editStyles from "./EditPageStyles.module.css";
import PropTypes from "prop-types";
import Tags from "../../../UI/Tags/Tags";
import UpVoteButton from "../../../Buttons/UpVoteButton/UpVoteButton";
import AddToCollectionButton from "../../../Buttons/AddToCollectionButton/AddToCollectionButton";
import ShareButton from "../../../Buttons/ShareButton/ShareButton";
import ShareOptions from "../../../UI/ShareOptions/ShareOptions";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import ItemName from "../../../UI/ItemName/ItemName";
import ItemPrice from "./ItemPrice/ItemPrice";
import { NavLink } from "react-router-dom";

const ProductInfo = (props) => {
  const { data, isEdit } = props;
  const [tags, setTags] = useState([]);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const styles = isEdit ? editStyles : regStyles;
  useEffect(() => {
    if (data) {
      setTags([...data?.categories.slice(0, 5)]);
    }
  }, [data]);
  const toggleShareOptions = () => setShowShareOptions(!showShareOptions);

  return (
    <>
      <Backdrop show={showShareOptions} clickHandler={toggleShareOptions}>
        <ShareOptions
          closeHandler={toggleShareOptions}
          link={window?.location?.href}
          show={showShareOptions}
        />
      </Backdrop>
      <div className={styles.infoContainer}>
        <div className={styles.tagsContainer}>
          <Tags tag={data?.__typename?.toLowerCase()} isBusinessType />
          {tags?.map((tag, i) => (
            <Tags tag={tag?.name} key={i} dimmed />
          ))}
        </div>
        <NavLink
          to={`/business/${data?.business?.id}`}
          className={styles.businessName}
        >
          {data?.business?.name}
        </NavLink>
        <ItemName
          name={data?.name}
          itemType={data?.__typename?.toLowerCase()}
        />
        <ItemPrice
          price={data?.price}
          itemType={data?.__typename?.toLowerCase()}
        />
        <div className={styles.btnContainer}>
          <UpVoteButton
            variant={styles.upVote}
            voteCount={data?.votes}
            voted={data?.isVoted}
            itemId={+data?.id}
            itemType={data?.__typename?.toUpperCase()}
            item={data}
          />
          <ShareButton
            variant={styles.shareBtn}
            shareHandler={toggleShareOptions}
          />
          <AddToCollectionButton
            variant={styles.addToCollection}
            voteCount={data?.votes}
            addedToCollection={data?.isBookmarked}
            itemId={+data?.id}
            itemType={data?.__typename?.toUpperCase()}
            item={data}
          />
        </div>
      </div>
    </>
  );
};

export default ProductInfo;
ProductInfo.propTypes = {
  data: PropTypes.object,
  isEdit: PropTypes.bool,
};
