import React from "react";
import regStyles from "./SignUpAd.module.css";
import adminStyles from "./AdminStyles.module.css";
import adImg from "../../../../assets/images/smallerSignAd.png";
import adImgWebp from "../../../../assets/images/smallerSignAd.webp";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { isAdmin } from "../../../../utils/cache";
import GetListedBtn from "../../Buttons/GetListedBtn/GetListedBtn";
import Picture from "../../UI/Picture";
const SignUpAd = (props) => {
  const { text, btnText, btnLink } = props;
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  return (
    <section className={styles.signUpAd}>
      <div className={styles.container}>
        <p className={styles.text}>
          {text ? text : "Ready to promote your Black-owned business?"}
        </p>
        <div className={styles.overFlowContainer}>
          <Picture
            fallback={adImg}
            alt='sign up ad'
            variant={styles.adImg}
            webpSrc={adImgWebp}
          />
          <GetListedBtn variant={styles.learn} btnLink={btnLink}>
            {btnText ? btnText : "Get Started"}
          </GetListedBtn>
        </div>
      </div>
    </section>
  );
};

export default SignUpAd;
SignUpAd.propTypes = {
  text: PropTypes.string,
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
};
