import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import styles from "./BusinessPhotos.module.css";
import Input from "../../GlobalComponents/UI/Input/Input";
import imageUpload from "../../../graphql/mutations/imageUpload";
import removeIcon from "../../../assets/images/remove.svg";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import useImageHandler from "../../../utils/useImageHandler";
import updateBusiness from "../../../graphql/mutations/updateBusiness";
import { BusinessOnboardingState } from "../../../constants";
import { savingBizInfoState } from "../../../utils/cache";

const MAX_PHOTOS_LENGTH = 4;
const RESIZED_WIDTH = 900;
const RESIZED_HEIGHT = 900;
const BusinessPhotos = (props) => {
  const { insertBusinessData, nextStepHandler, businessData } = props;

  const [chosenPhoto, setChosenPhoto] = useState(
    businessData?.featuredImage ?? ""
  );
  const [businessImages, setBusinessImages] = useState(
    businessData?.randomImages ?? []
  );
  const totalImages = chosenPhoto
    ? [chosenPhoto, ...businessImages]
    : [...businessImages];
  const [updateBiz, { loading: updateLoading }] = useMutation(updateBusiness);

  const [uploadImages, { loading: uploadLoading, error }] =
    useMutation(imageUpload);
  const loading = updateLoading || uploadLoading;
  const {
    errorMessage,
    images,
    changeHandler,
    dragHandler,
    dropHandler,
    resizing,
    resetHandler,
  } = useImageHandler(
    RESIZED_WIDTH,
    RESIZED_HEIGHT,
    MAX_PHOTOS_LENGTH - businessImages?.length
  );

  useEffect(() => {
    insertBusinessData({
      ...businessData,
      featuredImage: chosenPhoto,
      randomImages: businessImages,
    });
  }, [businessImages, chosenPhoto]);

  useEffect(() => {
    if (images?.length > 0) {
      uploadImages({
        variables: {
          files: images.map((file) => ({
            image: file,
            path: "applications",
          })),
        },
      }).then(({ data }) => {
        const uploadedImages = data?.uploadImages;
        if (!chosenPhoto) {
          setChosenPhoto(uploadedImages[0]);
          setBusinessImages((prevImages) => [
            ...prevImages,
            ...uploadedImages?.slice(1),
          ]);
        } else {
          setBusinessImages((prevImages) => [
            ...prevImages,
            ...data?.uploadImages,
          ]);
        }
      });
    }
  }, [images]);

  const handleFeaturedImageSelect = (newSelected, oldSelected) => {
    setChosenPhoto(newSelected);
    const newBusinessImages = businessImages?.filter(
      (img) => img.id !== newSelected?.id
    );
    setBusinessImages([...newBusinessImages, oldSelected]);
  };

  const deleteHandler = (imgId) => {
    if (imgId === chosenPhoto?.id) {
      if (businessImages?.length) {
        setChosenPhoto(businessImages[0]);
        setBusinessImages((prev) => prev?.slice(1));
      } else {
        setChosenPhoto("");
      }
    } else {
      const newBusinessImages = businessImages?.filter(
        (img) => img?.id !== imgId
      );
      setBusinessImages(newBusinessImages);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const businessImagesIds = businessImages?.map((img) => img?.id);
    savingBizInfoState({ loading: true, error: false });
    analytics.track("business_images");

    updateBiz({
      variables: {
        id: businessData?.id,
        featuredImage: +chosenPhoto?.id,
        gallery: businessImagesIds,
        onboardingState: BusinessOnboardingState.socialLinks, // next step
      },
    })
      .then(() => {
        savingBizInfoState({ loading: false });

        insertBusinessData({
          featuredImage: chosenPhoto,
          randomImages: businessImages,
        });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };
  return (
    <form className={styles.imagesForm} onSubmit={submitHandler}>
      <h2
        className={`${parentStyles.altHeader} ${parentStyles.altHeaderWithDescription}`}
      >
        Business Photos
      </h2>
      <p className={parentStyles.headingDescription}>
        Photos are essential to presenting your business on OBWS. No logos or
        text-heavy images. To help customers learn about and choose your
        business, upload multiple high-quality photos of your products, store,
        office, or team. Choose up to 4.
      </p>

      <Input
        hasLabel
        isRequired
        label='ADD PHOTOS BELOW'
        fileLabel='Browse photos or drag to add here'
        elementType='file'
        type='file'
        multiple
        inpContainerClass={`${parentStyles.fullWidthContainer} ${
          styles.dropzoneContainer
        } ${businessImages?.length > 0 && styles.extraMargin} `}
        changeHandler={changeHandler}
        clickHandler={resetHandler}
        handleDrop={dropHandler}
        handleDrag={dragHandler}
        invalid={error || errorMessage ? true : false}
        errorMessage={errorMessage}
      >
        <div className={styles.previewsContainer}>
          {totalImages?.map(({ path, id }, index) => (
            <div
              key={id}
              className={`${styles.imageContainer} ${styles.selectable} `}
            >
              {id === chosenPhoto?.id && <div className={styles.layer} />}
              <img
                src={path}
                onClick={handleFeaturedImageSelect.bind(
                  null,
                  { path, id },
                  chosenPhoto
                )}
                className={`${styles.businessImage} ${
                  id === chosenPhoto?.id && styles.activePhoto
                }`}
              />
              <img
                src={removeIcon}
                onClick={deleteHandler.bind(null, id)}
                className={styles.remove}
              />
            </div>
          ))}
        </div>
      </Input>
      <RegisterNextBtn
        variant={parentStyles.continueBtn}
        disabled={totalImages?.length === 0}
        isLoading={loading || resizing}
      >
        {loading || resizing ? <Spinner /> : "SAVE & CONTINUE"}
      </RegisterNextBtn>
    </form>
  );
};
export default BusinessPhotos;
BusinessPhotos.propTypes = {
  insertBusinessData: PropTypes.func,
  nextStepHandler: PropTypes.func,
  businessData: PropTypes.object,
};
