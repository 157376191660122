import React, { useEffect, useState } from "react";
import styles from "./FiltersContainer.module.css";
import Filters from "./Filters/Filters";
import Suggested from "./Suggested/Suggested";
import SortBy from "./SortBy/SortBy";
import Categories from "./Categories/Categories";
import Price from "./Price/Price";
import Type from "./Type/Type";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { useQuery, useReactiveVar } from "@apollo/client";
import {
  appliedSearchFilters,
  differentSearchLocationSelected,
  notAppliedSearchFilters,
  searchTotalCount,
  searchUserLocation,
  viewSearchMap,
} from "../../../utils/cache";
import priceRange from "../../../graphql/queries/search/priceRange";
import { convertTimeToFloat } from "../../../utils/formatters";
import { BUSINESS_STATUS, weekday } from "../../../constants";
import BackButton from "../../GlobalComponents/Buttons/BackButton/BackButton";
import { searchFilterBy, searchSortArr } from "../../../constants/search";
const FiltersContainer = (props) => {
  const { refetch, setApplyingFilter, filtersDrawer, drawerToggleHandler } =
    props;
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sort, setSort] = useState("VOTES");
  const [typeToggle, setTypeToggle] = useState([]);
  const [locationParam, setLocationParam] = useState();
  const [priceMinValue, setPriceMinValue] = useState(0);
  const [priceMaxValue, setPriceMaxValue] = useState(0);
  const [disableClear, setDisableClear] = useState(false);
  const [openNow, setOpenNow] = useState(false);
  const [searchTerm, setSearchTerm] = useSearchParams();
  const userLocation = useReactiveVar(searchUserLocation);
  const differentLocation = useReactiveVar(differentSearchLocationSelected);
  const appliedFiltrs = useReactiveVar(appliedSearchFilters);

  const showMap = useReactiveVar(viewSearchMap);
  useEffect(() => {
    const filterParam = searchTerm.get("f");
    if (filterParam && filterParam === "BUSINESS") {
      setTypeToggle(["BrickMortars", "Ecommerce", "Restaurants"]);
    }
    return () => {
      notAppliedSearchFilters(searchFilterBy);
      appliedSearchFilters([]);
      searchTotalCount(null);
    };
  }, []);

  const {
    data: priceRangeData,
    loading: priceRangeLoading,
    error: priceRangeError,
  } = useQuery(priceRange);
  const { itemsWithPriceRange: price } = priceRangeData ?? {};

  useEffect(() => {
    if (price) {
      setPriceMinValue(+price[1].price?.toFixed(0));
      setPriceMaxValue(+price[0].price?.toFixed(0));
    }
  }, [price]);
  const timeFilter = () => {
    const date = new Date();
    const dateFormat = date.toLocaleString("en-GB");
    const hoursFormat = dateFormat.slice(
      dateFormat.indexOf(",") + 2,
      dateFormat.length
    );
    const floatFormat = convertTimeToFloat(hoursFormat);
    const day = weekday[date.getDay()];
    return { day, time: floatFormat };
  };

  const refetchHandler = (isPriceChanged = false) => {
    let priceRange = null;
    if (isPriceChanged) {
      priceRange =
        priceMinValue || priceMaxValue
          ? { from: priceMinValue, to: priceMaxValue }
          : null;
    }
    if (
      isPriceChanged &&
      +price[0].price?.toFixed(0) === priceMaxValue &&
      +price[1].price?.toFixed(0) === priceMinValue
    ) {
      priceRange = null;
    }
    setApplyingFilter(true);
    if (price && !isPriceChanged) {
      setPriceMinValue(+price[1].price?.toFixed(0));
      setPriceMaxValue(+price[0].price?.toFixed(0));
    }

    const filters = appliedFiltrs?.map((fil) => fil?.filterType);
    const filtersWithTypes = [...new Set([...filters, ...typeToggle])];
    refetch({
      text: searchTerm.get("t") ?? "",
      page: 1,
      size: 20,
      lat: userLocation?.lat,
      lng: userLocation?.lng,
      sortBy: {
        type: sort,
        locationParameter: locationParam,
      },
      filters: {
        typesAndCategories: {
          types: filtersWithTypes,
          categories: selectedCategories,
          status: [BUSINESS_STATUS.APPROVED],
        },
        priceRange,
        openAt: openNow ? timeFilter() : null,
      },
    }).then(() => setApplyingFilter(false));
  };
  useEffect(() => {
    if (
      selectedCategories?.length === 0 &&
      sort === "VOTES" &&
      !openNow &&
      appliedFiltrs.length === 0 &&
      typeToggle.length === 0
    )
      setDisableClear(true);
    else setDisableClear(false);

    refetchHandler();
  }, [
    searchTerm,
    sort,
    locationParam,
    appliedFiltrs,
    selectedCategories,
    openNow,
    typeToggle,
  ]);
  useEffect(() => {
    if (showMap && userLocation) setLocationParam(userLocation);
    if (!showMap && userLocation) setLocationParam(userLocation);
    if (showMap && !userLocation && differentLocation?.lat) {
      setSort("NEAR_ME");
      setLocationParam(differentLocation);
    }
  }, [userLocation, showMap, differentLocation]);

  const categoryChangeHandler = (category) => {
    if (!category) {
      setSelectedCategories([]);
      searchTerm.set("c", "All Categories");
      setSearchTerm(searchTerm);
      return;
    }
    if (selectedCategories?.some((el) => el === category?.id)) {
      const newSelectedCategories = selectedCategories?.filter(
        (cat) => cat !== category?.id
      );
      setSelectedCategories([...newSelectedCategories]);
    } else {
      setSelectedCategories((prevCategories) => [
        ...prevCategories,
        category?.id,
      ]);
    }
  };
  const sortChangeHandler = (e) => {
    if (e.target.name === "NEAR_ME" && !userLocation && !differentLocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const crd = pos.coords;
          searchUserLocation({ lat: crd.latitude, lng: crd.longitude });
          setSort(e.target.name);
        },
        () => {
          return;
        }
      );
    } else {
      setSort(e.target.name);
    }
    const sortTerm = searchSortArr.find((el) => el.label === e.target.name);
    searchTerm.set("s", sortTerm?.name);
    setSearchTerm(searchTerm);
  };

  const typeChangeHandler = (e) => {
    const typeArr = e.target.name.split(",").filter((el) => el);
    setTypeToggle(typeArr);
    appliedSearchFilters([]);
    notAppliedSearchFilters(searchFilterBy);
    searchTerm.delete("f");
    typeArr.map((el) => searchTerm.append("f", el));
    setSearchTerm(searchTerm);
  };
  const handleMinChange = (e) => {
    const value = Math.min(+e.target.value, priceMaxValue - 12);
    setPriceMinValue(value);

    e.target.value = value.toString();
  };
  const handleMaxChange = (e) => {
    const value = Math.max(+e.target.value, priceMinValue + 12);
    setPriceMaxValue(value);
    e.target.value = value.toString();
  };
  useEffect(() => {
    if (price) {
      if (
        +price[0].price?.toFixed(0) === priceMaxValue &&
        +price[1].price?.toFixed(0) === priceMinValue
      )
        setDisableClear(true);
      else setDisableClear(false);
    }
  }, [price, priceMaxValue, priceMinValue]);

  const openNowHandler = () => setOpenNow(!openNow);

  const clearFilters = () => {
    drawerToggleHandler();
    setSelectedCategories(null);
    setSort("VOTES");
    setTypeToggle([]);
    setLocationParam();
    setOpenNow(false);
    appliedSearchFilters([]);
    notAppliedSearchFilters(searchFilterBy);
    if (price) {
      setPriceMinValue(+price[1].price?.toFixed(0));
      setPriceMaxValue(+price[0].price?.toFixed(0));
    }
  };

  return (
    <>
      <div
        className={`${styles.filtersDrawer} ${
          filtersDrawer ? styles.open : styles.close
        }`}
      >
        <div className={styles.header}>
          <h1 className={styles.title}>Filter & Sort by</h1>
          <BackButton
            variant={styles.backBtn}
            backHandler={drawerToggleHandler}
          />
        </div>
      </div>

      {filtersDrawer && (
        <button
          className={styles.apply}
          onClick={clearFilters}
          disabled={disableClear}
        >
          CLEAR FILTERS
        </button>
      )}
      <div
        className={`${styles.filtersSide} ${
          filtersDrawer ? styles.drawer : styles.noDrawer
        } `}
      >
        <Filters setTypeToggle={setTypeToggle} typeToggle={typeToggle} />
        <div className={styles.brdr} />
        <Suggested openNow={openNow} openNowHandler={openNowHandler} />
        <div className={styles.brdr} />
        <SortBy sortChangeHandler={sortChangeHandler} sort={sort} />
        <div className={styles.brdr} />
        <Categories
          categoryChangeHandler={categoryChangeHandler}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
        <div className={styles.brdr} />
        <Price
          rangeMaxValue={priceMaxValue}
          rangeMinValue={priceMinValue}
          handleRangeMinChange={handleMinChange}
          handleRangeMaxChange={handleMaxChange}
          range={price}
          loading={priceRangeLoading}
          error={priceRangeError}
          mouseUpHandler={refetchHandler.bind(null, true)}
        />
        <div className={styles.brdr} />
        <Type typeChangeHandler={typeChangeHandler} chosenType={typeToggle} />
      </div>
    </>
  );
};

export default FiltersContainer;
FiltersContainer.propTypes = {
  refetch: PropTypes.func,
  setApplyingFilter: PropTypes.func,
  filtersDrawer: PropTypes.bool,
  drawerToggleHandler: PropTypes.func,
};
