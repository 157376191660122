import React from "react";
import PropTypes from "prop-types";
import styles from "./RegisterNextBtn.module.css";

const RegisterNextBtn = (props) => {
  const { variant, children, disabled, clickHandler, isLoading } = props;
  return (
    <button
      className={`${variant} ${styles.btn} ${
        isLoading ? styles.loadingBg : styles.regularBg
      }`}
      disabled={isLoading ? false : disabled}
      onClick={clickHandler}
    >
      {children}
    </button>
  );
};

export default RegisterNextBtn;
RegisterNextBtn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  clickHandler: PropTypes.func,
  isLoading: PropTypes.bool,
};
