import { useMutation, useReactiveVar } from "@apollo/client";
import React from "react";
import Input from "../../components/GlobalComponents/UI/Input/Input";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";
import contactUs from "../../graphql/mutations/contactUs";
import useInput from "../../hooks/useInput";
import { authVar } from "../../utils/cache";
import styles from "./BusinessSupport.module.css";

const BusinessSupport = () => {
  const {
    value: message,
    valueChangeHandler: messageHandler,
    resetHandler: messageResetHandler,
  } = useInput();

  const [sendMessage, { data, loading, error }] = useMutation(contactUs);
  const userData = useReactiveVar(authVar);

  const submitHandler = (e) => {
    e.preventDefault();
    sendMessage({
      variables: {
        email: userData?.email,
        name: `${userData?.firstName} ${userData?.lastName}`,
        subject: "",
        content: message,
      },
    }).then(() => messageResetHandler());
  };

  return (
    <>
      {data ? (
        <h1 className={styles.successMessage}>
          We just received your message. Thank you very much for writing to us.
          We are working on your request and will get in touch as soon as
          possible.
        </h1>
      ) : (
        <form className={styles.supportForm} onSubmit={submitHandler}>
          <Input
            elementType='textArea'
            placeholder='Add your message here'
            value={message}
            changeHandler={messageHandler}
            inpContainerClass={styles.inpContainer}
            hasLabel={true}
            label='Send support ticket'
            invalid={error ? true : false}
            errorMessage={error?.message}
          />
          <button
            type='submit'
            className={`${styles.sendBtn} ${
              loading ? styles.loadingBg : styles.regularBg
            }`}
            disabled={message.length === 0}
          >
            {loading ? <Spinner /> : "SEND"}
          </button>
        </form>
      )}
    </>
  );
};

export default BusinessSupport;
