import React, { useEffect, useState } from "react";
import styles from "./MightLike.module.css";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ImageShapes from "../../UI/ImageShapes/ImageShapes";
import UpVoteButton from "../../Buttons/UpVoteButton/UpVoteButton";
import AddToCollectionButton from "../../Buttons/AddToCollectionButton/AddToCollectionButton";
import SeeAllBtn from "../../Buttons/SeeAllBtn/SeeAllBtn";
import Spinner from "../../UI/Spinner/Spinner";
import ErrorComponent from "../../UI/ErrorComponent/ErrorComponent";
import SectionLoader from "../../UI/SectionLoader/SectionLoader";

const MightLike = (props) => {
  const { data, isShared, loading, error, seeAllLink } = props;
  return (
    <section
      className={`${styles.popularSection} ${isShared && styles.shardSection}`}
    >
      <div className={styles.popularContainer}>
        <div className={styles.header}>
          <h3 className={styles.sectionTitle}>You might also like</h3>
          <SeeAllBtn route={seeAllLink} />
        </div>
        {error && <ErrorComponent />}
        <div
          className={`${data ? styles.items : styles.notLoaded} ${
            data?.length === 5 ? styles.colStyle : styles.rowStyle
          }`}
        >
          {loading && <SectionLoader itemsNumber={8} />}
          {data?.map((item, i) => (
            <NavLink
              to={`/${item?.__typename?.toLowerCase()}/${item?.id}`}
              key={`${item?.name}-${i}`}
              className={`${styles.item} ${
                data?.length === 5 && styles[`item${i}`]
              }`}
            >
              <div
                className={`${styles.imgContainer} ${
                  data?.length === 5 && styles[`imgContainer${i}`]
                }`}
              >
                <ImageShapes
                  variant={`${
                    i === 4 && data?.length === 5
                      ? styles.firstItemImg
                      : styles.itemImg
                  }`}
                  imgSrc={item?.featuredImage?.path}
                  alt={item?.product}
                />
                <UpVoteButton
                  variant={`${i === 4 ? styles.firstUpVote : styles.upVote}`}
                  voteCount={item?.votes}
                  itemType={item?.__typename.toUpperCase()}
                  itemId={+item?.id}
                  voted={item?.isVoted}
                  item={item}
                />
                <AddToCollectionButton
                  variant={`${
                    i === 4
                      ? styles.firstAddToCollection
                      : styles.addToCollection
                  }`}
                  addedToCollection={item?.isBookmarked}
                  itemType={item?.__typename.toUpperCase()}
                  itemId={+item?.id}
                  item={item}
                />
              </div>
              {item?.__typename.toLowerCase() === "business" ? (
                <>
                  <p className={styles.business}>{item?.name}</p>
                  <p className={styles.product}>
                    {item?.categories?.map((cat, i) => {
                      if (i !== item?.categories?.length - 1)
                        return `${cat.name}, `;
                      else return `${cat.name}`;
                    })}
                  </p>
                </>
              ) : (
                <>
                  <p className={styles.business}>{item?.business?.name}</p>
                  <p className={styles.product}>{item?.name}</p>
                </>
              )}
              <p className={styles.price}>
                {item?.price && `$${item?.price?.toFixed(2)}`}
              </p>
            </NavLink>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MightLike;
MightLike.propTypes = {
  data: PropTypes.array,
  isShared: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  seeAllLink: PropTypes.string,
};
