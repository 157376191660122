export const NavigationItems = [
  { navItem: "DISCOVER", route: "/", adminRoute: "/admin-editor" },
  {
    navItem: "PRODUCTS",
    route: "/products",
    adminRoute: "/admin-editor/products",
  },
  {
    navItem: "SERVICES",
    route: "/services",
    adminRoute: "/admin-editor/services",
  },
  {
    navItem: "BUSINESSES",
    route: "/businesses",
    adminRoute: "/admin-editor/businesses",
  },
  {
    navItem: "BRICK & MORTAR",
    route: "/brick-and-mortars",
    adminRoute: "/admin-editor/brick-and-mortars",
  },
  {
    navItem: "E-COMMERCE",
    route: "/e-commerce",
    adminRoute: "/admin-editor/e-commerce",
  },
  {
    navItem: "RESTAURANTS",
    route: "/restaurants",
    adminRoute: "/admin-editor/restaurants",
  },
  { navItem: "FOOD", route: "/food", adminRoute: "/admin-editor/food" },
];
