import { gql } from "@apollo/client";
export default gql`
  mutation membershipSubscribe(
    $cardToken: String
    $price: String!
    $applyCoupon: Boolean!
  ) {
    membershipSubscribe(
      cardToken: $cardToken
      paymentMethod: null
      price: $price
      applyCoupon: $applyCoupon
    ) {
      id
      stripeCustomerId
    }
  }
`;
