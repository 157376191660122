import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styles from "./SeeAllBtn.module.css";

const SeeAllBtn = (props) => {
  const { route, btnText } = props;
  return (
    <NavLink to={route} className={styles.seeAllBtn}>
      <span
        className={`${styles.text} ${
          btnText ? styles.customText : styles.seeText
        }`}
      >
        {btnText ? btnText : "SEE ALL"}
      </span>
      <svg
        className={`${styles.arrow} ${
          btnText ? styles.customTextArrow : styles.seeArrow
        }`}
        width='41'
        height='19'
        viewBox='0 0 41 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.43094e-07 9.5L39 9.5M39 9.5L30.3333 18M39 9.5L30.3333 0.999998'
          stroke='#0A0A0A'
          strokeWidth='1.47222'
        />
      </svg>
    </NavLink>
  );
};

export default SeeAllBtn;
SeeAllBtn.propTypes = { route: PropTypes.string, btnText: PropTypes.string };
