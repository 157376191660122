import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import collection from "../../../../graphql/queries/collection";
import useInput from "../../../../hooks/useInput";
import { collectionNameRules } from "../../../../utils/validationRules";
import ErrorComponent from "../../../GlobalComponents/UI/ErrorComponent/ErrorComponent";
import ImageShapes from "../../../GlobalComponents/UI/ImageShapes/ImageShapes";
import Input from "../../../GlobalComponents/UI/Input/Input";
import Spinner from "../../../GlobalComponents/UI/Spinner/Spinner";
import styles from "./EditCollection.module.css";
import deleteIcon from "../../../../assets/images/close.svg";
import updateCollection from "../../../../graphql/mutations/collections/updateCollection";
import PropTypes from "prop-types";

const EditCollection = (props) => {
  const { editToggle, closeHandler } = props;
  const { id } = useParams();
  const { data, loading, error } = useQuery(collection, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });
  const [
    edit,
    { data: editData, loading: editLoading, error: editError, reset },
  ] = useMutation(updateCollection, {
    refetchQueries: [{ query: collection, variables: { id } }],
  });

  const isLoading = loading || editLoading;
  const { name } = data?.collection ?? {};
  const { path: collectionImage } = data?.collection?.image ?? {};
  const {
    value: collectionName,
    hasError: collectionError,
    errorMessage: collectionErrorMessage,
    valueChangeHandler: collectionHandler,
    valueBlurHandler: collectionBlurHandler,
    isValid: collectionValid,
  } = useInput(collectionNameRules, name);

  const clearHandler = () => {
    setName("");
  };

  const editHandler = () => {
    if (!collectionValid) return;
    if (name === collectionName) {
      editToggle();
      closeHandler();
    }
    edit({
      variables: { id, name: collectionName },
    });
  };
  useEffect(() => {
    if (editData && !loading) {
      editToggle();
      closeHandler();
    }
  }, [editData, loading]);

  return (
    <>
      {loading && (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )}
      {error && <ErrorComponent variant={styles.errorMessage} />}
      {!loading && !error && (
        <div className={styles.editContainer}>
          <h1 className={styles.title}>Edit Collection</h1>
          <ImageShapes
            variant={styles.collectionImage}
            alt='collection image'
            imgSrc={collectionImage}
          />
          {/* <button className={styles.changeBtn}>CHANGE COVER</button> */}
          <div className={styles.inpIcon}>
            <Input
              type='text'
              inpContainerClass={styles.inpContainer}
              hasLabel
              isRequired
              label='NAME'
              value={collectionName}
              changeHandler={collectionHandler}
              blurHandler={collectionBlurHandler}
              invalid={collectionError || editError}
              errorMessage={editError?.message || collectionErrorMessage}
            />
            <img
              src={deleteIcon}
              className={styles.delete}
              alt='delete icon'
              onClick={clearHandler}
            />
          </div>

          <button
            type='button'
            className={` ${styles.confirmBtn} ${
              isLoading ? styles.loadingBg : styles.regularBg
            }`}
            disabled={!collectionName}
            onClick={editHandler}
          >
            {isLoading ? <Spinner variant={styles.loader} /> : "DONE"}
          </button>
        </div>
      )}
    </>
  );
};

export default EditCollection;
EditCollection.propTypes = {
  editToggle: PropTypes.func,
  closeHandler: PropTypes.func,
};
