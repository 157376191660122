import React from "react";
import ExploreThePlatform from "./ExploreThePlatform";
import brickExplore from "../../../../assets/images/brickExplore.png";
import products from "../../../../assets/images/products.png";
import restaurantsExplore from "../../../../assets/images/restaurantsExplore.png";
import servicesExplore from "../../../../assets/images/servicesExplore.png";
import eCommerceExplore from "../../../../assets/images/eCommerceExplore.png";
import brickExplorewebp from "../../../../assets/images/brickExplore.webp";
import productswebp from "../../../../assets/images/products.webp";
import restaurantsExplorewebp from "../../../../assets/images/restaurantsExplore.webp";
import servicesExplorewebp from "../../../../assets/images/servicesExplore.webp";
import eCommerceExplorewebp from "../../../../assets/images/eCommerceExplore.webp";

export default {
  title: "Sections/Explore The Platform",
  component: ExploreThePlatform,
};

export const categories = [
  {
    title: "Brick & mortars near you",
    subTitle: "Find the best Black-owned shops in your neighborhood",
    imgSrc: brickExplore,
    webpSrc: brickExplorewebp,
    route: "/brick-and-mortars",
  },
  {
    title: "Amazing products",
    subTitle: "Shop the best Black-owned products",
    imgSrc: products,
    webpSrc: productswebp,
    route: "/products",
  },
  {
    title: "E-commerce gems",
    subTitle: "Discover new online businesses that are shaking the culture up",
    imgSrc: eCommerceExplore,
    webpSrc: eCommerceExplorewebp,
    route: "/e-commerce",
  },
  {
    title: "On-the-go services",
    subTitle: "Book an expert for anything you may need",
    imgSrc: servicesExplore,
    webpSrc: servicesExplorewebp,
    route: "/services",
  },

  {
    title: "The hottest restaurants",
    subTitle: "Eat at the best Black-owned restaurants in your city",
    imgSrc: restaurantsExplore,
    webpSrc: restaurantsExplorewebp,
    route: "/restaurants",
  },
];

export const ExploreThePlatformStory = () => (
  <ExploreThePlatform categories={categories} />
);
