import React from "react";
import styles from "./DeleteAlert.module.css";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useApolloClient } from "@apollo/client";
import deleteCollection from "../../../../graphql/mutations/collections/deleteCollection";
import Spinner from "../../../GlobalComponents/UI/Spinner/Spinner";
import ErrorComponent from "../../../GlobalComponents/UI/ErrorComponent/ErrorComponent";

const DeleteAlert = (props) => {
  const { alertToggle } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [deleteMutation, { loading, error }] = useMutation(deleteCollection);
  const client = useApolloClient();

  const deleteHandler = () => {
    deleteMutation({ variables: { id } }).then(() => {
      navigate("/collections");
      client.resetStore();
    });
  };

  return (
    <div className={styles.alertContainer}>
      {!error && !loading && (
        <div className={styles.content}>
          <h1 className={styles.title}>Delete Collection?</h1>
          <p className={styles.text}>
            When you delete this collection, the photos and videos will still be
            saved.
          </p>
          <button className={styles.delete} onClick={deleteHandler}>
            DELETE COLLECTION
          </button>
          <button className={styles.cancel} onClick={alertToggle}>
            CANCEL
          </button>
        </div>
      )}
      {loading && <Spinner />}
      {error && <ErrorComponent variant={styles.errorMessage} />}
    </div>
  );
};

export default DeleteAlert;
DeleteAlert.propTypes = {
  alertToggle: PropTypes.func,
};
