import { useMutation, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { itemEditField, editView } from "../../../../../utils/cache";
import ApplyEditBtn from "../../../Buttons/ApplyEditBtn/ApplyEditBtn";
import EditTooltip from "../../../UI/EditTooltip/EditTooltip";
import Input from "../../../UI/Input/Input";
import PropTypes from "prop-types";
import UserPicture from "../../../UI/UserPicture/UserPicture";
import styles from "./OwnerPicture.module.css";
import imageUpload from "../../../../../graphql/mutations/imageUpload";
import useImageHandler from "../../../../../utils/useImageHandler";
import updateUserAvatar from "../../../../../graphql/mutations/updateUserAvatar";
import {
  errorToastHandler,
  queryToRefetch,
  successToastHandler,
} from "../../../../../utils";
import { ITEM_TYPES } from "../../../../../constants";

const OwnerPicture = (props) => {
  const { avatar, itemType, ownerId, itemId } = props;
  const showEditTooltip = useReactiveVar(itemEditField);
  const isEditing = useReactiveVar(editView);
  const [updateOwner, { loading }] = useMutation(updateUserAvatar, {
    refetchQueries: [
      {
        query: queryToRefetch(itemType?.toLowerCase()),
        variables: { id: itemId },
      },
    ],
  });
  const [preview, setPreview] = useState("");
  const [uploadProfilePicture, { loading: uploadLoading, data, error }] =
    useMutation(imageUpload);

  const {
    errorMessage,
    images,
    changeHandler,
    dragHandler,
    dropHandler,
    resizing,
  } = useImageHandler(315, 315, 1);

  useEffect(() => {
    if (images.length > 0) {
      const newFiles = images.map((file) => ({
        image: file,
        path: "applications",
      }));
      uploadProfilePicture({ variables: { files: newFiles } }).then(
        ({ data }) => {
          setPreview(data.uploadImages[0].path);
        }
      );
    }
  }, [images]);

  const submitHandler = (e) => {
    e.preventDefault();
    updateOwner({
      variables: { id: ownerId, avatar: data?.uploadImages[0]?.id },
    })
      .then(() => {
        itemEditField(null);
        setPreview("");
        successToastHandler("UPDATED SUCCESSFULLY");
      })
      .catch(() => errorToastHandler());
  };

  return (
    <div className={styles.container}>
      <EditTooltip
        show={showEditTooltip === "ownerAvatar" && isEditing}
        variant={styles.avatarTooltip}
        anchorStyle='left'
        closeHandler={itemEditField.bind(null, "")}
      >
        <form onSubmit={submitHandler}>
          {preview ? (
            <img
              src={preview}
              alt='profile picture'
              className={styles.profileImage}
            />
          ) : (
            <Input
              elementType='file'
              changeHandler={changeHandler}
              inpContainerClass={`${styles.inpContainer} ${
                (error || errorMessage) && styles.invalid
              }`}
              type='file'
              fileLabel='DRAG AND DROP PHOTO HERE'
              handleDrag={dragHandler}
              handleDrop={dropHandler}
              invalid={error || errorMessage ? true : false}
              errorMessage={errorMessage}
              isUploadProfile
            />
          )}
          <ApplyEditBtn
            loading={loading || uploadLoading || resizing}
            disable={!preview}
          />
        </form>
      </EditTooltip>
      <UserPicture
        path={avatar?.path}
        variant={`${styles.profilePic} ${
          isEditing && itemType === ITEM_TYPES?.BUSINESS
            ? styles.editImg
            : styles.noEdit
        }`}
        clickHandler={
          itemType === ITEM_TYPES?.BUSINESS
            ? itemEditField.bind(null, "ownerAvatar")
            : null
        }
      />
    </div>
  );
};

export default OwnerPicture;
OwnerPicture.propTypes = {
  avatar: PropTypes.object,
  itemType: PropTypes.string,
  ownerId: PropTypes.string,
  itemId: PropTypes.string,
};
