import React from "react";
import PropTypes from "prop-types";
import Modal from "../../GlobalComponents/UI/Modal/Modal";
import styles from "./CategoriesModal.module.css";
import BusinessCategories from "../../BusinessRegister/BusinessCategories/BusinessCategories";

export default function CategoriesModal({
  toggleCategoriesModal,
  handleAddCategory,
  chooseMultiple,
  selected,
}) {
  return (
    <Modal variant={`${styles.popUp} `}>
      <BusinessCategories
        isCategoriesModal={true}
        insertBusinessData={handleAddCategory}
        nextStepHandler={toggleCategoriesModal}
        chooseMultiple={chooseMultiple}
        selected={selected}
      />
    </Modal>
  );
}

CategoriesModal.propTypes = {
  toggleCategoriesModal: PropTypes.func,
  handleAddCategory: PropTypes.func,
  chooseMultiple: PropTypes.bool,
  selected: PropTypes.array,
};
