import React from "react";
import AuthenticationOptions from "../../components/GlobalComponents/Authentication/AuthenticationOptions/AuthenticationOptions";
import SignInForm from "../../components/Login/SignInForm/SignInForm";
import styles from "./Login.module.css";

const Login = () => {
  return (
    <div className={styles.loginPage}>
      <div className={styles.loginContainer}>
        <SignInForm />
        {/*TODO: Add and Integrate AuthenticationOptions */}
        <div className={styles.brdr}></div>
        <div className={styles.authOptions}>
          <AuthenticationOptions isLogin />
        </div>
      </div>
    </div>
  );
};

export default Login;
