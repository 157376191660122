import React from "react";
import Slider from "react-slick";
import styles from "../ProcessAndBenefits.module.css";
import PropTypes from "prop-types";
const TitleSlider = (props) => {
  const { text } = props;
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    arrows: false,
    cssEase: "linear",
    draggable: true,
    className: styles["slider"],
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 6,
        },
        breakpoint: 520,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {Array.from(Array(12).keys()).map((el, i) => (
          <h2 className={styles.title} key={i}>
            {text}
          </h2>
        ))}
      </Slider>
    </div>
  );
};

export default TitleSlider;
TitleSlider.propTypes = {
  text: PropTypes.string,
};
