import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { pageOptions } from "../../../constants";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import HeaderBackBtn from "../../GlobalComponents/Admin/HeaderBackBtn";
import SelectPageInp from "../../GlobalComponents/Admin/SelectPageInp";
import styles from "./Header.module.css";

const Header = (props) => {
  const [page, setPage] = useState("Discover");
  const [pageParams, setPageParams] = useSearchParams();
  const pageName = pageParams?.get("p");

  const backRoute = pageOptions.find(
    (el) => el?.value === pageName
  )?.adminRoute;
  const pageChangeHandler = (e) => {
    setPage(e.target.value);
    setPageParams({ p: e.target.value }, { replace: true });
  };

  useEffect(() => {
    const pageObj = pageOptions.find((el) => el?.value === pageName);
    setPage(pageObj?.value);
  }, [pageParams]);

  useEffect(() => {
    if (!pageName) {
      setPageParams({ p: page });
    }
  }, []);

  return (
    <header className={styles.header}>
      <HeaderBackBtn backRoute={backRoute} />
      <div className={styles.secondRow}>
        <AdminTitle title='Browse the things you need' />
        <SelectPageInp
          page={page}
          pageChangeHandler={pageChangeHandler}
          customStyle={styles?.select}
        />
      </div>
    </header>
  );
};

export default Header;
