import React from "react";
import styles from "./ApplyEditBtn.module.css";
import PropTypes from "prop-types";
import Spinner from "../../UI/Spinner/Spinner";
const ApplyEditBtn = (props) => {
  const { loading, disable } = props;
  return (
    <button
      type='submit'
      className={`${styles.apply} ${
        loading ? styles.loadingBg : styles.regularBg
      }`}
      disabled={disable}
    >
      {loading ? <Spinner variant={styles.loader} /> : "APPLY"}
    </button>
  );
};

export default ApplyEditBtn;
ApplyEditBtn.propTypes = {
  loading: PropTypes.bool,
  disable: PropTypes.bool,
};
