import React, { useRef } from "react";
import PropTypes from "prop-types";
import regStyles from "./NavigationHeader.module.css";
import adminStyles from "./AdminStyles.module.css";
import { NavigationItems } from "./NavigationItems";
import { NavLink, useLocation } from "react-router-dom";
import Tooltip from "../../UI/Tooltip/Tooltip";
import ConsumerOnboarding from "../../../Onboarding/Consumer/ConsumerOnboarding";
import { consumerOnboardingVar, isAdmin } from "../../../../utils/cache";
import { useReactiveVar } from "@apollo/client";

const NavigationHeader = (props) => {
  const { closeSideNav } = props;
  const { pathname } = useLocation();
  const childRef = useRef();
  const onboardingStep = useReactiveVar(consumerOnboardingVar);
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  const handleNextStep = () => consumerOnboardingVar(onboardingStep + 1);

  return (
    <ul className={styles.navItems} onClick={closeSideNav}>
      {NavigationItems.map((item, i) => (
        <li
          className={`${styles.item} ${
            pathname === "/awards2022" ? styles.landingColor : styles.regColor
          }`}
          key={i}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : styles.link
            }
            to={admin ? item?.adminRoute : item.route}
            end
          >
            {item.navItem}
          </NavLink>
          <Tooltip
            show={
              item.navItem === "PRODUCTS" &&
              pathname === "/" &&
              onboardingStep === 0
            }
            anchorVariant='top'
            handleTooltipClose={(e) => childRef?.current?.handleClose(e)}
          >
            <ConsumerOnboarding
              ref={childRef}
              onboardingStep={onboardingStep + 1}
              heading='Discover Anything'
              description='Use this navigational slider to discover new products,
                      services and businesses'
              handleNext={handleNextStep}
            />
          </Tooltip>
        </li>
      ))}
    </ul>
  );
};

export default NavigationHeader;

NavigationHeader.propTypes = {
  variant: PropTypes.string,
  closeSideNav: PropTypes.func,
};
