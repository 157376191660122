import React, { forwardRef } from "react";
import Slider from "react-slick";
import { judgesInfo } from "../judges";
import styles from "./ImagesSlider.module.css";
import PropTypes from "prop-types";
import Picture from "../../../GlobalComponents/UI/Picture";
const ImagesSlider = forwardRef((props, ref) => {
  const { setCurrentIndex, currentIndex } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    arrows: false,
    className: styles["slick-list"],
    afterChange: (index) => setCurrentIndex(index),
  };

  return (
    <div className={styles.images}>
      <Slider {...settings} ref={ref}>
        {judgesInfo?.map((judge) => (
          <Picture
            variant={styles.judgeImg}
            fallback={judge?.imgSrc}
            alt={judge?.name}
            key={judge?.name}
            loaderStyles={`${styles.loaderStyle} ${styles.judgeImg}`}
          />
        ))}
      </Slider>
      <div className={styles.customScrollbar}>
        <span className={styles.scrollbarText}>
          Judge {currentIndex + 1} of 4
        </span>
        <div className={styles.track}>
          <div
            className={styles.thumb}
            style={{ height: `${(currentIndex + 1) * 25}%` }}
          />
        </div>
      </div>
    </div>
  );
});

ImagesSlider.displayName = "ImagesSlider";
export default ImagesSlider;
ImagesSlider.propTypes = {
  setCurrentIndex: PropTypes.func,
  currentIndex: PropTypes.number,
};
