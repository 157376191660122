import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import CategoryRow from "./CategoryRow";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import CategoriesModal from "./CategoriesModal";
import Backdrop from "../../GlobalComponents/UI/Backdrop/Backdrop";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";

import getCategory from "../../../graphql/queries/businessOnboarding/getCategory";
import updateBusinessOnboarding from "../../../graphql/mutations/updateBusiness";
import parentStyles from "../../GlobalComponents/Authentication/SignUpForm/SignUpForm.module.css";
import styles from "../BusinessOnboarding.module.css";
import {
  BusinessOnboardingState,
  CATEGORIES_CHILDREN_PAGE_SIZE,
} from "../../../constants";
import { savingBizInfoState } from "../../../utils/cache";

export default function BusinessCategories({
  nextStepHandler,
  insertBusinessData,
  businessData,
}) {
  const businessId = businessData?.id;
  const selectedCategories = businessData?.categories ?? [];
  const [businessCategories, setBusinessCategories] = useState([]);
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
  const [categoriesIDs, setCategoriesIds] = useState([]);

  const [getCategoryById, { fetchMore, loading: categoryLoading }] =
    useLazyQuery(getCategory);
  const [updateBusiness, { loading: businessLoading }] = useMutation(
    updateBusinessOnboarding
  );

  const categoriesLength = businessCategories ? businessCategories?.length : 0;
  const isLoading = businessLoading || categoryLoading;
  const addButtons = Array(3 - categoriesLength).fill("add");

  useEffect(() => {
    setBusinessCategories(
      selectedCategories.filter((category) => category.parent === null)
    );
    const newIds = selectedCategories.map((category) => category.id);
    setCategoriesIds(newIds);
  }, [selectedCategories]);

  const toggleCategoriesModal = () => {
    setOpenCategoriesModal(!openCategoriesModal);
  };

  const fetchMoreSubCategories = (page, mainCategoryId) => {
    fetchMore({
      variables: { id: mainCategoryId, page: page + 1, size: 6 },
    }).then(({ data }) => {
      const newChildren = data?.category?.children;
      let newCategories = businessCategories;
      newCategories = newCategories.map((category) => {
        if (category.id === mainCategoryId) {
          return {
            ...category,
            children: {
              ...category.children,
              paginatorInfo: newChildren?.paginatorInfo,
              data: [...category.children.data, ...newChildren.data],
            },
          };
        } else {
          return category;
        }
      });
      setBusinessCategories(newCategories);
    });
  };

  const handleSelectSubCategory = (id) => {
    if (!categoriesIDs?.includes(id)) {
      setCategoriesIds([...categoriesIDs, id]);
    } else {
      const newIds = categoriesIDs.filter((categoryId) => categoryId !== id);
      setCategoriesIds(newIds);
    }
  };

  const handleAddCategory = (data) => {
    setCategoriesIds([...categoriesIDs, ...data.businessCategoriesIds]);
    const newBusinessCategories = [
      ...businessCategories,
      ...data.businessCategories,
    ];
    setBusinessCategories(newBusinessCategories);
  };

  const handleDeleteCategory = (categoryId) => {
    const newCategories = businessCategories.filter(
      (category) => category.id !== categoryId
    );
    const newCategoriesIDs = categoriesIDs.filter((id) => id !== categoryId);
    setBusinessCategories(newCategories);
    setCategoriesIds(newCategoriesIDs);
  };
  const handleSave = () => {
    if (isLoading) return;
    savingBizInfoState({ loading: true, error: false });
    analytics.track("business_sub_categories");

    updateBusiness({
      variables: {
        id: businessId,
        categories: categoriesIDs,
        onboardingState: BusinessOnboardingState.aboutBusiness, // next step
      },
    })
      .then(({ data }) => {
        savingBizInfoState({ loading: false });

        insertBusinessData({ categories: data?.updateBusiness?.categories });
        nextStepHandler();
      })
      .catch(() => {
        savingBizInfoState({
          loading: false,
          error: true,
          retry: submitHandler,
        });
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Categories</h2>
        <p className={styles.description}>
          Let customers know what your business offers by selecting categories.
          This will help customers find your business, and help you generate
          leads. For best results be as accurate as possible
        </p>
      </div>
      <div>
        {businessCategories?.map((category) => (
          <CategoryRow
            category={category}
            key={category.id}
            fetchMoreSubCategories={fetchMoreSubCategories}
            handleSelectSubCategory={handleSelectSubCategory}
            categoriesIDs={categoriesIDs}
            handleDeleteCategory={handleDeleteCategory}
          />
        ))}
      </div>
      {addButtons.length !== 0 &&
        addButtons.map((btn, index) => (
          <div key={index} className={styles.addCategoryRow}>
            <button
              className={styles.addCategoryBtn}
              onClick={toggleCategoriesModal}
            >
              Add category
            </button>
          </div>
        ))}
      <div className={styles.buttonsWrapper}>
        <RegisterNextBtn
          variant={parentStyles.continueBtn}
          clickHandler={handleSave}
          disabled={businessCategories.length === 0}
          isLoading={isLoading}
        >
          {isLoading ? <Spinner /> : "SAVE & CONTINUE"}
        </RegisterNextBtn>
      </div>
      <Backdrop show={openCategoriesModal} clickHandler={toggleCategoriesModal}>
        <CategoriesModal
          toggleCategoriesModal={toggleCategoriesModal}
          handleAddCategory={handleAddCategory}
          chooseMultiple={false}
          selected={businessCategories.map((category) => category.name)}
        />
      </Backdrop>
    </div>
  );
}

BusinessCategories.propTypes = {
  nextStepHandler: PropTypes.func,
  insertBusinessData: PropTypes.func,
  businessData: PropTypes.object,
};
