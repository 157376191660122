import React, { useEffect, useState } from "react";
import styles from "./DirectionsCard.module.css";
import PropTypes from "prop-types";
import userIcon from "../../../assets/images/userLocation.svg";
import destinationIcon from "../../../assets/images/destination.svg";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import axios from "axios";
const DirectionsCard = (props) => {
  const { userLocation, destination, fetchDirections, status } = props;
  const [user, setUserLocation] = useState();
  const [location, setLocation] = useState();
  const [error, setError] = useState("");

  useEffect(() => {
    if (userLocation) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${userLocation?.lat},${userLocation?.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
        )
        .then(({ data }) => setUserLocation(data.results[0].formatted_address));
    }
    if (destination) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${destination?.lat},${destination?.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
        )
        .then(({ data }) => setLocation(data.results[0].formatted_address));
    }
    if (status === "ZERO_RESULTS") {
      setError("Can't fetch directions");
    }
  }, [userLocation, destination, status]);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.addressesContainer}>
        <h1 className={styles.title}>Get Directions</h1>
        {user && (
          <div className={styles.container}>
            <img src={userIcon} alt='user' className={styles.userIcon} />
            <p className={`${styles.address} ${styles.user}`}>{user}</p>
          </div>
        )}
        {location ? (
          <div className={styles.container}>
            <img
              src={destinationIcon}
              alt='destination'
              className={styles.destinationIcon}
            />

            <p className={styles.address}>{location}</p>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
      <p className={styles.errorMessage}>{error}</p>
      <RegisterNextBtn variant={styles.btn} clickHandler={fetchDirections}>
        GET DIRECTIONS
      </RegisterNextBtn>
    </div>
  );
};

export default DirectionsCard;
DirectionsCard.propTypes = {
  userLocation: PropTypes.object,
  destination: PropTypes.object,
  fetchDirections: PropTypes.func,
  status: PropTypes.string,
};
