import React, { useEffect, useState } from "react";
import styles from "./Pagination.module.css";
import arrow from "../../../../assets/images/paginatorArrow.svg";
import PropTypes from "prop-types";
const Pagination = (props) => {
  const { fetchHandler, restVariables, totalPages, callback } = props;
  const [selectedPage, setSelectedPage] = useState(1);
  const [pages, setPages] = useState([]);
  useEffect(() => {
    if (selectedPage > pages[pages.length - 1]) {
      const newPages = pages.map((page) => page + 1);
      setPages(newPages);
    }
    if (selectedPage < pages[0]) {
      const newPages = pages.map((page) => page - 1);
      setPages(newPages);
    }
    fetchHandler({ variables: { ...restVariables, page: selectedPage } });
    callback && callback();
  }, [selectedPage]);
  useEffect(() => {
    if (totalPages)
      setPages(Array.from({ length: totalPages }, (_, i) => i + 1));
  }, [totalPages]);

  const increment = () => {
    if (selectedPage < totalPages) setSelectedPage((prevPage) => prevPage + 1);
  };
  const decrement = () => {
    setSelectedPage((prevPage) => {
      if (prevPage === 1) {
        return 1;
      }
      return prevPage - 1;
    });
  };

  return (
    <div className={styles.pagination}>
      <button className={styles.btn} type='button' onClick={decrement}>
        <img src={arrow} className={styles.leftArrow} alt='arrow' />
      </button>
      {pages?.map((page, i) => (
        <h6
          key={i}
          className={`${styles.page} ${
            selectedPage === page ? styles.selected : undefined
          }`}
          onClick={setSelectedPage.bind(null, page)}
        >
          {page}
        </h6>
      ))}
      <button className={styles.btn} type='button' onClick={increment}>
        <img src={arrow} className={styles.rightArrow} alt='arrow' />
      </button>
    </div>
  );
};

export default Pagination;
Pagination.propTypes = {
  fetchHandler: PropTypes.func,
  callback: PropTypes.func,
  restVariables: PropTypes.object,
  totalPages: PropTypes.number,
};
