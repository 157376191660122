import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import getUser from "../../../graphql/queries/getUser";
import getUsers from "../../../graphql/queries/getUsers";
import blockUserMutation from "../../../graphql/mutations/blockUser";
import unblockUserMutation from "../../../graphql/mutations/unblockUser";
import requestPwResetMutation from "../../../graphql/mutations/requestPwReset";
import updateUserMutation from "../../../graphql/mutations/updateUser";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import UserPicture from "../../GlobalComponents/UI/UserPicture/UserPicture";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import Input from "../../GlobalComponents/UI/Input/Input";
import {
  mapUserRole,
  getUserNames,
  errorToastHandler,
  successToastHandler,
} from "../../../utils";
import useInput from "../../../hooks/useInput";
import { USER_ROLES } from "../../../constants";
import {
  emailValidationRules,
  fullNameValidationRules,
  userPhoneValidation,
} from "../../../utils/validationRules";
import styles from "./UserProfile.module.css";

export default function UserProfile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [emailNetworkError, setEmailNetworkError] = useState("");
  const { data, loading: loadingUser } = useQuery(getUser, {
    variables: {
      id: id,
    },
  });

  const usersRefetchQuery = {
    query: getUsers,
    variables: {
      role: [USER_ROLES.BUSINESS_OWNER, USER_ROLES.USER],
      text: "",
    },
  };

  const userRefetchQuery = {
    query: getUser,
    variables: {
      id,
    },
  };

  const [blockUser, { loading: loadingBlockUser }] = useMutation(
    blockUserMutation,
    {
      refetchQueries: [usersRefetchQuery, userRefetchQuery],
    }
  );
  const [unblockUser, { loading: loadingUnblockUser }] = useMutation(
    unblockUserMutation,
    {
      refetchQueries: [usersRefetchQuery, userRefetchQuery],
    }
  );
  const [requestPwReset, { loading: loadingRequestPwReset }] = useMutation(
    requestPwResetMutation
  );

  const [updateUser, { loading: loadingUpdateUser }] = useMutation(
    updateUserMutation,
    {
      refetchQueries: [usersRefetchQuery, userRefetchQuery],
    }
  );

  const { user } = data ?? {};

  const {
    value: name,
    hasError: nameError,
    errorMessage: nameErrorMessage,
    valueChangeHandler: nameHandler,
    valueBlurHandler: nameBlurHandler,
    isValid: nameValid,
  } = useInput(fullNameValidationRules, `${user?.firstName} ${user?.lastName}`);

  const {
    value: email,
    hasError: emailError,
    errorMessage: emailErrorMessage,
    valueChangeHandler: emailHandler,
    valueBlurHandler: emailBlurHandler,
    isValid: emailValid,
  } = useInput(emailValidationRules, user?.email);

  const {
    value: phone,
    hasError: phoneError,
    errorMessage: phoneErrorMessage,
    valueChangeHandler: phoneHandler,
    valueBlurHandler: phoneBlurHandler,
    isValid: phoneValid,
  } = useInput(userPhoneValidation, user?.phone || "", null, true);

  const isSubmitting =
    loadingBlockUser ||
    loadingUnblockUser ||
    loadingRequestPwReset ||
    loadingUpdateUser;

  const isValidForm =
    phone.length > 0
      ? phoneValid && emailValid && nameValid
      : emailValid && nameValid;

  useEffect(() => {
    if (emailNetworkError.length) {
      setEmailNetworkError("");
    }
  }, [email]);

  const handleUserStatus = () => {
    if (user?.isBlocked) {
      unblockUser({
        variables: {
          id,
        },
      })
        .then(() => successToastHandler("UNBLOCKED SUCCESSFULLY "))
        .catch(() => errorToastHandler());
    } else {
      blockUser({
        variables: {
          id,
        },
      })
        .then(() => successToastHandler("BLOCKED SUCCESSFULLY"))
        .catch(() => errorToastHandler());
    }
  };

  const handleSendPassword = () => {
    requestPwReset({
      variables: {
        email: user?.email,
      },
    })
      .then(() => successToastHandler("PASSWORD SENT"))
      .catch(() => errorToastHandler());
  };

  const handleSaveChanges = () => {
    const { firstName, lastName } = getUserNames(name);
    updateUser({
      variables: {
        id,
        firstName,
        lastName,
        email,
        phone,
      },
    })
      .then(() => {
        successToastHandler("USER UPDATED");
        navigate("/admin");
      })
      .catch(() => {
        setEmailNetworkError(
          "This email might be already used please try another email"
        );
      });
  };

  if (loadingUser) {
    return <Spinner />;
  }
  return (
    <div className={styles.userProfileContainer}>
      <p
        className={styles.viewingText}
      >{`Viewing ${user?.firstName} ${user?.lastName}`}</p>
      <div className={styles.titleWrapper}>
        <AdminTitle title={mapUserRole(user?.roles[0])} />
        <UserPicture path={user?.avatar?.path} variant={styles.avatar} />
      </div>
      <div className={styles.section}>
        <div className={styles.group}>
          <Input
            hasLabel
            label='name'
            isRequired
            placeholder='e.g. Jamie Washington'
            type='text'
            inpContainerClass={styles.textField}
            value={name}
            changeHandler={nameHandler}
            blurHandler={nameBlurHandler}
            invalid={nameError}
            errorMessage={nameErrorMessage}
          />
        </div>
        <div className={styles.group}>
          <Input
            hasLabel
            label='Email'
            isRequired
            placeholder='e.g. jamie@obws.com'
            type='text'
            value={email}
            inpContainerClass={styles.textField}
            changeHandler={emailHandler}
            blurHandler={emailBlurHandler}
            invalid={emailError || emailNetworkError.length > 0}
            errorMessage={emailNetworkError || emailErrorMessage}
          />
        </div>
      </div>
      <div className={styles.section}>
        <AdminTitle title='Phone number' />
        <div className={styles.group}>
          <Input
            hasLabel
            label='Business phone number'
            placeholder='e.g. 555-555-5555'
            elementType='phone'
            isRequired
            inpContainerClass={styles.textField}
            value={phone}
            changeHandler={phoneHandler}
            blurHandler={phoneBlurHandler}
            invalid={phoneError}
            errorMessage={phoneErrorMessage}
          />
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <button
          className={`${styles.btn} ${styles.saveBtn}`}
          disabled={!isValidForm}
          onClick={handleSaveChanges}
        >
          Save Changes
        </button>
        <button
          className={`${styles.btn} ${styles.passwordBtn}`}
          onClick={handleSendPassword}
        >
          Send password
        </button>
        <button
          className={`${styles.btn} ${styles.removeBtn}`}
          onClick={handleUserStatus}
        >
          {user?.isBlocked ? `Unblock user` : `Block user`}
        </button>
        {isSubmitting && <Spinner />}
      </div>
    </div>
  );
}
