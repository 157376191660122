import { gql } from "@apollo/client";
export default gql`
  query BUSINESS(
    $id: ID
    $page: Int
    $collectionsPage: Int
    $categoriesChildrenPage: Int
    $categoriesChildrenSize: Int
  ) {
    business(id: $id) {
      id

      __typename
      collections(page: $collectionsPage) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          id
          name
        }
      }

      featuredImage {
        id
        path
      }
      randomImages {
        path
        id
      }
      website
      businessTypes
      workingHours {
        day
        open
        close
      }
      tags {
        name
      }
      name
      addrLine1
      addrLine2
      zip
      city {
        name
        id
      }
      state {
        name
        id
      }
      votes
      isVoted
      isBookmarked
      lat
      lng
      phone
      email
      facebook
      twitter
      instagram
      tiktok
      onboardingState
      desc
      aboutOwner
      categories {
        id
        name
        parent {
          id
        }
        children(page: $categoriesChildrenPage, size: $categoriesChildrenSize) {
          paginatorInfo {
            currentPage
            hasMorePages
          }
          data {
            id
            name
          }
        }
      }
      workingHours {
        day
        open
        close
      }
      representative {
        firstName
        lastName
        desc
        email
        id
        isNewUser
        roles {
          id
          permissions
          name
          label
        }
        stripeIdentity {
          firstName
          lastName
          number
          isVerified
          govImage {
            path
            id
          }
        }
        avatar {
          path
        }
      }
      comments(page: $page) {
        paginatorInfo {
          currentPage
          hasMorePages
        }
        data {
          id
          message
          createAt
          user {
            firstName
            lastName
            avatar {
              path
            }
          }
        }
      }
      aboutOwner
      desc
      ownerRole
      supplies {
        data {
          item {
            __typename
            ... on Product {
              id
              name
              desc
              price
              website
              randomImages {
                id
                path
              }
              votes
              isVoted
              isBookmarked
              specification
              collections(page: $collectionsPage) {
                paginatorInfo {
                  currentPage
                  hasMorePages
                }
                data {
                  id
                  name
                }
              }

              featuredImage {
                id
                path
              }
              business {
                name
                id
              }
              categories {
                id
                name
                parent {
                  id
                }
                children(
                  page: $categoriesChildrenPage
                  size: $categoriesChildrenSize
                ) {
                  data {
                    id
                    name
                  }
                }
              }
            }
            ... on Service {
              id
              name
              desc
              price
              website
              randomImages {
                id
                path
              }
              votes
              isVoted
              isBookmarked
              specification
              collections(page: $collectionsPage) {
                paginatorInfo {
                  currentPage
                  hasMorePages
                }
                data {
                  id
                  name
                }
              }

              featuredImage {
                id
                path
              }
              business {
                name
              }
              categories {
                id
                name
                parent {
                  id
                }
                children(
                  page: $categoriesChildrenPage
                  size: $categoriesChildrenSize
                ) {
                  data {
                    id
                    name
                  }
                }
              }
            }
            ... on Food {
              id
              name
              desc
              price
              linkToOrder
              ingredients
              randomImages {
                id
                path
              }
              votes
              isVoted
              isBookmarked
              collections(page: $collectionsPage) {
                paginatorInfo {
                  currentPage
                  hasMorePages
                }
                data {
                  id
                  name
                }
              }

              featuredImage {
                id
                path
              }
              business {
                name
              }
              categories {
                id
                name
                parent {
                  id
                }
                children(
                  page: $categoriesChildrenPage
                  size: $categoriesChildrenSize
                ) {
                  data {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
