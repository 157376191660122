import React, { useEffect, useState } from "react";
import ProductCarousel from "../../UI/ProductCarousel/ProductCarousel";
import styles from "./CarouselHeader.module.css";
import PropTypes from "prop-types";
import ProductInfo from "./ProductInfo/ProductInfo";
import Loader from "../../../Business/Header/Loader/Loader";
import EditBtns from "../../Buttons/EditBtns/EditBtns";
import HeaderBackBtn from "../../Admin/HeaderBackBtn";
import { useReactiveVar } from "@apollo/client";
import {
  consumerOnboardingVar,
  editView,
  isAdmin,
} from "../../../../utils/cache";
const CarouselHeader = (props) => {
  const { data, loading, isEdit } = props;
  const [images, setImages] = useState([]);
  const [itemType, setItemType] = useState("");
  const admin = useReactiveVar(isAdmin);
  const isEditing = useReactiveVar(editView);
  const onboardingStep = useReactiveVar(consumerOnboardingVar);

  useEffect(() => {
    if (data) {
      setImages([data?.featuredImage, ...data?.randomImages]);
      setItemType(data?.__typename?.toLowerCase());
    }
    if (loading) setImages([]);
  }, [data, loading]);
  return (
    <section
      className={`${styles.headerSection} ${
        isEdit ? styles.editHeader : styles.regHeader
      }
      ${
        onboardingStep === 4 || onboardingStep === 5
          ? styles.onboarding
          : styles.noOnboarding
      }`}
    >
      {!loading && isEdit && <EditBtns variant={styles.editBtn} />}
      {admin && !isEditing && <HeaderBackBtn isEdit backRoute={-1} />}

      <div className={styles.whiteBg}></div>

      {images.length <= 0 ? (
        <Loader />
      ) : (
        <div className={styles.wrapper}>
          <ProductCarousel
            carouselImages={images}
            website={data?.website || data?.linkToOrder}
            isEdit={isEdit}
            itemType={itemType}
          />
          <ProductInfo data={data} isEdit={isEdit} />
        </div>
      )}
    </section>
  );
};

export default CarouselHeader;
CarouselHeader.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
};
