import { gql } from "@apollo/client";
export default gql`
  mutation createService(
    $businessId: Int!
    $featuredImage: Int
    $name: String!
    $desc: String!
    $price: Float!
    $website: String!
    $gallery: [ID!]!
    $categories: [ID!]!
    $specification: String
  ) {
    createService(
      businessId: $businessId
      featuredImage: $featuredImage
      name: $name
      desc: $desc
      price: $price
      website: $website
      gallery: $gallery
      categories: $categories
      specification: $specification
    ) {
      id
    }
  }
`;
