import { gql } from "@apollo/client";

export default gql`
  mutation UpdateBusiness(
    $id: ID!
    $name: String
    $type: [BusinessType]
    $status: BusinessStatus
    $featuredImage: Int
    $state: Int
    $city: Int
    $gallery: [ID!]
    $features: [ID!]
    $categories: [ID!]
    $tags: [ID!]
    $phone: String
    $email: Email
    $zip: String
    $addrLine1: String
    $addrLine2: String
    $lat: Float
    $lng: Float
    $website: String
    $facebook: String
    $instagram: String
    $twitter: String
    $tiktok: String
    $desc: String
    $aboutOwner: String
    $govImageId: ID
    $workingHours: [WorkingHours]
    $onboardingState: BusinessOnboardingState
    $categoriesChildrenPage: Int
    $categoriesChildrenSize: Int
  ) {
    updateBusiness(
      id: $id
      name: $name
      type: $type
      status: $status
      featuredImage: $featuredImage
      state: $state
      city: $city
      gallery: $gallery
      features: $features
      categories: $categories
      tags: $tags
      phone: $phone
      email: $email
      zip: $zip
      addrLine1: $addrLine1
      addrLine2: $addrLine2
      lat: $lat
      lng: $lng
      website: $website
      facebook: $facebook
      instagram: $instagram
      twitter: $twitter
      tiktok: $tiktok
      desc: $desc
      aboutOwner: $aboutOwner
      govImageId: $govImageId
      workingHours: $workingHours
      onboardingState: $onboardingState
    ) {
      id
      featuredImage {
        id
        path
      }
      randomImages {
        path
        id
      }
      website
      businessTypes
      workingHours {
        day
        open
        close
      }
      name
      addrLine1
      addrLine2
      zip
      city {
        name
        id
      }
      state {
        name
        id
      }
      supplies {
        data {
          item {
            ... on Product {
              id
              name
              price
              featuredImage {
                id
                path
              }

              desc
              categories {
                id
                name
                parent {
                  id
                }
                children(
                  page: $categoriesChildrenPage
                  size: $categoriesChildrenSize
                ) {
                  data {
                    id
                    name
                  }
                }
              }

              specification
              randomImages {
                id
                path
              }

              website
            }
            ... on Service {
              id
              name
              price
              featuredImage {
                id
                path
              }

              desc
              categories {
                id
                name
                parent {
                  id
                }
                children(
                  page: $categoriesChildrenPage
                  size: $categoriesChildrenSize
                ) {
                  data {
                    id
                    name
                  }
                }
              }

              specification
              randomImages {
                id
                path
              }

              website
            }
            ... on Food {
              id
              name
              price
              featuredImage {
                id
                path
              }

              desc
              categories {
                id
                name
                parent {
                  id
                }
                children(
                  page: $categoriesChildrenPage
                  size: $categoriesChildrenSize
                ) {
                  data {
                    id
                    name
                  }
                }
              }

              ingredients
              randomImages {
                id
                path
              }

              linkToOrder
            }
          }
        }
      }

      lat
      lng
      phone
      email
      facebook
      twitter
      instagram
      tiktok
      onboardingState
      desc
      aboutOwner
      categories {
        id
        name
        parent {
          id
        }
        children {
          data {
            id
            name
          }
        }
      }
      aboutOwner
      desc
    }
  }
`;
