import React, { useState, useCallback, useRef } from "react";
import { useQuery } from "@apollo/client";
import getUsers from "../../graphql/queries/getUsers";
import RequestsSection from "../../components/AdminUserManagement/RequestsSection";
import UserManagementFilters from "../../components/AdminUserManagement/UserManagementFilters";
import UserManagementTable from "../../components/AdminUserManagement/UserManagementTable";
import { USER_ROLES, SORT_ORDER } from "../../constants";
import styles from "./AdminUserManagement.module.css";
import RowLoader from "../../components/GlobalComponents/UI/RowLoader/RowLoader";

const DEBOUNCE_VALUE = 1000;
export default function AdminUserManagement() {
  const [selectedUserType, setSelectedUserType] = useState(USER_ROLES.ALL);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASC);
  const [searchText, setSearchText] = useState("");
  const {
    fetchMore,
    loading: loadingUsers,
    data,
  } = useQuery(getUsers, {
    variables: {
      role: selectedUserType,
      sort: sortOrder,
      text: searchText,
    },
    notifyOnNetworkStatusChange: true,
  });
  const { data: users } = data?.users ?? {};
  const { currentPage, hasMorePages } = data?.users?.paginatorInfo ?? {};

  const observer = useRef();
  // for users pagination
  const itemsRef = useCallback(
    (node) => {
      if (loadingUsers) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePages) {
          fetchMore({
            variables: {
              page: currentPage + 1,
              role: selectedUserType,
            },
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMorePages, currentPage, loadingUsers, selectedUserType, sortOrder]
  );

  const handleUserTypeChange = (selectedType) =>
    setSelectedUserType(selectedType);
  const handleSort = () => {
    if (sortOrder === SORT_ORDER.ASC) {
      setSortOrder(SORT_ORDER.DESC);
    } else {
      setSortOrder(SORT_ORDER.ASC);
    }
  };

  const handleSearchChange = (e) => {
    if (searchText.length > 1) {
      setTimeout(() => {
        setSearchText(e.target.value);
      }, DEBOUNCE_VALUE);
    } else {
      setSearchText(e.target.value);
    }
  };

  return (
    <div className={styles.adminUserManagement}>
      <RequestsSection />
      <UserManagementFilters
        selectedFilterType={selectedUserType}
        handleUserTypeChange={handleUserTypeChange}
        searchText={searchText}
        handleSearchChange={handleSearchChange}
      />
      {users?.length > 0 && (
        <>
          <UserManagementTable
            users={users || []}
            handleSort={handleSort}
            sortOrder={sortOrder}
          />
          <div className={styles.paginatorRef} ref={itemsRef} />
        </>
      )}
      {loadingUsers && (
        <table className={styles.table}>
          <tbody>
            <RowLoader rows={5} />
          </tbody>
        </table>
      )}
    </div>
  );
}
