import React from "react";
import styles from "./BusinessPhone.module.css";
import PropTypes from "prop-types";
import { useReactiveVar } from "@apollo/client";
import { editView } from "../../../../../utils/cache";
import phoneIcon from "../../../../../assets/images/phone.png";
import EditTooltip from "../../../../GlobalComponents/UI/EditTooltip/EditTooltip";
import ApplyEditBtn from "../../../../GlobalComponents/Buttons/ApplyEditBtn/ApplyEditBtn";
import useInput from "../../../../../hooks/useInput";
import { businessPhoneValidation } from "../../../../../utils/validationRules";
import Input from "../../../../GlobalComponents/UI/Input/Input";
import useEditItem from "../../../../../hooks/useEditItem";
import { useParams } from "react-router-dom";

const BusinessPhone = (props) => {
  const { phone, showEditTooltip, editToggleHandler } = props;
  const {
    value: businessPhone,
    hasError: businessPhoneError,
    errorMessage: businessPhoneErrorMessage,
    valueChangeHandler: businessPhoneHandler,
    valueBlurHandler: businessPhoneBlurHandler,
    isValid: businessPhoneValid,
  } = useInput(
    businessPhoneValidation,
    phone?.indexOf("+") === -1 ? `+1${phone}` : phone,
    null,
    true
  );
  const isEditing = useReactiveVar(editView);

  const { id } = useParams();

  const { loading, editFormHandler } = useEditItem(id, {
    phone: businessPhone,
  });

  return (
    <div
      className={`${styles.phoneContainer} ${
        isEditing ? styles.edit : undefined
      }
      ${showEditTooltip === "phone" ? styles.highZ : undefined}`}
    >
      <p
        className={styles.phone}
        onClick={editToggleHandler.bind(null, "phone")}
      >
        {phone}
      </p>
      <img
        src={phoneIcon}
        alt='phone'
        onClick={editToggleHandler.bind(null, "phone")}
        className={styles.icon}
      />
      <EditTooltip
        show={showEditTooltip === "phone" && isEditing}
        variant={styles.tooltip}
        anchorStyle='top'
        closeHandler={editToggleHandler.bind(null, "")}
      >
        <form onSubmit={editFormHandler}>
          <Input
            hasLabel
            label='BUSINESS PHONE NUMBER'
            placeholder='e.g. 555-555-5555'
            elementType='phone'
            inpContainerClass={styles.inpContainer}
            value={businessPhone}
            changeHandler={businessPhoneHandler}
            blurHandler={businessPhoneBlurHandler}
            invalid={businessPhoneError}
            errorMessage={businessPhoneErrorMessage}
          />

          <ApplyEditBtn
            disable={!businessPhoneValid || businessPhone === phone}
            loading={loading}
          />
        </form>
      </EditTooltip>
    </div>
  );
};

export default BusinessPhone;
BusinessPhone.propTypes = {
  phone: PropTypes.string,
  showEditTooltip: PropTypes.string,
  editToggleHandler: PropTypes.func,
};
