import React from "react";
import styles from "./NextBtn.module.css";
import PropTypes from "prop-types";
const NextBtn = (props) => {
  const { className, style, onClick, variant } = props;

  return (
    <div
      className={`${className} ${styles.nextBtn} ${variant}`}
      style={{
        ...style,
      }}
      onClick={onClick}
    >
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.43982 10.6602L18.5238 10.6602M18.5238 10.6602L9.46172 20.5242M18.5238 10.6602L9.46173 0.796171"
          stroke="#0A0A0A"
          strokeWidth="0.834871"
        />
      </svg>
    </div>
  );
};

export default NextBtn;
NextBtn.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.func,
};
