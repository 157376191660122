import { useEffect, useState } from "react";
import { inputValidation } from "../utils/inputValidation";

const useInput = (
  validateRules = null,
  initialValue = null,
  matchValue = null,
  directHandling = false
) => {
  const [value, setValue] = useState("");
  const [touched, setTouched] = useState(false);
  const valueIsValid = inputValidation(
    { ...validateRules, matchValue },
    value
  )?.isValid;
  const hasError = !valueIsValid && touched;
  const errorMessage = inputValidation(
    { ...validateRules, matchValue },
    value
  )?.errorMessage;

  useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);

  const valueChangeHandler = (e) => {
    if (e !== null && e !== undefined)
      setValue(directHandling ? e : e.target.value);
    else setValue("");
  };

  const valueBlurHandler = (e) => {
    setTouched(true);
  };

  const resetInputHandler = () => setValue("");

  return {
    value,
    hasError,
    errorMessage,
    valueChangeHandler,
    valueBlurHandler,
    isValid: valueIsValid,
    resetHandler: resetInputHandler,
  };
};
export default useInput;
