import { useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CloseIconBtn from "../../components/GlobalComponents/Buttons/CloseIconBtn/CloseIconBtn";
import AboutBusiness from "../../components/GlobalComponents/Sections/AboutBusiness/AboutBusiness";
import AboutSection from "../../components/GlobalComponents/Sections/AboutSection/AboutSection";
import CarouselHeader from "../../components/GlobalComponents/Sections/CarouselHeader/CarouselHeader";
import Comments from "../../components/GlobalComponents/Sections/Comments/Comments";
import Backdrop from "../../components/GlobalComponents/UI/Backdrop/Backdrop";
import service from "../../graphql/queries/pages/service";
import { editView, isEdit } from "../../utils/cache";
import styles from "./EditService.module.css";
const EditService = () => {
  const { id } = useParams();

  const { data, loading, error, fetchMore } = useQuery(service, {
    variables: { id },
  });
  const isEditing = useReactiveVar(editView);
  useEffect(() => {
    isEdit(true);
    return () => {
      isEdit(false);
      editView(false);
    };
  }, []);
  const toggleEditHandler = () => {
    editView(!isEditing);
  };

  return (
    <div className={styles.editService}>
      <Backdrop show={isEditing} noPortal={true}>
        <CloseIconBtn
          clickHandler={toggleEditHandler}
          variant={styles.closeBtn}
        />
      </Backdrop>

      <div className={styles.page}>
        <CarouselHeader data={data?.service} loading={loading} isEdit />
        <div className={styles.middleContainer}>
          <div className={styles.aboutOwner}>
            <AboutSection
              data={data?.service}
              loading={loading}
              error={error}
              isEdit
              variant={styles.aboutSection}
              thirdParagraph='Shipping + pricing'
            />
          </div>
          <div className={styles.rightContainer}>
            <AboutBusiness
              data={data?.service}
              loading={loading}
              error={error}
            />
            <Comments
              data={data?.service}
              variant={styles.comments}
              renderLoading={loading}
              renderError={error}
              fetchMore={fetchMore}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditService;
