import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Input from "../../GlobalComponents/UI/Input/Input";
import dividerIcon from "../../../assets/images/divider.svg";
import styles from "./BusinessHoursDay.module.css";
import { convertFloatToMoment } from "../../../utils/formatters";
import timeArrow from "../../../assets/images/timeArrow.svg";
import closeIcon from "../../../assets/images/close.svg";
export default function BusinessHoursDay({
  day,
  handleAvailabilityChange,
  handleHoursChange,
  showDayError,
  isEcommerce,
}) {
  const [errorMessage, setErrorMessage] = useState("");

  const validateDay = () => {
    if (!day.open && !day.closed && (day.from === null || day.to === null)) {
      setErrorMessage("Please select the business hours.");
    } else if (day.from !== null || day.to !== null) {
      if (day.from >= day.to) {
        setErrorMessage("Please make sure that close time is after opening.");
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
    }
  };
  useEffect(() => {
    validateDay();
  }, [day, isEcommerce]);

  return (
    <div className={styles.brdrContainer}>
      <div className={styles.dayContainer}>
        <p className={styles.dayName}>{day.dayName}</p>
        <div className={styles.fieldsWrapper}>
          <Input
            placeholder='OPENS AT'
            elementType='time'
            inpContainerClass={styles.inpContainer}
            className={styles.intervalsField}
            changeHandler={(value) => {
              handleHoursChange("from", day.dayName, value);
            }}
            value={day.from ? convertFloatToMoment(day.from) : null}
            clearIcon={<img src={closeIcon} className={styles.close} />}
            inputIcon={
              !day?.from ? (
                <img src={timeArrow} className={styles.arrow} />
              ) : null
            }
          />
          <img src={dividerIcon} alt='divider' />
          <Input
            placeholder='CLOSE AT'
            elementType='time'
            inpContainerClass={styles.inpContainer}
            className={styles.intervalsField}
            changeHandler={(value) =>
              handleHoursChange("to", day.dayName, value)
            }
            value={day?.to ? convertFloatToMoment(day.to) : null}
            clearIcon={<img src={closeIcon} className={styles.close} />}
            inputIcon={
              !day?.to ? <img src={timeArrow} className={styles.arrow} /> : null
            }
          />
        </div>
        <div className={styles.fieldsWrapper}>
          <Input
            elementType='radio'
            hasLabel
            label='Open 24 hours'
            inpContainerClass={`${styles.checkboxContainer} ${styles.firstCheckboxContainer}`}
            checked={day.open}
            changeHandler={() =>
              handleAvailabilityChange("open", day.open, day.dayName)
            }
            name={day.dayName}
            id={day.dayName}
            customCheckBox={styles.checkMark}
          />

          <Input
            elementType='radio'
            hasLabel
            label='Closed'
            inpContainerClass={styles.checkboxContainer}
            name={day.dayName}
            checked={day.closed}
            id={day.dayName}
            changeHandler={() =>
              handleAvailabilityChange("closed", day.closed, day.dayName)
            }
            customCheckBox={styles.checkMark}
          />
        </div>
      </div>
      {showDayError && errorMessage.length > 0 && (
        <p className={styles.error}>{errorMessage}</p>
      )}
    </div>
  );
}

BusinessHoursDay.propTypes = {
  day: PropTypes.object,
  handleAvailabilityChange: PropTypes.func,
  handleHoursChange: PropTypes.func,
  isEcommerce: PropTypes.bool,
  showDayError: PropTypes.bool,
};
