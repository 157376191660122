import React from "react";
import styles from "./UpdateBtn.module.css";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const UpdateBtn = (props) => {
  const { link } = props;

  return (
    <NavLink to={link} className={styles.updateBtn}>
      UPDATE
    </NavLink>
  );
};

export default UpdateBtn;
UpdateBtn.propTypes = {
  link: PropTypes.string,
};
