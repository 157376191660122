import React from "react";
import PropTypes from "prop-types";
import placeholder from "../../../../assets/images/profilePlaceholder.png";

const UserPicture = (props) => {
  const { path, variant, clickHandler } = props;

  if (path) {
    return (
      <img
        src={path}
        alt='user picture'
        className={variant}
        onClick={clickHandler}
      />
    );
  }

  return (
    <img
      src={placeholder}
      alt='user picture'
      className={variant}
      onClick={clickHandler}
    />
  );
};

export default UserPicture;
UserPicture.propTypes = {
  path: PropTypes.string,
  variant: PropTypes.string,
  clickHandler: PropTypes.func,
};
