import { useReactiveVar } from "@apollo/client";
import {
  authVar,
  brazeCustomAttributes,
  rolesVar,
  savedPath,
} from "../utils/cache";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { USER_TYPES } from "../constants";
import { checkUserRole } from "../utils";
import axios from "axios";

const useLogin = () => {
  const existingAttributes = useReactiveVar(brazeCustomAttributes);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const toPath = useReactiveVar(savedPath);

  // identify user for analytics

  const identifyUserHandler = async (userId) => {
    // update session count
    const newSessionCount = existingAttributes?.user_session_count + 1;

    // ip address
    axios.get("https://api.ipify.org?format=json").then(({ data }) => {
      // sesseion location
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          analytics.identify(userId, {
            Ip_Group: data?.ip,
            user_last_session_location: {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
            },
            user_session_count: newSessionCount,
          });
        },
        () => {
          analytics.identify(userId, {
            Ip_Group: data?.ip,
            user_session_count: newSessionCount,
          });
        },
        {
          enableHighAccuracy: true,
        }
      );
      analytics.identify(userId, {
        Ip_Group: data?.ip,
        user_session_count: newSessionCount,
      });
    });
  };

  const saveUserData = (data) => {
    cookies.set("auth-token", data.accessToken, {
      path: "/",
    });
    cookies.set("refresh-token", data.refreshToken, {
      path: "/",
    });
    cookies.set("user-id", data.user.id, {
      path: "/",
    });
    cookies.set("expired-at", new Date().getTime() + 1800000, {
      path: "/",
    });
    authVar(data.user);
    rolesVar(data.roles);
    identifyUserHandler(data?.user?.id);
  };

  const onSuccesHandler = (data) => {
    if (checkUserRole(data?.roles, USER_TYPES.business)) {
      if (data?.businesses?.data?.length === 0 && !data?.subscribed) {
        navigate("/select-tier");
      } else {
        navigate("/business-dashboard");
      }
    } else if (checkUserRole(data?.roles, USER_TYPES.admin)) {
      navigate("/admin");
    } else {
      if (toPath) {
        navigate(toPath);
      } else {
        navigate("/");
      }
    }
  };
  return [saveUserData, onSuccesHandler];
};
export default useLogin;
