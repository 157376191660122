import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { useSearchParams } from "react-router-dom";
import EditorSearchBar from "../../GlobalComponents/Layouts/SearchBar/EditorSearchBar/EditorSearchBar";
import PropTypes from "prop-types";
import { pageOptions } from "../../../constants";
import AdminTitle from "../../GlobalComponents/Admin/AdminTitle";
import HeaderBackBtn from "../../GlobalComponents/Admin/HeaderBackBtn";
import SelectPageInp from "../../GlobalComponents/Admin/SelectPageInp";
const Header = (props) => {
  const { addItem } = props;
  const [page, setPage] = useState("Discover");
  const [filter, setFilter] = useState(["PRODUCT", "SERVICE", "FOOD"]);
  const [pageParams, setPageParams] = useSearchParams();
  const pageName = pageParams?.get("p");
  const backRoute = pageOptions.find(
    (el) => el?.value === pageName
  )?.adminRoute;

  const pageChangeHandler = (e) => {
    setPage(e.target.value);
    const pageObj = pageOptions.find((el) => el?.value === e.target.value);
    setFilter(pageObj?.filter);
    setPageParams({ p: e.target.value }, { replace: true });
  };

  useEffect(() => {
    const pageObj = pageOptions.find((el) => el?.value === pageName);
    setPage(pageObj?.value);
    setFilter(pageObj?.filter);
  }, [pageParams]);
  useEffect(() => {
    if (!pageName) {
      setPageParams({ p: page });
    }
  }, []);
  return (
    <header className={styles.header}>
      <HeaderBackBtn backRoute={backRoute} />
      <div className={styles.secondRow}>
        <AdminTitle title='Editor’s Picks' />
        <EditorSearchBar
          inpContainer={styles.searchInp}
          placeholder='Search for any business, product, or service'
          isEditorSearch
          selectHandler={addItem}
          filters={filter}
        />
      </div>
      <SelectPageInp
        page={page}
        pageChangeHandler={pageChangeHandler}
        customStyle={styles?.select}
      />
    </header>
  );
};

export default Header;
Header.propTypes = {
  addItem: PropTypes.func,
};
