import React from "react";
import styles from "./Input.module.css";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-number-input";
import TimePicker from "rc-time-picker";
import PlacesAutocomplete from "react-places-autocomplete";
import { ReactComponent as CameraIcon } from "../../../../assets/images/camera.svg";
import searchIcon from "../../../../assets/images/searchIcon.svg";
import "rc-time-picker/assets/index.css";

const Input = (props) => {
  let inputElement = null;
  let errorMessage;
  if (props.invalid) {
    errorMessage = <p className={styles.errorMessage}>{props.errorMessage}</p>;
  }

  switch (props.elementType) {
    case "file":
      inputElement = (
        <label
          onDragEnter={props.handleDrag}
          onDrop={props.handleDrop}
          onDragOver={props.handleDrag}
          onDragLeave={props.handleDrag}
        >
          <input
            id='file-input'
            type={props.type}
            value={props.value}
            multiple={props.multiple}
            onChange={props.changeHandler}
            onClick={props.clickHandler}
            accept='image/*'
            disabled={props.disabled}
          />
          {props.isUploadProfile ? (
            props.fileLabel
          ) : (
            <div className={styles.multipleLabelContainer}>
              {props.fileLabel && <CameraIcon />}
              <label htmlFor='file-input'>{props.fileLabel}</label>
            </div>
          )}
        </label>
      );
      break;
    case "phone":
      inputElement = (
        <PhoneInput
          defaultCountry='US'
          placeholder={props.placeholder}
          value={props.value}
          className={`${props.className} ${props.invalid && styles.invalid}`}
          onBlur={props.blurHandler}
          onChange={props.changeHandler}
        />
      );
      break;
    case "checkbox":
      inputElement = (
        <>
          <input
            type='checkbox'
            value={props.checked}
            checked={props.checked}
            onChange={props.changeHandler}
            placeholder='test placeholder'
          />
          <span
            className={props.checked ? props.customCheckBox : undefined}
          ></span>
        </>
      );
      break;
    case "radio":
      inputElement = (
        <>
          <input
            type='radio'
            value={props.checked}
            checked={props.checked}
            onChange={props.changeHandler}
            placeholder='test placeholder'
            name={props.name}
            disabled={props.disabled}
          />
          <span
            className={props.checked ? props.customCheckBox : undefined}
          ></span>
        </>
      );
      break;
    case "time":
      inputElement = (
        <TimePicker
          showSecond={true}
          className={props.className}
          onChange={props.changeHandler}
          format='HH:mm:ss'
          defaultValue={props.defaultValue}
          use12Hours
          value={props?.value}
          inputReadOnly
          placeholder={props.placeholder}
          placement='bottomLeft'
          popupStyle={styles.timePopup}
          clearIcon={props.clearIcon}
          inputIcon={props.inputIcon}
        />
      );
      break;
    case "places":
      inputElement = (
        <PlacesAutocomplete
          value={props.value}
          searchOptions={{ componentRestrictions: { country: ["us", "ca"] } }}
          onChange={props.changeHandler}
          onSelect={props.selectHandler}
          onError={props.placesErrorHandler}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div>
              <input
                onBlur={props.blurHandler}
                {...getInputProps({
                  placeholder: props.placeholder ?? "Search Places ...",
                  className: `${props.className} ${
                    props.invalid && styles.invalid
                  }`,
                })}
              />
              {suggestions?.length > 0 && (
                <div className={`${props.placesContainerClass}`}>
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.placeId}
                      {...getSuggestionItemProps(suggestion, {
                        className: `${styles.suggestionItem} ${
                          suggestion.active
                            ? styles.activeSuggestion
                            : styles.notActiveSuggestion
                        }`,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      );
      break;
    case "select":
      inputElement = (
        <select
          defaultValue={props.defaultValue}
          value={props.value}
          onChange={props.changeHandler}
          className={`${props.className} ${props.invalid && styles.invalid}`}
          onBlur={props.blurHandler}
        >
          <option key={"0"} value='' disabled hidden>
            {props?.placeholder ?? "Select"}
          </option>
          {props?.options?.map((option) => (
            <option key={`${option?.id}${option?.label}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
      break;
    case "twoValuesSlider":
      inputElement = (
        <>
          <input
            type='range'
            min={props.minRange}
            max={props.maxRange}
            value={props.rangeMinValue}
            onChange={props.handleRangeMinChange}
            className={styles.z3}
            onMouseUp={props.mouseUpHandler}
          />
          <div
            className={styles.minRange}
            style={{
              left: `${
                ((props.rangeMinValue - props.minRange) /
                  (props.maxRange - props.minRange)) *
                  160 +
                5
              }px`,
            }}
          >
            {`${props.rangeMinValue}$`}
          </div>
          <input
            type='range'
            max={props.maxRange}
            min={props.minRange}
            value={props.rangeMaxValue}
            onChange={props.handleRangeMaxChange}
            className={styles.z4}
            onMouseUp={props.mouseUpHandler}
          />
          <div
            className={styles.maxRange}
            style={{
              left: `${
                ((props.rangeMaxValue - props.minRange) /
                  (props.maxRange - props.minRange)) *
                  160 +
                5
              }px`,
            }}
          >
            {`${props.rangeMaxValue}$`}
          </div>

          <div className={props.rangeSliderClass} />
        </>
      );
      break;
    case "textArea":
      inputElement = (
        <textarea
          type={props.type}
          value={props.value}
          onChange={props.changeHandler}
          className={`${props.className}  ${styles.textArea} ${
            props.invalid && styles.invalid
          }`}
          placeholder={props.placeholder}
          onBlur={props.blurHandler}
          onFocus={props.focusHandler}
          onKeyDown={props.keyDownHandler}
          maxLength={props.max}
          rows='4'
          cols='50'
        />
      );
      break;
    case "search":
      inputElement = (
        <>
          <img src={searchIcon} alt='search' className={styles.searchIcon} />
          <input
            type={props.type}
            value={props.value}
            onChange={props.changeHandler}
            className={`${props.className} ${props.invalid && styles.invalid}`}
            placeholder={props.placeholder}
            onBlur={props.blurHandler}
            onFocus={props.focusHandler}
            onKeyDown={props.keyDownHandler}
            maxLength={props.max}
          />
        </>
      );
      break;
    default:
      inputElement = (
        <input
          type={props.type}
          value={props.value}
          onChange={props.changeHandler}
          className={`${props.className} ${props.invalid && styles.invalid}`}
          placeholder={props.placeholder}
          onBlur={props.blurHandler}
          onFocus={props.focusHandler}
          onKeyDown={props.keyDownHandler}
          maxLength={props.max}
        />
      );
  }

  return (
    <div
      className={`${props.inpContainerClass} ${
        props.invalid && styles.invalid
      }`}
    >
      {props.hasLabel && (
        <label className={props.labelClass}>
          {props.label}
          {props.isRequired && " *"}
        </label>
      )}
      {inputElement}
      {props.children}
      {errorMessage}
    </div>
  );
};

export default Input;
Input.propTypes = {
  elementType: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  changeHandler: PropTypes.func,
  selectHandler: PropTypes.func,
  className: PropTypes.string,
  hasLabel: PropTypes.bool,
  isRequired: PropTypes.bool,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  inpContainerClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  options: PropTypes.arrayOf(PropTypes.object),
  blurHandler: PropTypes.func,
  focusHandler: PropTypes.func,
  keyDownHandler: PropTypes.func,
  fileLabel: PropTypes.string,
  handleDrag: PropTypes.func,
  handleDrop: PropTypes.func,
  checked: PropTypes.bool,
  multiple: PropTypes.bool,
  customCheckBox: PropTypes.string,
  max: PropTypes.number,
  name: PropTypes.any,
  id: PropTypes.string,
  radioName: PropTypes.any,
  rangeMinValue: PropTypes.number,
  rangeMaxValue: PropTypes.number,
  maxRange: PropTypes.number,
  minRange: PropTypes.number,
  rangeMinText: PropTypes.any,
  rangeMaxText: PropTypes.any,
  handleRangeMaxChange: PropTypes.func,
  handleRangeMinChange: PropTypes.func,
  rangeSliderClass: PropTypes.string,
  placesContainerClass: PropTypes.string,
  placesItemClass: PropTypes.string,
  placesErrorHandler: PropTypes.func,
  mouseUpHandler: PropTypes.func,
  disabled: PropTypes.bool,
  clearIcon: PropTypes.any,
  inputIcon: PropTypes.any,
  isUploadProfile: PropTypes.bool,
  clickHandler: PropTypes.func,
};
