import React, { useState } from "react";
import PropTypes from "prop-types";
import ImageShapes from "../../GlobalComponents/UI/ImageShapes/ImageShapes";
import styles from "./CommentRow.module.css";

const MAX_DISPLAY_LENGTH = 95;

export default function CommentRow({ comment }) {
  const [showMore, setShowMore] = useState(false);
  const isLongComment = comment?.message?.length >= MAX_DISPLAY_LENGTH;
  const toggleShowMore = () => setShowMore(!showMore);
  return (
    <div className={styles.commentRow}>
      <p className={styles.rowTitle}>{comment?.CommentOn?.name}</p>
      <div className={styles.imageContainer}>
        <ImageShapes
          imgSrc={comment?.CommentOn?.featuredImage?.path}
          variant={styles.image}
        />
        <div className={styles.commentWrapper}>
          <p
            className={`${styles.comment} ${
              isLongComment ? styles?.longComment : ""
            } ${showMore ? styles.showAllComment : ""}`}
          >
            {comment?.message}
          </p>
          {isLongComment && (
            <button onClick={toggleShowMore} className={styles.showMoreBtn}>
              {showMore ? "show less" : "show more"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
CommentRow.propTypes = {
  comment: PropTypes.object,
};
