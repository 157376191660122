import React from "react";
import Modal from "../Modal/Modal";
import styles from "./RegisterPopUp.module.css";
import closeIcons from "../../../../assets/images/close.svg";
import { NavLink, useLocation } from "react-router-dom";
import { savedPath } from "../../../../utils/cache";
import PropTypes from "prop-types";

const RegisterPopUp = (props) => {
  const { pathname, search, hash } = useLocation();
  const { closeBackdrop, heading, body } = props;

  const handleLoginSavedPath = () => savedPath(`${pathname}${hash}${search}`);
  return (
    <Modal variant={styles.popUp}>
      <header className={styles.popUpHeader}>
        <h6 className={styles.title}>
          {heading ? heading : "Sign up for more!"}
        </h6>
        <img
          src={closeIcons}
          className={styles.close}
          onClick={closeBackdrop}
          alt='close'
        />
      </header>
      <p className={styles.text}>
        {body
          ? body
          : "To have access to all of these amazing perks please feel free to sign in or sign up."}
      </p>
      <NavLink to='/login'>
        <button className={styles.login} onClick={handleLoginSavedPath}>
          LOGIN
        </button>
      </NavLink>
      <NavLink to='/consumer-register'>
        <button className={styles.signup}>SIGNUP</button>
      </NavLink>
    </Modal>
  );
};

export default RegisterPopUp;
RegisterPopUp.propTypes = {
  closeBackdrop: PropTypes.func,
  heading: PropTypes.string,
  body: PropTypes.string,
};
