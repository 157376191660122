import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Settings.module.css";
import useInput from "../../hooks/useInput";
import Input from "../../components/GlobalComponents/UI/Input/Input";
import { emailValidationRules } from "../../utils/validationRules";
const BusinessEmail = (props) => {
  const { existingEmail, updateVariables } = props;
  const {
    value: email,
    hasError: emailError,
    errorMessage: emailErrorMessage,
    valueChangeHandler: emailHandler,
    valueBlurHandler: emailBlurHandler,
    isValid: emailValid,
  } = useInput(emailValidationRules, existingEmail);
  useEffect(() => {
    updateVariables({
      email: email,
    });
  }, [email]);

  return (
    <Input
      hasLabel
      isRequired
      label='BUSINESS EMAIL'
      placeholder='e.g. yourname@gmail.com'
      type='email'
      inpContainerClass={`${styles.inpContainer} ${
        emailError && styles.invalid
      }`}
      value={email}
      changeHandler={emailHandler}
      blurHandler={emailBlurHandler}
      invalid={emailError}
      errorMessage={emailErrorMessage}
    />
  );
};

export default BusinessEmail;
BusinessEmail.propTypes = {
  existingEmail: PropTypes.string,
  updateVariables: PropTypes.func,
};
