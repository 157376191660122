import React, { useEffect, useRef } from "react";
import regStyles from "./EditorPicksSection.module.css";
import adminStyles from "./AdminStyles.module.css";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ImageShapes from "../../UI/ImageShapes/ImageShapes";
import UpVoteButton from "../../Buttons/UpVoteButton/UpVoteButton";
import AddToCollectionButton from "../../Buttons/AddToCollectionButton/AddToCollectionButton";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { BUSINESS_TYPES } from "../../../../constants";
import Tooltip from "../../UI/Tooltip/Tooltip";
import ConsumerOnboarding from "../../../Onboarding/Consumer/ConsumerOnboarding";
import editorPicksQuery from "../../../../graphql/queries/editorPicks";
import ErrorComponent from "../../UI/ErrorComponent/ErrorComponent";
import { consumerOnboardingVar, isAdmin } from "../../../../utils/cache";
import SectionLoader from "../../UI/SectionLoader/SectionLoader";
import UpdateBtn from "../../Buttons/UpdateBtn/UpdateBtn";

const EditorPicksSection = (props) => {
  const { pageName } = props;
  const { data, loading, error } = useQuery(editorPicksQuery, {
    variables: { page: pageName },
  });
  const { editorPicks } = data ?? {};
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  const navigate = useNavigate();
  const childRef = useRef();
  const onboardingStep = useReactiveVar(consumerOnboardingVar);
  const onBoardingItem = useRef();
  const isEcommerce = pageName === BUSINESS_TYPES.ecommerce;

  const handleNextStep = (pick, step) => {
    consumerOnboardingVar(step + 1);
    if (step === 3)
      navigate(`/${pick?.__typename?.toLowerCase()}/${+pick?.id}`);
  };

  useEffect(() => {
    if (onboardingStep === 2 && pageName === "Discover") {
      window.scrollTo({
        top: onBoardingItem?.current?.offsetTop,
        behavior: "smooth",
      });
    }
  }, [onboardingStep]);

  const itemPriceAddress = (item) => {
    if (item?.price) {
      return `$${item?.price?.toFixed(2)}`;
    } else if (!isEcommerce) {
      return `${item?.city?.name}, ${item?.state?.abbr}`;
    } else {
      return "";
    }
  };

  return (
    <section className={styles.editorPicksSection}>
      <div className={styles.editorPicksContainer}>
        <div className={styles.header}>
          <h3 className={styles.sectionTitle}>Editor’s Picks</h3>
          {admin && (
            <UpdateBtn link={`/admin-editor/editor-picks?p=${pageName}`} />
          )}
        </div>
        <div className={styles.picks}>
          {error && <ErrorComponent />}
          {loading && (
            <SectionLoader itemsNumber={pageName === "Discover" ? 8 : 4} />
          )}
          {editorPicks?.map((pick, i) => (
            <NavLink
              to={`/${pick?.__typename?.toLowerCase()}/${pick?.id}`}
              key={`${pick?.name}-${i}`}
              className={`${styles.pick} ${
                i === 0 &&
                (onboardingStep === 2 || onboardingStep === 3) &&
                styles.onBoarding
              }`}
              ref={i === 1 ? onBoardingItem : null}
            >
              <div className={styles.imgContainer}>
                <ImageShapes
                  variant={styles.pickImg}
                  imgSrc={pick?.featuredImage?.path}
                  alt={pick?.product}
                />
                <UpVoteButton
                  variant={styles.upVote}
                  voteCount={pick?.votes}
                  itemType={pick?.__typename?.toUpperCase()}
                  itemId={+pick?.id}
                  voted={pick?.isVoted}
                  item={pick}
                />
                <AddToCollectionButton
                  variant={styles.addToCollection}
                  addedToCollection={pick?.isBookmarked}
                  itemType={pick?.__typename?.toUpperCase()}
                  itemId={+pick?.id}
                  item={pick}
                />
                <Tooltip
                  show={
                    (onboardingStep === 2 || onboardingStep === 3) &&
                    pageName === "Discover" &&
                    i == 0
                  }
                  anchorVariant='top'
                  handleTooltipClose={(e) => childRef?.current?.handleClose(e)}
                  customClass={
                    onboardingStep === 2
                      ? styles.upVoteTooltip
                      : styles.addToCollectionTooltip
                  }
                >
                  <ConsumerOnboarding
                    ref={childRef}
                    onboardingStep={onboardingStep + 1}
                    heading={
                      onboardingStep === 2
                        ? "Upvote your favorites"
                        : "Add to your collection"
                    }
                    description={
                      onboardingStep === 2
                        ? "Upvote your favorite products, services, and businesses to help bring awareness to the business and curate your feed"
                        : "Add items and create custom collections, so you can never lose track of the things you come across."
                    }
                    handleNext={handleNextStep.bind(null, pick, onboardingStep)}
                  />
                </Tooltip>
              </div>
              {pick?.__typename?.toLowerCase() === "business" ? (
                <>
                  <p className={styles.business}>{pick?.name}</p>
                  <p className={styles.product}>
                    {pick?.categories?.map((cat, i) => {
                      if (i !== pick?.categories?.length - 1)
                        return `${cat.name}, `;
                      else return `${cat.name}`;
                    })}
                  </p>
                </>
              ) : (
                <>
                  <p className={styles.business}>{pick?.business?.name}</p>
                  <p className={styles.product}>{pick?.name}</p>
                </>
              )}
              <p className={styles.price}>{itemPriceAddress(pick)}</p>
            </NavLink>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EditorPicksSection;

EditorPicksSection.propTypes = {
  pageName: PropTypes.string,
};
