import React from "react";
import styles from "../SharedStyles.module.css";
import googleStore from "../../../../../assets/images/googlePlay.svg";
import appStore from "../../../../../assets/images/appStore.svg";
const PlayStores = () => {
  const stores = [
    {
      name: "google play",
      icon: googleStore,
      link: "https://play.google.com/store/apps/details?id=com.obws.stag",
    },
    {
      name: "app store",
      icon: appStore,
      link: "https://apps.apple.com/us/app/official-black-wall-street-2-0/id1623894546",
    },
  ];
  return (
    <div className={styles.container}>
      <span className={styles.containerTitle}>Get the OBWS app</span>
      <div className={styles.stores}>
        {stores.map((store) => (
          <div className={styles.storeContainer} key={store?.name}>
            <a href={store?.link} target='_blank' rel='noreferrer'>
              <img src={store?.icon} alt={store?.name} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayStores;
